import { getLang, i18n } from "i18n/localisation";
import {
  fetchCachedProperties,
  getLocalePropertyPrice,
  getPropertyDistrictByName,
  getPropertyGeoboundary,
  isPropertyFromDataSource,
  // getAverageTimeOnMarket,
  // getPropertyTimeOnMarket
} from "./properties";
import { getAllMapObjectsByType } from "db/mapStore";
import moment from "moment";
import { fetchAnalyticsRequest } from "api/analytics";

// Extract price calculations into separate utility functions
export const calculatePricePerSqm = (price, size) => {
  if (!price || !size || isNaN(price) || isNaN(size) || price <= 0 || size <= 0)
    return null;
  return parseFloat(price) / parseFloat(size);
};

const formatPriceDifference = (property, currentPrice, averagePrice) => {
  const difference = currentPrice - averagePrice;
  const prefix = difference > 0 ? "+" : "";
  return {
    value: `${prefix}${getLocalePropertyPrice(property, Math.round(difference))}`,
    positive: difference > 0,
    negative: difference < 0,
  };
};

const formatPriceDifferencePercentage = (currentPrice, averagePrice) => {
  const percentageDiff = ((currentPrice - averagePrice) / averagePrice) * 100;
  const prefix = percentageDiff > 0 ? "+" : "";
  return {
    value: `${prefix}${Math.round(percentageDiff)}%`,
    positive: percentageDiff > 0,
    negative: percentageDiff < 0,
  };
};

// Extract property filtering logic
const filterPropertiesByTypology = (properties, referenceProperty) => {
  // flat without a subtypology must be compared against all flats
  if (
    referenceProperty.detailedType.typology == "flat" &&
    (
      referenceProperty.detailedType.subTypology == "" ||
      referenceProperty.detailedType.subTypology == "flat"
    ) &&
    referenceProperty.buildingType == "property"
  ) {
    return properties.filter(
      (p) => p.detailedType.typology == "flat" && p.buildingType == "property",
    );
  }

  // same goes for house without a subtypology
  if (
    referenceProperty.detailedType.typology == "house" &&
    referenceProperty.detailedType.subTypology == "" &&
    referenceProperty.detailedType.isVilla == false &&
    referenceProperty.buildingType == "property"
  ) {
    return properties.filter(
      (p) => p.detailedType.typology == "house" && p.buildingType == "property",
    );
  }

  // for villas we need to filter by isVilla and typology
  if (
    referenceProperty.detailedType.isVilla &&
    referenceProperty.buildingType == "property" &&
    referenceProperty.detailedType.typology == "house"
  ) {
    return properties.filter(
      (p) =>
        p.detailedType.isVilla == true &&
        p.detailedType.typology == "house" &&
        p.buildingType == "property",
    );
  }

  return properties.filter(
    (p) =>
      p.detailedType.typology == referenceProperty.detailedType.typology &&
      (p.detailedType.subTypology ?? "") ==
        (referenceProperty.detailedType.subTypology ?? "") &&
      (p.buildingType ?? "property") ==
        (referenceProperty.buildingType ?? "property") &&
      (p.detailedType.isVilla ?? false) ==
        (referenceProperty.detailedType.isVilla ?? false),
  );
};

const calculateStatistics = (
  property,
  currentProperty,
  mean,
  averagePrice,
  averageRentalYield,
  averageTimeOnMarket,
) => {
  if (property.saleType === "rent") {
    return calculateRentalStatistics(property, currentProperty, averagePrice);
  }

  return calculateSaleStatistics(
    property,
    currentProperty,
    mean,
    averagePrice,
    averageRentalYield,
    averageTimeOnMarket,
  );
};

const calculateSaleStatistics = (
  property,
  currentProperty,
  mean,
  averagePrice,
  averageRentalYield,
  averageTimeOnMarket,
) => {
  const statistics = [
    {
      label: i18n("Average price/m²:"),
      value: getLocalePropertyPrice(property, Math.round(mean)),
    },
    {
      label: i18n("Property price/m²:"),
      value: getLocalePropertyPrice(
        property,
        Math.round(parseFloat(property.price) / parseFloat(property.size)),
      ),
    },
    {
      ...formatPriceDifference(
        property,
        parseFloat(property.price) / parseFloat(property.size),
        mean,
      ),
      label: i18n("Price/m² difference:"),
    },
    {
      ...formatPriceDifferencePercentage(
        parseFloat(property.price) / parseFloat(property.size),
        mean,
      ),
      label: i18n("Price/m² difference %:"),
    },
    {
      label: i18n("Average price:"),
      value: getLocalePropertyPrice(property, Math.round(averagePrice)),
    },
    {
      ...formatPriceDifference(
        property,
        currentProperty.absolutePrice,
        averagePrice,
      ),
      label: i18n("Price difference:"),
    },
  ];

  if (averageRentalYield) {
    statistics.push({
      label: i18n("Average long term rental yield"),
      value: `${getLocalePropertyPrice(property, Math.round(averageRentalYield))} / ${i18n("month")}`,
    });
  }

  statistics.push({
    label: i18n("Average time on market"),
    value: `${averageTimeOnMarket} ${i18n("days")}`,
  });

  return statistics;
};

const calculateRentalStatistics = (property, currentProperty, averagePrice) => {
  const suffix = property.rental_isShortTerm
    ? i18n(" / day")
    : i18n(" / month");

  return [
    {
      label: i18n("Average price:"),
      value:
        getLocalePropertyPrice(property, Math.round(averagePrice)) + suffix,
    },
    {
      label: i18n("Property price:"),
      value: getLocalePropertyPrice(property, property.price) + suffix,
    },
    {
      ...formatPriceDifference(
        property,
        currentProperty.absolutePrice,
        averagePrice,
      ),
      label: i18n("Price difference:"),
    },
    {
      ...formatPriceDifferencePercentage(
        currentProperty.absolutePrice,
        averagePrice,
      ),
      label: i18n("Price difference %:"),
    },
  ];
};

const calculateBins = (prices, currentProperty, property, binCount = 16) => {
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const binSize = (maxPrice - minPrice) / binCount;

  return Array.from({ length: binCount }, (_, i) => {
    const lowerBound = minPrice + i * binSize;
    const upperBound = i === binCount - 1 ? maxPrice : lowerBound + binSize;

    const count = prices.filter((p) =>
      i === 0
        ? p >= lowerBound && p <= upperBound
        : i === binCount - 1
          ? p >= lowerBound && p <= upperBound
          : p >= lowerBound && p < upperBound,
    ).length;

    const isCurrentPropertyBin =
      property.saleType === "rent"
        ? currentProperty.absolutePrice >= lowerBound &&
          currentProperty.absolutePrice < upperBound
        : currentProperty.pricePerSqm >= lowerBound &&
          currentProperty.pricePerSqm <= upperBound;

    return {
      label: `${getLocalePropertyPrice(property, Math.round(lowerBound))} - ${getLocalePropertyPrice(property, Math.round(upperBound))}`,
      count,
      isCurrentPropertyBin,
    };
  });
};

const MIN_PROPERTIES_FOR_STATISTICS = 50;

// gets the properties for processing (we pass in the property and the district/zone/municipality)
// the optimal area will be returned along with the properties
// thanks to LRUCache, we cache the properties so this function can be called multiple times
export async function getPropertiesForProcessing(
  property,
  propertyDistrict,
  propertyZone,
  propertyMunicipality,
) {
  if (!propertyDistrict && !propertyZone)
    return {
      properties: null,
      rentalProperties: null,
      resolvedDistrict: null,
    };

  // Fetch and filter properties
  const [
    districtProperties,
    zoneProperties,
    municipalityProperties,
    districtRentalProperties,
    zoneRentalProperties,
    municipalityRentalProperties,
  ] = await Promise.all([
    fetchCachedProperties(
      propertyDistrict?.id ?? propertyZone?.id,
      property.saleType ?? "sale",
    ),
    fetchCachedProperties(propertyZone?.id, property.saleType ?? "sale"),
    fetchCachedProperties(
      propertyMunicipality?.id,
      property.saleType ?? "sale",
    ),
    fetchCachedProperties(propertyDistrict?.id ?? propertyZone?.id, "rent"),
    fetchCachedProperties(propertyZone?.id, "rent"),
    fetchCachedProperties(propertyMunicipality?.id, "rent"),
  ]);

  const filteredDistrictProperties = filterPropertiesByTypology(
    districtProperties,
    property,
  );
  const filteredZoneProperties = filterPropertiesByTypology(
    zoneProperties,
    property,
  );
  const filteredMunicipalityProperties = filterPropertiesByTypology(
    municipalityProperties,
    property,
  );
  const filteredDistrictRentalProperties = filterPropertiesByTypology(
    districtRentalProperties,
    property,
  );
  const filteredZoneRentalProperties = filterPropertiesByTypology(
    zoneRentalProperties,
    property,
  );
  const filteredMunicipalityRentalProperties = filterPropertiesByTypology(
    municipalityRentalProperties,
    property,
  );

  // Determine which properties to use based on count
  const useDistrict =
    filteredDistrictProperties.length >= MIN_PROPERTIES_FOR_STATISTICS;
  let properties = [];
  let rentalProperties = [];
  let resolvedDistrict = propertyDistrict;

  if (useDistrict) {
    properties = filteredDistrictProperties;
    rentalProperties = filteredDistrictRentalProperties;
  } else if (filteredZoneProperties.length >= MIN_PROPERTIES_FOR_STATISTICS) {
    properties = filteredZoneProperties;
    rentalProperties = filteredZoneRentalProperties;
    resolvedDistrict = propertyZone;
  } else if (
    filteredMunicipalityProperties.length >= MIN_PROPERTIES_FOR_STATISTICS
  ) {
    properties = filteredMunicipalityProperties;
    rentalProperties = filteredMunicipalityRentalProperties;
    resolvedDistrict = propertyMunicipality;
  }

  if (properties.length < MIN_PROPERTIES_FOR_STATISTICS)
    return {
      properties: null,
      rentalProperties: null,
      resolvedDistrict: null,
    };

  return {
    properties,
    rentalProperties,
    resolvedDistrict,
  };
}

// Add this utility function near the top with other utility functions
export const removeOutliers = (data, key = null) => {
  const values = key
    ? data.map((item) => parseFloat(item[key]))
    : data.map((v) => parseFloat(v));
  const sorted = values.sort((a, b) => a - b);

  const q1 = sorted[Math.floor(sorted.length * 0.25)];
  const q3 = sorted[Math.floor(sorted.length * 0.75)];
  const iqr = q3 - q1;
  const lowerBound = q1 - 1.5 * iqr;
  const upperBound = q3 + 1.5 * iqr;

  return key
    ? data.filter((item) => {
        const value = parseFloat(item[key]);
        return value >= lowerBound && value <= upperBound;
      })
    : data.filter((value) => {
        const v = parseFloat(value);
        return v >= lowerBound && v <= upperBound;
      });
};

// Gets an array of prices per sqm
// with the current property highlighted if needed for further processing
export const getPricesPerSqm = (properties, property) => {
  const pricesWithSqm = isPropertyFromDataSource(property, "airbnb")
    ? properties.map((p) => ({
        id: p.id,
        pricePerSqm: parseFloat(p.price),
        absolutePrice: parseFloat(p.price),
        size: 1,
        isCurrentProperty: property.id === p.id,
      }))
    : properties
        .map((p) => ({
          id: p.id,
          pricePerSqm: calculatePricePerSqm(parseFloat(p.price), parseFloat(p.size)),
          absolutePrice: parseFloat(p.price),
          size: parseFloat(p.size),
          isCurrentProperty: property.id === p.id,
        }))
        .filter((p) => p.price !== null);

  // Remove outliers but preserve current property
  return pricesWithSqm.filter((p) => p.pricePerSqm !== null);
};

// calculates the average rental yield for the properties
const calculateAverageRentalYield = (rentalProperties) => {
  return rentalProperties.length >= 5
    ? rentalProperties.reduce((a, b) => a + parseFloat(b.price), 0) /
        rentalProperties.length
    : null;
};

// Modify calculatePriceStatistics to work with cleaned data
// returns the average price per sqm and the average absolute price
// if rental then the average var returned in the averageAbsolutePrice
// if sale then the average var is the average price per sqm
export const calculatePriceStatistics = (pricesPerSqm, property) => {
  const prices =
    property.saleType === "rent"
      ? removeOutliers(pricesPerSqm.map((p) => p.absolutePrice))
      : removeOutliers(pricesPerSqm.map((p) => p.pricePerSqm));

  const average = prices.reduce((a, b) => a + b, 0) / prices.length;
  let cleanedOriginalPrices = removeOutliers(
    pricesPerSqm.map((p) => p.absolutePrice),
  );

  // we have already removed outliers for the absolute price for rentals
  // so reset the cleanedOriginalPrices
  if (property.saleType === "rent") {
    cleanedOriginalPrices = pricesPerSqm.map((p) => p.absolutePrice);
  }

  const averageAbsolutePrice =
    cleanedOriginalPrices.reduce((a, b) => a + b, 0) /
    cleanedOriginalPrices.length;
  return { average, averageAbsolutePrice };
};

// calculates the average time on market for the properties
export const calculateAverageTimeOnMarket = (properties) => {
  if (!properties || properties.length === 0) {
    console.log("No properties provided, returning 0");
    return 0;
  }

  const validTimeOnMarketData = properties
    .filter((p) => {
      const hasImportTime = !!p.initialImportTime;
      if (!hasImportTime) {
        console.log("Property missing import time:", p);
      }
      return hasImportTime;
    })
    .map((p) => {
      let importTime;
      try {
        // Convert Unix timestamp (in seconds) to milliseconds
        const timestampMs =
          typeof p.initialImportTime === "number"
            ? p.initialImportTime * 1000
            : Number(p.initialImportTime) * 1000;

        importTime = moment(timestampMs);

        if (!importTime.isValid()) {
          return NaN;
        }

        const days = moment().diff(importTime, "days");
        return days;
      } catch (err) {
        console.error("Error parsing import time:", err);
        return NaN;
      }
    })
    .filter((days) => {
      const isValid = !isNaN(days) && days >= 0 && days < 3650; // Max 10 years
      if (!isValid) {
      }
      return isValid;
    });

  if (validTimeOnMarketData.length === 0) {
    return 0;
  }

  const sum = validTimeOnMarketData.reduce((a, b) => a + b, 0);
  const average = Math.round(sum / validTimeOnMarketData.length);
  return average;
};

// gets the stats data for the property
export async function getStatsData(property, properties, rentalProperties) {
  if (!properties) return null;

  const pricesPerSqm = getPricesPerSqm(properties, property);

  // Create a current property price per sqm object for calculating diffs
  // and comparisons
  const currentProperty = {
    id: property.id,
    pricePerSqm: calculatePricePerSqm(property.price, property.size),
    absolutePrice: parseFloat(property.price),
    size: parseFloat(property.size),
    isCurrentProperty: true,
  };

  const { average, averageAbsolutePrice } = calculatePriceStatistics(
    pricesPerSqm,
    property,
  );
  const averageRentalYield = calculateAverageRentalYield(rentalProperties);
  const averageTimeOnMarket = calculateAverageTimeOnMarket(properties);

  return calculateStatistics(
    property,
    currentProperty,
    average,
    averageAbsolutePrice,
    averageRentalYield,
    averageTimeOnMarket,
  );
}

// Modify getPricePerSqmDistributionChartData to use cleaned data
// we need to pass in the property and the properties to calculate the chart data
// we need to pass in the current property to highlight it in the chart
export async function getPricePerSqmDistributionChartData(
  property,
  properties,
  cma = false,
) {
  if (!properties) return null;

  let filteredProperties =
    !cma && property
      ? filterPropertiesByTypology(properties, property)
      : properties;

  const pricesPerSqm = getPricesPerSqm(filteredProperties, property || {});
  let currentProperty = property
    ? pricesPerSqm.find((p) => p.isCurrentProperty)
    : null;

  // Clean the prices data
  const prices = removeOutliers(
    pricesPerSqm
      .map((p) => parseFloat(p.pricePerSqm))
      .filter((price) => !isNaN(price)),
  );

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRange = maxPrice - minPrice;
  const binSize = priceRange / 16;

  const bins = Array(16).fill(0);

  // Ensure bin index is between 0 and 15 inclusive
  const currentPropertyBin = currentProperty
    ? Math.max(
        0,
        Math.min(
          Math.floor(
            (parseFloat(currentProperty.pricePerSqm) - minPrice) / binSize,
          ),
          15,
        ),
      )
    : -1;

  // Count properties in each bin
  prices.forEach((p) => {
    const binIndex = Math.max(
      0,
      Math.min(Math.floor((p - minPrice) / binSize), 15),
    );
    bins[binIndex]++;
  });

  // Create price range labels
  const labels = Array(16)
    .fill()
    .map((_, i) => {
      const start = Math.round(minPrice + i * binSize);
      const end = Math.round(minPrice + (i + 1) * binSize);
      return `${getLocalePropertyPrice(property, start)} - ${getLocalePropertyPrice(property, end)}`;
    });

  const backgroundColor = Array(16).fill("#8884");
  if (property) {
    backgroundColor[currentPropertyBin] = "#ee3943";
  }

  return {
    chartData: {
      labels,
      datasets: [
        {
          data: bins,
          backgroundColor,
          borderColor: backgroundColor.map((c) =>
            c === "#8884" ? "#888" : "#ee3943",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: !!property,
          position: "top",
          labels: {
            generateLabels: () =>
              property
                ? [
                    {
                      text: i18n("Other Properties"),
                      fillStyle: "#8884",
                      strokeStyle: "#888",
                      lineWidth: 1,
                    },
                    {
                      text: i18n("Current Property"),
                      fillStyle: "#ee3943",
                      strokeStyle: "#ee3943",
                      lineWidth: 1,
                    },
                  ]
                : [],
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: { size: 14 },
          },
        },
        y: {
          ticks: { stepSize: 1 },
          title: {
            display: true,
            text: i18n("Number of Properties"),
            font: { size: 14 },
          },
        },
      },
    },
  };
}

// Modify getPriceDistributionChartData to use cleaned data
// we need to pass in the property and the properties to calculate the chart data
// we need to pass in the current property to highlight it in the chart
export async function getPriceDistributionChartData(
  property,
  properties,
  cma = false,
) {
  if (!properties?.length) return null;

  let filteredProperties =
    !cma && property
      ? filterPropertiesByTypology(properties, property)
      : properties;

  // Clean the prices data
  const prices = removeOutliers(
    filteredProperties
      .map((p) => parseFloat(p.price))
      .filter((price) => !isNaN(price)),
  );

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRange = maxPrice - minPrice;
  const binSize = priceRange / 16;

  const bins = Array(16).fill(0);

  // Ensure bin index is between 0 and 15 inclusive
  const currentPropertyBin = property
    ? Math.max(
        0,
        Math.min(
          Math.floor((parseFloat(property.price) - minPrice) / binSize),
          15,
        ),
      )
    : -1;

  // Count properties in each bin
  filteredProperties.forEach((p) => {
    const price = parseFloat(p.price);
    const binIndex = Math.max(
      0,
      Math.min(Math.floor((price - minPrice) / binSize), 15),
    );
    bins[binIndex]++;
  });

  // Create price range labels
  const labels = Array(16)
    .fill()
    .map((_, i) => {
      const start = Math.round(minPrice + i * binSize);
      const end = Math.round(minPrice + (i + 1) * binSize);
      return `${getLocalePropertyPrice(property, start)} - ${getLocalePropertyPrice(property, end)}`;
    });

  const backgroundColor = Array(16).fill("#8884");
  if (property) {
    backgroundColor[currentPropertyBin] = "#ee3943";
  }

  return {
    chartData: {
      labels,
      datasets: [
        {
          data: bins,
          backgroundColor,
          borderColor: backgroundColor.map((c) =>
            c === "#8884" ? "#888" : "#ee3943",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: !!property,
          position: "top",
          labels: {
            generateLabels: () =>
              property
                ? [
                    {
                      text: i18n("Other Properties"),
                      fillStyle: "#8884",
                      strokeStyle: "#888",
                      lineWidth: 1,
                    },
                    {
                      text: i18n("Current Property"),
                      fillStyle: "#ee3943",
                      strokeStyle: "#ee3943",
                      lineWidth: 1,
                    },
                  ]
                : [],
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: { size: 14 },
          },
        },
        y: {
          ticks: { stepSize: 1 },
          title: {
            display: true,
            text: i18n("Number of Properties"),
            font: { size: 14 },
          },
        },
      },
    },
  };
}

// we need to pass in the property and the properties to calculate the chart data
// we need to pass in the current property to highlight it in the chart
export async function getAverageTimeOnMarketChartData(
  property,
  properties,
  cma = false,
) {
  if (!properties?.length) return null;

  // Filter properties by same typology only if we have a reference property
  let filteredProperties;
  if (!cma) {
    filteredProperties = property
      ? filterPropertiesByTypology(properties, property)
      : properties;
  } else {
    filteredProperties = properties;
  }

  // Calculate price ranges
  const prices = removeOutliers(
    filteredProperties
      .map((p) => parseFloat(p.price))
      .filter((price) => !isNaN(price)), // Filter out invalid prices
  );

  if (prices.length === 0) return null;

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRange = maxPrice - minPrice;
  const binSize = priceRange / 16;

  // Initialize bins with arrays to store time on market values
  const timeBins = Array(16)
    .fill()
    .map(() => []);

  // Calculate current property bin index
  const currentPropertyBin = property
    ? Math.max(
        0,
        Math.min(
          Math.floor((parseFloat(property.price) - minPrice) / binSize),
          15,
        ),
      )
    : -1;

  // Sort properties into price bins and calculate their time on market
  filteredProperties.forEach((p) => {
    const price = parseFloat(p.price);
    if (isNaN(price)) return;

    const binIndex = Math.max(
      0,
      Math.min(Math.floor((price - minPrice) / binSize), 15),
    );

    if (isNaN(binIndex)) return;

    const importTime = parseFloat(p.initialImportTime);
    if (isNaN(importTime)) return;

    const timeOnMarket = moment().diff(moment.unix(importTime), "days");
    if (timeOnMarket < 0 || timeOnMarket > 3650) return; // Skip invalid times

    timeBins[binIndex].push(timeOnMarket);
  });

  // Calculate average time for each bin
  const averageTimes = timeBins.map((bin) =>
    bin.length ? Math.round(bin.reduce((a, b) => a + b, 0) / bin.length) : 0,
  );

  // Create price range labels
  const labels = Array(16)
    .fill()
    .map((_, i) => {
      const start = Math.round(minPrice + i * binSize);
      const end = Math.round(minPrice + (i + 1) * binSize);
      return `${getLocalePropertyPrice(property || {}, start)} - ${getLocalePropertyPrice(property || {}, end)}`;
    });

  const datasets = [
    {
      label: i18n("Average Days on Market"),
      data: averageTimes,
      backgroundColor: "#8884",
      borderColor: "#888",
      borderWidth: 1,
    },
  ];

  if (cma && property && currentPropertyBin >= 0) {
    // Check if there are other properties in the current bin
    const hasOtherPropertiesInBin = timeBins[currentPropertyBin]?.length > 0;

    if (hasOtherPropertiesInBin) {
      // Highlight the bin in red if there are other properties
      datasets[0].backgroundColor = Array(16)
        .fill()
        .map((_, i) => (i === currentPropertyBin ? "#ee3943" : "#8884"));
    }

    return {
      chartData: {
        labels,
        datasets,
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: i18n("Price Range"),
              font: { size: 14 },
            },
          },
          y: {
            ticks: { stepSize: 1 },
            title: {
              display: true,
              text: i18n("Average Days on Market"),
              font: { size: 14 },
            },
          },
        },
      },
    };
  }

  return {
    chartData: {
      labels,
      datasets: [
        {
          data: averageTimes,
          backgroundColor: averageTimes.map((_, i) =>
            property && i === currentPropertyBin ? "#ee3943" : "#8884",
          ),
          borderColor: averageTimes.map((_, i) =>
            property && i === currentPropertyBin ? "#ee3943" : "#888",
          ),
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: !!property,
          position: "top",
          labels: {
            generateLabels: () => {
              const labels = [
                {
                  text: i18n("Other Price Ranges"),
                  fillStyle: "#8884",
                  strokeStyle: "#888",
                  lineWidth: 1,
                },
              ];

              if (!cma) {
                labels.push({
                  text: i18n("Current Property's Price Range"),
                  fillStyle: "#ee3943",
                  strokeStyle: "#ee3943",
                  lineWidth: 1,
                });
              }

              return labels;
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Price Range"),
            font: { size: 14 },
          },
        },
        y: {
          ticks: { stepSize: 1 },
          title: {
            display: true,
            text: i18n("Average Days on Market"),
            font: { size: 14 },
          },
        },
      },
    },
  };
}

// Historic data function remains largely the same
export async function getAveragePricePerSqmHistoricChartData(areaId) {
  if (!areaId) return null;

  const results = await fetchAnalyticsRequest("av_price_per_sqm", areaId);
  if (!results?.length) return null;

  const sortedResults = results.sort(
    (a, b) => parseFloat(a.time_taken_from) - parseFloat(b.time_taken_from),
  );

  return {
    chartData: {
      labels: sortedResults.map((r) =>
        moment.unix(parseFloat(r.time_taken_from)).format("YYYY"),
      ),
      datasets: [
        {
          label: i18n("Average Price per m²"),
          data: sortedResults.map((r) => parseFloat(r.value)),
          fill: false,
          backgroundColor: "#ee3943",
          borderColor: "#ee3943",
          borderWidth: 2,
          pointRadius: 0,
        },
      ],
    },
    options: {
      scales: {
        x: { title: { display: true, text: i18n("Time") } },
        y: { title: { display: true, text: i18n("Price per m²") } },
      },
    },
  };
}

// Function to generate bedroom distribution chart data
export function getBedroomDistributionChartData(properties) {
  if (!properties || properties.length === 0) return null;

  // Filter out properties without bedroom data
  const validProperties = properties.filter(
    (property) => property.bedrooms && !isNaN(parseInt(property.bedrooms))
  );

  if (validProperties.length === 0) return null;

  // Count properties by number of bedrooms
  const bedroomCounts = {};
  validProperties.forEach((property) => {
    const bedrooms = parseInt(property.bedrooms);
    bedroomCounts[bedrooms] = (bedroomCounts[bedrooms] || 0) + 1;
  });

  // Sort by bedroom count and prepare data for chart
  const sortedBedrooms = Object.keys(bedroomCounts)
    .map(Number)
    .sort((a, b) => a - b);

  return {
    chartData: {
      labels: sortedBedrooms.map((count) => 
        count === 1 ? i18n("1 Bedroom") : i18n("{count} Bedrooms", { count })
      ),
      datasets: [
        {
          label: i18n("Number of Properties"),
          data: sortedBedrooms.map((count) => bedroomCounts[count]),
          backgroundColor: "#ee3943",
          borderColor: "#ee3943",
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              const value = context.raw;
              return value === 1 
                ? i18n("1 Property") 
                : i18n("{count} Properties", { count: value });
            }
          }
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: i18n("Number of Bedrooms"),
            font: { size: 14 },
          },
        },
        y: {
          beginAtZero: true,
          ticks: { 
            precision: 0,
            stepSize: 1 
          },
          title: {
            display: true,
            text: i18n("Number of Properties"),
            font: { size: 14 },
          },
        },
      },
    },
  };
}
