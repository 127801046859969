import fireApiRequest from "./core";

var FETCH_PLOTS_BY_BOUNDS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_plots_by_bounds";
var GET_PLOT_BY_REF_CAT_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_plot_by_refcat";
var FETCH_SUBPLOTS_BY_PARENT_PLOT_IDS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_subplots_by_parent_plot_ids";
var FETCH_FILTERED_SUBPLOTS_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/get_subplots_by_bounds";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_PLOTS_BY_BOUNDS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_plots_by_bounds";
  GET_PLOT_BY_REF_CAT_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_plot_by_refcat";
  FETCH_SUBPLOTS_BY_PARENT_PLOT_IDS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_subplots_by_parent_plot_ids";
  FETCH_FILTERED_SUBPLOTS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/get_subplots_by_bounds";
}

let abortController = new AbortController();

/**
 * fetches plots within a certain bounds from the server
 */
export async function fetchPlotsByBounds(bounds) {
  // If there's an ongoing fetch, abort it
  if (abortController) abortController.abort();

  // Create a new AbortController for the new fetch
  abortController = new AbortController();

  let plots = [];

  try {
    let data = await fireApiRequest(FETCH_PLOTS_BY_BOUNDS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ bounds }),
      signal: abortController.signal, // Pass the abort signal to the fetch request
    });

    plots = data.plots;
  } catch (e) {
    if (e.name === "AbortError") {
      console.log("Fetch aborted");
    } else {
      // Handle other errors
    }
  }

  return plots;
}
/**
 * fetches a plot by its refcat
 */
export async function getPlotByRefCat(refCat) {
  try {
    let data = await fireApiRequest(GET_PLOT_BY_REF_CAT_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ refcat: refCat }),
    });

    return data.plot;
  } catch (e) {
    console.log(e);
  }

  return null;
}

/**
 * fetches subplots by parent plot ids
 */
export async function fetchSubplotsByPlotId(plotId, filters) {
  if (filters && filters.propertyType) {
    filters = {
      ...filters,
      propertyType: filters.propertyType.id
    };
  }
  try {
    let data = await fireApiRequest(FETCH_FILTERED_SUBPLOTS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ parent_id: plotId, function: "get_subplots_by_parent", filters }),
    });

    return data.subplots;
  } catch (e) {
    console.log(e);
  }

  return [];
}

/**
 * Fetches filtered subplots based on bounds and filter criteria
 * 
 * @param {Object} bounds - Geographic bounds (min_lat, max_lat, min_lng, max_lng)
 * @param {Object} filters - Filter criteria (min_floor, max_floor, min_surface, etc.)
 * @returns {Array} Array of filtered subplot objects
 */
export async function fetchRefcatsByBounds(bounds, filters) {
  if (filters && filters.propertyType) {
    // Create a new filters object to avoid modifying the original
    filters = {
      ...filters,
      propertyType: filters.propertyType.id
    };
  }
  try {
    let data = await fireApiRequest(FETCH_FILTERED_SUBPLOTS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ 
        bounds,
        filters,
        function: "get_refcats_by_bounds",
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return [];
}



export async function fetchSubplotsByBounds(bounds, area, filters, lastEvaluatedKey = null) {

  if (filters && filters.propertyType) {
    filters = {
      ...filters,
      propertyType: filters.propertyType.id
    };
  }

  try {
    let data = await fireApiRequest(FETCH_FILTERED_SUBPLOTS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ 
        bounds,
        area,
        filters,
        function: "get_subplots_by_bounds",
        last_evaluated_key: lastEvaluatedKey
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return [];
}