import React, { useEffect, useState, useRef } from "react";
import { Page, Text, View, Document, Image, pdf } from "@react-pdf/renderer";
import {
  getLang,
  getLargeTextLocalisation,
  getLocalisation,
  i18n,
} from "i18n/localisation";
import { CORS_PROXY } from "config/constants";
import { getLocalePropertyPrice } from "utils/properties";
import { PDFDocument, degrees } from "pdf-lib";
import { PDF_STYLES } from "config/pdf";
import {
  getStatsData,
  getPriceDistributionChartData,
  getAverageTimeOnMarketChartData,
  getPricePerSqmDistributionChartData,
  getAveragePricePerSqmHistoricChartData,
  getPropertiesForProcessing,
} from "utils/stats";
import Chart from "chart.js/auto";
import { StyleSheet } from "@react-pdf/renderer";

import BedIcon from "../../assets/property/bed.png";
import ShowerIcon from "../../assets/property/shower.png";
import { capitalizeWord } from "utils/helpers";
import ValuationGraph from "../../assets/property/valuationGraph.png";
import widthIcon from "../../assets/property/InsightIcons/icon-park-outline_auto-width.png";
import averageIcon from "../../assets/property/InsightIcons/icon-park-outline_average.png";
import chartIcon from "../../assets/property/InsightIcons/icon-park-outline_chart-line.png";
import checkIcon from "../../assets/property/InsightIcons/icon-park-outline_check-one.png";
import timeIcon from "../../assets/property/InsightIcons/icon-park-outline_time.png";
import phoneIcon from "../../assets/property/phone.png";
import emailIcon from "../../assets/property/envelope-simple.png";

// import placeholder image
import placeholderImage from "../../assets/property/sold-properties-placeholder.png";

export const CmaDocument = (props) => {
  const propertyImageUrl =
    CORS_PROXY + encodeURIComponent(props.propertyImageUrl);
  const agencyLogoUrl = CORS_PROXY + encodeURIComponent(props.agencyLogoUrl);
  const lang = props.lang;
  const pieChartUrl =
    CORS_PROXY +
    encodeURIComponent(
      `https://dashboard.irealty.app/assets/cma/${lang}_pie_chart_spain_cma.png`,
    );

  let studyAreaText = props.studyAreas
    .filter((s) => s.metadata)
    .map((s) => s.metadata.name)
    .join(", ");

  if (studyAreaText.length === 0) {
    studyAreaText = getLocalisation("Custom area", lang);
  }

  const chunkArray = (array, chunkSize) => {
    return array.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!result[chunkIndex]) result[chunkIndex] = [];
      result[chunkIndex].push(item);
      return result;
    }, []);
  };

  // const propertiesForSale = props.selectedProperties.filter(p => p.saleType === 'sale');
  // const propertiesSold = props.selectedProperties.filter(p => p.saleType === 'sold');
  const allProperties = props.selectedProperties;

  const renderPropertyPages = (properties, lang) => {
    // Split into chunks of 4, but first page has 3 properties
    const firstPage = properties.slice(0, 3);
    const remainingProperties = properties.slice(3);
    const propertyChunks = [firstPage, ...chunkArray(remainingProperties, 4)];

    return propertyChunks.map((chunk, pageIndex) => (
      <Page key={pageIndex} style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          {/* Show title only on the first page */}
          {pageIndex === 0 && (
            <>
              <Text style={PDF_STYLES.title2}>
                {getLocalisation("Comparables", lang)}
              </Text>
              <View style={{ marginTop: 16 }} />
              <View
                style={{
                  backgroundColor: "#f5f7fa",
                  borderRadius: 8,
                  padding: 12,
                  marginBottom: 0,
                }}
              >
                <Text style={{ fontSize: 10, color: "#6c757d" }}>
                  {getLargeTextLocalisation("cma_pdf_comparables_text_1", lang)}
                </Text>
              </View>
            </>
          )}

          {chunk.map((property, index) => (
            <React.Fragment key={index}>
              <View
                style={{
                  marginBottom: 16,
                  height: 1,
                  backgroundColor: "#ddd",
                  marginTop: 16,
                }}
              />
              <View
                style={{
                  padding: 0,
                  flexDirection: "row",
                  alignItems: "flex-start",
                  backgroundColor: "#fff",
                }}
              >
                {/* Property Image */}
                <View
                  style={{
                    position: "relative",
                    width: 200,
                    height: 133.33,
                    marginRight: 16,
                  }}
                >
                  <Image
                    style={{
                      width: "100%",
                      height: "100%", 
                      minHeight: 133.33,
                      minWidth: 200,
                      borderRadius: 8,
                      objectFit: "cover",
                      zIndex: -2,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    src={
                      property.saleType === "sale" ||
                      property.saleType === "rent"
                        ? CORS_PROXY +
                          encodeURIComponent(property.multimedia.images[0].url)
                        : CORS_PROXY +
                          encodeURIComponent(
                            `https://dev-catastro-images.s3.amazonaws.com/${property.refcat}.jpg`
                          )
                    }
                    onError={(e) => {
                      e.target.src = placeholderImage;
                    }}
                  />
                  <Image
                    style={{
                      width: "100%",
                      height: "100%", 
                      minHeight: 133.33,
                      minWidth: 200,
                      borderRadius: 8,
                      objectFit: "cover",
                      zIndex: -1,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    src={placeholderImage}
                  />
                  <View
                    style={{
                      position: "absolute",
                      top: 8,
                      left: 8,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#333",
                        borderRadius: 10,
                        padding: "4px 8px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 8,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#fff",
                          fontWeight: "normal",
                        }}
                      >
                        {index +
                          1 +
                          (pageIndex === 0 ? 0 : (pageIndex - 1) * 4 + 3)}
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor:
                          property.saleType === "sale"
                            ? "#4CAF50"
                            : property.saleType === "rent"
                              ? "#4CAF50"
                              : "#CF4343",
                        borderRadius: 100,
                        padding: "4px 12px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#fff",
                          fontWeight: "500",
                        }}
                      >
                        {property.saleType === "sale" ? (
                          getLocalisation("Active", lang)
                        ) : property.saleType === "rent" ? (
                          getLocalisation("Active", lang)
                        ) : (
                          <>
                            <Text style={{ fontWeight: "bold" }}>
                              {getLocalisation("Sold: ", lang)}
                            </Text>
                            {getSoldText(property)}
                          </>
                        )}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{ fontWeight: "500", fontSize: 12, marginBottom: 8 }}
                  >
                    {getPropertyTitle(property)}
                  </Text>
                  {/* Price and Price per square meter */}
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#222",
                      marginBottom: 8,
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalePropertyPrice(property, property.price)}
                    {property.saleType === "rent" && (
                      <Text
                        style={{
                          color: "#222",
                          fontSize: 12,
                          fontWeight: "normal",
                        }}
                      >
                        {getLocalisation("/ month", lang)}
                      </Text>
                    )}
                    <Text
                      style={{
                        color: "#222",
                        fontSize: 12,
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      (€{Math.round(property.price / property.size)}/m²)
                    </Text>
                  </Text>

                  {/* Features */}
                  <View style={{ flexDirection: "row", marginBottom: 8 }}>
                    {property.saleType === "sale" ||
                      (property.saleType === "rent" && (
                        <>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              padding: "3px 8px",
                              borderRadius: 100,
                              marginRight: 8,
                            }}
                          >
                            <Image
                              style={{ width: 10, height: 10, marginRight: 4 }}
                              src={require("../../assets/property/bed.png")}
                            />
                            <Text
                              style={{
                                fontSize: 8.75,
                                color: "#222",
                                fontWeight: "400",
                              }}
                            >
                              {property.rooms || 0}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "#f5f5f5",
                              padding: "3px 8px",
                              borderRadius: 100,
                              marginRight: 8,
                            }}
                          >
                            <Image
                              style={{ width: 10, height: 10, marginRight: 4 }}
                              src={require("../../assets/property/shower.png")}
                            />
                            <Text
                              style={{
                                fontSize: 8.75,
                                color: "#222",
                                fontWeight: "400",
                              }}
                            >
                              {property.bathrooms || 0}
                            </Text>
                          </View>
                        </>
                      ))}
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: "3px 8px",
                        borderRadius: 100,
                        alignSelf: "flex-start",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8.75,
                          color: "#222",
                          fontWeight: "400",
                        }}
                      >
                        {property.size || 0}m²
                      </Text>
                    </View>
                  </View>

                  {/* Days on market & other details */}
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 1, marginRight: 8 }}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#717171",
                          marginBottom: 8,
                        }}
                      >
                        {getLocalisation("Year of Construction: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {property.yearConstructed || "-"}
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#717171",
                          marginBottom: 8,
                        }}
                      >
                        {getLocalisation("Days on the market: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {property.saleType === "sale"
                            ? getDaysOnTheMarketSale(property)
                            : property.saleType === "rent"
                              ? getDaysOnTheMarketSale(property)
                              : "-"}
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "#717171",
                          marginBottom: 8,
                        }}
                      >
                        {getLocalisation("Energy Certificate: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {property.saleType === "sale"
                            ? getEnergyCertificateText(property)
                            : property.saleType === "rent"
                              ? getEnergyCertificateText(property)
                              : "-"}
                        </Text>
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontSize: 10, color: "#717171" }}>
                        {getLocalisation("Features: ", lang)}
                        <Text style={{ fontWeight: "bold", color: "#222" }}>
                          {getFeaturesText(property) || "-"}
                        </Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </React.Fragment>
          ))}
        </View>
      </Page>
    ));
  };

  const renderSaleInfoPages = () => {
    return (
      <>
        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.title2}>
              {getLocalisation(
                "The 3 factors influencing the sale of your property",
                lang,
              )}
            </Text>
            <View style={PDF_STYLES.spacer} />
            <View style={PDF_STYLES.greyBox}>
              <Text style={PDF_STYLES.text2}>
                {getLargeTextLocalisation("cma_pdf_page_2_text_1", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>1</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation("The State of Conservation", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_2_text_2", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>2</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation("The Price", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_2_text_3", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>3</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation("Marketing", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_2_text_4", lang)}
            </Text>
            {agencyLogoUrl && (
              <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
            )}
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.title2}>
              {getLocalisation("Explanation of the report", lang)}
            </Text>
            <View style={PDF_STYLES.spacer} />
            <View style={PDF_STYLES.greyBox}>
              <Text style={PDF_STYLES.text2}>
                {getLargeTextLocalisation("cma_pdf_page_3_text_1", lang)}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.faintText}>
              {getLocalisation("This analysis includes:", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>1</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation(
                  "Similar properties for sale in your immediate area",
                  lang,
                )}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_3_text_2", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.row}>
              <View style={PDF_STYLES.circle}>
                <Text style={PDF_STYLES.numText}>2</Text>
              </View>
              <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                {getLocalisation(
                  "Properties that have not been sold for a long time",
                  lang,
                )}
              </Text>
            </View>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_3_text_3", lang)}
            </Text>
            <View style={PDF_STYLES.midSpacer} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_3_text_3", lang)}
            </Text>
            <View style={PDF_STYLES.midSpacer} />
            <View style={PDF_STYLES.divider} />
            {agencyLogoUrl && (
              <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
            )}
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation(
                "The valuation of your property is a balancing exercise",
                lang,
              )}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_4_text_1", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("The true market value of your property", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_4_text_2", lang)}
            </Text>
            <View style={PDF_STYLES.midSpacer} />
            <View style={PDF_STYLES.divider} />
            <View style={PDF_STYLES.midSpacer} />
            <Text style={{ ...PDF_STYLES.heading2, fontWeight: 700 }}>
              {getLocalisation("Main sources buyers use to find homes", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <View style={PDF_STYLES.spacer2} />
            <Image style={PDF_STYLES.propertyImage} src={pieChartUrl} />
            {agencyLogoUrl && (
              <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
            )}
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.title2}>
              {getLocalisation("Expenses after selling your property", lang)}
            </Text>
            <View style={PDF_STYLES.spacer} />
            <View style={PDF_STYLES.greyBox}>
              <Text style={PDF_STYLES.text2}>
                {getLargeTextLocalisation("cma_pdf_page_5_text_1", lang)}
              </Text>
            </View>

            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("For non-residents in Spain", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_5_text_2", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Taxes on your Income Tax Return", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {" "}
              • {getLocalisation("Profits up to 6.000€: 19%", lang)}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              • {getLocalisation("Profits between 6.000€ - 50.000€: 21%", lang)}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              •{" "}
              {getLocalisation("Profits between 50.000€ - 200.000€: 23%", lang)}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              •{" "}
              {getLocalisation(
                "Profits between 200.000€ - 300.000€: 27%",
                lang,
              )}
            </Text>
            <Text style={PDF_STYLES.text2}>
              {" "}
              • {getLocalisation("Profits over €300,000: 28%", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLocalisation(
                "These percentages are applied progressively.",
                lang,
              )}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Municipality Tax", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLocalisation("This is dependent on your municipality.", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Proprietors' Community", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_5_text_3", lang)}
            </Text>

            <View style={PDF_STYLES.spacer2} />
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("IBI and BASURA TAX", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_5_text_4", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            {agencyLogoUrl && (
              <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
            )}
          </View>
        </Page>

        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Energy Certificate", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_6_text_1", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />

            <Text style={{ ...PDF_STYLES.heading2 }}>
              {getLocalisation("Cancellation of Mortgage", lang)}
            </Text>
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLargeTextLocalisation("cma_pdf_page_6_text_2", lang)}
            </Text>
            {agencyLogoUrl && (
              <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
            )}
          </View>
        </Page>
      </>
    );
  };

  const renderMarketAnalysisPages = (property) => {
    const municipality = props.municipality || props.district;
    const district = props.municipality ? props.district : "";

    return (
      <>
        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <Text style={PDF_STYLES.title2}>
              {getLocalisation("Explanation of the report", lang)}
            </Text>
            {/* divider */}
            <View style={PDF_STYLES.divider} />
            <View style={PDF_STYLES.greyBox}>
              <Text style={PDF_STYLES.text2}>
                {getLocalisation("This property is located", lang) +
                  " " +
                  (municipality
                    ? getLocalisation("in the municipality of ", lang) +
                      municipality
                    : district
                      ? getLocalisation("in the district of ", lang) + district
                      : "")}
                {municipality && district
                  ? getLocalisation(
                      ", specifically in the district of ",
                      lang,
                    ) + district
                  : ""}
                {". " +
                  getLocalisation(
                    "We proceed to the comparison and data collection based on similar properties.",
                    lang,
                  )}
              </Text>
            </View>

            {/* spacer */}
            <View style={PDF_STYLES.spacer2} />
            <Text style={PDF_STYLES.text2}>
              {getLocalisation("This analysis includes:", lang)}
            </Text>

            {props.chartImages?.timeOnMarketImageUrl && (
              <>
                <View style={PDF_STYLES.spacer2} />
                <View style={PDF_STYLES.row}>
                  <View style={PDF_STYLES.circle}>
                    <Text style={PDF_STYLES.numText}>1</Text>
                  </View>
                  <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                    {getLocalisation("Time on Market", lang)}
                  </Text>
                </View>
                {/* <Text style={PDF_STYLES.heading2}>
                  1. {getLocalisation("Time on Market", lang)}
                </Text> */}
                <View style={PDF_STYLES.spacer} />
                <Text style={PDF_STYLES.text2}>
                  {`${getLocalisation("The average time for this type of property in this area is", lang)} ${props.averageDaysOnMarket}.`}
                </Text>
                <View style={PDF_STYLES.spacer} />
                <Text style={PDF_STYLES.heading3}>
                  {getLocalisation(
                    "Average Time on Market by Price Range",
                    lang,
                  )}
                </Text>
                <Image
                  source={props.chartImages.timeOnMarketImageUrl}
                  style={{
                    width: "80%",
                    marginVertical: 16,
                  }}
                  cache={true}
                />
              </>
            )}
            {agencyLogoUrl && (
              <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
            )}
          </View>
        </Page>

        {/* new page */}
        <Page style={PDF_STYLES.page}>
          <View style={PDF_STYLES.section}>
            <>
              <View style={PDF_STYLES.spacer2} />
              <View style={PDF_STYLES.row}>
                <View style={PDF_STYLES.circle}>
                  <Text style={PDF_STYLES.numText}>2</Text>
                </View>
                <Text style={{ ...PDF_STYLES.heading2, flex: 1 }}>
                  {getLocalisation("Market Analysis", lang)}
                </Text>
              </View>
              <View style={PDF_STYLES.spacer} />
              <Text style={PDF_STYLES.text2}>
                {`${getLocalisation("Analysis of unsold properties reveals pricing strategies that fail to attract ready buyers. Overpricing often results in sellers receiving less money than if they had set the right price from the outset. In the analysis of the ", lang)} ${props.district} ${getLocalisation("area, we obtain the following market data:", lang)}`}
              </Text>
            </>

            {props.chartImages?.pricePerSqmImageUrl && (
              <>
                <View style={PDF_STYLES.spacer2} />
                {/* <Text style={PDF_STYLES.heading2}>
                    {getLocalisation("Price per Square Meter Distribution", lang)}
                  </Text> */}
                <View style={PDF_STYLES.spacer} />
                <Text style={PDF_STYLES.heading3}>
                  {getLocalisation("Price per Square Meter Distribution", lang)}
                </Text>
                <Image
                  source={props.chartImages.pricePerSqmImageUrl}
                  style={{
                    width: "80%",
                    marginVertical: 16,
                  }}
                  cache={true}
                />
              </>
            )}

            {props.chartImages?.priceDistImageUrl && (
              <>
                <View style={PDF_STYLES.spacer2} />
                {/* <Text style={PDF_STYLES.heading2}>
                    {getLocalisation("Average Time on Market by Price Range", lang)}
                  </Text> */}
                <View style={PDF_STYLES.spacer} />
                <Text style={PDF_STYLES.heading3}>
                  {getLocalisation("Absolute Price Distribution", lang)}
                </Text>
                <Image
                  source={props.chartImages.priceDistImageUrl}
                  style={{
                    width: "80%",
                    marginVertical: 16,
                  }}
                  cache={true}
                />
              </>
            )}

            {agencyLogoUrl && (
              <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
            )}
          </View>
        </Page>
        {props.chartImages?.historicPriceImageUrl && property.buildingType === "property" && (
          <>
            <Page style={PDF_STYLES.page}>
              <View style={PDF_STYLES.section}>
                <View style={PDF_STYLES.spacer2} />
                <View style={PDF_STYLES.spacer} />
                <Text style={PDF_STYLES.heading3}>
                  {getLocalisation("Historic Price per Square Meter", lang)} {getLocalisation("in", lang)} {studyAreaText}
                </Text>
                <Image
                  source={props.chartImages.historicPriceImageUrl}
                  style={{
                    width: "80%",
                    marginVertical: 16,
                  }}
                  cache={true}
                />
                {agencyLogoUrl && (
                  <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
                )}
              </View>
            </Page>
          </>
        )}
      </>
    );
  };

  const renderRentalMarketAnalysisPages = () => {
    return (
      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={PDF_STYLES.title2}>
            {getLocalisation("Estimated Valuation", lang)}
          </Text>
          {/* divider */}
          <View style={PDF_STYLES.divider} />
          <View style={PDF_STYLES.spacer} />

          <View style={PDF_STYLES.valuationContainer}>
            <View style={PDF_STYLES.valuationRow}>
              <View style={PDF_STYLES.quickSale}>
                <Text style={PDF_STYLES.quickSaleLabel}>
                  ↓ {getLocalisation("Quick Rental", lang)}
                </Text>
                <Text style={PDF_STYLES.salePrice}>
                  €{Math.round(props.valuationValue * 0.92).toLocaleString()}
                </Text>
                <Text style={PDF_STYLES.pricePerSqm}>
                  €
                  {Math.round(
                    (props.valuationValue * 0.92) / props.property.size,
                  )}
                  /m²
                </Text>
              </View>

              <View style={PDF_STYLES.marketValue}>
                <Text style={PDF_STYLES.marketValueLabel}>
                  ✓ {getLocalisation("Market Value", lang)}
                </Text>
                <Text style={PDF_STYLES.marketValuePrice}>
                  €{Math.round(props.valuationValue).toLocaleString()}
                </Text>
                <Text style={PDF_STYLES.pricePerSqm}>
                  €{Math.round(props.valuationValue / props.property.size)}/m²
                </Text>
              </View>

              <View style={PDF_STYLES.highOffer}>
                <Text style={PDF_STYLES.highOfferLabel}>
                  ↑ {getLocalisation("High Rental", lang)}
                </Text>
                <Text style={PDF_STYLES.salePrice}>
                  €{Math.round(props.valuationValue * 1.15).toLocaleString()}
                </Text>
                <Text style={PDF_STYLES.pricePerSqm}>
                  €
                  {Math.round(
                    (props.valuationValue * 1.15) / props.property.size,
                  )}
                  /m²
                </Text>
              </View>
            </View>

            <View style={PDF_STYLES.distributionChart}>
              {/* image of assets/property/valuationGraph.png */}
              <Image
                src={ValuationGraph}
                style={PDF_STYLES.distributionChartImage}
              />
            </View>
          </View>

          <Text style={PDF_STYLES.heading2}>
            {getLocalisation("Analysis of similar properties", lang)}
          </Text>

          <View style={PDF_STYLES.statsGrid}>
            <View style={PDF_STYLES.statsBox}>
              <Image src={averageIcon} style={PDF_STYLES.statsIcon} />
              <Text style={PDF_STYLES.statsValue}>{props.averagePrice}</Text>
              <Text style={PDF_STYLES.statsLabel}>
                {getLocalisation("Average price of properties", lang)}
              </Text>
            </View>

            <View style={PDF_STYLES.statsBox}>
              <Image src={widthIcon} style={PDF_STYLES.statsIcon} />
              <Text style={PDF_STYLES.statsValue}>
                €{Math.round(props.priceRange.min).toLocaleString()} - €
                {Math.round(props.priceRange.max).toLocaleString()}
              </Text>
              <Text style={PDF_STYLES.statsLabel}>
                {getLocalisation("Price range of properties", lang)}
              </Text>
            </View>

            {props.chartImages?.priceDistImageUrl && (
              <>
                <View style={PDF_STYLES.spacer2} />
                <Image
                  source={props.chartImages.priceDistImageUrl}
                  style={{
                    width: "100%",
                  }}
                  cache={true}
                />
              </>
            )}
          </View>

          {agencyLogoUrl && (
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          )}
        </View>
      </Page>
    );
  };

  const renderMarketInsightsPage = () => {
    return (
      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={PDF_STYLES.title2}>
            {getLocalisation("Estimated Valuation", lang)}
          </Text>
          {/* divider */}
          <View style={PDF_STYLES.divider} />
          <View style={PDF_STYLES.spacer} />

          <View style={PDF_STYLES.valuationContainer}>
            <View style={PDF_STYLES.valuationRow}>
              <View style={PDF_STYLES.quickSale}>
                <Text style={PDF_STYLES.quickSaleLabel}>
                  ↓ {getLocalisation("Quick Sale", lang)}
                </Text>
                <Text style={PDF_STYLES.salePrice}>
                  €{Math.round(props.valuationValue * 0.92).toLocaleString()}
                </Text>
                <Text style={PDF_STYLES.pricePerSqm}>
                  €
                  {Math.round(
                    (props.valuationValue * 0.92) / props.property.size,
                  )}
                  /m²
                </Text>
              </View>

              <View style={PDF_STYLES.marketValue}>
                <Text style={PDF_STYLES.marketValueLabel}>
                  ✓ {getLocalisation("Market Value", lang)}
                </Text>
                <Text style={PDF_STYLES.marketValuePrice}>
                  €{Math.round(props.valuationValue).toLocaleString()}
                </Text>
                <Text style={PDF_STYLES.pricePerSqm}>
                  €{Math.round(props.valuationValue / props.property.size)}/m²
                </Text>
              </View>

              <View style={PDF_STYLES.highOffer}>
                <Text style={PDF_STYLES.highOfferLabel}>
                  ↑ {getLocalisation("High Offer", lang)}
                </Text>
                <Text style={PDF_STYLES.salePrice}>
                  €{Math.round(props.valuationValue * 1.15).toLocaleString()}
                </Text>
                <Text style={PDF_STYLES.pricePerSqm}>
                  €
                  {Math.round(
                    (props.valuationValue * 1.15) / props.property.size,
                  )}
                  /m²
                </Text>
              </View>
            </View>

            <View style={PDF_STYLES.distributionChart}>
              {/* image of assets/property/valuationGraph.png */}
              <Image
                src={ValuationGraph}
                style={PDF_STYLES.distributionChartImage}
              />
            </View>
          </View>

          <Text style={PDF_STYLES.heading2}>
            {getLocalisation("Analysis of similar properties", lang)}
          </Text>

          <View style={PDF_STYLES.statsGrid}>
            <View style={PDF_STYLES.statsBox}>
              <Image src={averageIcon} style={PDF_STYLES.statsIcon} />
              <Text style={PDF_STYLES.statsValue}>{props.averagePrice}</Text>
              <Text style={PDF_STYLES.statsLabel}>
                {getLocalisation("Average price of properties", lang)}
              </Text>
            </View>

            <View style={PDF_STYLES.statsBox}>
              <Image src={widthIcon} style={PDF_STYLES.statsIcon} />
              <Text style={PDF_STYLES.statsValue}>
                €{Math.round(props.priceRange.min).toLocaleString()} - €
                {Math.round(props.priceRange.max).toLocaleString()}
              </Text>
              <Text style={PDF_STYLES.statsLabel}>
                {getLocalisation("Price range of properties", lang)}
              </Text>
            </View>

            <View style={PDF_STYLES.statsBox}>
              <Image src={checkIcon} style={PDF_STYLES.statsIcon} />
              <Text style={PDF_STYLES.statsValue}>
                {props.averagePricePerSqm}
              </Text>
              <Text style={PDF_STYLES.statsLabel}>
                {getLocalisation(
                  "Average price per m² of active listings",
                  lang,
                )}
              </Text>
            </View>

            {props.averagePricePerSqmSold && (
              <View style={PDF_STYLES.statsBox}>
                <Image src={chartIcon} style={PDF_STYLES.statsIcon} />
                <Text style={PDF_STYLES.statsValue}>
                  {props.averagePricePerSqmSold}
              </Text>
              <Text style={PDF_STYLES.statsLabel}>
                {getLocalisation(
                  "Average price per m² of sold properties",
                  lang,
                )}
                </Text>
              </View>
            )}

            <View style={PDF_STYLES.statsBox}>
              <Image src={timeIcon} style={PDF_STYLES.statsIcon} />
              <Text style={PDF_STYLES.statsValue}>
                {props.averageDaysOnMarket}
              </Text>
              <Text style={PDF_STYLES.statsLabel}>
                {getLocalisation("Average days on the market", lang)}
              </Text>
            </View>

            <Text style={PDF_STYLES.footnote}>
              *
              {getLocalisation(
                "Properties included within this analysis are listed in the comparables section",
                lang,
              )}
            </Text>
          </View>

          {agencyLogoUrl && (
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          )}
        </View>
      </Page>
    );
  };

  // useEffect(() => {
  //   console.log('Chart images updated:', props.chartImages);
  //   console.log('Average price:', props.averagePrice);
  //   console.log('Price range:', props.priceRange);
  //   console.log('Average price per sqm:', props.averagePricePerSqm);
  //   console.log('Average days on market:', props.averageDaysOnMarket)
  //   console.log('Average price per sqm sold:', props.averagePricePerSqmSold);
  // }, [props.chartImages, props.averagePrice, props.priceRange, props.averagePricePerSqm, props.averageDaysOnMarket, props.averagePricePerSqmSold]);

  return (
    <Document>
      <Page style={PDF_STYLES.page}>
        <View style={{ ...PDF_STYLES.section, marginTop: 0, paddingTop: 0, marginBottom: 0, paddingBottom: 0 }}>
          <Text
            style={{ ...PDF_STYLES.title, fontSize: lang === "es" ? 34 : 40 }}
          >
            {getLocalisation("Property Valuation Report", lang)}
          </Text>
          <Text style={{ ...PDF_STYLES.heading, marginBottom: 12 }}>
            {props.clientName} {props.clientSurname}
          </Text>
          {(props.customImage || propertyImageUrl) && (
            <View style={{ position: 'relative', maxHeight: "45%", marginVertical: 10, overflow: 'hidden', borderRadius: 8 }}>
              {/* Background blurred image */}
              <Image
                style={{ 
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: "cover",
                  opacity: 0.3,
                  filter: "blur(8px)",
                  zIndex: -1,
                }}
                src={props.customImage ? props.customImage : propertyImageUrl}
              />
              {/* Main centered image */}
              <Image
                style={{ 
                  ...PDF_STYLES.propertyImage, 
                  width: '100%',
                  objectFit: "contain",
                  zIndex: 0,
                  position: 'relative',
                }}
                src={props.customImage ? props.customImage : propertyImageUrl}
              />
            </View>
          )}
          <Text style={{ ...PDF_STYLES.heading, marginTop: 16, fontWeight: "bold"}}>
            {props.propertyName}
          </Text>
          {/* add the address here */}
          <View style={{ ...PDF_STYLES.col, marginBottom: 0, paddingBottom: 0 }}>
              {props.address && (
                <>
                  <View style={PDF_STYLES.smallSpacer} />
                  {props.address.includes("Escalera") ? (
                    <View>
                      <Text style={{ ...PDF_STYLES.text, fontSize: 12 }}>
                        {props.address.split(" Escalera")[0]}
                      </Text>
                      <View style={PDF_STYLES.smallSpacer} />
                      <Text style={{ ...PDF_STYLES.text, fontSize: 12 }}>
                        Escalera {props.address.split(" Escalera")[1]}
                      </Text>
                    </View>
                  ) : (
                    <Text style={{ ...PDF_STYLES.text, fontSize: 12 }}>
                      {props.address}
                    </Text>
                  )}
                  <View style={PDF_STYLES.smallSpacer} />
                </>
              )}
              {!props.address && (
                <View style={PDF_STYLES.smallSpacer} />
              )}
              <Text style={{ ...PDF_STYLES.text, fontSize: 12 }}>{props.district}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={{ ...PDF_STYLES.text, fontSize: 12 }}>{props.municipality}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={{ ...PDF_STYLES.text, fontSize: 12 }}>{props.province}</Text>
              <View style={PDF_STYLES.spacer} />
          <Text style={{ ...PDF_STYLES.title, fontWeight: "bold", marginTop: 16, fontSize: 12, marginBottom: 5 }}>
            {getLocalisation("Agent Contact Information", lang)}
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
            {agencyLogoUrl && (
              <Image 
                src={agencyLogoUrl} 
                style={{ width: 60, height: 60, borderRadius: 2, marginRight: 10 }} 
              />
            )}
            <View>
              {(props.personalDetails?.firstName || props.personalDetails?.lastName) && (
                <Text style={{ ...PDF_STYLES.subHeading, fontSize: 12, fontWeight: "bold", color: "#000" }}>
                  {props.personalDetails?.firstName} {props.personalDetails?.lastName}
                </Text>
              )}
              {props.agencyDetails?.name && (
                <Text style={{ ...PDF_STYLES.text, fontSize: 10, fontWeight: "bold" }}>
                  {props.agencyDetails.name}
                </Text>
              )}
              {props.personalDetails?.phone && (
                <View style={{ flexDirection: 'row', alignItems: 'center', opacity: 0.7, marginTop: 4 }}>
                  <Image src={phoneIcon} style={{ width: 8, height: 8, marginRight: 4 }} />
                  <Text style={{ ...PDF_STYLES.text, fontSize: 10 }}>
                    {props.personalDetails.phone}
                  </Text>
                </View>
              )}
              {props.personalDetails?.email && (
                <View style={{ flexDirection: 'row', alignItems: 'center', opacity: 0.7, marginTop: 4 }}>
                  <Image src={emailIcon} style={{ width: 8, height: 8, marginRight: 4 }} />
                  <Text style={{ ...PDF_STYLES.text, fontSize: 10 }}>
                    {props.personalDetails.email}
                  </Text>
                </View>
              )}
            </View>
          </View>
            </View>

        </View>
      </Page>
      <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <Text style={{ ...PDF_STYLES.title2 }}>
            {getLocalisation("Property Information", lang)}
          </Text>

          <View style={PDF_STYLES.divider} />

          {/* console log the property */}
          {console.log(props.property)}
          
          {/* Property Details Section */}
          <View style={{ marginBottom: 20 }}>
            <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 10, paddingBottom: 5 }}>
              {getLocalisation("Property Details", lang)}
            </Text>
            
            <View style={{ flexDirection: 'row', flexWrap: 'wrap'}}>
              {props.property?.typology && (
                <View style={{ width: '25%', marginBottom: 15 }}>
                  <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                    {getLocalisation("Property Type:", lang)}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {getLocalisation(props.property.typology.charAt(0).toUpperCase() + props.property.typology.slice(1), lang)}
                  </Text>
                </View>
              )}
              
              {props.property?.rooms && (
                <View style={{ width: '25%', marginBottom: 15 }}>
                  <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                    {getLocalisation("Bedrooms:", lang)}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {props.property.rooms}
                  </Text>
                </View>
              )}
              
              {props.property?.bathrooms && (
                <View style={{ width: '25%', marginBottom: 15 }}>
                  <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                    {getLocalisation("Bathrooms:", lang)}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {props.property.bathrooms}
                  </Text>
                </View>
              )}
              
              {props.property?.size && (
                <View style={{ width: '25%', marginBottom: 15 }}>
                  <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                    {getLocalisation("Area built:", lang)}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {props.property.size}m²
                  </Text>
                </View>
              )}
            
              {props.property?.yearConstructed && (
                <View style={{ width: '25%', marginBottom: 15 }}>
                  <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                    {getLocalisation("Year of construction:", lang)}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {props.property.yearConstructed}
                  </Text>
                </View>
              )}
                
              {props.property?.status && (
                <View style={{ width: '25%', marginBottom: 15}}>
                  <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                    {getLocalisation("Condition:", lang)}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {getLocalisation(props.property.status.charAt(0).toUpperCase() + props.property.status.slice(1), lang)}
                  </Text>
                </View>
              )}

              {props.property?.features && props.property.features.length > 0 && (
                <View style={{ width: (!props.property?.yearConstructed && !props.property?.status) ? '100%' : 
                               (!props.property?.yearConstructed || !props.property?.status) ? '50%' : '25%', marginBottom: 15}}>
                  <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                    {getLocalisation("Features:", lang)}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {props.property.features.map(feature => getLocalisation(feature.label.charAt(0).toUpperCase() + feature.label.slice(1), lang)).join(", ")}
                  </Text>
                </View>
              )}
            </View>
            
            <View>
              <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                {getLocalisation("Conditions:", lang)}
              </Text>
              {/* Replace with three-column grid layout with smaller text */}
              { props.conditions && props.conditions.length > 0 && (
                <View style={{ marginTop: 4, flexDirection: 'row' }}>
                  {/* Filter conditions to include all with answers */}
                  {(() => {
                    const filteredConditions = props.conditions.filter(condition => {
                      if (condition.type === "yesno") {
                        return condition.variant === "yes" || condition.variant === "no";
                      } else if (condition.type === "select" || condition.type === "text" || condition.type === "number") {
                        return condition.variant && condition.variant !== "";
                      } else {
                        return condition.variant && condition.variant !== "";
                      }
                    });
                    
                    if (filteredConditions.length === 0) return (
                      <Text style={{ ...PDF_STYLES.text, fontSize: 10 }}>-</Text>
                    );
                    
                    // Determine items per column
                    const itemsPerColumn = Math.ceil(filteredConditions.length / 3);
                    
                    // Create the three columns
                    return (
                      <>
                        {/* First column */}
                        <View style={{ width: '33%', paddingRight: 10 }}>
                          {filteredConditions.slice(0, itemsPerColumn).map((condition, index) => (
                            <View key={index} style={{ marginBottom: 5 }}>
                              <Text style={{ ...PDF_STYLES.text, fontSize: 10 }}>
                                {getLocalisation(condition.label, lang)}
                              </Text>
                              <Text style={{ ...PDF_STYLES.text, fontSize: 10, fontWeight: 'bold' }}>
                                {condition.type === "yesno" 
                                  ? (condition.variant === "yes" ? getLocalisation("Yes", lang) : getLocalisation("No", lang))
                                  : condition.variant || "-"}
                              </Text>
                            </View>
                          ))}
                        </View>
                        
                        {/* Second column */}
                        <View style={{ width: '33%', paddingLeft: 10, paddingRight: 10 }}>
                          {filteredConditions.slice(itemsPerColumn, itemsPerColumn * 2).map((condition, index) => (
                            <View key={index} style={{ marginBottom: 5 }}>
                              <Text style={{ ...PDF_STYLES.text, fontSize: 10 }}>
                                {getLocalisation(condition.label, lang)}
                              </Text>
                              <Text style={{ ...PDF_STYLES.text, fontSize: 10, fontWeight: 'bold' }}>
                                {condition.type === "yesno" 
                                  ? (condition.variant === "yes" ? getLocalisation("Yes", lang) : getLocalisation("No", lang))
                                  : condition.variant || "-"}
                              </Text>
                            </View>
                          ))}
                        </View>
                        
                        {/* Third column */}
                        <View style={{ width: '33%', paddingLeft: 10 }}>
                          {filteredConditions.slice(itemsPerColumn * 2).map((condition, index) => (
                            <View key={index} style={{ marginBottom: 5 }}>
                              <Text style={{ ...PDF_STYLES.text, fontSize: 10 }}>
                                {getLocalisation(condition.label, lang)}
                              </Text>
                              <Text style={{ ...PDF_STYLES.text, fontSize: 10, fontWeight: 'bold' }}>
                                {condition.type === "yesno" 
                                  ? (condition.variant === "yes" ? getLocalisation("Yes", lang) : getLocalisation("No", lang))
                                  : condition.variant || "-"}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </>
                    );
                  })()}
                </View>
              )}
            </View>
          </View>
          
          {/* Property Images Section */}
          { !props.isCmaProperty && props.property?.images && props.property.images.length > 0 && (
            <View style={{ marginBottom: 20 }}>
              <Text style={{ fontSize: 12, fontWeight: 'bold', paddingBottom: 5 }}>
                {getLocalisation("Property Images", lang)}
              </Text>
              
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {props.property.images.slice(0, 8).map((image, index) => (
                  <View key={index} style={{ width: '25%', padding: 3, flexWrap: 'wrap', marginBottom: 1 }}>
                    <Image
                      style={{ width: '100%', height: 70, borderRadius: 3 }}
                      src={image.url ? CORS_PROXY + encodeURIComponent(image.url) : propertyImageUrl}
                    />
                  </View>
                ))}
                {props.property.images.length > 12 && (
                  <View style={{ width: '25%', padding: 3, flexWrap: 'wrap', marginBottom: 5, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 8, fontWeight: 'semibold', color: '#666' }}>
                      +{props.property.images.length - 12} {getLocalisation("more photos", lang)}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}
          
          {/* Location Section */}
          <View>
            <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 10, paddingBottom: 5 }}>
              {getLocalisation("Location", lang)}
            </Text>
            
            <View style={{ flexDirection: 'row' }}>
              {props.locationUrl && (
                <View style={{ width: props.address || props.district || props.municipality || props.province || props.property?.ref ? '60%' : '100%' }}>
                  <Image
                    style={{ width: '100%', height: 150, borderRadius: 3 }}
                    src={props.locationUrl}
                  />
                </View>
              )}
              
              {(props.address || props.district || props.municipality || props.province || props.property?.ref) && (
                <View style={{ width: props.locationUrl ? '40%' : '100%', paddingLeft: props.locationUrl ? 10 : 0 }}>
                  {(props.address || props.district || props.municipality || props.province) && (
                    <>
                      <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                        {getLocalisation("Address:", lang)}
                      </Text>
                      <Text style={{ fontSize: 11, marginBottom: 8 }}>
                        {props.address && (
                          <>
                            {props.address.includes("Escalera") ? (
                              <>
                                {props.address.split(" Escalera")[0]}{"\n"}
                                Escalera {props.address.split(" Escalera")[1]}{"\n"}
                              </>
                            ) : (
                              <>{props.address}{"\n"}</>
                            )}
                          </>
                        )}
                        {props.district && `${props.district},${"\n"}`}
                        {props.municipality && `${props.municipality},${"\n"}`}
                        {props.province && props.province}
                      </Text>
                    </>
                  )}
                    {props.property?.ref && props.property.ref.length >= 14 && !props.property.ref.includes(" ") && (
                      <>
                        <Text style={{ fontSize: 10, color: '#666', marginBottom: 4 }}>
                          {getLocalisation("Cadastral Reference:", lang)}
                        </Text>
                        <Text style={{ fontSize: 11 }}>
                          {props.property.ref}
                        </Text>
                      </>
                    )}
                </View>
              )}
            </View>
          </View>
        </View>
      </Page>
      {/* <Page style={PDF_STYLES.page}>
        <View style={PDF_STYLES.section}>
          <View style={PDF_STYLES.row}>
            {studyAreaText && (
              <>
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Study area:", lang)}{" "}
                </Text>
                <Text style={PDF_STYLES.text}>{studyAreaText}</Text>
              </>
            )}
            {props.rooms > 0 && (
              <>
                {studyAreaText && <View style={PDF_STYLES.verticalDivider} />}
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Bedrooms:", lang)}{" "}
                </Text>
                <Text style={PDF_STYLES.text}>{props.rooms}</Text>
              </>
            )}
            {props.bathrooms && (
              <>
                {(studyAreaText || props.rooms > 0) && <View style={PDF_STYLES.verticalDivider} />}
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Bathrooms:", lang)}{" "}
                </Text>
                <Text style={PDF_STYLES.text}>{props.bathrooms}</Text>
              </>
            )}
          </View>
          {props.property?.size && (
            <View style={PDF_STYLES.row}>
              <Text style={PDF_STYLES.faintText}>
                {getLocalisation("Area built:", lang)}{" "}
              </Text>
              <Text style={PDF_STYLES.text}>{props.property.size}m²</Text>
            </View>
          )}
          <View style={PDF_STYLES.spacer} />
          {props.locationUrl && (
            <Image style={PDF_STYLES.propertyImage} src={props.locationUrl} />
          )}
          <View style={PDF_STYLES.spacer} />
          <View style={PDF_STYLES.row}>
            <View style={PDF_STYLES.col}>
              <Text style={PDF_STYLES.faintText}>
                {getLocalisation("Address:", lang)}
              </Text>
              {props.address && (
                <>
                  <View style={PDF_STYLES.smallSpacer} />
                  {props.address.includes("Escalera") ? (
                    <View>
                      <Text style={PDF_STYLES.text}>
                        {props.address.split(" Escalera")[0]}
                      </Text>
                      <View style={PDF_STYLES.smallSpacer} />
                      <Text style={PDF_STYLES.text}>
                        Escalera {props.address.split(" Escalera")[1]}
                      </Text>
                    </View>
                  ) : (
                    <Text style={PDF_STYLES.text}>
                      {props.address}
                    </Text>
                  )}
                  <View style={PDF_STYLES.smallSpacer} />
                </>
              )}
              {!props.address && (
                <View style={PDF_STYLES.smallSpacer} />
              )}
              <Text style={PDF_STYLES.text}>{props.district}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={PDF_STYLES.text}>{props.municipality}</Text>
              <View style={PDF_STYLES.smallSpacer} />
              <Text style={PDF_STYLES.text}>{props.province}</Text>
            </View>
            {props.property.isCatastro && (
              <View style={PDF_STYLES.col}>
                <Text style={PDF_STYLES.faintText}>
                  {getLocalisation("Catastral reference:", lang)}
                </Text>
                <View style={PDF_STYLES.smallSpacer} />
                <Text style={PDF_STYLES.text}>{props.property.ref}</Text>
              </View>
            )}
          </View>
          {agencyLogoUrl && (
            <Image style={PDF_STYLES.agencyLogo} src={agencyLogoUrl} />
          )}
        </View>
      </Page> */}
      {props.pdfSections?.statistics &&
        props.saleType !== "rent" &&
        renderMarketInsightsPage()}
      {props.pdfSections?.statistics &&
        props.saleType === "rent" &&
        renderRentalMarketAnalysisPages()}
      {props.pdfSections?.marketAnalysis &&
        props.saleType !== "rent" &&
        renderMarketAnalysisPages(props.property)}
      {props.pdfSections?.comparables &&
        renderPropertyPages(allProperties, lang)}
      {props.pdfSections?.salesInfo &&
        props.saleType !== "rent" &&
        renderSaleInfoPages()}
    </Document>
  );
};

// function to get the days on the market
const getDaysOnTheMarketSale = (property) => {
  const today = new Date();
  const initialImportTime = new Date(
    parseFloat(property.initialImportTime) * 1000,
  );
  const diffTime = Math.abs(today - initialImportTime);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const getSoldText = (property) => {
  // return feb 2024, or may 2023 depending on the sold timestamp
  const soldDate = new Date(parseFloat(property.timestamp) * 1000);
  const soldMonth = soldDate.getMonth();
  const soldYear = soldDate.getFullYear();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[soldMonth] + " " + soldYear;
};

const getPriceRange = async (property, properties) => {
  // get lowest and highest price from the properties array
  const prices = properties.map((p) => p.price);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  return { min: minPrice, max: maxPrice };
};

const getAveragePricePerSqm = async (property, properties) => {
  // get the average price per square meter from the properties array
  const pricesPerSqm = properties.map((p) => p.pricePerSqm);
  const averagePricePerSqm =
    pricesPerSqm.reduce((a, b) => a + b, 0) / pricesPerSqm.length;
  return averagePricePerSqm;
};

const getFeaturesText = (property) => {
  // for each true value in the features array, add the feature to the string
  const features = [];
  if (property.features && typeof property.features === "object") {
    Object.keys(property.features).forEach((key) => {
      if (property.features[key]) {
        features.push(key);
      }
    });
  }

  //  convert each from camel case to normal case with spaces and remove "has" from the beginning of the string
  const formattedFeatures = features.map((feature) => {
    const cleanedFeature = feature
      .replace(/^has/, "")
      .replace(/^is/, "")
      .replace(/([A-Z])/g, " $1")
      .trim();

    // Translate each feature
    return i18n(cleanedFeature);
  });

  return formattedFeatures.join(", ");
};

const generatePropertySummary = (props) => {
  let summary = '';
  const lang = props.lang || 'en';
  
  // Property name and client reference
  if (props.propertyName || (props.clientName && props.clientSurname)) {
    if (props.propertyName) {
      summary += `${props.propertyName}`;
      
      if (props.clientName || props.clientSurname) {
        summary += ` ${getLocalisation("is being presented to", lang)} ${props.clientName || ''} ${props.clientSurname || ''}`.trim();
      }
    } else if (props.clientName || props.clientSurname) {
      summary += `${getLocalisation("This property assessment has been prepared for", lang)} ${props.clientName || ''} ${props.clientSurname || ''}`.trim();
    }
    
    summary += '. ';
  }
  
  // Basic property description with condition
  if (props.property?.status) {
    summary += `${getLocalisation("This", lang)} ${props.property.status.toLowerCase()} ${getLocalisation("property", lang)}`;
  } else {
    summary += `${getLocalisation("This property", lang)}`;
  }
  
  // Size information
  if (props.property?.size) {
    summary += ` ${getLocalisation("offers", lang)} ${props.property.size} ${getLocalisation("square meters of living space", lang)}`;
  }
  
  // Location information
  if (props.district || props.municipality || props.province) {
    summary += ` ${getLocalisation("and is located in", lang)} `;
    
    const locationParts = [];
    if (props.district) locationParts.push(props.district);
    if (props.municipality && props.municipality !== props.district) locationParts.push(props.municipality);
    if (props.province && props.province !== props.municipality) locationParts.push(props.province);
    
    if (locationParts.length === 1) {
      summary += locationParts[0];
    } else if (locationParts.length > 1) {
      const lastLocation = locationParts.pop();
      summary += `${locationParts.join(', ')} ${getLocalisation("in", lang)} ${lastLocation}`;
    }
  }
  
  summary += '. ';
  
  // Year of construction
  if (props.property?.yearConstructed) {
    summary += `${getLocalisation("Built in", lang)} ${props.property.yearConstructed}, `;
  }
  
  // Features
  if (props.property?.features && props.property.features.length > 0) {
    summary += `${getLocalisation("the property boasts", lang)} `;
    
    const featuresText = props.property.features.map(feature => feature.label.toLowerCase());
    
    if (featuresText.length === 1) {
      summary += `${getLocalisation("a", lang)} ${featuresText[0]}`;
    } else if (featuresText.length === 2) {
      summary += `${featuresText[0]} ${getLocalisation("and", lang)} ${featuresText[1]}`;
    } else {
      const lastFeature = featuresText.pop();
      summary += `${featuresText.join(', ')}, ${getLocalisation("and", lang)} ${lastFeature}`;
    }
    
    summary += '. ';
  }
  
  // Conditions
  if (props.conditions && props.conditions.length > 0) {
    // Handle yes/no conditions
    const positiveConditions = props.conditions.filter(condition => 
      condition.type === "yesno" && condition.variant === "yes"
    );
    
    if (positiveConditions.length > 0) {
      summary += `${getLocalisation("Additional highlights include", lang)} `;
      
      let conditionsText = positiveConditions.map(condition => {
        // Extract just the question part without prefixes
        let text = condition.label
          .replace(/^Is it\s+/, '')
          .replace(/\?$/, '')
          .replace(/^Are there any\s+/, '')
          .replace(/^Choose type of\s+/, '')
          .replace(/^Is there any\s+/, '')
          .replace(/^Does it have\s+/, '')
          .replace(/^Does the building have\s+/, '')
          .replace(/^Is the building\s+/, '')
          .replace(/^the building\s+/, '')
          .toLowerCase();
        
        return text;
      });

      // Remove duplicate parking mentions
      conditionsText = conditionsText.filter((condition, index, self) => 
        condition !== "parking space" || self.indexOf(condition) === index
      );
      
      if (conditionsText.length === 1) {
        summary += conditionsText[0];
      } else if (conditionsText.length === 2) {
        summary += `${conditionsText[0]} ${getLocalisation("and", lang)} ${conditionsText[1]}`;
      } else if (conditionsText.length > 2) {
        const lastCondition = conditionsText.pop();
        summary += `${conditionsText.join(', ')}, ${getLocalisation("and", lang)} ${lastCondition}`;
      }
      
      summary += '. ';
    }
  }
  
  // Remove any trailing spaces and ensure proper ending
  summary = summary.trim();
  if (!summary.endsWith('.')) {
    summary += '.';
  }
  
  return summary;
};

const getEnergyCertificateText = (property) => {
  if (property.energyCertificate) {
    if (property.energyCertificate.hasEnergyCertificate) {
      // if hasEnergyCertificate is true, return the energy certificate type
      if (property.energyCertificate.consumptionGrade) {
        return property.energyCertificate.consumptionGrade;
      } else {
        return i18n("Has Energy Certificate");
      }
    } else {
      // if inProgress is true, return "In Progress"
      if (property.energyCertificate.inProgress) {
        return i18n("In Progress");
      } else {
        return i18n("No Energy Certificate");
      }
    }
  }
  return "-";
};

export function getPropertyTypologyHuman(
  property,
  plural = false,
  skipRentalPrefix = false,
) {
  if (property.buildingType == "land") {
    if (plural) {
      return i18n("Lands");
    }

    return i18n("Land");
  }

  if (property.buildingType == "building") {
    if (plural) {
      return i18n("Buildings");
    }

    return i18n("Building");
  }

  if (property.buildingType == "office") {
    if (plural) {
      return i18n("Offices");
    }

    return i18n("Office");
  }

  if (property.buildingType == "garage") {
    if (plural) {
      return i18n("Garages");
    }

    return i18n("Garage");
  }

  if (property.buildingType == "storage") {
    if (plural) {
      return i18n("Storages");
    }

    return i18n("Storage");
  }

  if (property.buildingType == "room") {
    if (plural) {
      return i18n("Rooms");
    }

    return i18n("Room");
  }

  if (property.buildingType == "commercial") {
    if (property.commercial_type == "industrial") {
      if (plural) {
        return i18n("Industrial properties");
      }

      return i18n("Industrial property");
    } else {
      if (plural) {
        return i18n("Commercial properties");
      }

      return i18n("Commercial property");
    }
  }

  let title = capitalizeWord(property.propertyType);

  if (property.detailedType && property.detailedType.subTypology) {
    if (property.detailedType.subTypology === "semidetachedHouse") {
      title = i18n("Semi-detached house");

      if (plural) {
        title = i18n("Semi-detached houses");
      }
    } else if (property.detailedType.subTypology === "independantHouse") {
      title = i18n("Detached house");

      if (plural) {
        title = i18n("Detached houses");
      }
    } else if (property.detailedType.subTypology === "terracedHouse") {
      title = i18n("Terrace house");

      if (plural) {
        title = i18n("Terrace houses");
      }
    } else if (property.detailedType.subTypology === "countryHouse") {
      title = i18n("Country house");

      if (plural) {
        title = i18n("Country houses");
      }
    } else if (property.detailedType.subTypology === "studio") {
      title = i18n("Studio");

      if (plural) {
        title = i18n("Studios");
      }
    }
  }

  if (property.detailedType.isVilla) {
    title = "Villa";

    if (plural) {
      title = "Villas";
    }
  }

  if (property.detailedType && property.detailedType.typology && !title) {
    if (property.detailedType.typology === "flat") {
      title = i18n("Flat");

      if (plural) {
        title = i18n("Flats");
      }
    }

    if (property.rooms === 0) {
      title = i18n("Studio");

      if (plural) {
        title = i18n("Studios");
      }
    }

    if (property.detailedType.subTypology) {
      if (property.detailedType.subTypology === "duplex") {
        title = i18n("Duplex");

        if (plural) {
          title = i18n("Duplexes");
        }
      }

      if (property.detailedType.subTypology === "penthouse") {
        title = i18n("Penthouse");

        if (plural) {
          title = i18n("Penthouses");
        }
      }
    }

    if (property.detailedType.typology === "house") {
      title = i18n("House");

      if (plural) {
        title = i18n("Houses");
      }
    }
  }

  if (!title) {
    title = i18n("Property");

    if (plural) {
      title = i18n("Properties");
    }
  }

  if (property.saleType == "rent" && !skipRentalPrefix) {
    if (getLang().code == "es") {
      return "Alquiler de " + title;
    } else {
      return `${title} ${i18n("for rent")}`;
    }
  }

  return title;
}

// transforms data points in from the backend to a more
// human readable title
export function getPropertyTitle(property) {
  let title = getPropertyTypologyHuman(property);
  let location = property.address;

  if (!location || location == "n/a" || location == "none" || location == "") {
    location =
      property.neighborhood !== "n/a" && property.neighborhood !== "none"
        ? property.neighborhood
        : null;
  }

  if (!location || location == "n/a" || location == "none" || location == "") {
    location = property.district;
  }

  if (!location || location == "n/a" || location == "none" || location == "") {
    location = property.municipality;
  }

  return `${title} ${i18n("in")} ${location}`;
}

// Update the chartToImageUrl function to use the correct Image constructor
const chartToImageUrl = async (
  chartData,
  options,
  width = 800,
  height = 400,
) => {
  return new Promise((resolve, reject) => {
    try {
      // Create canvas in memory
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");

      // Set white background
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);

      // Create and render the chart
      const chart = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: {
          ...options,
          animation: false,
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            ...options.plugins,
            legend: {
              ...options.plugins?.legend,
              labels: {
                ...options.plugins?.legend?.labels,
                font: {
                  size: 12,
                  family: "Arial",
                },
              },
            },
          },
          scales: {
            ...options.scales,
            x: {
              ...options.scales?.x,
              ticks: {
                ...options.scales?.x?.ticks,
                font: {
                  size: 10,
                  family: "Arial",
                },
              },
            },
            y: {
              ...options.scales?.y,
              ticks: {
                ...options.scales?.y?.ticks,
                font: {
                  size: 10,
                  family: "Arial",
                },
              },
            },
          },
        },
      });

      // Wait for chart render and convert to PNG
      requestAnimationFrame(() => {
        try {
          // Get base64 image and ensure it's properly formatted
          const base64Image = canvas.toDataURL("image/png", 1.0);

          // Create a test image using the native HTML Image constructor
          const testImage = document.createElement("img");
          testImage.onload = () => {
            chart.destroy();
            resolve(base64Image);
          };
          testImage.onerror = () => {
            chart.destroy();
            reject(new Error("Generated image is invalid"));
          };
          testImage.src = base64Image;
        } catch (err) {
          chart.destroy();
          reject(err);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
};

const generateChartImages = async (
  property,
  properties,
  valuationValue,
  selectedDistricts,
) => {
  try {
    const priceDistData = await getPriceDistributionChartData(
      property,
      properties,
      true,
    );
    // console.log('priceDistData', priceDistData);
    const timeOnMarketData = await getAverageTimeOnMarketChartData(
      property,
      properties,
      true,
    );
    // console.log('timeOnMarketData', timeOnMarketData);
    const pricePerSqmDistData = await getPricePerSqmDistributionChartData(
      property,
      properties,
      true,
    );
    // console.log('pricePerSqmDistData', pricePerSqmDistData);
    // itteratively go through selectedDistricts and get the historic price data until one with data is found
    let historicPriceData = null;
    for (const district of selectedDistricts) {
      historicPriceData = await getAveragePricePerSqmHistoricChartData(
        district.id,
      );
      if (historicPriceData) {
        break;
      }
    }
    // console.log('historicPriceData', historicPriceData);
    const [
      priceDistImageUrl,
      timeOnMarketImageUrl,
      pricePerSqmImageUrl,
      historicPriceImageUrl,
    ] = await Promise.all([
      chartToImageUrl(priceDistData.chartData, priceDistData.options),
      chartToImageUrl(timeOnMarketData.chartData, timeOnMarketData.options),
      chartToImageUrl(
        pricePerSqmDistData.chartData,
        pricePerSqmDistData.options,
      ),
      historicPriceData
        ? chartToImageUrl(
            historicPriceData.chartData,
            historicPriceData.options,
          )
        : null,
    ]);

    // Validate base64 images
    if (
      !priceDistImageUrl?.startsWith("data:image/png;base64,") ||
      !timeOnMarketImageUrl?.startsWith("data:image/png;base64,") ||
      !pricePerSqmImageUrl?.startsWith("data:image/png;base64,") ||
      (historicPriceImageUrl &&
        !historicPriceImageUrl.startsWith("data:image/png;base64,"))
    ) {
      console.error("Invalid image data generated");
      return null;
    }

    return {
      priceDistImageUrl,
      timeOnMarketImageUrl,
      pricePerSqmImageUrl,
      historicPriceImageUrl,
    };
  } catch (error) {
    console.error("Error generating chart images:", error);
    throw error;
  }
};

const CmaPDFProvider = (props) => {
  const [mergedUrl, setMergedUrl] = useState(null);
  const [mergeError, setMergeError] = useState(null);
  const [mergeInProgress, setMergeInProgress] = useState(false);
  const downloadTriggered = useRef(false);
  const [chartImages, setChartImages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [documentReady, setDocumentReady] = useState(false);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [basePdfData, setBasePdfData] = useState(null);
  const [basePdfMeta, setBasePdfMeta] = useState({ loading: true });
  const [statsData, setStatsData] = useState(null);
  const { refcat, isInternalValuation } = props;

  // Construct the additional PDF URL with the dynamic refcat and CORS proxy
  const additionalPdfUrl =
    CORS_PROXY +
    encodeURIComponent(
      `https://www1.sedecatastro.gob.es/CYCBienInmueble/SECImprimirCroquisYDatos.aspx?del=1&mun=1&refcat=${refcat}`,
    );

  useEffect(() => {
    const generateCharts = async () => {
      if (!props.properties) {
        setIsLoading(false);
        return;
      }

      try {
        const propertyWithDetailedType = {
          ...props.property,
          detailedType: {
            typology: props.property.typology,
            subTypology: "",
            isVilla: false,
          },
        };

        // Add price to property
        const propertyWithPrice = {
          ...propertyWithDetailedType,
          price: props.valuationValue,
        };

        // Filter sale properties
        const saleProperties = props.selectedProperties.filter(
          (p) => p.saleType !== "sold",
        );
        saleProperties.push({
          ...propertyWithDetailedType,
          price: props.valuationValue,
          saleType: "sale",
        });

        const allSaleProperties = props.properties.filter(
          (p) => p.saleType !== "sold",
        );
        allSaleProperties.push({
          ...propertyWithDetailedType,
          price: props.valuationValue,
          saleType: "sale",
        });

        const allSoldProperties = props.properties.filter(
          (p) => p.saleType === "sold",
        );
        const allProperties = [...allSaleProperties, ...allSoldProperties];

        // get all property data
        // Get all data sequentially
        const priceRange = await getPriceRange(
          propertyWithDetailedType,
          saleProperties,
        );
        const images = await generateChartImages(
          propertyWithPrice,
          allProperties,
          props.valuationValue,
          props.selectedDistricts,
        );
        const stats = await getStatsData(
          propertyWithDetailedType,
          saleProperties,
          [],
        );
        console.log("stats", stats);

        // Get sold properties stats
        const soldProperties = props.selectedProperties.filter(
          (p) => p.saleType === "sold",
        );

        let statsDataSold = null;

        if (soldProperties.length > 0) {
          statsDataSold = await getStatsData(
            propertyWithDetailedType,
            soldProperties,
            [],
          );
        }

        // Extract required stats
        const averageDaysOnMarket =
          stats.find(
            (stat) =>
              stat.label === "Average time on market" ||
              stat.label === "Tiempo en el Mercado",
          )?.value || "0 días";
        const averagePricePerSqm =
          stats.find(
            (stat) =>
              stat.label === "Average price/m²:" ||
              stat.label === "Precio medio/m²:" ||
              stat.label === "Precio/m² medio:",
          )?.value || "0 €";
        const averagePrice =
          stats.find(
            (stat) =>
              stat.label === "Average price:" || stat.label === "Precio medio:",
          )?.value || "0 €";

        let averagePricePerSqmSold = null;
        if (statsDataSold) {
          averagePricePerSqmSold =
            statsDataSold.find(
              (stat) =>
                stat.label === "Average price/m²:" ||
                stat.label === "Precio medio/m²:" ||
                stat.label === "Precio/m² medio:",
            )?.value || "0 €";
        }

        // Create document with all data
        const doc = (
          <CmaDocument
            {...props}
            chartImages={images}
            averagePrice={averagePrice || 0}
            priceRange={priceRange || { min: 0, max: 0 }}
            averagePricePerSqm={averagePricePerSqm}
            averageDaysOnMarket={averageDaysOnMarket || 0}
            averagePricePerSqmSold={averagePricePerSqmSold}
          />
        );

        // Set all state at once after everything is ready
        setStatsData({
          averagePrice,
          priceRange,
          averagePricePerSqm,
          averageDaysOnMarket,
          averagePricePerSqmSold,
        });
        setPdfDocument(doc);
        setChartImages(images);
      } catch (error) {
        console.error("Error generating charts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    generateCharts();
  }, [props.property, props.properties, props.valuationValue]);

  // Create PDF document when charts are ready
  useEffect(() => {
    if (!isLoading && chartImages && statsData) {
      // console.log('Creating PDF document with charts');
      const doc = (
        <CmaDocument
          {...props}
          chartImages={chartImages}
          averagePrice={statsData.averagePrice || 0}
          priceRange={statsData.priceRange || { min: 0, max: 0 }}
          averagePricePerSqm={statsData.averagePricePerSqm || 0}
          averageDaysOnMarket={statsData.averageDaysOnMarket || 0}
          averagePricePerSqmSold={statsData.averagePricePerSqmSold || 0}
        />
      );
      setPdfDocument(doc);
    }
  }, [isLoading, chartImages, props, statsData]);

  useEffect(() => {
    if (!pdfDocument) {
      return;
    }

    const generatePdf = async () => {
      try {
        setBasePdfMeta({ loading: true });
        const pdfBlob = await pdf(pdfDocument, { open: false }).toBlob();
        setBasePdfData({ blob: pdfBlob });
        setBasePdfMeta({ loading: false });
      } catch (error) {
        setBasePdfMeta({ loading: false, error });
      }
    };

    generatePdf();
  }, [pdfDocument]);

  // Monitor PDF document creation status
  useEffect(() => {
    const checkDocumentReady = async () => {
      const status = {
        isLoading,
        hasCharts: !!chartImages,
        hasStatsData: !!statsData,
        hasDocument: !!pdfDocument,
        hasBasePdfData: !!basePdfData?.blob,
      };

      // console.log('Checking document status:', status);

      if (
        !isLoading &&
        chartImages &&
        pdfDocument &&
        basePdfData?.blob &&
        statsData
      ) {
        // console.log('CMA Document is ready for merging');
        setDocumentReady(true);
      } else {
        setDocumentReady(false);
      }
    };

    checkDocumentReady();
  }, [isLoading, chartImages, pdfDocument, basePdfData, statsData]);

  // Handle PDF merging only when document is ready
  useEffect(() => {
    const mergePdfs = async () => {
      // Add checks to prevent duplicate merges
      if (!documentReady || mergeInProgress || mergeError || mergedUrl) {
        return;
      }

      try {
        setMergeInProgress(true);
        setMergeError(null);
        setMergedUrl(null);

        const basePdfBytes = await basePdfData.blob.arrayBuffer();

        if (!basePdfBytes) {
          throw new Error("Failed to generate base PDF bytes.");
        }

        if (refcat && !isInternalValuation) {
          const basePdf = await PDFDocument.load(basePdfBytes);

          const response = await fetch(additionalPdfUrl);
          if (!response.ok) {
            throw new Error("Failed to fetch additional PDF.");
          }
          const additionalPdfBytes = await response.arrayBuffer();
          const additionalPdf = await PDFDocument.load(additionalPdfBytes);

          const mergedPdf = await PDFDocument.create();

          // copy the base pdf into the merged pdf
          const basePages = await mergedPdf.copyPages(
            basePdf,
            basePdf.getPageIndices(),
          );
          basePages.forEach((page) => mergedPdf.addPage(page));

          // copy the additional pdf into the merged pdf
          const additionalPages = await mergedPdf.copyPages(
            additionalPdf,
            additionalPdf.getPageIndices(),
          );

          additionalPages.forEach((page) => {
            page.setRotation(degrees(-90));
            mergedPdf.addPage(page);
          });

          const mergedPdfBytes = await mergedPdf.save();

          const mergedBlob = new Blob([mergedPdfBytes], {
            type: "application/pdf",
          });

          const mergedPdfUrl = URL.createObjectURL(mergedBlob);
          // console.log('Single PDF created successfully');
          setMergedUrl(mergedPdfUrl);
        } else {
          const mergedBlob = new Blob([basePdfBytes], {
            type: "application/pdf",
          });

          const mergedPdfUrl = URL.createObjectURL(mergedBlob);
          // console.log('Single PDF created successfully');
          setMergedUrl(mergedPdfUrl);
        }
      } catch (err) {
        console.error("Error merging PDFs:", err);
        setMergeError(err.message);
      } finally {
        setMergeInProgress(false);
      }
    };

    mergePdfs();
  }, [
    documentReady,
    mergeError,
    mergedUrl,
    mergeInProgress,
    isLoading,
    chartImages,
    pdfDocument,
    basePdfData,
    additionalPdfUrl,
    refcat,
    isInternalValuation,
  ]);

  useEffect(() => {
    return () => {
      if (mergedUrl) {
        URL.revokeObjectURL(mergedUrl);
      }
    };
  }, [mergedUrl]);

  useEffect(() => {
    if (mergedUrl && !downloadTriggered.current) {
      downloadTriggered.current = true;
      const newTab = window.open(mergedUrl, "_blank");
      if (!newTab) {
        console.error(
          "Failed to open a new tab. Check if pop-ups are blocked.",
        );
      } else {
        console.log("PDF opened in new tab");
      }
      // downloadTriggered.current = false;
    }
  }, [mergedUrl]);

  // if (basePdfMeta.loading) {
  //   console.log('PDF is being generated...');
  //   return <div>Generating PDF...</div>;
  // }

  // if (basePdfMeta.error) {
  //   console.error('PDF generation error:', basePdfMeta.error);
  //   return <div>Error generating PDF: {basePdfMeta.error.message}</div>;
  // }

  // if (mergeError) {
  //   console.error('PDF merge error:', mergeError);
  //   return <div>Error merging PDFs: {mergeError}</div>;
  // }

  return null;
};

export default CmaPDFProvider;
