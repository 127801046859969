import { createReducer } from "@reduxjs/toolkit";
import {
  setAgencies,
  setFetchingAgencies,
  setFilteredAgencies,
  setSelectedAgency,
  resetAgencyState,
  setAgencyFilters,
  addAgencyFilter,
  removeAgencyFilter
} from "../actions/agencyActions";

const initialState = {
  agencies: [],
  filteredAgencies: [],
  fetchingAgencies: false,
  selectedAgency: null,
  agencyFilters: []
};

const agencyReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAgencies, (state, action) => {
      state.agencies = action.payload;
    })
    .addCase(setFetchingAgencies, (state, action) => {
      state.fetchingAgencies = action.payload;
    })
    .addCase(setFilteredAgencies, (state, action) => {
      state.filteredAgencies = action.payload;
    })
    .addCase(setSelectedAgency, (state, action) => {
      state.selectedAgency = action.payload;
    })
    .addCase(resetAgencyState, (state, action) => {
      state.agencies = [];
      state.filteredAgencies = [];
      state.fetchingAgencies = false;
      state.selectedAgency = null;
      state.agencyFilters = [];
    })
    .addCase(setAgencyFilters, (state, action) => {
      state.agencyFilters = action.payload;
    })
    .addCase(addAgencyFilter, (state, action) => {
      state.agencyFilters.push(action.payload);
    })
    .addCase(removeAgencyFilter, (state, action) => {
      state.agencyFilters = state.agencyFilters.filter(filter => filter.type !== action.payload.type);
    });
});

export default agencyReducer;
