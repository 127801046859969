import { getPolygonById } from "utils/polygon";
import fireApiRequest from "./core";
import store from "store";
import { setFetchingAgencies } from "actions/agencyActions";

var FETCH_AGENCIES_BY_BOUNDS = "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_agencies_by_bounds";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_AGENCIES_BY_BOUNDS = "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_agencies_by_bounds";
}

/**
 * Fetches all agencies that are within the bounds of the polygon
 * @param {String} polygonId - The id of the polygon to fetch agencies for
 * @returns {Promise<Array>} - A promise that resolves to an array of agencies
 */
export async function fetchAgenciesByPolygon(polygonId) {
  store.dispatch(setFetchingAgencies(true));
  const polygon = window.drawnPolygons.find(p => p.irealtyId === polygonId);
  var bounds = new window.google.maps.LatLngBounds();

  polygon.getPath().forEach(function(latlng) {
      bounds.extend(latlng);
  });

  const boundingRect = {
      minLat: bounds.getSouthWest().lat(),
      maxLat: bounds.getNorthEast().lat(),
      minLong: bounds.getSouthWest().lng(),
      maxLong: bounds.getNorthEast().lng()
  };

  // Break the bounding rectangle into 6 quadrants
  const quadrants = divideIntoQuadrants(boundingRect, 6);
  let allAgencies = [];

  try {
    // Make parallel requests for each quadrant
    const requests = quadrants.map(quadrant => 
      fireApiRequest(FETCH_AGENCIES_BY_BOUNDS, {
        method: "POST",
        body: JSON.stringify({
          bounding_rect: quadrant,
        }),
      })
    );
    
    // Wait for all requests to complete
    const responses = await Promise.all(requests);
    
    // Combine all agencies from all quadrants
    responses.forEach(response => {
      if (response.agencies && response.agencies.length > 0) {
        allAgencies = [...allAgencies, ...response.agencies];
      }
    });
    
    // Remove duplicate agencies (if any)
    allAgencies = removeDuplicateAgencies(allAgencies);
    
  } catch (error) {
    console.error("Error fetching agencies from quadrants:", error);
  }

  // Filter out agencies that are outside the polygon
  if (allAgencies.length > 0 && polygon) {
    allAgencies = allAgencies.filter(agency => {
      // Create a Google Maps LatLng object for the agency location
      const agencyLocation = new window.google.maps.LatLng(
        agency.latitude,
        agency.longitude
      );
      
      // Check if the agency location is within the polygon
      return window.google.maps.geometry.poly.containsLocation(
        agencyLocation,
        polygon
      );
    });
  }

  store.dispatch(setFetchingAgencies(false));
  return allAgencies;
}

// Helper function to divide a bounding rectangle into n quadrants
function divideIntoQuadrants(boundingRect, n = 6) {
  const { minLat, maxLat, minLong, maxLong } = boundingRect;
  const quadrants = [];
  
  // For 6 quadrants, we'll do a 2x3 grid (2 rows, 3 columns)
  const latMid = (minLat + maxLat) / 2;
  const longDelta = (maxLong - minLong) / 3;
  
  // Create the 6 quadrants
  for (let row = 0; row < 2; row++) {
    for (let col = 0; col < 3; col++) {
      quadrants.push({
        minLat: row === 0 ? minLat : latMid,
        maxLat: row === 0 ? latMid : maxLat,
        minLong: minLong + (col * longDelta),
        maxLong: minLong + ((col + 1) * longDelta)
      });
    }
  }
  
  return quadrants;
}

// Helper function to remove duplicate agencies based on their ID
function removeDuplicateAgencies(agencies) {
  const uniqueAgencies = {};
  agencies.forEach(agency => {
    uniqueAgencies[agency.id] = agency;
  });
  
  return Object.values(uniqueAgencies);
}
