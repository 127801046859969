import { getPropertyTitle } from "utils/properties";
import { getLang, i18n } from "../../i18n/localisation";
import { Tooltip } from "react-tooltip";
import rightChevron from "../../assets/core/right_chevron.svg";
import { selectPropertyCard } from "utils/ui";
import { useRef, useState } from "react";
import {
  capitalizeWord,
  debouncedMapPanTo,
  debouncedMapSetZoom,
  formatNumber,
  formatPrice,
  isMobile,
  openCatastroRef,
  randomNumberWithStep,
} from "utils/helpers";
import { getGlobalMapInstance, panMapByPixels } from "utils/map";
import moment from "moment";
import "moment/locale/es";
import { connect, useDispatch } from "react-redux";
import { getSubscriptionPlan, userHasRole } from "utils/userHelpers";
import { setPricingModal } from "actions/miscActions";
import UpgradeView from "./UpgradeView";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { sendAnalyticsEvent } from "lib/analytics";
import React from "react";
const SoldPropertyCard = (props) => {
  const {
    property,
    selectPropertyMarker,
    drawnPolygons,
    isSelectable,
    onSelect,
    selected,
  } = props;
  const cardRef = useRef(null);
  const [imageError, setImageError] = useState(false);

  const lang = getLang();
  const soldDate = capitalizeWord(
    moment
      .unix(parseInt(property.timestamp))
      .locale(lang.code)
      .format("MMM YYYY"),
  );
  const price = formatNumber(parseFloat(property.price));
  const pricePerM2 = formatNumber(
    parseInt(parseFloat(property.price) / parseFloat(property.size)),
  );
  const polygon = drawnPolygons.find(
    (polygon) => polygon.irealtyId === property.polygonId,
  );
  const dispatch = useDispatch();

  const onClick = (e) => {
    if (isSelectable) {
      sendAnalyticsEvent("Property Card Click", {
        type: "select property",
        details: property,
      });
      return onSelect(property);
    }

    const selectedCard = e.currentTarget;
    const map = getGlobalMapInstance();
    debouncedMapSetZoom(map, 18);
    debouncedMapPanTo(map, {
      lat: parseFloat(property.latitude),
      lng: parseFloat(property.longitude),
    });

    // center the property between so it doesn't encroach too much on the sidepanel
    let sidePanel = document.getElementById("property-panel");
    let sidePanelWidth = sidePanel.offsetWidth;
    setTimeout(() => {
      panMapByPixels(map, sidePanelWidth / 2, 0);
    }, 250);

    selectPropertyMarker(property.id);

    // select the card
    document.querySelectorAll(".sold-property-panel-card").forEach((el) => {
      if (el.id != selectedCard.id) {
        el.classList.remove("selected");
      }
    });

    selectedCard.classList.add("selected");
  };

  const renderSimilarityScore = () => {
    if (property.similarityScore || property.similarityScore === 0) {
      const similarityPercantage = parseInt(property.similarityScore * 100);
      const tooltipText = `${similarityPercantage}% ${i18n("similarity")}`;
      const tooltipId = "similarity-tooltip-" + property.id;

      if (property.similarityScore >= 0.9) {
        return (
          <div
            className="sold-property-panel-card_similarity-score"
            data-tooltip-id={tooltipId}
          >
            <div className="sold-property-panel-card_similarity-score_circle high"></div>
            <span>{similarityPercantage}%</span>
            <Tooltip className="chip-tooltip" id={tooltipId}>
              <div>{tooltipText}</div>
            </Tooltip>
          </div>
        );
      }

      if (property.similarityScore >= 0.7) {
        return (
          <div
            className="sold-property-panel-card_similarity-score"
            data-tooltip-id={tooltipId}
          >
            <div className="sold-property-panel-card_similarity-score_circle medium"></div>

            <span>{similarityPercantage}%</span>
            <Tooltip className="chip-tooltip" id={tooltipId}>
              <div>{tooltipText}</div>
            </Tooltip>
          </div>
        );
      }

      return (
        <div
          className="sold-property-panel-card_similarity-score"
          data-tooltip-id={tooltipId}
        >
          <div className="sold-property-panel-card_similarity-score_circle low"></div>
          <span>{similarityPercantage}%</span>
          <Tooltip className="chip-tooltip" id={tooltipId}>
            <div>{tooltipText}</div>
          </Tooltip>
        </div>
      );
    }
  };

  const onCatastroClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    openCatastroRef(property.refcat);
  };

  const openPricingModal = () => {
    dispatch(setPricingModal(true));
  };
  // the image url is the refcat.jpg in the dev-catastro-images s3 bucket
  const imageUrl = property.refcat
    ? `https://dev-catastro-images.s3.amazonaws.com/${property.refcat}.jpg`
    : require(`../../assets/property/sold-properties-placeholder.png`);

  // do not show the card if the user is on a free plan
  if (getSubscriptionPlan() == null) {
    return (
      <div
        className="sold-property-panel-card blurred"
        onClick={openPricingModal}
      >
        <a
          target={property.id}
          className="sold-property-panel-card-title-sold-properties"
        >
          <UpgradeView />
        </a>
        <div className="sold-property-panel-card_header">
          <div className="sold-property-panel-card_header_badge">
            <span>
              {i18n("Sold")}:{" "}
              <span className="sold-property-panel-card_header_badge_date">
                {i18n("Upgrade to view")}
              </span>
            </span>
          </div>
          {/* <div className="sold-property-panel-card_header_right">
            <span>{i18n("Upgrade to view")}</span>
          </div> */}
        </div>
        <div className="divider"></div>
        <div className="sold-property-panel-card_body">
          <div className="sold-property-panel-card_body_title">
            <span>{i18n("Upgrade to view")}</span>
          </div>
          <span className="sold-property-panel-card_body_sold-for">
            {i18n("Sold for")}:
          </span>
          <span className="sold-property-panel-card_body_sold-for-price">
            <span className="blurred-text">
              {formatPrice(randomNumberWithStep(1000000, 10000000, 100000))}
            </span>
            <span className="blurred-text">
              ({formatPrice(randomNumberWithStep(100, 1000, 10))}/㎡)
            </span>
          </span>
          <div className="sold-property-panel-card_body_size">
            <span className="blurred-text">
              {randomNumberWithStep(100, 1000, 10)}m² {i18n("built")}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id={"sold-property-" + property.id}
      className="sold-property-panel-card"
      onClick={onClick}
    >
      <div className="sold-property-panel-card_image">
        <img
          src={
            imageError
              ? require(`../../assets/property/sold-properties-placeholder.png`)
              : imageUrl
          }
          alt={getPropertyTitle(property)}
          loading="lazy"
          onError={() => setImageError(true)}
        />
      </div>
      {!imageError && <div className="sold-property-panel-card_gradient"></div>}
      {isSelectable && (
        <div className="sold-property-panel-card_header">
          <div className="sold-property-panel-card_header_similarity-score">
            {renderSimilarityScore()}
            <div className="sold-property-panel-card_checkbox">
              <Checkbox fillOpacityUnchecked="1" checked={selected} />
            </div>
          </div>
        </div>
      )}
      <div
        className={`sold-property-panel-card_header ${isSelectable ? "selectable-header" : ""}`}
      >
        <div className="sold-property-panel-card_header_badge">
          <span>
            {i18n("Sold")}:{" "}
            <span className="sold-property-panel-card_header_badge_date">
              {soldDate}
            </span>
          </span>
        </div>
      </div>
      {/* <div className="divider"></div> */}
      <div className="sold-property-panel-card_body">
        <div className="sold-property-panel-card_body_title">
          <span>{getPropertyTitle(property)}</span>
        </div>
        <span className="sold-property-panel-card_body_sold-for">
          {i18n("Sold for")}:
        </span>
        <span className="sold-property-panel-card_body_sold-for-price">
          <span>{price} €</span>
          <span>({pricePerM2} €/㎡)</span>
        </span>
        <div className="sold-property-panel-card_body_size">
          <span>
            {property.size}m² {i18n("built")}
          </span>
        </div>
      </div>
      <div className="divider"></div>
      <div className="sold-property-panel-card_footer">
        <div className="sold-property-panel-card_footer_poligon-dot-content">
          <div
            className="property-panel-card_footer_poligon-dot-content"
            data-tooltip-id={`polygon-selection-tooltip-polygon-${property.id}`}
            data-tooltip-content={
              polygon && polygon.metadata
                ? polygon.metadata.name
                : i18n("Custom area")
            }
          >
            <div className="polygon-selection-tray_row_item">
              <div
                className="polygon-selection-tray_row_item_circle"
                style={{
                  backgroundColor: polygon ? polygon.colourCode : "#fff",
                }}
              ></div>
              <Tooltip
                className="chip-tooltip"
                id={`polygon-selection-tooltip-polygon-${property.id}`}
              />
            </div>
          </div>
        </div>
        {!isSelectable && (
          <div
            className="sold-property-panel-card_footer_catastro"
            onClick={onCatastroClick}
          >
            <span>{i18n("Catastro")}</span>
            <img src={rightChevron} alt="Catastro" />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect((state) => ({
  user: state.user,
  drawnPolygons: state.polygon.drawnPolygons,
}))(SoldPropertyCard);
