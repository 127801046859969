import { useState } from "react";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import { setCmaProperty, setCmaSearchResults, setManualValuationMode } from "actions/cmaActions";
import { setCanDrawPlots, setShowPlots } from "actions/plotsActions";
import { clearDrawnPlots, fetchPlotsByMapBounds } from "utils/map";
import { CMA_STAGE_SEARCH_ADDRESS } from "reducers/cmaReducer";

function ManualValuationToggle({ cma, plots, dispatch }) {
  const onManualValuationModeChange = (manualMode) => {
    dispatch(setManualValuationMode(manualMode));
    dispatch(setCanDrawPlots(!manualMode));
    dispatch(setCmaSearchResults([]));
    dispatch(setCmaProperty(null));

    if (!manualMode) {
      window.cmaManualValuationMarker?.setMap(null);
      fetchPlotsByMapBounds();
    } else {
      clearDrawnPlots();
    }
  };

  if (!window.location.pathname.startsWith("/valuation") || cma.stage !== CMA_STAGE_SEARCH_ADDRESS) {
    return null;
  }

  return (
    <div className="search-bar_toggle-content cma-toggle-valuation-mode" style={{ position: 'absolute', bottom: '12px', right: '372px' }}>
      <div
        onClick={() => onManualValuationModeChange(false)}
        className={`search-bar_toggle-btn sale ${!cma.manualValuationMode ? 'selected' : ''}`}
      >
        <span>{i18n("Valuate with Catastro")}</span>
      </div>
      <div className="vertical-divider"></div>
      <div
        onClick={() => onManualValuationModeChange(true)}
        className={`search-bar_toggle-btn sold ${cma.manualValuationMode ? 'selected' : ''}`}
      >
        <span>{i18n("Valuate by Map")}</span>
      </div>
    </div>
  );
}

export default connect((state) => ({
  cma: state.cma,
  plots: state.plots,
}))(ManualValuationToggle);
