import { getLang } from "i18n/localisation";
import fireApiRequest from "./core";

var GENERATE_FILTERS_FROM_NATURAL_LANGUAGE_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/ai_filters_from_natural_language";
var PROPERTY_CHAT_ENDPOINT =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/property_chat";
var GENERATE_DESCRIPTION =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/generate_description";
var GEOLOCATE_PROPERTY =
  "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/geolocate_property";

if (process.env.REACT_APP_NODE_ENV === "production") {
  GENERATE_FILTERS_FROM_NATURAL_LANGUAGE_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/ai_filters_from_natural_language";
  PROPERTY_CHAT_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/property_chat";
  GENERATE_DESCRIPTION =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/generate_description";
  GEOLOCATE_PROPERTY =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/geolocate_property";
}

/**
 * Fetches properties by criteria
 * @param {Object} property criteria object
 * @returns
 */
export async function generateFiltersFromNaturalLanguageRequest(query) {
  try {
    let data = await fireApiRequest(
      GENERATE_FILTERS_FROM_NATURAL_LANGUAGE_ENDPOINT,
      {
        method: "POST",
        body: JSON.stringify({
          query,
          lang: getLang().code,
        }),
      },
    );

    return data;
  } catch (e) {
    console.log(e);
  }

  return { filters: null, area: null };
}

export async function propertyChatRequest(chatHistory, propertyObject) {
  try {
    let data = await fireApiRequest(PROPERTY_CHAT_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        chat_history: chatHistory,
        property_obj: propertyObject,
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return { response: null };
}

export async function generateDescriptionRequest(propertyObject, lang = "en") {
  try {
    let data = await fireApiRequest(GENERATE_DESCRIPTION, {
      method: "POST",
      body: JSON.stringify({
        property_obj: propertyObject,
        lang,
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return { response: null };
}

export async function geolocatePropertyRequest(propertyId) {
  try {
    let data = await fireApiRequest(GEOLOCATE_PROPERTY, {
      method: "POST",
      body: JSON.stringify({
        property_id: propertyId,
      }),
    });

    return data;
  } catch (e) {
    console.log(e);
  }

  return null;
}
