import { setAppWideLogoLoader, setPricingModal } from "actions/miscActions";
import { Auth } from "aws-amplify";
import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "sonner";
import {
  getSubscriptionPlan,
  isTeamMember,
  isTeamOwner,
} from "utils/userHelpers";
import billingIconActive from "../../assets/settings/active/billing_active.svg";
import companyDetailsIconActive from "../../assets/settings/active/company_active.svg";
import personalIconActive from "../../assets/settings/active/personal_active.svg";
import preferencesIconActive from "../../assets/settings/active/preferences_active.svg";
import languageIcon from "../../assets/settings/language.svg";
import languageIconActive from "../../assets/settings/active/language_active.svg";
import teamIconActive from "../../assets/settings/active/team_active.svg";
import billingIcon from "../../assets/settings/billing.svg";
import supportIcon from "../../assets/settings/support.svg";
import companyDetailsIcon from "../../assets/settings/company.svg";
import logoutIcon from "../../assets/settings/logout.svg";
import personalIcon from "../../assets/settings/personal.svg";
import preferencesIcon from "../../assets/settings/preferences.svg";
import teamIcon from "../../assets/settings/team.svg";
import "./settings.scss";
import SettingsBillingContent from "./SettingsBillingContent";
import SettingsCompanyContent from "./SettingsCompanyContent";
import SettingsPersonalContent from "./SettingsPersonalContent";
import SettingsPreferencesContent from "./SettingsPreferencesContent";
import SettingsTeamContent from "./SettingsTeamContent";
import SignOutModal from "./SignOutModal";
import ValuationContent from "./ValuationContent";

const SETTINGS_TAB_INDEXES = {
  PERSONAL: 0,
  COMPANY_DETAILS: 1,
  PREFERENCES: 2,
  BILLING: 3,
  TEAM: 4,
  LOGOUT: 5,
  SUPPORT: 6,
  VALUATION_CONDITIONS: 7,
};

window.updatePaymentMsgDone = false;

const SettingsTabs = (props) => {
  let initialTab = SETTINGS_TAB_INDEXES.PERSONAL;
  const [signOutModalOpen, setSignOutModalOpen] = useState(false);

  const signOut = async () => {
    props.dispatch(setAppWideLogoLoader(true));
    await Auth.signOut();
    window.location.href = "/";
  };

  if (window.location.href.includes("update_payment=true")) {
    initialTab = SETTINGS_TAB_INDEXES.BILLING;
  }

  if (window.location.href.includes("tab=valuation")) {
    initialTab = SETTINGS_TAB_INDEXES.VALUATION_CONDITIONS;
  }

  const [tabIndex, setTabIndex] = useState(initialTab);

  useEffect(() => {
    if (
      window.location.href.includes("update_payment=true") &&
      !window.updatePaymentMsgDone
    ) {
      window.updatePaymentMsgDone = true;
      toast.message(i18n("Payment method updated successfully"), {
        duration: 3000,
      });
    }
  }, []);

  const renderPersonalSettings = () => {
    return <SettingsPersonalContent />;
  };

  const renderCompanyDetails = () => {
    return <SettingsCompanyContent />;
  };

  const renderPreferences = () => {
    return <SettingsPreferencesContent />;
  };

  const renderBilling = () => {
    return <SettingsBillingContent />;
  };

  const renderTeam = () => {
    return <SettingsTeamContent />;
  };

  const renderValuationConditions = () => {
    return <ValuationContent />;
  };

  const renderTabContent = () => {
    switch (tabIndex) {
      case SETTINGS_TAB_INDEXES.PERSONAL:
        return renderPersonalSettings();
      case SETTINGS_TAB_INDEXES.COMPANY_DETAILS:
        return renderCompanyDetails();
      case SETTINGS_TAB_INDEXES.PREFERENCES:
        return renderPreferences();
      case SETTINGS_TAB_INDEXES.BILLING:
        return renderBilling();
      case SETTINGS_TAB_INDEXES.TEAM:
        return renderTeam();
      case SETTINGS_TAB_INDEXES.VALUATION_CONDITIONS:
        return renderValuationConditions();
      default:
        return null;
    }
  };

  const onTabClick = (index) => {
    setTabIndex(index);
  };

  return (
    <div className="settings_tabs">
      <div className="settings_tabs_nav_wrapper">
        <div className="settings_tabs_nav">
          <div
            onClick={() => onTabClick(SETTINGS_TAB_INDEXES.PERSONAL)}
            className={
              "settings_tabs_nav_item" +
              (tabIndex === SETTINGS_TAB_INDEXES.PERSONAL ? " active" : "")
            }
          >
            {tabIndex === SETTINGS_TAB_INDEXES.PERSONAL ? (
              <img src={personalIconActive} alt="personal icon" />
            ) : (
              <img src={personalIcon} alt="personal icon" />
            )}
            <span>{i18n("Personal details")}</span>
          </div>
          <div className="divider"></div>
          {!isTeamMember() && (
            <>
              <div
                onClick={() => onTabClick(SETTINGS_TAB_INDEXES.COMPANY_DETAILS)}
                className={
                  "settings_tabs_nav_item" +
                  (tabIndex === SETTINGS_TAB_INDEXES.COMPANY_DETAILS
                    ? " active"
                    : "")
                }
              >
                {tabIndex === SETTINGS_TAB_INDEXES.COMPANY_DETAILS ? (
                  <img src={companyDetailsIconActive} alt="details icon" />
                ) : (
                  <img src={companyDetailsIcon} alt="details icon" />
                )}
                <span>{i18n("Company details")}</span>
              </div>
              <div className="divider"></div>
            </>
          )}
          {isTeamOwner() && (
            <>
              <div
                onClick={() => onTabClick(SETTINGS_TAB_INDEXES.TEAM)}
                className={
                  "settings_tabs_nav_item" +
                  (tabIndex === SETTINGS_TAB_INDEXES.TEAM ? " active" : "")
                }
              >
                {tabIndex === SETTINGS_TAB_INDEXES.TEAM ? (
                  <img src={teamIconActive} alt="team icon" />
                ) : (
                  <img src={teamIcon} alt="team icon" />
                )}
                <span>{i18n("Team")}</span>
              </div>
              <div className="divider"></div>
            </>
          )}
          <div
            onClick={() =>
              onTabClick(SETTINGS_TAB_INDEXES.VALUATION_CONDITIONS)
            }
            className={
              "settings_tabs_nav_item" +
              (tabIndex === SETTINGS_TAB_INDEXES.VALUATION_CONDITIONS
                ? " active"
                : "")
            }
          >
            {tabIndex === SETTINGS_TAB_INDEXES.VALUATION_CONDITIONS ? (
              <img
                src={preferencesIconActive}
                alt="valuation conditions icon"
              />
            ) : (
              <img src={preferencesIcon} alt="valuation conditions icon" />
            )}
            <span>
              {i18n("Valuation")}
            </span>
          </div>
          <div className="divider"></div>
          <div
            onClick={() => onTabClick(SETTINGS_TAB_INDEXES.PREFERENCES)}
            className={
              "settings_tabs_nav_item" +
              (tabIndex === SETTINGS_TAB_INDEXES.PREFERENCES ? " active" : "")
            }
          >
            {tabIndex === SETTINGS_TAB_INDEXES.PREFERENCES ? (
              <img src={languageIconActive} alt="preferences icon" />
            ) : (
              <img src={languageIcon} alt="preferences icon" />
            )}
            <span>{i18n("Language")}</span>
          </div>
          {!isTeamMember() && (
            <>
              <div className="divider"></div>
              <div
                onClick={() => onTabClick(SETTINGS_TAB_INDEXES.BILLING)}
                className={
                  "settings_tabs_nav_item" +
                  (tabIndex === SETTINGS_TAB_INDEXES.BILLING ? " active" : "")
                }
              >
                {tabIndex === SETTINGS_TAB_INDEXES.BILLING ? (
                  <img src={billingIconActive} alt="billings icon" />
                ) : (
                  <img src={billingIcon} alt="billings icon" />
                )}
                <span>{i18n("Billing and Plan")}</span>
              </div>
            </>
          )}
          <div className="divider"></div>
          <a
            href="mailto:support@irealty.app"
            className={
              "settings_tabs_nav_item" +
              (tabIndex === SETTINGS_TAB_INDEXES.SUPPORT ? " active" : "")
            }
          >
            <img src={supportIcon} alt="logout icon" />
            <span>{i18n("Support")}</span>
          </a>
          <div className="divider"></div>
          <div
            onClick={() => setSignOutModalOpen(true)}
            className={
              "settings_tabs_nav_item" +
              (tabIndex === SETTINGS_TAB_INDEXES.LOGOUT ? " active" : "")
            }
          >
            <img src={logoutIcon} alt="logout icon" />
            <span>{i18n("Log out")}</span>
          </div>
        </div>
      </div>
      {renderTabContent()}
      <SignOutModal
        open={signOutModalOpen}
        closeModal={() => setSignOutModalOpen(false)}
        signOut={signOut}
      />
    </div>
  );
};

export default connect((state) => ({
  lang: state.user.userData.preferences.lang,
}))(SettingsTabs);
