import fireApiRequest from "./core";

var GET_LEADS_ENDPOINT =
  "https://jmngyu2d2e.execute-api.eu-west-2.amazonaws.com/dev/get_leads";
var GET_LEAD_ENDPOINT =
  "https://jmngyu2d2e.execute-api.eu-west-2.amazonaws.com/dev/get_lead";
var UPDATE_LEAD_ENDPOINT =
  "https://jmngyu2d2e.execute-api.eu-west-2.amazonaws.com/dev/update_lead";
var CREATE_LEAD_ENDPOINT =
  "https://jmngyu2d2e.execute-api.eu-west-2.amazonaws.com/dev/create_lead";
var DELETE_LEAD_ENDPOINT =
  "https://jmngyu2d2e.execute-api.eu-west-2.amazonaws.com/dev/delete_lead";

if (process.env.REACT_APP_NODE_ENV === "production") {
  GET_LEADS_ENDPOINT =
    "https://f22a66v9qh.execute-api.eu-west-2.amazonaws.com/production/get_leads";
  GET_LEAD_ENDPOINT =
    "https://f22a66v9qh.execute-api.eu-west-2.amazonaws.com/production/get_lead";
  UPDATE_LEAD_ENDPOINT =
    "https://f22a66v9qh.execute-api.eu-west-2.amazonaws.com/production/update_lead";
  DELETE_LEAD_ENDPOINT =
    "https://f22a66v9qh.execute-api.eu-west-2.amazonaws.com/production/delete_lead";
  CREATE_LEAD_ENDPOINT =
    "https://f22a66v9qh.execute-api.eu-west-2.amazonaws.com/production/create_lead";
}

/**
 * Get leads paginated
 */
export async function getLeadsRequest(page, teamId, searchQuery = null) {
  let result = null;
  let params = {
    page,
    team_id: teamId,
    page_size: 25
  };

  if (searchQuery) {
    params.query = searchQuery;
  }

  try {
    result = await fireApiRequest(GET_LEADS_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(params),
    });
  } catch (e) {
    console.log(e);
  }

  return result;
}

/**
 * Get lead by id
 */
export async function getLeadRequest(leadId) {
  try {
    return await fireApiRequest(GET_LEAD_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        lead_id: leadId,
      }),
    });
  } catch (e) {
    console.log(e);
  }

  return null;
}

/**
 * Save lead
 */
export async function updateLeadRequest(leadId, lead) {
  try {
    await fireApiRequest(UPDATE_LEAD_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        lead_id: leadId,
        lead_data: lead,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Create lead
 */
export async function createLeadRequest(lead) {
  try {
    await fireApiRequest(CREATE_LEAD_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({
        lead_data: lead,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Delete lead
 */
export async function deleteLeadRequest(leadId) {
  await fireApiRequest(DELETE_LEAD_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      lead_id: leadId,
    }),
  });
}
