import { i18n } from "i18n/localisation";
import store from "../../store";
import { getPriceDropPercentage, getPricePerM2 } from "../../utils/helpers";

export const sortTypes = {
  priceAsc: 0,
  priceDesc: 1,
  relevance: 2,
  sizeAsc: 3,
  sizeDesc: 4,
  priceM2Asc: 5,
  priceM2Desc: 6,
  priceReductionDesc: 7,
  newest: 8,
  oldest: 9,
  newestSold: 10,
  oldestSold: 11,
};

export const sortFuncs = {
  priceAsc: (properties) => {
    return [...properties].sort(
      (a, b) => parseFloat(a.price) - parseFloat(b.price),
    );
  },

  priceDesc: (properties) => {
    return [...properties].sort(
      (a, b) => parseFloat(b.price) - parseFloat(a.price),
    );
  },

  relevance: (properties) => {
    let collectionProperties = store
      .getState()
      .collections.collections.reduce((prev, cur) => {
        return [].concat(prev, cur.properties);
      }, []);

    const isPropertyInCollection = (property) => {
      return collectionProperties.some((c) => c.id === property.id) ? 1 : 0;
    };

    return [...properties].sort(
      (a, b) => isPropertyInCollection(b) - isPropertyInCollection(a),
    );
  },

  priceReductionDesc: (properties) => {
    return [...properties].sort((a, b) => {
      let aValue = getPriceDropPercentage(a);
      let bValue = getPriceDropPercentage(b);
      return bValue - aValue;
    });
  },

  priceM2Asc: (properties) => {
    return [...properties].sort((a, b) => getPricePerM2(a) - getPricePerM2(b));
  },

  priceM2Desc: (properties) => {
    return [...properties].sort((a, b) => getPricePerM2(b) - getPricePerM2(a));
  },

  sizeAsc: (properties) => {
    return [...properties].sort(
      (a, b) => parseFloat(a.size) - parseFloat(b.size),
    );
  },

  sizeDesc: (properties) => {
    return [...properties].sort(
      (a, b) => parseFloat(b.size) - parseFloat(a.size),
    );
  },

  newest: (properties) => {
    return [...properties].sort(
      (a, b) =>
        parseFloat(b.initialImportTime) - parseFloat(a.initialImportTime),
    );
  },

  oldest: (properties) => {
    return [...properties].sort(
      (a, b) =>
        parseFloat(a.initialImportTime) - parseFloat(b.initialImportTime),
    );
  },

  newestSold: (properties) => {
    return [...properties].sort(
      (a, b) => parseFloat(b.timestamp) - parseFloat(a.timestamp),
    );
  },

  oldestSold: (properties) => {
    return [...properties].sort(
      (a, b) => parseFloat(a.timestamp) - parseFloat(b.timestamp),
    );
  },
};

export const SORT_LABEL_MAPPINGS = {
  Relevance: () => i18n("Relevance"),
  "Lowest price": () => i18n("Lowest price"),
  "Highest price": () => i18n("Highest price"),
  "Price drop": () => i18n("Price drop"),
  "Lowest price ㎡": () => i18n("Lowest price ㎡"),
  "Highest price ㎡": () => i18n("Highest price ㎡"),
  Smallest: () => i18n("Smallest"),
  Biggest: () => i18n("Biggest"),
  Newest: () => i18n("Newest"),
  Oldest: () => i18n("Oldest"),
};

export const SORT_OPTIONS = [
  {
    type: sortTypes.relevance,
    label: "Relevance",
  },
  {
    type: sortTypes.priceAsc,
    label: "Lowest price",
  },
  {
    type: sortTypes.priceDesc,
    label: "Highest price",
  },
  {
    type: sortTypes.priceReductionDesc,
    label: "Price drop",
  },
  {
    type: sortTypes.priceM2Asc,
    label: "Lowest price ㎡",
  },
  {
    type: sortTypes.priceM2Desc,
    label: "Highest price ㎡",
  },
  {
    type: sortTypes.sizeAsc,
    label: "Smallest",
  },
  {
    type: sortTypes.sizeDesc,
    label: "Biggest",
  },
  {
    type: sortTypes.newest,
    label: "Newest",
  },
  {
    type: sortTypes.oldest,
    label: "Oldest",
  },
];

export const SOLD_PROPERTIES_SORT_OPTIONS = [
  {
    type: sortTypes.newestSold,
    label: "Newest",
  },
  {
    type: sortTypes.oldestSold,
    label: "Oldest",
  },
  {
    type: sortTypes.priceAsc,
    label: "Lowest price",
  },
  {
    type: sortTypes.priceDesc,
    label: "Highest price",
  },
  {
    type: sortTypes.priceM2Asc,
    label: "Lowest price ㎡",
  },
  {
    type: sortTypes.priceM2Desc,
    label: "Highest price ㎡",
  },
  {
    type: sortTypes.sizeAsc,
    label: "Smallest",
  },
  {
    type: sortTypes.sizeDesc,
    label: "Biggest",
  },
];

// gets the sort function to apply a sort to list of properties
export function getSortFunction(sort) {
  if (sort.type === sortTypes.priceAsc) {
    return sortFuncs.priceAsc;
  }

  if (sort.type === sortTypes.priceDesc) {
    return sortFuncs.priceDesc;
  }

  if (sort.type === sortTypes.relevance) {
    return sortFuncs.relevance;
  }

  if (sort.type === sortTypes.priceReductionDesc) {
    return sortFuncs.priceReductionDesc;
  }

  if (sort.type === sortTypes.priceM2Asc) {
    return sortFuncs.priceM2Asc;
  }

  if (sort.type === sortTypes.priceM2Desc) {
    return sortFuncs.priceM2Desc;
  }

  if (sort.type === sortTypes.sizeAsc) {
    return sortFuncs.sizeAsc;
  }

  if (sort.type === sortTypes.sizeDesc) {
    return sortFuncs.sizeDesc;
  }

  if (sort.type === sortTypes.newest) {
    return sortFuncs.newest;
  }

  if (sort.type === sortTypes.oldest) {
    return sortFuncs.oldest;
  }

  if (sort.type === sortTypes.newestSold) {
    return sortFuncs.newestSold;
  }

  if (sort.type === sortTypes.oldestSold) {
    return sortFuncs.oldestSold;
  }

  return sortFuncs.relevance;
}

/**
 * Converts ai sort string to frontend sort object
 */
export function aiSortToFrontendSort(aiSort) {
  let sort = null;

  if (aiSort === "price_asc") {
    sort = SORT_OPTIONS.find((so) => so.type === sortTypes.priceAsc);
  }

  if (aiSort === "price_desc") {
    sort = SORT_OPTIONS.find((so) => so.type === sortTypes.priceDesc);
  }

  if (aiSort === "size_asc") {
    sort = SORT_OPTIONS.find((so) => so.type === sortTypes.sizeAsc);
  }

  if (aiSort === "size_desc") {
    sort = SORT_OPTIONS.find((so) => so.type === sortTypes.sizeDesc);
  }

  if (aiSort === "price_drop") {
    sort = SORT_OPTIONS.find((so) => so.type === sortTypes.priceReductionDesc);
  }

  if (aiSort === "price_per_m2_asc") {
    sort = SORT_OPTIONS.find((so) => so.type === sortTypes.priceM2Asc);
  }

  if (aiSort === "price_per_m2_desc") {
    sort = SORT_OPTIONS.find((so) => so.type === sortTypes.priceM2Desc);
  }

  return sort;
}

export function savedSortToFrontendSort(sort) {
  return {
    ...sort,
    type: parseFloat(sort.type),
  };
}

// gets the sort function to apply a sort to list of properties
function scoreAttribute(attributeAVal, attributeBVal, attributeWeight) {
  if (parseFloat(attributeAVal) == 0) {
    return 0;
  }

  if (parseFloat(attributeBVal) == 0) {
    return 0;
  }

  if (parseFloat(attributeAVal) > parseFloat(attributeBVal)) {
    return (
      (parseFloat(attributeBVal) / parseFloat(attributeAVal)) * attributeWeight
    );
  }

  return (
    (parseFloat(attributeAVal) / parseFloat(attributeBVal)) * attributeWeight
  );
}

// set weights for each attribute
const BEDS_WEIGHT = 0.25;
const BATHS_WEIGHT = 0.15;
const SIZE_WEIGHT = 0.3;
const PLOT_WEIGHT = 0.15;
const FEATURES_WEIGHT = 0.15;

// land weights
const LAND_BUILDABLE_WEIGHT = 0.3;
const LAND_PLOT_WEIGHT = 0.5;
const LAND_BUILDABLE_FLOORS_WEIGHT = 0.13;
const LAND_FEATURES_WEIGHT = 0.07;

// commercial weights
const COMMERCIAL_PLOT_WEIGHT = 0.5;
const COMMERCIAL_FLOORS_WEIGHT = 0.1;
const COMMERCIAL_ROOMS_WEIGHT = 0.1;
const COMMERCIAL_TOILETS_WEIGHT = 0.04;
const COMMERCIAL_FEATURES_WEIGHT = 0.26;

// get the feature score for two properties
function scoreFeatures(propertyA, propertyB) {
  let features = [];
  let score = 0;

  Object.keys(propertyA.features).forEach((key) => {
    if (propertyA.features[key]) {
      features.push(key);
    }
  });

  if (features.length === 0) {
    return FEATURES_WEIGHT;
  }

  let divisionFactor = FEATURES_WEIGHT / features.length;
  for (let feature of features) {
    if (propertyB.features[feature]) {
      score += divisionFactor;
    }
  }

  return score;
}

// get the feature score for two lands
function scoreLandFeatures(propertyA, propertyB) {
  let features = [];
  let score = 0;

  if (!propertyA.land_features || !propertyB.land_features) {
    return 0;
  }

  Object.keys(propertyA.land_features).forEach((key) => {
    if (propertyA.land_features[key]) {
      features.push(key);
    }
  });

  if (features.length === 0) {
    return LAND_FEATURES_WEIGHT;
  }

  let divisionFactor = LAND_FEATURES_WEIGHT / features.length;
  for (let feature of features) {
    if (propertyB.land_features[feature]) {
      score += divisionFactor;
    }
  }

  return score;
}

// get the feature score for two commercial properties
function scoreCommercialFeatures(propertyA, propertyB) {
  let features = [];
  let score = 0;

  if (!propertyA.commercial_features || !propertyB.commercial_features) {
    return 0;
  }

  Object.keys(propertyA.commercial_features).forEach((key) => {
    if (propertyA.commercial_features[key]) {
      features.push(key);
    }
  });

  if (features.length === 0) {
    return COMMERCIAL_FEATURES_WEIGHT;
  }

  let divisionFactor = COMMERCIAL_FEATURES_WEIGHT / features.length;
  for (let feature of features) {
    if (propertyB.commercial_features[feature]) {
      score += divisionFactor;
    }
  }

  return score;
}

// gets the similarity score for two properties
export function getPropertySimilarityScore(propertyA, propertyB) {
  // if propertyB is a sold property, set the similarity score to just be size
  if (propertyB.saleType === "sold") {
    return scoreAttribute(propertyA.size, propertyB.size, 1);
  }

  let score = 0;
  score += scoreAttribute(propertyA.rooms, propertyB.rooms, BEDS_WEIGHT);
  score += scoreAttribute(
    propertyA.bathrooms,
    propertyB.bathrooms,
    BATHS_WEIGHT,
  );

  score += scoreAttribute(propertyA.size, propertyB.size, SIZE_WEIGHT);

  if (propertyA.plotSize && propertyB.plotSize) {
    score += scoreAttribute(
      propertyA.plotSize,
      propertyB.plotSize,
      PLOT_WEIGHT,
    );
  } else {
    score += scoreAttribute(propertyA.size, propertyB.size, PLOT_WEIGHT);
  }

  let featuresScore = scoreFeatures(propertyA, propertyB);

  // if no features are present, we have to rebase the remaining weights up
  // to 1.0 by calculating the scaling factor, which is 1 divided by the sum of
  // the remaining weights
  if (featuresScore == 0) {
    score += scoreAttribute(propertyA.size, propertyB.size, FEATURES_WEIGHT);
  } else {
    score += featuresScore;
  }

  // Update score based on property condition/status
  if (propertyB.saleType === "sale") {
    if (propertyA.buildingType === "property" && propertyA.status !== "all") {
      if (propertyA.status !== propertyB.status) {
        score = score * 0.75;
      }
    }

    if (propertyA.buildingType === "commercial" && propertyA.status !== "all") {
      if (propertyA.status !== propertyB.status) {
        score = score * 0.75;
      }
    }
  }

  return score;
}

// gets the similarity score for two lands
export function getLandSimilarityScore(propertyA, propertyB) {
  let score = 0;
  score += scoreAttribute(propertyA.size, propertyB.size, LAND_PLOT_WEIGHT);

  if (propertyA.land_buildableSize && propertyB.land_buildableSize) {
    score += scoreAttribute(
      propertyA.land_buildableSize,
      propertyB.land_buildableSize,
      LAND_BUILDABLE_WEIGHT,
    );
  } else {
    score += scoreAttribute(
      propertyA.size,
      propertyB.size,
      LAND_BUILDABLE_WEIGHT,
    );
  }

  if (propertyA.land_buildableFloors && propertyB.land_buildableFloors) {
    score += scoreAttribute(
      propertyA.land_buildableFloors,
      propertyB.land_buildableFloors,
      LAND_BUILDABLE_FLOORS_WEIGHT,
    );
  } else {
    score += scoreAttribute(
      propertyA.size,
      propertyB.size,
      LAND_BUILDABLE_FLOORS_WEIGHT,
    );
  }

  let featuresScore = scoreLandFeatures(propertyA, propertyB);

  // if no features are present, we have to rebase the remaining weights up
  // to 1.0 by calculating the scaling factor, which is 1 divided by the sum of
  // the remaining weights
  if (featuresScore == 0) {
    score += scoreAttribute(
      propertyA.size,
      propertyB.size,
      LAND_FEATURES_WEIGHT,
    );
  } else {
    score += featuresScore;
  }

  return score;
}

// gets the similarity score for two lands
export function getCommercialSimilarityScore(propertyA, propertyB) {
  let score = 0;
  score += scoreAttribute(
    propertyA.size,
    propertyB.size,
    COMMERCIAL_PLOT_WEIGHT,
  );

  if (propertyA.numFloors && propertyB.numFloors) {
    score += scoreAttribute(
      propertyA.numFloors,
      propertyB.numFloors,
      COMMERCIAL_FLOORS_WEIGHT,
    );
  } else {
    score += scoreAttribute(
      propertyA.size,
      propertyB.size,
      COMMERCIAL_FLOORS_WEIGHT,
    );
  }

  if (propertyA.rooms && propertyB.rooms) {
    score += scoreAttribute(
      propertyA.rooms,
      propertyB.rooms,
      COMMERCIAL_ROOMS_WEIGHT,
    );
  } else {
    score += scoreAttribute(
      propertyA.size,
      propertyB.size,
      COMMERCIAL_ROOMS_WEIGHT,
    );
  }

  if (propertyA.bathrooms && propertyB.bathrooms) {
    score += scoreAttribute(
      propertyA.rooms,
      propertyB.rooms,
      COMMERCIAL_TOILETS_WEIGHT,
    );
  } else {
    score += scoreAttribute(
      propertyA.size,
      propertyB.size,
      COMMERCIAL_TOILETS_WEIGHT,
    );
  }

  let featuresScore = scoreCommercialFeatures(propertyA, propertyB);

  // if no features are present, we have to rebase the remaining weights up
  // to 1.0 by calculating the scaling factor, which is 1 divided by the sum of
  // the remaining weights
  if (featuresScore == 0) {
    score += scoreAttribute(
      propertyA.size,
      propertyB.size,
      COMMERCIAL_FEATURES_WEIGHT,
    );
  } else {
    score += featuresScore;
  }

  return score;
}

// sorts properties by similarity to propertyA
export function cmaSort(propertyA, properties) {
  // properties
  if (propertyA.buildingType === "property") {
    // interpolate similarity score into property object
    let interpolatedProperties = properties.map((property) => {
      return {
        ...property,
        similarityScore: getPropertySimilarityScore(propertyA, property),
      };
    });

    return interpolatedProperties.sort((a, b) => {
      return b.similarityScore - a.similarityScore;
    });
  }

  // lands
  if (propertyA.buildingType === "land") {
    // interpolate similarity score into property object
    let interpolatedProperties = properties.map((property) => {
      return {
        ...property,
        similarityScore: getLandSimilarityScore(propertyA, property),
      };
    });

    return interpolatedProperties.sort((a, b) => {
      return b.similarityScore - a.similarityScore;
    });
  }

  // commercial properties
  if (propertyA.buildingType === "commercial") {
    // interpolate similarity score into property object
    let interpolatedProperties = properties.map((property) => {
      return {
        ...property,
        similarityScore: getCommercialSimilarityScore(propertyA, property),
      };
    });

    return interpolatedProperties.sort((a, b) => {
      return b.similarityScore - a.similarityScore;
    });
  }

  return properties;
}
