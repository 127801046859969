import { i18n } from "i18n/localisation";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { roundToNearest1000, roundToNearest50 } from "utils/helpers";
import "./cma.scss";
import { connect } from "react-redux";

const ValuationSlider = ({
  originalValuation,
  onValuationChange,
  step = 1000,
  saleType,
  ...props
}) => {
  const [value, setValue] = useState(originalValuation);
  let min = roundToNearest1000(parseInt(originalValuation * 0.75));
  let max = roundToNearest1000(parseInt(originalValuation * 1.25));

  if (saleType == "rent") {
    min = roundToNearest50(parseInt(originalValuation * 0.75));
    max = roundToNearest50(parseInt(originalValuation * 1.25));
  }

  const sliderRef = useRef(null);
  const valuationSliderTooltipId = "valuation-slider-tooltip";

  useEffect(() => {
    updateSliderBackground();
  }, [value]);

  const handleChange = (e) => {
    setValue(parseInt(e.target.value));
  };

  const onDragEnd = (e) => {
    if (onValuationChange) {
      onValuationChange(parseInt(e.target.value));
    }
  };

  const updateSliderBackground = () => {
    if (sliderRef.current) {
      const percentage = ((value - min) / (max - min)) * 100;
      sliderRef.current.style.background = `linear-gradient(to right, #ee3943 ${percentage}%, #ddd ${percentage}%)`;
    }
  };

  return (
    <div className="valuation-slider">
      <h2>
        {i18n("Valuation price")}{" "}
        <span data-tooltip-id={valuationSliderTooltipId} className="info-icon">
          ⓘ
        </span>
      </h2>
      <h1>
        €{value.toLocaleString()}
        {saleType == "rent" && (
          <small className="valuation-slider_monthly-label">
            {i18n("/ month")}
          </small>
        )}
      </h1>
      <div className="slider-container">
        <input
          ref={sliderRef}
          type="range"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={handleChange}
          onMouseUp={onDragEnd}
          className="slider"
        />
        <div className="slider-labels">
          {[min, originalValuation, max].map((val, index) => (
            <div
              key={index}
              className={"slider-label" + (index === 1 ? " center" : "")}
            >
              <span>€{val.toLocaleString()}</span>
              {index === 1 && (
                <div className="recommended-label">{i18n("Recommended")}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Tooltip
        className="valuation-slider-tooltip"
        id={valuationSliderTooltipId}
      >
        <h2>{i18n("Adjusting Valuation")}</h2>
        <span>
          {i18n(
            "The valuation can be adjusted 25% below or above or below the recommended price.",
          )}
        </span>
      </Tooltip>
    </div>
  );
};

export default connect((state) => ({
  saleType: state.filters.saleType,
}))(ValuationSlider);
