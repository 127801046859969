import Dashboard from "../Dashboard";
import PropertyMap from "../map/PropertyMap";
import { i18n } from "i18n/localisation";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import React from "react";
import magnify from "../../assets/search/magnify.svg";
import { userHasRole } from "utils/userHelpers";

function CatastroSearchPage({ catastro }) {
  if (!userHasRole("catastro")) {
    window.location.href = "/";
  }

  const overlayContent = (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100%', 
      padding: '20px',
      textAlign: 'center'
    }}>
      <div style={{ fontSize: '48px', marginBottom: '20px' }}>
        <img src={magnify} alt="magnify" />
      </div>
      <h3 style={{ marginBottom: '10px', color: '#ffffff', fontSize: '16px', maxWidth: '300px' }}>
        {i18n("Zoom in to view catastro plots")}
      </h3>
      <p style={{ color: '#cacecd', fontSize: '14px', maxWidth: '300px' }}>
        {i18n("Please zoom in closer to the map to load and view the catastro plot details")}
      </p>
    </div>
  );

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("Catastro")}</title>
      </Helmet>

      <PropertyMap 
        page="catastro" 
        hideSearchBar 
        overlay={catastro && catastro.stage >= 1 ? overlayContent : null}
        zoomOverlayThreshold={16}
      />
    </Dashboard>
  );
} 

export default connect((state) => ({
  catastro: state.catastro,
}))(CatastroSearchPage);
