import React, { Component } from 'react';
import { connect } from 'react-redux';
import PrimaryInput from 'components/core/PrimaryInput';
import closeIcon from 'assets/core/close.svg';
import leftChevronIcon from 'assets/core/left_chevron.svg';
import { i18n } from 'i18n/localisation';
import './agency.scss';
import { addAgencyFilterUpdate, removeAgencyFilterUpdate } from 'lib/filter/agencyFilters';
import Dropdown from 'components/core/Dropdown';

class AgencyFilterPanel extends Component {
  // Clear all filters
  clearFilters() {
    const { agencyFilters } = this.props;
    // Remove each filter one by one
    agencyFilters.forEach(filter => {
      removeAgencyFilterUpdate(filter);
    });
  }

  // Common update filter logic
  updateFilter(type, value) {
    const { agencyFilters } = this.props;

    // Find existing filter of this type
    const existingFilter = agencyFilters.find(filter => filter.type === type);

    // If a filter of this type exists, remove it
    if (existingFilter) {
      removeAgencyFilterUpdate(existingFilter);
    }

    // Only add a new filter if the value is not empty
    if (value && value.toString().trim().length > 0) {
      const newFilter = { type, value };
      addAgencyFilterUpdate(newFilter);
    }
  }

  // Get the current value for a specific filter type
  getFilterValue(type) {
    const { agencyFilters } = this.props;
    const filter = agencyFilters.find(f => f.type === type);
    return filter ? filter.value : '';
  }

  render() {
    const { filteredAgencies, toggleFilterMode, isVisible } = this.props;

    return (
      <div className={`property-filter-panel-container agency ${isVisible ? 'slide-in' : 'slide-out'}`}>
        <div className="property-filter-panel-header">
          <div className="flex w-full">
            <div onClick={toggleFilterMode} className="primary-btn">
              <img alt="Back icon" src={leftChevronIcon} />
            </div>
            <span className="property-filter-panel-header_property-count">
              {filteredAgencies.length} {filteredAgencies.length === 1 ? i18n("agency") : i18n("agencies")}
            </span>
            <div
              onClick={() => this.clearFilters()}
              className="primary-btn ml-2"
            >
              <img src={closeIcon} alt="Close icon" />
            </div>
          </div>
          <div className="spacer"></div>
          <div ref={this.headerDivider} className="divider"></div>
        </div>
        <div className="property-filter-panel">
          <p className="property-filter-panel_section-header">{i18n("Agency name")}</p>
          <div className="property-filter-panel_section">
            <PrimaryInput
              placeholder={i18n("Search by agency name")}
              value={this.getFilterValue("name")}
              onChange={(e) => this.updateFilter("name", e.target.value)}
            />
          </div>

          <p className="property-filter-panel_section-header">{i18n("Minimum rating")}</p>
          <div className="property-filter-panel_section">
            <Dropdown
            items={[1, 2, 3, 4, 5].map(rating => ({
              label: rating,
              value: rating,
              onSelect: () => this.updateFilter("rating", rating)
            }))}
              label={this.getFilterValue("rating") ? this.getFilterValue("rating") : i18n("Minimum rating")}
            />
          </div>

          <p className="property-filter-panel_section-header">{i18n("Agency address")}</p>
          <div className="property-filter-panel_section">
            <PrimaryInput
              placeholder={i18n("Search by agency address")}
              value={this.getFilterValue("address")}
              onChange={(e) => this.updateFilter("address", e.target.value)}
            />
          </div>

          <p className="property-filter-panel_section-header">{i18n("Agency phone")}</p>
          <div className="property-filter-panel_section">
            <PrimaryInput
              placeholder={i18n("Search by agency phone")}
              value={this.getFilterValue("phone")}
              onChange={(e) => this.updateFilter("phone", e.target.value)}
            />
          </div>

          <p className="property-filter-panel_section-header">{i18n("Agency email")}</p>
          <div className="property-filter-panel_section">
            <PrimaryInput
              placeholder={i18n("Search by agency email")}
              value={this.getFilterValue("email")}
              onChange={(e) => this.updateFilter("email", e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  filteredAgencies: state.agency.filteredAgencies,
  agencyFilters: state.agency.agencyFilters
}))(AgencyFilterPanel);
