import { i18n } from "i18n/localisation";
import { useEffect, useState } from "react";
import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import PrimaryInput from "components/core/PrimaryInput";
import { createLead, updateLead } from "actions/leadActions";
import { useDispatch, useSelector } from "react-redux";

const EditLeadModal = ({ open, onClose, lead }) => {
  const dispatch = useDispatch();
  const { teamId } = useSelector((state) => state.user.userData);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (lead) {
      setName(lead.name || "");
      setEmail(lead.email || "");
      setPhone(lead.phone || "");
    } else {
      setName("");
      setEmail("");
      setPhone("");
    }
  }, [lead]);

  const handleOnSubmit = () => {
    let updatedLead = {
      name,
      email,
      phone,
    }

    if (!lead) {
      updatedLead.team_id = teamId;
      dispatch(createLead(updatedLead));
    } else {
      dispatch(updateLead(lead.id, updatedLead));
    }

    onClose();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onClose();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{lead ? i18n("Edit Lead") : i18n("Create Lead")}</DialogTitle>
        </DialogHeader>
        <div className="p-4 mb-4">
          <p className="mb-2 text-sm font-medium text-gray-700">
            {i18n("Name")}
          </p>
          <PrimaryInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={i18n("Enter name")}
          />
          <p className="mt-4 mb-2 text-sm font-medium text-gray-700">
            {i18n("Email")}
          </p>
          <PrimaryInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={i18n("Enter email")}
            type="email"
          />
          <p className="mt-4 mb-2 text-sm font-medium text-gray-700">
            {i18n("Phone")}
          </p>
          <PrimaryInput
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder={i18n("Enter phone")}
            type="tel"
          />
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button variant="text" onClick={() => onClose()}>
              {i18n("Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => handleOnSubmit()}
              className="ml-auto"
              disabled={!name || !email || !phone}
            >
              {lead ? i18n("Save") : i18n("Create")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditLeadModal;
