import { fetchCollections } from "actions/collectionActions";
import { incrementNumberOfPolygonsFetched } from "actions/polygonActions";
import { setFetchingProperties } from "actions/propertiesActions";
import { Storage } from "aws-amplify";
import { getLang, getLocalisation, i18n } from "i18n/localisation";
import store from "store";
import {
  fetchPropertiesByMunicipalityRequest,
  fetchPropertiesRequest,
} from "../api/properties";
import { PROPERTY_FEATURE_TYPE } from "../components/property/PropertyFeatureChip";
import { capitalizeWord } from "./helpers";
import {
  getBoundingRectangleFromPolygon,
  getNearestNeighbourGeoboundary,
  getPolygonById,
  isInPolygon,
} from "./polygon";
import moment from "moment";
import { sendAnalyticsEvent } from "lib/analytics";
import { getFilteredProperties } from "lib/filter/filters";
import aPropertiesIcon from "assets/property/aProperties.png";
import engelVolkersIcon from "assets/property/engel_volkers.png";
import fotocasaIcon from "assets/property/fotocasa.png";
import idealistaIcon from "assets/property/idealista.png";
import pisosIcon from "assets/property/pisos.png";
import airbnbIcon from "assets/property/airbnb.png";
import irealtyIcon from "assets/logo.svg";
import { getAllMapObjectsByType } from "db/mapStore";
import { LRUCache } from "./cache";

window.polygonAbortControllers = {};

// memory cache for properties
let propertiesCache = new LRUCache(6);

// fetches cached properties from the geohash bucket
export async function fetchCachedProperties(locationId, saleType) {
  if (!locationId) {
    return [];
  }

  let cacheKey = locationId + "_" + saleType + ".json";

  if (propertiesCache.get(cacheKey)) {
    return propertiesCache.get(cacheKey);
  }

  let url = await Storage.get(cacheKey, {
    bucket:
      process.env.REACT_APP_NODE_ENV === "production"
        ? "irealty-property-fetch"
        : "irealty-property-fetch-dev",
    expires: 60,
  });

  let response = await fetch(url);
  let json = await response.json();
  propertiesCache.set(cacheKey, json.properties);
  return json.properties;
}

/**
 * fetches properties within the provided polygon
 * subdivides the polygon into smaller parallel requests
 * if the polygon is too large
 * @param {Object} polygon
 * @param {Function} callback that receives the properties for each subdivision
 * note: the callback can be called several times if the polygon is subdivided
 */
export async function fetchPropertiesByPolygon(
  polygon,
  polygonId,
  options = null,
  cb,
) {
  let startFetchTime = moment().unix();

  store.dispatch(setFetchingProperties(true));

  let saleType = null;
  let type = null;
  // Extract saleType from options object if provided
  if (options && options.saleType) {
    saleType = options.saleType;
  }
  if (options && options.type) {
    type = options.type;
  }
  // Fallback to store value if still null
  if (saleType === null) {
    saleType = store.getState().filters.saleType ?? "sale";
  }

  // every time properties are fetch we refresh the collections
  store.dispatch(fetchCollections());

  // abort previous fetch if it exists
  if (window.polygonAbortControllers[polygonId]) {
    window.polygonAbortControllers[polygonId].abort();
  }

  // assign new abort controller to polygon
  window.polygonAbortControllers[polygonId] = new AbortController();

  // fetch property by polygon bounding rectangle
  let bounds = getBoundingRectangleFromPolygon(polygon);

  let properties = await fetchPropertiesRequest(
    {
      bounding_rect: bounds,
      cache_key: polygonId + "_" + saleType,
      sale_type: saleType,
    },
    window.polygonAbortControllers[polygonId],
  );

  // download the properties from the bucket if the key is provided
  // as this means the result is either geocached or surpasses lambda 6mb limit
  let isCached = false;
  let tempCache = false;
  if (properties.key) {
    // temp caches are raycasted
    if (properties.key.includes("temp_")) {
      tempCache = true;
    }

    let url = await Storage.get(properties.key, {
      bucket:
        process.env.REACT_APP_NODE_ENV === "production"
          ? "irealty-property-fetch"
          : "irealty-property-fetch-dev",
      expires: 60,
    });

    let response = await fetch(url);
    let json = await response.json();
    properties = json.properties;
    isCached = true;
  }

  // filter by raycasting the polygon and finding which properties are inside
  // only if not precached or is temp cached
  if (!isCached || tempCache) {
    properties = await filterPropertiesAsync(properties, polygon);
  }

  // pre calculate days on market if in market insights page
  if (window.location.pathname.includes("/market-insights")) {
    properties = properties.map((p) => {
      return {
        daysOnMarket: moment().diff(moment.unix(p.initialImportTime), "days"),
        ...p,
      };
    });
  }

  // redo this with sold properties if type is cma
  if (type == "cma" && saleType == "sale") {
    let soldProperties = await fetchPropertiesRequest(
      {
        bounding_rect: bounds,
        cache_key: polygonId + "_sold",
        sale_type: "sold",
      },
      window.polygonAbortControllers[polygonId],
    );
    let isCached = false;
    let tempCache = false;
    if (soldProperties.key) {
      // temp caches are raycasted

      if (soldProperties.key.includes("temp_")) {
        tempCache = true;
      }

      let url = await Storage.get(soldProperties.key, {
        bucket:
          process.env.REACT_APP_NODE_ENV === "production"
            ? "irealty-property-fetch"
            : "irealty-property-fetch-dev",
        expires: 60,
      });

      let response = await fetch(url);
      let json = await response.json();
      // only add sold properties whose timestamp is within 2 years
      isCached = true;
      soldProperties = json.properties;
    }

    soldProperties = soldProperties.filter((p) => {
      const twoYearsAgo = moment().subtract(2, "years").unix();
      return p.timestamp >= twoYearsAgo;
    });
    // merge sold properties with active properties
    properties = [...properties, ...soldProperties];
  }

  // remove dupes
  let allProperties = store.getState().property.properties;
  properties = await deduplicatePropertiesAsync(properties, allProperties);

  store.dispatch(incrementNumberOfPolygonsFetched());

  // Call callback with properties
  cb(properties);

  // start analytics
  let loadTime = moment().unix() - startFetchTime;
  let drawnPolygon = getPolygonById(polygonId);
  let area = "custom";

  if (drawnPolygon.metadata) {
    area = drawnPolygon.metadata.name;
    if (
      drawnPolygon.metadata.type === "district" ||
      drawnPolygon.metadata.type === "zone" ||
      drawnPolygon.metadata.type === "locality"
    ) {
      area += ", " + drawnPolygon.metadata.municipality;
    }
  }

  sendAnalyticsEvent("Property Search", {
    area: area,
    numberOfProperties: properties.length,
    loadTime: loadTime,
  });
  // end analytics

  // set fetching properties to false if all polygons are fetched
  // or if any properties are fetched
  if (
    store.getState().polygon.numberOfPolygonsFetched ===
      store.getState().polygon.drawnPolygons.length ||
    store.getState().property.properties.length > 0
  ) {
    store.dispatch(setFetchingProperties(false));
  }

  return properties;
}

// filters properties by polygon in an async manner to prevent blocking the main thread
async function filterPropertiesAsync(properties, polygon) {
  return new Promise((resolve) => {
    let filteredProperties = [];
    let index = 0;

    function filterChunk() {
      let chunkSize = 100;
      let chunk = properties.slice(index, index + chunkSize);
      chunk.forEach((property) => {
        if (isInPolygon(polygon, property)) {
          filteredProperties.push(property);
        }
      });
      index += chunkSize;
      if (index < properties.length) {
        setTimeout(filterChunk, 0);
      } else {
        resolve(filteredProperties);
      }
    }

    filterChunk();
  });
}

// deduplicates properties by comparing the id of the properties
// in an async manner to prevent blocking the main thread
async function deduplicatePropertiesAsync(properties, allProperties) {
  return new Promise((resolve) => {
    let deduplicatedProperties = [];
    let index = 0;

    function deduplicateChunk() {
      let chunkSize = 100;
      let chunk = properties.slice(index, index + chunkSize);
      chunk.forEach((current) => {
        const x = allProperties.find((item) => item.id === current.id);
        if (!x) {
          deduplicatedProperties.push(current);
        }
      });
      index += chunkSize;
      if (index < properties.length) {
        setTimeout(deduplicateChunk, 0);
      } else {
        resolve(deduplicatedProperties);
      }
    }

    deduplicateChunk();
  });
}

// replace spaces and non-alphanumeric characters with underscores
export function toSnakeCase(input) {
  const cleanString = input.replace(/[^a-zA-Z0-9]/g, "_");
  const snakeCaseString = cleanString.toLowerCase().replace(/_{2,}/g, "_");
  return snakeCaseString;
}

/**
 * fetches properties within the provided municipality
 * and match them against polygon
 * @param {Object} polygon
 * @returns
 */
export async function fetchPropertiesByMunicipality(polygon, municipality) {
  try {
    let properties = await fetchPropertiesByMunicipalityRequest(municipality);

    properties = properties.filter((property) => {
      return isInPolygon(polygon, property);
    });

    return properties;
  } catch (e) {
    console.log(e);
  }
}

// checks if a property includes a private garage
// by checking for the token 'garage' in description + if its a house and not a flat
export function propertyIncludesPrivateGarage(property) {
  if (!property.description) {
    return false;
  }

  let condition = false;
  let descriptionTokens = property.description.toLowerCase().split(/[\s,.!?]+/);
  condition = descriptionTokens.includes("garage");

  if (property.detailedType && property.detailedType.subTypology) {
    condition =
      property.detailedType.subTypology === "semidetachedHouse" ||
      property.detailedType.subTypology === "independantHouse" ||
      property.detailedType.typology === "villa" ||
      property.detailedType.subTypology === "terracedHouse";
  } else {
    return false;
  }

  return condition;
}

// similar to propertyIncludesPrivateGarage but instead of checking for a house
// it checks if its a flat/apartment and checks for the hasParkingSpace bool
export function propertyIncludesParking(property) {
  if (property.parkingSpace && property.parkingSpace.hasParkingSpace) {
    if (property.detailedType && property.detailedType.typology) {
      return (
        property.detailedType.typology === "flat" ||
        (property.detailedType.subTypology &&
          property.detailedType.subTypology === "terracedHouse")
      );
    }
  }

  return false;
}

// checks if a property is a villa by checking the labels field
// of the property and if its not there then by checking for villa in the description
export function isPropertyVilla(property) {
  let labels = property.labels;

  if (labels && labels.length > 0) {
    return labels.find((l) => l.text.toLowerCase().trim() === "villa");
  }

  if (!property.description) {
    return false;
  }

  let descriptionTokens = property.description.toLowerCase().split(/[\s,.!?]+/);
  return descriptionTokens.includes("villa");
}

// extracts features from a private bank property object
export function getFeaturesFromPrivateBankProperty(privateBankProperty) {
  let features = [];

  if (privateBankProperty.privateBank_propertyStatus) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.privateBank_propertyStatus,
      value: privateBankProperty.privateBank_propertyStatus,
    });
  }

  if (privateBankProperty.privateBank_modificationDate) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.privateBank_modificationDate,
      value: privateBankProperty.privateBank_modificationDate,
    });
  }

  return features;
}

// extracts features from a land object
export function getFeaturesFromLand(land) {
  let features = [];

  // land type
  features.push({
    type: PROPERTY_FEATURE_TYPE.land_type,
    value: i18n(capitalizeWord(land.land_type)),
    unlocalisedValue: capitalizeWord(land.land_type),
  });

  // road access
  if (land.land_roadAccess) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_roadAccess,
      value: i18n("Road access"),
      unlocalisedValue: "Road access",
    });
  }

  // water
  if (land.land_features && land.land_features.hasWater) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_water,
      value: i18n("Water"),
      unlocalisedValue: "Water",
    });
  }

  // electricity
  if (land.land_features && land.land_features.hasElectricity) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_electricity,
      value: i18n("Electricity"),
      unlocalisedValue: "Electricity",
    });
  }

  // sewage
  if (land.land_features && land.land_features.hasSewage) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_sewage,
      value: i18n("Sewage system"),
      unlocalisedValue: "Sewage system",
    });
  }

  // street lighting
  if (land.land_features && land.land_features.hasStreetLighting) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_streetLighting,
      value: i18n("Street lighting"),
      unlocalisedValue: "Street lighting",
    });
  }

  // sidewalk
  if (land.land_features && land.land_features.hasSidewalk) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_sidewalk,
      value: i18n("Sidewalk"),
      unlocalisedValue: "Sidewalk",
    });
  }

  // natural gas
  if (land.land_features && land.land_features.hasNaturalGas) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_gas,
      value: i18n("Natural gas"),
      unlocalisedValue: "Natural gas",
    });
  }

  // bank land
  if (land.isBankProperty) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.isBankProperty,
      value: i18n("Bank land"),
      unlocalisedValue: "Bank land",
    });
  }

  // buildable floors
  if (land.land_buildableFloors) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.land_buildableFloors,
      value: land.land_buildableFloors + " " + i18n("Buildable floors"),
    });
  }

  return features;
}

// extracts features from a commercial property object
export function getFeaturesFromCommercialProperty(property) {
  let features = [];

  // commercial type
  if (property.commercial_type == "commercial") {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_type,
      value: i18n("Commercial property"),
      unlocalisedValue: "Commercial property",
    });
  } else {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_type,
      value: i18n("Warehouse"),
      unlocalisedValue: "Warehouse",
    });
  }

  // rooms
  if (property.rooms > 0) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.rooms,
      value: property.rooms,
    });
  }

  // toilets
  if (property.bathrooms > 0) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.bathrooms,
      value: property.bathrooms + " " + i18n("toilets"),
    });
  }

  // shop windows
  if (property.commercial_shopWindows) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_shopWindows,
      value: property.commercial_shopWindows + " " + i18n("shop windows"),
    });
  }

  // community cost
  if (property.commercial_communityCost) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_communityCost,
      value:
        "€" +
        property.commercial_communityCost +
        " " +
        i18n("community cost per month"),
    });
  }

  // gross annual profitability
  if (property.commercial_grossAnnualProfitability) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_grossAnnualProfitability,
      value:
        property.commercial_grossAnnualProfitability +
        "% " +
        i18n("gross annual profitability"),
    });
  }

  // facade
  if (property.commercial_facade) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_facade,
      value: property.commercial_facade + "% " + i18n("facade"),
    });
  }

  // bank property
  if (property.isBankProperty) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.isBankProperty,
      value: i18n("Bank property"),
    });
  }

  // business activity
  if (property.commercial_businessActivities != "other") {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_businessActivities,
      value:
        i18n("Activity:") + " " + i18n(property.commercial_businessActivities),
      unlocalisedValue:
        "Activity:" + " " + property.commercial_businessActivities,
    });
  }

  // heating
  if (property.commercial_features && property.commercial_features.hasHeating) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_heating,
      value: i18n("Heating"),
      unlocalisedValue: "Heating",
    });
  }

  // extractor fan
  if (
    property.commercial_features &&
    property.commercial_features.hasExtractorFan
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_extractorFan,
      value: i18n("Extractor fan"),
      unlocalisedValue: "Extractor fan",
    });
  }

  // safety gate
  if (
    property.commercial_features &&
    property.commercial_features.hasSafetyGate
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_safetyGate,
      value: i18n("Safety gate"),
      unlocalisedValue: "Safety gat",
    });
  }

  // cctv
  if (property.commercial_features && property.commercial_features.hasCCTV) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_cctv,
      value: i18n("CCTV"),
      unlocalisedValue: "CCTV",
    });
  }

  // on corner
  if (property.commercial_features && property.commercial_features.isOnCorner) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_onCorner,
      value: i18n("On corner"),
      unlocalisedValue: "On corner",
    });
  }

  // office
  if (property.commercial_features && property.commercial_features.hasOffice) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_office,
      value: i18n("Office"),
      unlocalisedValue: "Office",
    });
  }

  // accessible bathrooms
  if (
    property.commercial_features &&
    property.commercial_features.hasAccessibleBathrooms
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_accessibleBathrooms,
      value: i18n("Accessible bathrooms"),
      unlocalisedValue: "Accessible bathrooms",
    });
  }

  // air con
  if (
    property.commercial_features &&
    property.commercial_features.hasAirConditioning
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_ac,
      value: i18n("Air conditioning"),
      unlocalisedValue: "Air conditioning",
    });
  }

  // fully equipped kitchen
  if (
    property.commercial_features &&
    property.commercial_features.hasFullyEquippedKitchen
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_kitchen,
      value: i18n("Equipped kitchen"),
      unlocalisedValue: "Equipped kitchen",
    });
  }

  // alarm system
  if (
    property.commercial_features &&
    property.commercial_features.hasAlarmSystem
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_alarm,
      value: i18n("Alarm system"),
      unlocalisedValue: "Alarm system",
    });
  }

  // storeroom
  if (
    property.commercial_features &&
    property.commercial_features.hasStoreroom
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_store,
      value: i18n("Store room"),
      unlocalisedValue: "Store room",
    });
  }

  // smoke extractor
  if (
    property.commercial_features &&
    property.commercial_features.hasSmokeExtractor
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_smokeExtractor,
      value: i18n("Smoke extractor"),
      unlocalisedValue: "Smoke extractor",
    });
  }

  // auxiliary entrance
  if (
    property.commercial_features &&
    property.commercial_features.hasAuxiliaryEntrance
  ) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.commercial_auxEntrance,
      value: i18n("Auxiliary entrance"),
      unlocalisedValue: "Auxiliary entrance",
    });
  }

  return features;
}

export function getFeaturesFromOfficeProperty(property) {
  let features = [];

  if (property.office_exclusiveUse) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.office_exclusiveUse,
      value: i18n("Only offices"),
      unlocalisedValue: "Only offices",
    });
  } else {
    features.push({
      type: PROPERTY_FEATURE_TYPE.office_exclusiveUse,
      value: i18n("Mixed use"),
      unlocalisedValue: "Mixed use",
    });
  }

  if (property.office_features) {
    if (property.office_features.hasAirConditioning) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.hasAirConditioning,
        value: i18n("Air conditioning"),
        unlocalisedValue: "Air conditioning",
      });
    }

    if (property.office_features.hasCentralHeating) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.heating,
        value: i18n("Heating"),
        unlocalisedValue: "Heating",
      });
    }

    if (property.office_features.hasKitchen) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.office_hasKitchen,
        value: i18n("Kitchen"),
        unlocalisedValue: "Kitchen",
      });
    }

    if (property.office_features.isWarehouse) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.office_isWareHouse,
        value: i18n("Warehouse"),
        unlocalisedValue: "Warehouse",
      });
    }

    if (property.office_features.hasToilets) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.office_hasToilets,
        value: i18n("Toilets"),
        unlocalisedValue: "Toilets",
      });
    }

    if (property.office_features.hasEmergencyExit) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.office_hasEmergencyExit,
        value: i18n("Emergency exit"),
        unlocalisedValue: "Emergency exit",
      });
    }
  }

  return features;
}

export function getFeaturesFromGarageProperty(property) {
  let features = [];

  if (property.garage_features) {
    if (property.garage_features.hasAutomaticDoor) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_hasAutomaticDoor,
        value: i18n("Automatic door"),
        unlocalisedValue: "Automatic door",
      });
    }

    if (property.garage_features.hasSecurity) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_hasSecurity,
        value: i18n("Security"),
        unlocalisedValue: "Security",
      });
    }

    if (property.garage_features.garage_isCovered) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_isCovered,
        value: i18n("Covered"),
        unlocalisedValue: "Covered",
      });
    }

    if (property.garage_features.hasLift) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_hasLift,
        value: i18n("Lift"),
        unlocalisedValue: "Lift",
      });
    }

    if (property.garage_features.hasCCTV) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_hasCCTV,
        value: i18n("CCTV"),
        unlocalisedValue: "CCTV",
      });
    }

    if (property.garage_features.hasAlarm) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_hasAlarm,
        value: i18n("Alarm"),
        unlocalisedValue: "Alarm",
      });
    }
  }

  if (property.garage_multipleSpaces) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.garage_multipleSpaces,
      value: i18n("Multiple spaces"),
      unlocalisedValue: "Multiple spaces",
    });
  }

  if (property.garage_spaceSize) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.garage_spaceSize,
      value: i18n(capitalizeWord(property.garage_spaceSize)),
      unlocalisedValue: capitalizeWord(property.garage_spaceSize),
    });
  }

  if (property.garage_vehicle) {
    if (property.garage_vehicle === "motorcycle") {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_vehicle,
        value: i18n("Motorcycle"),
        unlocalisedValue: "Motorcycle",
      });
    }

    if (property.garage_vehicle === "car") {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_vehicle,
        value: i18n("Car"),
        unlocalisedValue: "Car",
      });
    }

    if (property.garage_vehicle === "carAndMotorcycle") {
      features.push({
        type: PROPERTY_FEATURE_TYPE.garage_vehicle,
        value: i18n("Multi Vehicle"),
        unlocalisedValue: "Multi Vehicle",
      });
    }
  }

  if (features.length === 0) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.garage,
      value: i18n("Garage"),
      unlocalisedValue: "Garage",
    });
  }

  return features;
}

export function getFeaturesFromStorageProperty(property) {
  let features = [];

  if (property.storage_features) {
    if (property.storage_features.is24hAccess) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.storage_24HourAccess,
        value: i18n("24 hour access"),
        unlocalisedValue: "24 hour access",
      });
    }

    if (property.storage_features.hasLoadingBay) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.storage_hasLoadingBay,
        value: i18n("Loading bay"),
        unlocalisedValue: "Loading bay",
      });
    }
  }

  if (features.length === 0) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.storage,
      value: i18n("Storage"),
      unlocalisedValue: "Storage",
    });
  }

  return features;
}

export function getFeaturesFromBuildingProperty(property) {
  let features = [];

  if (property.building_use) {
    for (let buildingUse of property.building_use) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_use,
        value: i18n(capitalizeWord(buildingUse)),
        unlocalisedValue: capitalizeWord(buildingUse),
      });
    }
  }

  if (property.building_features) {
    if (property.building_features.hasTenants) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_hasTenants,
        value: i18n("Tenants"),
        unlocalisedValue: "Tenants",
      });
    }
  }

  if (property.building_garageSpaces && property.building_garageSpaces > 0) {
    if (parseInt(property.building_garageSpaces) === 1) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_garageSpaces,
        value: property.building_garageSpaces + " " + i18n("garage space"),
      });
    } else {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_garageSpaces,
        value: property.building_garageSpaces + " " + i18n("garage spaces"),
      });
    }
  }

  if (property.building_numLifts) {
    if (parseInt(property.building_numLifts) === 1) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_numLifts,
        value: property.building_numLifts + " " + i18n("lift"),
      });
    } else {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_numLifts,
        value: property.building_numLifts + " " + i18n("lifts"),
      });
    }
  }

  if (property.building_numUnits) {
    if (parseInt(property.building_numUnits) === 1) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_numUnits,
        value: property.building_numUnits + " " + i18n("unit"),
      });
    } else {
      features.push({
        type: PROPERTY_FEATURE_TYPE.building_numUnits,
        value: property.building_numUnits + " " + i18n("units"),
      });
    }
  }

  if (features.length === 0) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.building,
      value: i18n("Building"),
      unlocalisedValue: "Building",
    });
  }

  return features;
}

// extracts frontend features for the given property
export function getFeaturesFromProperty(property) {
  if (property.isPrivateBankProperty) {
    return getFeaturesFromPrivateBankProperty(property);
  }
  if (property.buildingType == "land") {
    return getFeaturesFromLand(property);
  }

  if (property.buildingType == "commercial") {
    return getFeaturesFromCommercialProperty(property);
  }

  if (property.buildingType == "building") {
    return getFeaturesFromBuildingProperty(property);
  }

  if (property.buildingType == "office") {
    return getFeaturesFromOfficeProperty(property);
  }

  if (property.buildingType == "garage") {
    return getFeaturesFromGarageProperty(property);
  }

  if (property.buildingType == "storage") {
    return getFeaturesFromStorageProperty(property);
  }

  let features = [];

  // num bedrooms
  features.push({
    type: PROPERTY_FEATURE_TYPE.bedrooms,
    value: property.rooms,
  });

  // num bathrooms
  features.push({
    type: PROPERTY_FEATURE_TYPE.bathrooms,
    value: property.bathrooms,
  });

  // pool
  if (property.features && property.features.hasSwimmingPool) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.pool,
      value: i18n("Pool"),
      unlocalisedValue: "Pool",
    });
  }

  // air con
  if (property.features && property.features.hasAirConditioning) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.ac,
      value: i18n("Air conditioning"),
      unlocalisedValue: "Air conditioning",
      hideFromPanel: true,
    });
  }

  // terrace
  if (property.features && property.features.hasTerrace) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.terrace,
      value: i18n("Terrace"),
      unlocalisedValue: "Terrace",
      hideFromPanel: true,
    });
  }

  // garden
  if (property.features && property.features.hasGarden) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.garden,
      value: i18n("Garden"),
      unlocalisedValue: "Garden",
      hideFromPanel: true,
    });
  }

  // lift
  if (property.features.hasLift) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.lift,
      unlocalisedValue: "Lift",
      value: i18n("Lift"),
    });
  }

  // parking / garage
  if (property.features.hasGarage) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.parking,
      unlocalisedValue: "Parking / Garage",
      value: i18n("Parking / Garage"),
    });
  }

  // sea view
  if (property.features.hasSeaView) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.seaView,
      unlocalisedValue: "Sea view",
      value: i18n("Sea view"),
    });
  }

  // greenery
  if (property.features.hasGreenery) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.greenery,
      value: i18n("Greenery"),
      unlocalisedValue: "Greenery",
      hideFromPanel: true,
    });
  }

  // fitted wardrobes
  if (property.features.hasFittedWardrobes) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.wardrobe,
      value: i18n("Fitted wardrobes"),
      unlocalisedValue: "Fitted wardrobes",
      hideFromPanel: true,
    });
  }

  // balcony
  if (property.features.hasBalcony) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.balcony,
      value: i18n("Balcony"),
      unlocalisedValue: "Balcony",
      hideFromPanel: true,
    });
  }

  // storage
  if (property.features.hasStorage) {
    features.push({
      type: PROPERTY_FEATURE_TYPE.storeroom,
      value: i18n("Storage"),
      unlocalisedValue: "Storage",
      hideFromPanel: true,
    });
  }

  // rental features
  if (property.saleType == "rent") {
    // max tenants
    if (property.rental_maxTenants) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.rental_maxTenants,
        value: property.rental_maxTenants + " " + i18n("max tenants"),
      });
    }

    // pets allowed
    if (property.features.petsAllowed) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.rental_petsAllowed,
        value: i18n("Pets"),
        unlocalisedValue: "Pets",
        hideFromPanel: true,
      });
    }

    // children allowed
    if (property.features.childrenAllowed) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.rental_childrenAllowed,
        value: i18n("Children"),
        unlocalisedValue: "Children",
        hideFromPanel: true,
      });
    }

    // accessibility
    if (property.features.accessibility) {
      features.push({
        type: PROPERTY_FEATURE_TYPE.rental_accessibility,
        value: i18n("Accessibility"),
        unlocalisedValue: "Accessibility",
        hideFromPanel: true,
      });
    }
  }

  return features;
}

export function getPropertyTypologyHuman(
  property,
  plural = false,
  skipRentalPrefix = false,
) {
  if (property.buildingType == "land") {
    if (plural) {
      return i18n("Lands");
    }

    return i18n("Land");
  }

  if (property.buildingType == "building") {
    if (plural) {
      return i18n("Buildings");
    }

    return i18n("Building");
  }

  if (property.buildingType == "office") {
    if (plural) {
      return i18n("Offices");
    }

    return i18n("Office");
  }

  if (property.buildingType == "garage") {
    if (plural) {
      return i18n("Garages");
    }

    return i18n("Garage");
  }

  if (property.buildingType == "storage") {
    if (plural) {
      return i18n("Storages");
    }

    return i18n("Storage");
  }

  if (property.buildingType == "room") {
    if (plural) {
      return i18n("Rooms");
    }

    return i18n("Room");
  }

  if (property.buildingType == "commercial") {
    if (property.commercial_type == "industrial") {
      if (plural) {
        return i18n("Industrial properties");
      }

      return i18n("Industrial property");
    } else {
      if (plural) {
        return i18n("Commercial properties");
      }

      return i18n("Commercial property");
    }
  }

  let title = capitalizeWord(property.propertyType);

  if (property.detailedType && property.detailedType.subTypology) {
    if (property.detailedType.subTypology === "semidetachedHouse") {
      title = i18n("Semi-detached house");

      if (plural) {
        title = i18n("Semi-detached houses");
      }
    } else if (property.detailedType.subTypology === "independantHouse") {
      title = i18n("Detached house");

      if (plural) {
        title = i18n("Detached houses");
      }
    } else if (property.detailedType.subTypology === "terracedHouse") {
      title = i18n("Terrace house");

      if (plural) {
        title = i18n("Terrace houses");
      }
    } else if (property.detailedType.subTypology === "countryHouse") {
      title = i18n("Country house");

      if (plural) {
        title = i18n("Country houses");
      }
    } else if (property.detailedType.subTypology === "studio") {
      title = i18n("Studio");

      if (plural) {
        title = i18n("Studios");
      }
    }
  }

  if (property.detailedType.isVilla) {
    title = "Villa";

    if (plural) {
      title = "Villas";
    }
  }

  if (property.detailedType && property.detailedType.typology && !title) {
    if (property.detailedType.typology === "flat") {
      title = i18n("Flat");

      if (plural) {
        title = i18n("Flats");
      }
    }

    if (property.rooms === 0) {
      title = i18n("Studio");

      if (plural) {
        title = i18n("Studios");
      }
    }

    if (property.detailedType.subTypology) {
      if (property.detailedType.subTypology === "duplex") {
        title = i18n("Duplex");

        if (plural) {
          title = i18n("Duplexes");
        }
      }

      if (property.detailedType.subTypology === "penthouse") {
        title = i18n("Penthouse");

        if (plural) {
          title = i18n("Penthouses");
        }
      }
    }

    if (property.detailedType.typology === "house") {
      title = i18n("House");

      if (plural) {
        title = i18n("Houses");
      }
    }
  }

  if (!title) {
    title = i18n("Property");

    if (plural) {
      title = i18n("Properties");
    }
  }

  if (property.saleType == "rent" && !skipRentalPrefix) {
    if (getLang().code == "es") {
      return "Alquiler de " + title;
    } else {
      return `${title} ${i18n("for rent")}`;
    }
  }

  return title;
}

// transforms data points in from the backend to a more
// human readable title
export function getPropertyTitle(property) {
  let title = getPropertyTypologyHuman(property);
  let location = property.address;

  if (!location || location == "n/a" || location == "none" || location == "") {
    location =
      property.neighborhood !== "n/a" && property.neighborhood !== "none"
        ? property.neighborhood
        : null;
  }

  if (!location || location == "n/a" || location == "none" || location == "") {
    location = property.district;
  }

  if (!location || location == "n/a" || location == "none" || location == "") {
    location = property.municipality;
  }

  return `${title} ${i18n("in")} ${location}`;
}

// the area text that is embedded in the panel header
export function getAreaText() {
  const { drawnPolygons } = store.getState().polygon;

  if (drawnPolygons.length === 1 && drawnPolygons[0].metadata) {
    return drawnPolygons[0].metadata.name;
  } else if (drawnPolygons.length === 1) {
    return i18n("selected area");
  }

  return i18n("selected areas");
}

// text to display for property count
export function getPropertiesCountText(withAreaText = true) {
  let properties = getFilteredProperties();

  return properties.length + " " + i18n("results");
}

export function getAdvertisersName(property) {
  if (
    property.contactInfo.commercialName &&
    property.contactInfo.commercialName.length > 0
  ) {
    return property.contactInfo.commercialName;
  }

  return property.contactInfo.contactName;
}

const dataSourceConfig = {
  fotocasa: {
    title: "fotocasa",
    icon: fotocasaIcon,
  },
  pisos: {
    title: "pisos",
    icon: pisosIcon,
  },
  engel_volkers: {
    title: "Engel & Völkers",
    icon: engelVolkersIcon,
  },
  aproperties: {
    title: "aProperties",
    icon: aPropertiesIcon,
  },
  idealista: {
    title: "idealista",
    icon: idealistaIcon,
  },
  airbnb: {
    title: "Airbnb",
    icon: airbnbIcon,
  },
  irealty: {
    title: "iRealty",
    icon: irealtyIcon,
  },
};

// flatten the price history of a property such that each price change
// is a separate event
function flattenPropertyPriceHistory(property, overrideLang = null) {
  let priceHistory = property.priceHistory || [];

  priceHistory = priceHistory
    .sort((a, b) => a.time - b.time)
    .map((item) => ({
      id: property.id,
      url: property.url,
      price: item.price,
      time: parseFloat(item.time),
      dataSource: dataSourceConfig[property.dataSource],
      event: "price_change",
      label: overrideLang
        ? getLocalisation("Price change", overrideLang)
        : i18n("Price change"),
      advertiser: getAdvertisersName(property),
      country: property.country,
    }));

  // if the last price history event is not the current price
  // then add a price change event
  if (
    property.initialImportTime !== property.updatedTime &&
    property.updatedTime &&
    priceHistory.length > 0
  ) {
    if (priceHistory[priceHistory.length - 1].price !== property.price) {
      let priceChangeEvent = {
        id: property.id,
        url: property.url,
        price: property.price,
        time: parseFloat(property.updatedTime),
        dataSource: dataSourceConfig[property.dataSource],
        event: "price_change",
        label: overrideLang
          ? getLocalisation("Price change", overrideLang)
          : i18n("Price change"),
        advertiser: getAdvertisersName(property),
        country: property.country,
      };

      priceHistory.push(priceChangeEvent);
    }
  }

  priceHistory.unshift({
    id: property.id,
    url: property.url,
    price: priceHistory.length > 0 ? priceHistory[0].price : property.price,
    time: parseFloat(property.initialImportTime),
    dataSource: dataSourceConfig[property.dataSource],
    event: "listing_added",
    label: overrideLang
      ? getLocalisation("Listing registered", overrideLang)
      : i18n("Listing registered"),
    advertiser: getAdvertisersName(property),
    country: property.country,
  });

  // cleanup for exact price change events
  for (let i = 0; i < priceHistory.length; i++) {
    if (i > 0 && priceHistory[i].price == priceHistory[i - 1].price) {
      priceHistory.splice(i, 1);
    }
  }

  return priceHistory;
}

// returns the chronological history of a property
// in the form of an array of events
export function extractPropertyHistory(property, overrideLang = null) {
  let propertyHistory = flattenPropertyPriceHistory(property, overrideLang);

  // add price change status + duplicate status
  propertyHistory = propertyHistory
    .sort((a, b) => a.time - b.time)
    .map((item, index) => {
      let newItem = { ...item };

      if (index > 0) {
        newItem.priceChangePercentage = Math.round(
          Math.abs(
            ((parseFloat(newItem.price) -
              parseFloat(propertyHistory[index - 1].price)) /
              parseFloat(propertyHistory[index - 1].price)) *
              100,
          ),
        );

        if (
          parseFloat(newItem.price) >
          parseFloat(propertyHistory[index - 1].price)
        ) {
          newItem.priceChange = "increase";
        } else if (
          parseFloat(newItem.price) <
          parseFloat(propertyHistory[index - 1].price)
        ) {
          newItem.priceChange = "decrease";
        }
      }

      return newItem;
    });

  return propertyHistory;
}

// Gets the geoboudnary in which the property is located
export async function getPropertyGeoboundary(
  property,
  enforceMunicipality = false,
) {
  let propertyDistrict = await getNearestNeighbourGeoboundary(
    property.latitude,
    property.longitude,
    "district",
  );

  let propertyMunicipality = await getNearestNeighbourGeoboundary(
    property.latitude,
    property.longitude,
    "municipality",
  );

  // fallback to municipality if no district present
  if (!propertyDistrict || enforceMunicipality) {
    propertyDistrict = propertyMunicipality;
  }

  return propertyDistrict;
}

// Gets the zone in which the property is located
export async function getPropertyZone(property) {
  let propertyZone = await getNearestNeighbourGeoboundary(
    property.latitude,
    property.longitude,
    "district",
    "zone",
  );

  return propertyZone;
}

// formats the property price according to that property's origin country
export function getLocalePropertyPrice(property, price) {
  const propertyCurrencyMapping = {
    es: "EUR",
    en: "USD",
    "en-GB": "GBP",
  };

  const locale = getLang().code;

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: propertyCurrencyMapping["es"],
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
}

// gets the property's geoboundary by na,e
export async function getPropertyDistrictByName(property) {
  let geoboundaries = await getAllMapObjectsByType("es", "district");
  return geoboundaries.find(
    (district) =>
      district.name.toLowerCase() === property.neighborhood.toLowerCase() &&
      property.municipality === district.municipality,
  );
}

export function isPropertyFromDataSource(property, dataSource) {
  return property.dataSource === dataSource;
}

export function getPropertyTimeOnMarket(property) {
  // Get the initial import time as a float
  const importTime = parseFloat(property.initialImportTime);
  // Convert the import time to a Date object (milliseconds)
  const importDate = new Date(importTime * 1000);
  // Get the current date
  const currentDate = new Date();
  // Calculate the difference in milliseconds
  const diffMs = currentDate - importDate;
  // Convert milliseconds to days
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function getAverageTimeOnMarket(properties) {
  // Calculate the total days on market for all properties
  const totalDays = properties.reduce((sum, property) => {
    return sum + getPropertyTimeOnMarket(property);
  }, 0);
  // Compute the average days on market
  const averageDays = totalDays / properties.length;

  // convert to whole integer
  const averageWholeDays = Math.floor(averageDays);

  return averageWholeDays;
}
