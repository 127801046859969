import { createReducer } from "@reduxjs/toolkit";
import {
  setCatastros,
  setSelectedCatastro,
  setFilteredCatastros,
  setActiveFilters,
  setSortOption,
  clearSelectedCatastro,
  setLoading,
  setError,
  setCatastroStage,
  setChildrenCatastros,
  setLastEvaluatedKey
} from '../actions/catastro';
import { getFilteredCatastros, applySorting } from '../utils/catastroFilters';

const initialState = {
  all: [],              // All catastro data
  filtered: [],         // Filtered catastro data based on search/filters
  selected: null,       // Currently selected catastro
  activeFilters: {},    // Active filters object
  sortOption: 'newest', // Default sort option
  loading: false,       // Loading state
  error: null,         // Error message
  stage: 0,             // Current panel stage (0: Search, 1: Details, 2: Area Selection)
  childrenCatastros: null, // Children catastros for a parent catastro
  lastEvaluatedKey: null // Last evaluated key for pagination
};

const catastroReducer = createReducer(initialState, {
  [setCatastros]: (state, action) => {
    state.all = action.payload;
    state.filtered = getFilteredCatastros(action.payload, state.activeFilters);
  },

  [setSelectedCatastro]: (state, action) => {
    state.selected = action.payload;
  },

  [setFilteredCatastros]: (state, action) => {
    state.filtered = applySorting(action.payload, state.sortOption);
  },

  [setActiveFilters]: (state, action) => {
    const filteredCatastros = getFilteredCatastros(state.all, action.payload);
    const sortedFilteredCatastros = applySorting(filteredCatastros, state.sortOption);
    
    state.activeFilters = action.payload;
    state.filtered = sortedFilteredCatastros;
  },

  [setSortOption]: (state, action) => {
    state.sortOption = action.payload;
    state.filtered = applySorting(state.filtered, action.payload);
  },

  [clearSelectedCatastro]: (state) => {
    state.selected = null;
  },

  [setLoading]: (state, action) => {
    state.loading = action.payload;
  },

  [setError]: (state, action) => {
    state.error = action.payload;
  },

  [setCatastroStage]: (state, action) => {
    state.stage = action.payload;
  },

  [setChildrenCatastros]: (state, action) => {
    state.childrenCatastros = action.payload;
  },

  [setLastEvaluatedKey]: (state, action) => {
    state.lastEvaluatedKey = action.payload;
  }
});

export default catastroReducer; 