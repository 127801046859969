import ConfirmModal from "components/ui/Modal/ConfirmModal";
import Switch from "components/ui/Switch/Switch";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { toast } from "sonner";
import {
  getSerializedSearchObject,
  getUnreadNotifications,
} from "utils/helpers";
import { getSubscriptionPlan, userHasRole } from "utils/userHelpers";
import profilePic from "../../assets/core/default_profile.svg";
import logo from "../../assets/logo.svg";
import cmaIcon from "../../assets/navbar/cma.svg";
import DiamondIcon from "../../assets/navbar/diamond_svg";
import dismissIcon from "../../assets/navbar/dismiss_icon.svg";
import favoritesIcon from "../../assets/navbar/favorites.svg";
import feedbackIcon from "../../assets/navbar/feedback.svg";
import matchIcon from "../../assets/navbar/match.svg";
import menuIcon from "../../assets/navbar/menu.svg";
import myPropertiesIcon from "../../assets/navbar/my_properties.svg";
import newDevelopmentsIcon from "../../assets/navbar/new_developments.svg";
import notificationIcon from "../../assets/navbar/notification.svg";
import preferencesIcon from "../../assets/settings/preferences.svg";
import searchIcon from "../../assets/navbar/search.svg";
import marketInsightsIcon from "../../assets/navbar/market_insights.svg";
import dashboardIcon from "../../assets/navbar/dashboard.svg";
import agencyDirectoryIcon from "../../assets/settings/team.svg";
import catastroIcon from "../../assets/navbar/catastro.svg";
import leadsIcon from "../../assets/core/natural_gas.svg";
import "./navbar.scss";

const Navbar = (props) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [nextPath, setNextPath] = useState(null);
  const [willTriggerWarningModal, setWillTriggerWarningModal] = useState(false);
  const unreadNotifications = getUnreadNotifications();
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();
  let pathname = location.pathname;
  let profile =
    props.agencyDetails && props.agencyDetails.picture
      ? props.agencyDetails.picture
      : profilePic;

  // Fetch subscription plan whenever the subscription data changes or the state.subscription changes
  useEffect(() => {
    // re-render the navbar when the subscription data is fetched
    // set the subscription plan
    setSubscriptionPlan(getSubscriptionPlan(props.user));
    setSubscriptionLoaded(props.subscription.subscriptionDataFetched);
  }, [props.subscription]);

  useEffect(() => {
    // condition to trigger warning dialog when navigating away from the search page
    if (
      pathname === "/" &&
      props.properties.length > 0 &&
      !props.searchContext
    ) {
      window.onbeforeunload = () => true;
      setWillTriggerWarningModal(true);
    }

    // condition to trigger warning dialog when navigating away from the valuation page
    if (
      pathname === "/valuation" &&
      props.properties.length > 0 &&
      !props.cma.isPDFGenerated
    ) {
      window.onbeforeunload = () => true;
      setWillTriggerWarningModal(true);
    }

    // remove event when component unmounts
    return () => {
      window.onbeforeunload = null;
      setWillTriggerWarningModal(false);
    };
  }, [
    pathname,
    props.cma.isPDFGenerated,
    props.searchContext,
    props.properties,
  ]);

  // reset certain slices of the state on location change
  const onLocationChange = (e, newLocation, skipWarning = false) => {
    e.preventDefault();
    sendAnalyticsEvent("Click Navbar", {
      type: newLocation,
    });

    // if we are on the search page and there are drawn polygons, ask the user if they want to save the search
    // before navigating away
    if (willTriggerWarningModal && !skipWarning) {
      setIsConfirmModalOpen(true);
      setNextPath(newLocation);
      return;
    }

    if (willTriggerWarningModal && !skipWarning) {
      setIsConfirmModalOpen(true);
      setNextPath(newLocation);
      return;
    }

    props.setProperties([]);
    props.setFilteredProperties([]);
    props.setDrawnPolygons([]);
    props.incrementNumberOfPolygonsFetched(0);
    props.setFilters([]);
    props.setSaleType("sale");
    props.setCmaProperty(null);
    props.setInvisibleFilters([]);
    props.setCanDrawPlots(true);
    props.setSelectedPlot(null);
    props.setSort(require("../../lib/filter/sort").SORT_OPTIONS[0]);
    props.setIsPDFGenerated(false);
    props.setManualValuationMode(false);
    props.setCmaSearchResults([]);
    props.setCmaProperty(null);
    props.resetAgencyState();
    props.resetLeadState();
    window.drawnPolygons.forEach((p) => {
      p.setMap(null);
    });
    window.drawnPlots = [];
    window.drawnPolygons = [];

    if (
      newLocation === "/market-insights" ||
      newLocation === "/search" ||
      newLocation === "/agency-directory"
    ) {
      props.setCanDrawPlots(false);
      props.setCanDrawGeographicPolygons(true);
      props.setHideBounds(false);
      props.setCanShowBoundsToolbarItem(true);
    }

    navigate(newLocation);
  };

  const onCancelConfirmModal = () => {
    setIsConfirmModalOpen(false);

    if (nextPath) {
      onLocationChange(new Event("dialog_location_change"), nextPath, true);
      setNextPath(null);
    }
  };

  const onConfirmConfirmModal = async () => {
    setIsConfirmModalOpen(false);
    props.setGlobalLoading(true);
    props.setGlobalLoadingMessage(i18n("Saving search..."));
    const searchObject = await getSerializedSearchObject();
    props.setSearchObject(searchObject);
    props.setGlobalLoading(false);
    props.setSaveSearchModalOpen(true);
  };

  const onToggleClientMode = () => {
    let isOn = localStorage.getItem("clientMode") === "true";
    sendAnalyticsEvent("Click Navbar", {
      type: "client mode",
      details: isOn ? "deactivated" : "activated",
    });

    if (isOn) {
      localStorage.removeItem("clientMode");
    } else {
      localStorage.setItem("clientMode", "true");
    }

    props.setClientMode(!isOn);
    toast.message(
      `${i18n("Client mode")} ${isOn ? i18n("deactivated") : i18n("activated")}`,
      {
        duration: 1000,
      },
    );
  };

  // set the pricing modal to open
  const onOpenPricingModal = () => {
    props.setPricingModal(true);
    sendAnalyticsEvent("Click Navbar", {
      type: "pricing modal",
    });
  };

  const onToggleNotificationPanel = () => {
    props.setNotificationsOpen(!props.notificationsOpen);
  };

  const [isTooltipVisible, setTooltipVisible] = useState(
    props.highlightProIcon,
  );

  if (props.highlightProIcon && !isTooltipVisible) {
    setTooltipVisible(true);
  } else if (!props.highlightProIcon && isTooltipVisible) {
    setTooltipVisible(false);
  }

  const dismissTooltip = () => {
    setTooltipVisible(false);
  };

  const ConfirmExitModal = () => {
    return pathname === "/" ? (
      <ConfirmModal
        open={isConfirmModalOpen}
        title={i18n("Save current search")}
        confirmationText={i18n("Do you want to save the current search?")}
        confirmText={i18n("Save Search")}
        cancelText={i18n("Don't Save")}
        onCancel={() => onCancelConfirmModal()}
        onConfirm={() => onConfirmConfirmModal()}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    ) : (
      // trigerred when navigating away from the valuation page
      <ConfirmModal
        open={isConfirmModalOpen}
        title={i18n("Lose unsaved changes")}
        confirmationText={i18n("Do you want to quit without saving?")}
        confirmText={i18n("Go back")}
        cancelText={i18n("Yes, continue")}
        onCancel={() => onCancelConfirmModal()}
        onConfirm={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    );
  };

  const MenuList = () => {
    const closeMenu = () => {
      const background = document.querySelector(".menu-list-background");
      const menu = document.querySelector(".menu-list");

      // First remove active classes to trigger the transitions
      menu.classList.remove("active");
      background.classList.remove("active");

      // Wait for the transition to complete before hiding the background
      setTimeout(() => {
        background.style.display = "none";
      }, 300); // Match this with your CSS transition duration
    };

    return (
      <div
        onClick={(e) => {
          // Close menu when clicking the background (outside the menu)
          if (e.target === e.currentTarget) {
            closeMenu();
          }
        }}
        className="menu-list-background"
      >
        <div className="menu-list">
          <div className="menu-list_header">
            <button onClick={closeMenu} className="menu-list_close-button">
              <span>×</span>
            </button>
          </div>
          <Link
            to="/"
            onClick={(e) => {
              onLocationChange(e, "/");
              closeMenu();
            }}
            className="menu-list_item"
          >
            <img src={dashboardIcon} alt="Dashboard icon" />
            <span>{i18n("Dashboard")}</span>
          </Link>
          <Link
            to="/search"
            onClick={(e) => {
              onLocationChange(e, "/search");
              closeMenu();
            }}
            className="menu-list_item"
          >
            <img src={searchIcon} alt="Search icon" />
            <span>{i18n("Search Properties")}</span>
          </Link>
          <Link
            to="/valuation"
            onClick={(e) => {
              onLocationChange(e, "/valuation");
              closeMenu();
            }}
            className="menu-list_item"
          >
            <img src={cmaIcon} alt="Smart Valuation icon" />
            <span>{i18n("Smart Valuation")}</span>
          </Link>
          <Link
            to="/market-insights"
            onClick={(e) => {
              if (!userHasRole("market_insights")) {
                e.preventDefault();
                props.setPricingModal(true);
                return;
              }

              onLocationChange(e, "/market-insights");
              closeMenu();
            }}
            className="menu-list_item"
          >
            <img src={marketInsightsIcon} alt="Market Insights icon" />
            <span>{i18n("Market Insights")}</span>
          </Link>
          {process.env.REACT_APP_NODE_ENV !== "production" && (
            <Link
              to="/my-properties"
              onClick={(e) => {
                onLocationChange(e, "/my-properties");
                closeMenu();
              }}
              className="menu-list_item"
            >
              <img src={myPropertiesIcon} alt="CRM icon" />
              <span>{i18n("CRM")}</span>
            </Link>
          )}
          <Link
            to="/collections"
            onClick={(e) => {
              onLocationChange(e, "/collections");
              closeMenu();
            }}
            className="menu-list_item"
          >
            <img src={favoritesIcon} alt="Favorites icon" />
            <span>{i18n("Collections")}</span>
          </Link>
          <Link to="/leads" className="menu-list_item" onClick={closeMenu}>
            <img
              className="menu-list_profile-image"
              src={leadsIcon}
              alt="Leads icon"
            />
            <span>{i18n("My Leads")}</span>
          </Link>
          {process.env.REACT_APP_NODE_ENV !== "production" && userHasRole("catastro") && (
            <Link
              to="/catastro"
              onClick={(e) => {
                onLocationChange(e, "/catastro");
                closeMenu();
              }}
              className="menu-list_item"
            >
            <img src={catastroIcon} alt="Catastro icon" />
            <span>{i18n("Catastro Search")}</span>
          </Link>
          )}
          {userHasRole("agency_directory") && (
            <Link
              to="/agency-directory"
              onClick={(e) => {
                onLocationChange(e, "/agency-directory");
                closeMenu();
              }}
              className="menu-list_item"
            >
              <img src={agencyDirectoryIcon} alt="Agency Directory icon" />
              <span>{i18n("Agency Directory")}</span>
            </Link>
          )}
          <div
            className="menu-list_item"
            onClick={() => {
              onToggleNotificationPanel();
              closeMenu();
            }}
          >
            <img src={notificationIcon} alt="Notification icon" />
            <span>{i18n("Notifications")}</span>
          </div>
          <Link to="/settings" className="menu-list_item" onClick={closeMenu}>
            <img
              className="menu-list_profile-image"
              src={profile}
              alt="Profile icon"
            />
            <span>{i18n("Account Settings")}</span>
          </Link>

          <div className="menu-list_item" onClick={onToggleClientMode}>
            {props.clientMode ? (
              <>
                <img src={preferencesIcon} alt="Feedback icon" />
                <span>{i18n("Turn off client mode")}</span>
              </>
            ) : (
              <>
                <img src={preferencesIcon} alt="Feedback icon" />
                <span>{i18n("Turn on client mode")}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const DesktopNavbar = () => {
    return (
      <div className="navbar">
        <div className="navbar_contents">
          <div className="navbar_logo-container">
            <Link to="/" onClick={(e) => onLocationChange(e, "/")}>
              <div className="navbar_logo-container_wrapper">
                <img src={logo} alt="iRealty beta logo" />
              </div>
            </Link>
          </div>
          <div className="navbar_items">
            <Link
              to="/"
              data-tooltip-id="dashboard-tooltip"
              data-tooltip-content={i18n("Dashboard")}
              onClick={(e) => onLocationChange(e, "/")}
              className={
                "navbar-item" +
                (pathname === "/"
                  ? " active"
                  : "")
              }
            >
              <img src={dashboardIcon} alt="Dashboard icon" />
            </Link>
            <Link
              to="/search"
              data-tooltip-id="search-tooltip"
              data-tooltip-content={i18n("Property Search")}
              onClick={(e) => onLocationChange(e, "/search")}
              className={
                "navbar-item" +
                (pathname.startsWith("/search")
                  ? " active"
                  : "")
              }
            >
              <img src={searchIcon} alt="Search icon" />
            </Link>
            <Link
              data-tooltip-id="cma-tooltip"
              data-tooltip-content={i18n("Smart Valuation")}
              to="/valuation"
              onClick={(e) => onLocationChange(e, "/valuation")}
              className={
                "navbar-item" + (pathname === "/valuation" ? " active" : "")
              }
            >
              <img src={cmaIcon} alt="Smart Valuation icon" />
            </Link>
            <Link
              data-tooltip-id="market-insights-tooltip"
              data-tooltip-content={i18n("Market Insights")}
              to="/market-insights"
              onClick={(e) => {
                onLocationChange(e, "/market-insights");
              }}
              className={
                "navbar-item" +
                (pathname === "/market-insights" ? " active" : "")
              }
            >
              <img src={marketInsightsIcon} alt="Market Insights icon" />
            </Link>
            {process.env.REACT_APP_NODE_ENV === "production" && (
              <div
                data-tooltip-id="matches-tooltip"
                data-tooltip-content={i18n("CRM (Coming Soon)")}
                href="/crm"
                className={
                  "navbar-item disabled" +
                  (pathname === "/matches" ? " active" : "")
                }
              >
                <img src={myPropertiesIcon} alt="CRM icon" />
              </div>
            )}
            {process.env.REACT_APP_NODE_ENV !== "production" && (
              <Link
                to="/my-properties"
                data-tooltip-id="my-properties-tooltip"
                data-tooltip-content={i18n("CRM")}
                onClick={(e) => onLocationChange(e, "/my-properties")}
                className={
                  "navbar-item" +
                  (pathname.startsWith("/my-properties") ? " active" : "")
                }
              >
                <img src={myPropertiesIcon} alt="CRM icon" />
              </Link>
            )}
            <Link
              data-tooltip-id="collections-tooltip"
              data-tooltip-content={i18n("Collections")}
              to="/collections"
              onClick={(e) => onLocationChange(e, "/collections")}
              className={
                "navbar-item" +
                (pathname.startsWith("/collections") ? " active" : "")
              }
            >
              <img src={favoritesIcon} alt="Favorites icon" />
            </Link>
            <Link
              data-tooltip-id="leads-tooltip"
              data-tooltip-content={i18n("My Leads")}
              to="/leads"
              onClick={(e) => {
                onLocationChange(e, "/leads");
              }}
              className={
                "navbar-item" +
                (pathname === "/leads" ? " active" : "")
              }
            >
              <img src={leadsIcon} alt="Leads icon" />
            </Link>
            {process.env.REACT_APP_NODE_ENV !== "production" && userHasRole("catastro") && (
              <Link
                to="/catastro"
                data-tooltip-id="catastro-tooltip"
                data-tooltip-content={i18n("Catastro Search")}
                onClick={(e) => onLocationChange(e, "/catastro")}
                className={
                "navbar-item" +
                (pathname.startsWith("/catastro")
                  ? " active"
                  : "")
              }
            >
                <img src={catastroIcon} alt="Catastro icon" />
              </Link>
            )}
            {
              userHasRole("agency_directory") && (
                <Link
                  data-tooltip-id="agency-directory-tooltip"
                  data-tooltip-content={i18n("Agency Directory")}
                  to="/agency-directory"
                  onClick={(e) => {
                    onLocationChange(e, "/agency-directory");
                  }}
                  className={
                    "navbar-item" +
                    (pathname === "/agency-directory" ? " active" : "")
                  }
                >
                  <img src={agencyDirectoryIcon} alt="Agency Directory icon" />
                </Link>
              )}
            {subscriptionPlan !== "ultra" && subscriptionPlan !== "enterprise" && subscriptionLoaded && (
              <div
                data-tooltip-id="bank-properties-tooltip"
                className={
                  "navbar-item" +
                  (pathname === "#" ? " active" : "") +
                  (props.highlightProIcon ? " new" : "")
                }
                onClick={() => {
                  onOpenPricingModal();
                }}
              >
                <DiamondIcon
                  color={
                    props.highlightProIcon
                      ? "rgba(0, 122, 255, 1)"
                      : "rgba(34, 34, 34, 1)"
                  }
                  width={20}
                  height={20}
                />
              </div>
            )}

            {/* Tooltip Content */}
            {isTooltipVisible &&
              getSubscriptionPlan(props.user) !== "ultra" &&
              subscriptionLoaded && (
                <Tooltip
                  id="bank-properties-tooltip"
                  place="right"
                  isOpen={isTooltipVisible}
                  className="bank-properties-tooltip"
                  clickable={true}
                  border={"2px solid rgba(0, 122, 255, 1)"}
                >
                  <div className="react-tooltip-content">
                    <span className="title">
                      {i18n("Upgrade your license")}
                    </span>
                    <span>
                      {i18n(
                        "Get ahead by upgrading to create Valuations, Unlock off-market properties and access exclusive new tools.",
                      )}
                    </span>
                    <button className="dismiss-btn" onClick={dismissTooltip}>
                      <img src={dismissIcon} />
                    </button>
                  </div>
                </Tooltip>
              )}
            <div
              data-tooltip-id="notifications-tooltip"
              data-tooltip-content={i18n("Notifications")}
              className={
                "navbar-item" + (pathname === "/notifications" ? " active" : "")
              }
              onClick={() => onToggleNotificationPanel()}
            >
              <img src={notificationIcon} alt="Notification icon" />
              {unreadNotifications.length > 0 && (
                <div
                  className={
                    "notification-badge" +
                    (unreadNotifications.length > 99 ? " overflow" : "")
                  }
                >
                  {unreadNotifications.length > 99 ? (
                    <span className="notification-badge_overflow">99+</span>
                  ) : (
                    <span>{unreadNotifications.length}</span>
                  )}
                </div>
              )}
            </div>
            <div className="flex-grow"></div>
          </div>
          <Link
            to="/settings"
            onClick={(e) => onLocationChange(e, "/settings")}
            className="navbar_account-container"
          >
            <div
              data-tooltip-id="settings-tooltip"
              data-tooltip-content={i18n("Account Settings")}
              className="navbar_profile-image"
            >
              <img src={profile} alt="Profile icon" />
            </div>
          </Link>
          <div className="navbar_divider"></div>
          <div
            data-tooltip-id="client-mode-tooltip"
            data-tooltip-content={i18n("Client mode")}
            className="navbar_footer"
          >
            <Switch onToggle={onToggleClientMode} isOn={props.clientMode} />
          </div>
        </div>
        <Tooltip id="dashboard-tooltip" place="right" />
        <Tooltip id="search-tooltip" place="right" />
        <Tooltip id="collections-tooltip" place="right" />
        <Tooltip id="cma-tooltip" place="right" />
        <Tooltip id="market-insights-tooltip" place="right" />
        <Tooltip id="new-developments-tooltip" place="right" />
        <Tooltip id="matches-tooltip" place="right" />
        <Tooltip id="notifications-tooltip" place="right" />
        <Tooltip id="my-properties-tooltip" place="right" />
        <Tooltip id="settings-tooltip" place="right" />
        <Tooltip id="client-mode-tooltip" place="right" />
        <Tooltip id="help-tooltip" place="right" />
        <Tooltip id="feedback-tooltip" place="right" />
        <Tooltip id="agency-directory-tooltip" place="right" />
        <Tooltip id="leads-tooltip" place="right" />
        <Tooltip id="catastro-tooltip" place="right" />
        <ConfirmExitModal />
      </div>
    );
  };

  const MobileNavbar = () => {
    const toggleMenu = () => {
      // Add a small delay to ensure the display:block happens before the transition
      const background = document.querySelector(".menu-list-background");
      const menu = document.querySelector(".menu-list");

      background.style.display = "block";
      // Force a reflow by accessing offsetHeight and storing it in a throwaway variable
      const _ = background.offsetHeight;

      // Now add the active class to trigger the transition
      background.classList.add("active");
      menu.classList.add("active");
    };

    return (
      <div className="navbar-mobile">
        <div className="navbar-mobile_logo-container">
          <Link to="/" onClick={(e) => onLocationChange(e, "/")}>
            <div className="navbar-mobile_logo-container_wrapper">
              <img src={logo} alt="iRealty logo" />
            </div>
          </Link>
        </div>
        <div onClick={toggleMenu} className="navbar-mobile_menu">
          <img src={menuIcon} alt="Menu icon" />
        </div>
        <MenuList />

        <ConfirmExitModal />
      </div>
    );
  };

  return (
    <>
      <DesktopNavbar />
      <MobileNavbar />
    </>
  );
};

// Map the setProperties action to props
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setProperties: require("../../actions/propertiesActions").setProperties, // avoid circular dependencies
      setFilteredProperties: require("../../actions/propertiesActions")
        .setFilteredProperties,
      setDrawnPolygons: require("../../actions/polygonActions")
        .setDrawnPolygons,
      setFilters: require("../../actions/filterActions").setFilters,
      setSaleType: require("../../actions/filterActions").setSaleType,
      setInvisibleFilters: require("../../actions/filterActions")
        .setInvisibleFilters,
      setSort: require("../../actions/filterActions").setSort,
      setClientMode: require("../../actions/miscActions").setClientMode,
      setCmaProperty: require("../../actions/cmaActions").setCmaProperty,
      setSelectedPlot: require("../../actions/plotsActions").setSelectedPlot,
      setCanDrawPlots: require("../../actions/plotsActions").setCanDrawPlots,
      setSaveSearchModalOpen: require("../../actions/saveSearchActions")
        .setSaveSearchModalOpen,
      setSearchObject: require("../../actions/saveSearchActions")
        .setSearchObject,
      setGlobalLoading: require("../../actions/miscActions").setGlobalLoading,
      setGlobalLoadingMessage: require("../../actions/miscActions")
        .setGlobalLoadingMessage,
      setNotificationsOpen: require("../../actions/notificationsActions")
        .setNotificationsOpen,
      setIsPDFGenerated: require("../../actions/cmaActions").setIsPDFGenerated,
      setPricingModal: require("../../actions/miscActions").setPricingModal,
      setHighlightProIcon: require("../../actions/miscActions")
        .setHighlightProIcon,
      incrementNumberOfPolygonsFetched: require("../../actions/polygonActions")
        .incrementNumberOfPolygonsFetched,
      setCanDrawGeographicPolygons: require("../../actions/mapActions")
        .setCanDrawGeographicPolygons,
      setHideBounds: require("../../actions/mapActions").setHideBounds,
      setCanShowBoundsToolbarItem: require("../../actions/mapActions")
        .setCanShowBoundsToolbarItem,
      setManualValuationMode: require("../../actions/cmaActions").setManualValuationMode,
      setCmaSearchResults: require("../../actions/cmaActions").setCmaSearchResults,
      setCmaProperty: require("../../actions/cmaActions").setCmaProperty,
      resetAgencyState: require("../../actions/agencyActions").resetAgencyState,
      resetLeadState: require("../../actions/leadActions").resetLeadState,
    },
    dispatch,
  );
};

export default connect(
  (state) => ({
    clientMode: state.misc.clientMode,
    agencyDetails: state.user.userData.agencyDetails,
    personalDetails: state.user.userData.personalDetails,
    lang: state.user.userData.preferences.lang,
    user: state.auth.user,
    userData: state.user.userData,
    misc: state.misc,
    drawnPolygons: state.polygon.drawnPolygons,
    searchContext: state.saveSearch.searchContext,
    notifications: state.notifications.notifications,
    notificationsOpen: state.notifications.notificationsOpen,
    cma: state.cma,
    highlightProIcon: state.misc.highlightProIcon,
    properties: state.property.properties,
    subscription: state.auth.subscription,
  }),
  mapDispatchToProps,
)(Navbar);
