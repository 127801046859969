import Dashboard from 'components/Dashboard';
import React, { useEffect, useState } from 'react';
import './webValuator.scss';
import { getLang, i18n } from 'i18n/localisation';
import { connect } from 'react-redux';
import { getUserRoles } from 'utils/userHelpers';
import { setPricingModal } from 'actions/miscActions';
import fireApiRequest from 'api/core';
import Button from 'components/ui/Button/Button';

var SCRIPT_URL = "https://irealty-lead-magnet-dev.s3.eu-west-2.amazonaws.com/lead-magnet.js";
var STYLES_URL = "https://irealty-lead-magnet-dev.s3.eu-west-2.amazonaws.com/lead-magnet.css";
var GENERATING_SCRIPT_ENDPOINT = "https://jmngyu2d2e.execute-api.eu-west-2.amazonaws.com/dev/add_lead_magnet_domain_referrer";
if (process.env.REACT_APP_NODE_ENV === "production") {
  SCRIPT_URL = "https://irealty-lead-magnet.s3.eu-west-2.amazonaws.com/lead-magnet.js";
  STYLES_URL = "https://irealty-lead-magnet.s3.eu-west-2.amazonaws.com/lead-magnet.css";
  GENERATING_SCRIPT_ENDPOINT = "https://f22a66v9qh.execute-api.eu-west-2.amazonaws.com/production/add_lead_magnet_domain_referrer";
}

const Modal = ({ isOpen, onClose, script }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(script);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{i18n("Generated Script")}</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <span>
          <p>
            {i18n("To embed the Web Valuator on your website, follow these steps:")}
          </p>
          <ol className="modal-body-list">
            <li>{i18n("Copy the script below")}</li>
            <li>{i18n("Paste it into the HTML of the page where you want the valuator to appear")}</li>
            <li>{i18n("Replace \"lead-magnet-container\" with the ID of the HTML element where you want the valuator to be displayed")}</li>
            <li>
              {i18n("If you don't have a specific container element, you can create one by adding")}{" "}
              <code className="code-inline">div id=\"lead-magnet-container\"&gt;&lt;/div&gt;</code> 
              {i18n("to your page where you want the valuator to appear")}
            </li>
          </ol>
          <p>
            {i18n("If you encounter any issues with the implementation, please contact your website developer or administrator for assistance.")}
          </p>
          </span>
          <pre className="code-block">
            <code>{script}</code>
          </pre>
          <button
            className="copy-button"
            onClick={copyToClipboard}
          >
            {copied ? i18n("Copied!") : i18n("Copy to Clipboard")}
          </button>
        </div>
      </div>
    </div>
  );
};

const WebValuatorPage = ({ teamId, user }) => {
  const [formData, setFormData] = useState({
    primaryColor: '#222222', // rgba(34, 34, 34, 1)
    secondaryColor: '#D1D1D1', // rgba(209, 209, 209, 1)
    language: getLang().code,
    domain: ''
  });

  const lang = getLang().code;
  const [showModal, setShowModal] = useState(false);
  const [generatedScript, setGeneratedScript] = useState('');
  const [isGeneratingScript, setIsGeneratingScript] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      language: lang
    });

    document.documentElement.scrollTop = 0;
  }, [lang]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const generateScript = async () => {
    if (isGeneratingScript) return;

    setIsGeneratingScript(true);

    try {
      await fireApiRequest(GENERATING_SCRIPT_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          domain_name: formData.domain,
        })
      });

      const script = `<script src="${SCRIPT_URL}"></script>
      <script>
        // Initialize lead magnet form
        window.initLeadMagnet('lead-magnet-container', {
          lang: '${formData.language}',
          primaryColor: "${formData.primaryColor}",
          secondaryColor: "${formData.secondaryColor}",
          clientId: "${teamId}"
        });

        const styles = document.createElement('link');
        styles.href = "${STYLES_URL}";
        styles.rel = 'stylesheet';
        document.head.appendChild(styles);
      </script>`;
      
      setGeneratedScript(script);
      setShowModal(true);
    } catch (error) {
      console.error('Error generating script:', error);
    } finally {
      setIsGeneratingScript(false);
    }
  };

  const openTestValuator = () => {
    const params = new URLSearchParams({
      primaryColor: formData.primaryColor,
      secondaryColor: formData.secondaryColor,
      lang: formData.language
    });
    window.open(`/web-valuator/test?${params.toString()}`, '_blank');
  };

  return (
    <Dashboard>
      <div 
        className="back-button absolute"
        onClick={() => window.history.back()}
      >
        ← {i18n("Back")}
      </div>
      <div className="web-valuator-container">
        <h1>{i18n("Web Valuator Configuration")}</h1>
        
        <div className="web-valuator-description">
          <h2>{i18n("What is the Web Valuator?")}</h2>
          <p>{i18n("The Web Valuator is a powerful tool that allows you to embed an interactive property valuation form directly on your website. It provides potential clients with instant property valuations while capturing their contact information for your lead generation pipeline.")}</p>
          
          <h3>{i18n("Key Benefits:")}</h3>
          <ul>
            <li>{i18n("Seamlessly integrates with your website's design through customizable colors and styling")}</li>
            <li>{i18n("Captures qualified leads by collecting property details and contact information")}</li>
            <li>{i18n("Supports multiple languages to reach a broader audience")}</li>
            <li>{i18n("Mobile-responsive design that works on all devices")}</li>
            <li>{i18n("Easy to implement with a simple copy-paste script")}</li>
          </ul>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label>{i18n("Color Settings")}</label>
            <div className="color-inputs-grid">
              <div className="color-input-wrapper">
                <label>{i18n("Primary Color")}</label>
                <input
                  type="color"
                  name="primaryColor"
                  value={formData.primaryColor}
                  onChange={handleInputChange}
                />
                <div className="color-value">{formData.primaryColor}</div>
              </div>

              <div className="color-input-wrapper">
                <label>{i18n("Secondary Color")}</label>
                <input
                  type="color"
                  name="secondaryColor"
                  value={formData.secondaryColor}
                  onChange={handleInputChange}
                />
                <div className="color-value">{formData.secondaryColor}</div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>{i18n("Language")}</label>
            <select
              name="language"
              value={formData.language}
              onChange={handleInputChange}
            >
              <option value="en">English</option>
              <option value="es">Español</option>
            </select>
          </div>

          <div className="form-group">
            <label>{i18n("Domain")}</label>
            <p>{i18n("This is required to in order for the web valuator to work with your site")}</p>
            <p>
              <strong>
                {i18n("This is different from a url, for example if ")}
                <code className="code-inline">https://dashboard.irealty.app/search</code>
                {i18n(" is the url,")}<br />
                {i18n("then ")}
                <code className="code-inline">dashboard.irealty.app</code>
                {i18n("is the domain")}
              </strong>
            </p>
            <input
              type="text"
              name="domain"
              value={formData.domain}
              onChange={handleInputChange}
              placeholder={i18n("Enter your domain (e.g., example.com)")}
            />
          </div>

          <div className="button-group">
            <Button 
              className="web-valuator-button"
              type="button" 
              onClick={generateScript}
              disabled={!formData.domain.trim()}
            >
              {isGeneratingScript ? i18n("Generating...") : i18n("Generate Script")}
            </Button>

            <Button 
              className="web-valuator-button"
              variant="secondary"
              type="button" 
              onClick={openTestValuator}
            >
              {i18n("Test Valuator")}
            </Button>
          </div>
        </form>

        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          script={generatedScript}
        />
      </div>
    </Dashboard>
  );
};

export default connect(state => ({
  teamId: state.user.userData?.teamId,
  user: state.user
}))(WebValuatorPage);
