import MapOverlay from "./MapOverlay";
import "./map.scss";
import propertyPinNibble from "../../assets/map/property_pin_nibble.svg";
import favouriteIcon from "../../assets/map/favourite_icon.svg";
import favouriteIconSelected from "../../assets/map/favourite_icon_selected.svg";
import favouriteIconHovered from "../../assets/map/favourite_icon_hovered.svg";
import { selectPropertyCard } from "../../utils/ui";
import { connect } from "react-redux";
import { useEffect, useState, memo, useMemo } from "react";
import { formatNumber, formatPrice, randomNumberWithStep } from "utils/helpers";
import { i18n } from "i18n/localisation";
import { getSubscriptionPlan } from "utils/userHelpers";
import { getFilteredProperties } from "lib/filter/filters";
import { MARKET_INSIGHTS_MODES } from "reducers/mapReducer";
import moment from "moment";

// Wrap the component with React.memo to prevent unnecessary re-renders
const PropertyMarker = memo(function PropertyMarker(props) {
  const {
    position,
    property,
    map,
    cluster,
    disableActions,
    page,
    saleType,
    filters,
    marketInsightsMode,
  } = props;

  // Helper functions for cluster metrics
  const filterValidPropertiesForCurrentMode = (properties) => {
    switch (marketInsightsMode) {
      case MARKET_INSIGHTS_MODES.AVG_BEDROOMS:
        return properties.filter(p => !isNaN(parseFloat(p.rooms)));
      case MARKET_INSIGHTS_MODES.DENSITY:
        return properties; // All properties are valid for density
      case MARKET_INSIGHTS_MODES.DAYS_ON_MARKET:
        return properties.filter(p => p.daysOnMarket && !isNaN(parseFloat(p.daysOnMarket)));
      case MARKET_INSIGHTS_MODES.AVG_PRICE:
        return properties.filter(p => !isNaN(parseFloat(p.price)));
      default: // pricePerM2
        if (saleType === "rent") {
          return properties.filter(p => !isNaN(parseFloat(p.price)));
        }
        return properties.filter(p => {
          const price = parseFloat(p.price);
          const size = parseFloat(p.size);
          return !isNaN(price) && !isNaN(size) && size > 0;
        });
    }
  };

  const formatClusterPrice = (price) => {
    let formattedPrice = Math.round(price);
    let suffix = "";

    if (formattedPrice >= 1000000) {
      formattedPrice = (formattedPrice / 1000000).toFixed(1);
      suffix = "M";
    } else if (formattedPrice >= 1000) {
      formattedPrice = (formattedPrice / 1000).toFixed(1);
      suffix = "K";
    }

    return `${formattedPrice}${suffix}`;
  };

  const getClusterSizeClass = (value, average) => {
    if (marketInsightsMode === MARKET_INSIGHTS_MODES.DENSITY) {
      if (value < 10) {
        return " small";
      } else if (value > 99) {
        return " large";
      } else {
        return " medium";
      }
    }

    if (value < average * 0.8) {
      return " small";
    } else if (value > average * 1.2) {
      return " large";
    } else {
      return " medium";
    }
  };

  const calculateClusterMetric = (properties) => {
    switch (marketInsightsMode) {
      case MARKET_INSIGHTS_MODES.AVG_BEDROOMS: {
        const avgBedrooms = properties.reduce((sum, p) => sum + parseFloat(p.rooms), 0) / properties.length;
        return {
          value: avgBedrooms,
          display: Math.round(avgBedrooms).toString()
        };
      }
      case MARKET_INSIGHTS_MODES.DENSITY: {
        return {
          value: properties.length,
          display: properties.length.toString()
        };
      }
      case MARKET_INSIGHTS_MODES.DAYS_ON_MARKET: {
        const avgDays = properties.reduce((sum, p) => {
          return !isNaN(p.daysOnMarket) ? sum + p.daysOnMarket : sum;
        }, 0) / properties.length;
        return {
          value: avgDays,
          display: `${Math.round(avgDays)} ${i18n("days")}`
        };
      }
      case MARKET_INSIGHTS_MODES.AVG_PRICE: {
        const avgPrice = properties.reduce((sum, p) => sum + parseFloat(p.price), 0) / properties.length;
        const formattedPrice = formatClusterPrice(avgPrice);
        
        let suffix = "";
        if (saleType === "rent") {
          suffix = filters.find((f) => f.path === "rental_isShortTerm") ? "/d" : "/m";
        }
        
        return {
          value: avgPrice,
          display: `€${formattedPrice}${suffix}`
        };
      }
      default: { // pricePerM2
        // Only calculate price per sqm for sale, not for rent
        if (saleType === "rent") {
          const avgPrice = properties.reduce((sum, p) => sum + parseFloat(p.price), 0) / properties.length;
          const formattedPrice = formatClusterPrice(avgPrice);
          const suffix = filters.find((f) => f.path === "rental_isShortTerm") ? "/d" : "/m";
          
          return {
            value: avgPrice,
            display: `€${formattedPrice}${suffix}`
          };
        } else {
          const avgPricePerSqm = properties.reduce((sum, p) => {
            return sum + parseFloat(p.price) / parseFloat(p.size);
          }, 0) / properties.length;
          
          const formattedPrice = formatClusterPrice(avgPricePerSqm);
          
          return {
            value: avgPricePerSqm,
            display: `€${formattedPrice}/m²`
          };
        }
      }
    }
  };

  const [isHovering, setIsHovering] = useState(false);
  const [allPropertiesAvg, setAllPropertiesAvg] = useState(0);

  useEffect(() => {
    if (page !== "marketInsights") {
      return;
    }

    let filteredProperties = getFilteredProperties();
    // Calculate average metric across all properties based on current mode
    const allPropertiesAvg = calculateAverageForProperties(filteredProperties);
    setAllPropertiesAvg(allPropertiesAvg);
  }, [getFilteredProperties(), marketInsightsMode]);

  // Extract logic to calculate averages based on property type and mode
  const calculateAverageForProperties = (properties) => {
    if (!properties.length) return 0;
    
    if (marketInsightsMode === MARKET_INSIGHTS_MODES.AVG_BEDROOMS) {
      return properties.reduce((sum, p) => {
        const bedrooms = parseFloat(p.rooms);
        return !isNaN(bedrooms) ? sum + bedrooms : sum;
      }, 0) / properties.length;
    }
    
    if (marketInsightsMode === MARKET_INSIGHTS_MODES.DENSITY) {
      return properties.length;
    }
    
    if (marketInsightsMode === MARKET_INSIGHTS_MODES.DAYS_ON_MARKET) {
      return properties.reduce((sum, p) => {
        return !isNaN(p.daysOnMarket) ? sum + p.daysOnMarket : sum;
      }, 0) / properties.length;
    }
    
    // Default: price or price per sqm (based on sale type)
    return properties.reduce((sum, p) => {
      const price = parseFloat(p.price);

      if (saleType === "rent" || marketInsightsMode === MARKET_INSIGHTS_MODES.AVG_PRICE) {
        return !isNaN(price) ? sum + price : sum;
      }

      const size = parseFloat(p.size);
      return !isNaN(price) && !isNaN(size) && size > 0
        ? sum + price / size
        : sum;
    }, 0) / properties.length;
  };

  // scroll to the property on the side panel
  const onPropertyPinClick = (e) => {
    if (disableActions) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (page !== "cma") {
      props.selectMarker();
      window.propertyPanelScrollToProperty(property.id);
    } else {
      const sidePanel = document.querySelector(".cma-form");
      const selectedCard = document.querySelector(`#property-${property.id}`);
      sidePanel.scrollTop = selectedCard.offsetTop - 210;
    }
  };

  // fires a zoom to cluster event on the parent property map component
  const onClusterClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (page === "marketInsights") {
      return;
    }

    props.onClusterClick(cluster);
  };

  // Memoize expensive calculations
  const clusterMetric = useMemo(() => {
    if (page === "marketInsights" && cluster && cluster.properties.cluster && props.clusterProperties.length > 0) {
      const validProperties = filterValidPropertiesForCurrentMode(props.clusterProperties);
      if (validProperties.length > 0) {
        return calculateClusterMetric(validProperties);
      }
    }
    return null;
  }, [page, cluster, props.clusterProperties, marketInsightsMode, saleType, filters]);

  // Memoize marker text calculation
  const markerText = useMemo(() => {
    if (!property) return "";
    
    if (property.isCatastro) {
      return property.ref;
    } else {
      let unformattedPrice = parseInt(property.price);

      if (property.saleType == "rent") {
        if (property.rental_isShortTerm) {
          return "€" + formatNumber(unformattedPrice) + "/d";
        } else {
          return "€" + formatNumber(unformattedPrice) + "/m";
        }
      } else {
        if (property.saleType === "sold" && getSubscriptionPlan() === null) {
          unformattedPrice = randomNumberWithStep(1000000, 10000000, 100000);
        }

        let price = Math.ceil(unformattedPrice / 1000);
        let priceSuffix = "K";

        // format price in the millions
        if (price > 999) {
          price = Math.ceil(price / 100);
          price = parseFloat((price / 10).toFixed(1));
          priceSuffix = "M";
        }

        let text = `€${price}${priceSuffix}`;

        // if is privateBankProperty and price is 0 then show price on request
        if (property.isPrivateBankProperty && price === 0) {
          text = i18n("P.O.R");
        }
        
        return text;
      }
    }
  }, [property]);

  // if its a cluster then render the cluster instead of a property pin
  if (cluster && cluster.properties.cluster) {
    let count = cluster.properties.point_count;
    let baseClassName = "map-cluster";

    // Calculate average price per sqm for market insights clusters
    let clusterMarkerText = count;

    if (page === "marketInsights") {
      baseClassName = "heatmap-cluster";

      const validProperties = filterValidPropertiesForCurrentMode(props.clusterProperties);

      if (validProperties.length > 0) {
        const { value, display } = calculateClusterMetric(validProperties);
        
        // Add size class based on how the cluster's value compares to overall average
        baseClassName += getClusterSizeClass(value, allPropertiesAvg);
        
        clusterMarkerText = display;
      }
    } else {
      clusterMarkerText = count;

      if (count < 10) {
        baseClassName += " padded-cluster";
      } else if (count > 99) {
        baseClassName += " lesser-padded-cluster";
        clusterMarkerText = "99+";
      }
    }

    return (
      <MapOverlay
        position={{
          lat: position.lat,
          lng: position.lng,
        }}
        map={map}
      >
        <div
          className={baseClassName}
          onClick={onClusterClick}
          data-properties={props.clusterProperties.map((p) => p.id).join(",")}
        >
          {clusterMarkerText}
        </div>
      </MapOverlay>
    );
  }

  if (page === "marketInsights") {
    return null;
  }

  // Use a different variable name to avoid conflict with the memoized markerText
  let displayMarkerText = "";
  let isSelected = props.isSelected || property.isCmaProperty;
  let isInCollection = props.collections.find((c) =>
    c.properties.find((p) => p.id === property.id),
  );

  // catastro refs are displayed on map differently
  if (property.isCatastro) {
    displayMarkerText = property.ref;
  } else {
    let unformattedPrice = parseInt(property.price);

    if (property.saleType == "rent") {
      if (property.rental_isShortTerm) {
        displayMarkerText = "€" + formatNumber(unformattedPrice) + "/d";
      } else {
        displayMarkerText = "€" + formatNumber(unformattedPrice) + "/m";
      }
    } else {
      if (property.saleType === "sold" && getSubscriptionPlan() === null) {
        unformattedPrice = randomNumberWithStep(1000000, 10000000, 100000);
      }

      let price = Math.ceil(unformattedPrice / 1000);
      let priceSuffix = "K";

      // format price in the millions
      if (price > 999) {
        price = Math.ceil(price / 100);
        price = parseFloat((price / 10).toFixed(1));
        priceSuffix = "M";
      }

      displayMarkerText = `€${price}${priceSuffix}`;

      // if is privateBankProperty and price is 0 then show price on request
      if (property.isPrivateBankProperty && price === 0) {
        displayMarkerText = i18n("P.O.R");
      }
    }
  }

  const onHover = (inbound) => {
    if (property.isCatastro) {
      return;
    }

    setIsHovering(inbound);
  };

  return (
    <>
      {map && (
        <MapOverlay
          position={{
            lat: position.lat,
            lng: position.lng,
          }}
          map={map}
        >
          <div
            onMouseOver={() => onHover(true)}
            onMouseOut={() => onHover(false)}
            id={"property-marker-" + property.id}
            onClick={onPropertyPinClick}
            className={
              "property-marker" +
              (isInCollection ? " property-marker-favourite" : "") +
              (isSelected ? " selected" : "") +
              (isHovering ? " hovered" : "")
            }
          >
            <img className="property-marker_nibble" src={propertyPinNibble} />
            {isInCollection &&
              (isSelected ? (
                <img
                  className="property-marker_favourite"
                  src={favouriteIconSelected}
                />
              ) : isHovering ? (
                <img
                  className="property-marker_favourite"
                  src={favouriteIconHovered}
                />
              ) : (
                <img
                  className="property-marker_favourite"
                  src={favouriteIcon}
                />
              ))}
            <span
              className={
                property.saleType === "sold" && getSubscriptionPlan() === null
                  ? "blurred-text"
                  : ""
              }
            >
              {displayMarkerText}
            </span>
          </div>
        </MapOverlay>
      )}
    </>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function to prevent unnecessary re-renders
  // Only re-render if these specific props change
  return (
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.position.lat === nextProps.position.lat &&
    prevProps.position.lng === nextProps.position.lng &&
    prevProps.property?.id === nextProps.property?.id &&
    prevProps.marketInsightsMode === nextProps.marketInsightsMode &&
    prevProps.page === nextProps.page
  );
});

export default connect((state) => ({
  collections: state.collections.collections,
  properties: state.property.properties,
  saleType: state.filters.saleType,
  filters: state.filters.filters,
  marketInsightsMode: state.map.marketInsightsMode,
}))(PropertyMarker);
