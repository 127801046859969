import Dashboard from "components/Dashboard";
import { i18n } from "i18n/localisation";
import { useNavigate, useParams } from "react-router-dom";
import emailIcon from "../../assets/core/email.svg";
import whatsappIcon from "../../assets/core/whatsapp_white.svg";
import phoneIcon from "../../assets/property/phone.png";
import locationIcon from "../../assets/property/location.svg";
import "./leadDetails.scss";
import { capitalizeWord } from "utils/helpers";
import { getLeadRequest } from "api/leads";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const LeadDetails = () => {
  const { leadId } = useParams();
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLead = async () => {
      const response = await getLeadRequest(leadId);
      setLead(response);
      setLoading(false);
    };

    fetchLead();
  }, [leadId]);

  if (loading || !lead) {
    return (
      <Dashboard>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      </Dashboard>
    );
  }

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR'
    }).format(price);
  };

  const handleOpenWhatsApp = () => {
    window.open(`https://wa.me/${lead.phone.trim()}`, '_blank');
  };

  const handleOpenEmail = () => {
    window.open(`mailto:${lead.email}`, '_blank');
  };

  const handleLocationClick = () => {
    if (lead.details?.location?.lat && lead.details?.location?.lng) {
      const url = `https://www.google.com/maps?q=${lead.details.location.lat},${lead.details.location.lng}`;
      window.open(url, '_blank');
    }
  };

  const getConditionText = (condition) => {
    switch (condition) {
      case "good":
        return i18n("Good");
      case "renew":
        return i18n("Needs renovation");
      case "new":
        return i18n("New");
      default:
        return i18n("Good");
    }
  }

  const getTypologyText = (typology) => {
    switch (typology) {
      case "flat":
        return i18n("Flat");
      case "house":
        return i18n("House");
      case "penthouse":
        return i18n("Penthouse");
      case "duplex":
        return i18n("Duplex");
      case "semidetachedHouse":
        return i18n("Semi-detached house");
      case "independantHouse":
        return i18n("Independent");
      case "terracedHouse":
        return i18n("Terraced house");
      case "studio":
        return i18n("Studio");
      case "countryHouse":
        return i18n("Country house");
      default:
        return i18n("Flat");
    }
  }

  const getFeaturesText = (features, parking) => {
    let finalText = "";

    features.forEach(feature => {
      if (feature === "hasAirConditioning") {
        finalText += i18n("Air conditioning") + ", ";
      }
      if (feature === "hasLift") {
        finalText += i18n("Lift") + ", ";
      }
      if (feature === "hasSwimmingPool") {
        finalText += i18n("Pool") + ", ";
      }
      if (feature === "hasGarden") {
        finalText += i18n("Garden") + ", ";
      }
      if (feature === "hasStorage") {
        finalText += i18n("Storage") + ", ";
      }
      if (feature === "hasSeaView") {
        finalText += i18n("Sea view") + ", ";
      }
      if (feature === "hasBalcony") {
        finalText += i18n("Balcony") + ", ";
      }
      if (feature === "hasTerrace") {
        finalText += i18n("Terrace") + ", ";
      }
    });

    if (parking && parking !== "0") {
      finalText += i18n("Parking") + ", ";
    }

    return finalText.slice(0, -2);
  }

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("Lead Details")}</title>
      </Helmet>
      <div className="lead-details">
        <div className="lead-details_header">
          <div className="lead-details_header_info">
            <div className="lead-details_header_title">
              <h1>{lead.name}</h1>
            </div>
            <p className="lead-details_header_subtitle">
              {i18n("Lead created on")} {formatDate(lead.created_at)}
            </p>
          </div>
          <div className="lead-details_header_actions">
            <button className="action-button whatsapp" onClick={handleOpenWhatsApp}>
              <img src={whatsappIcon} alt="WhatsApp" />
              {i18n("WhatsApp")}
            </button>
            <button className="action-button email" onClick={handleOpenEmail}>
              <img src={emailIcon} alt="Email" />
              {i18n("Email")}
            </button>
          </div>
        </div>

        <div className="lead-details_content">
          <div className="lead-details_section">
            <h2>{i18n("Contact Information")}</h2>
            <div className="lead-details_section_content">
              <div className="info-item">
                <img src={phoneIcon} alt="Phone" />
                <span>{lead.phone}</span>
              </div>
              <div className="info-item">
                <img src={emailIcon} alt="Email" />
                <span>{lead.email}</span>
              </div>
              {
                lead.details && lead.details.location && (
                  <>
                    <div className="horizontal-divider"></div>
                    <p className="info-item-location-title mb-2">{i18n("Location of valuation")}:</p>
                    <div className="info-item info-item-location" onClick={handleLocationClick}>
                      <img src={locationIcon} alt="Location" />
                      <span>{lead.details.location.address}</span>
                    </div>
                  </>
                )
              }
            </div>
          </div>

          {lead.source === "lead_magnet" && (
            <>
              <div className="lead-details_section">
                <h2>{i18n("Web Valuator Details")}</h2>
                <div className="lead-details_section_content">
                  <div className="property-grid">
                    <div className="property-item">
                      <span className="label">{i18n("Typology")}</span>
                      <span className="value">{getTypologyText(lead.details.typology)}</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Size")}</span>
                      <span className="value">{lead.details.size}m²</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Bedrooms")}</span>
                      <span className="value">{lead.details.bedrooms}</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Bathrooms")}</span>
                      <span className="value">{lead.details.bathrooms}</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Condition")}</span>
                      <span className="value">{getConditionText(lead.details.condition)}</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Orientation")}</span>
                      <span className="value">{i18n(capitalizeWord(lead.details.orientation))}</span>
                    </div>
                    {
                      lead.details.energy_rating && (
                        <div className="property-item">
                          <span className="label">{i18n("Energy Rating")}</span>
                          <span className="value">{lead.details.energy_rating}</span>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="lead-details_section">
                <h2>{i18n("Additional Information")}</h2>
                <div className="lead-details_section_content">
                  <div className="property-grid">
                    <div className="property-item">
                      <span className="label">{i18n("Valuation")}</span>
                      <span className="value">{formatPrice(lead.details.valuation)}</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Relationship with property")}</span>
                      <span className="value">{i18n(capitalizeWord(lead.details.relationship))}</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Selling time")}</span>
                      <span className="value">{lead.details.selling_time} {parseInt(lead.details.selling_time) === 1 ? i18n("month") : i18n("months")}</span>
                    </div>
                    <div className="property-item">
                      <span className="label">{i18n("Features")}</span>
                      <span className="value">{getFeaturesText(lead.details.features, lead.details.parking)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Dashboard>
  );
};

export default LeadDetails;