import { createReducer } from "@reduxjs/toolkit";
import {
  setCmaProperty,
  setIsPDFGenerated,
  setTempFullRef,
  setManualValuationMode,
  setCmaSearchResults,
  setCmaStage,
} from "../actions/cmaActions";

export const CMA_STAGE_SEARCH_ADDRESS = 0;
export const CMA_STAGE_STUDY_AREA = 1;
export const CMA_STAGE_REFERENCES = 2;
export const CMA_STAGE_SOLD_REFERENCES = 3;

const initialState = {
  cmaProperty: null,
  isPDFGenerated: false,
  tempFullRef: null,
  manualValuationMode: false,
  cmaSearchResults: [],
  stage: CMA_STAGE_SEARCH_ADDRESS,
};

const cmaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCmaProperty, (state, action) => {
      state.cmaProperty = action.payload;
    })
    .addCase(setIsPDFGenerated, (state, action) => {
      state.isPDFGenerated = action.payload;
    })
    .addCase(setTempFullRef, (state, action) => {
      state.tempFullRef = action.payload;
    })
    .addCase(setManualValuationMode, (state, action) => {
      state.manualValuationMode = action.payload;
    })
    .addCase(setCmaSearchResults, (state, action) => {
      state.cmaSearchResults = action.payload;
    })
    .addCase(setCmaStage, (state, action) => {
      state.stage = action.payload;
    });
});

export default cmaReducer;
