import {
  setAgencyReference,
  setBathrooms,
  setBedrooms,
  setDescription,
  setDetailedType,
  setEnergyCertificate,
  setFeatures,
  setPlotSize,
  setPrice,
  setSize,
  setStatus,
  setTitle,
  setUsableSize,
  setYearConstructed,
} from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import { i18n } from "i18n/localisation";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import EmptySection from "../EmptySection";
import SectionWrapper from "../SectionWrapper";
import { SECTION_WRAPPER_CONTENT } from "../uploadPropertyPageConfig";
import { getTitle } from "../utils";
import InputDetails from "./InputDetails";
import SavedDetails from "./SavedDetails";

const PropertyDetails = () => {
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const [content, setContent] = useState(SECTION_WRAPPER_CONTENT.EMPTY_CONTENT);
  const [details, setDetails] = useState();
  const [isCompleted, setIsCompleted] = useState();
  const propertyDetails = useSelector((state) => ({
    bathrooms: state.myProperties.bathrooms,
    detailedType: state.myProperties.detailedType,
    features: state.myProperties.features,
    price: state.myProperties.price,
    bedrooms: state.myProperties.bedrooms,
    size: state.myProperties.size,
    usableSize: state.myProperties.usableSize,
    plotSize: state.myProperties.plotSize,
    status: state.myProperties.status,
    yearConstructed: state.myProperties.yearConstructed,
    energyCertificate: state.myProperties.energyCertificate,
    agencyReference: state.myProperties.agencyReference,
  }));
  const municipality = useSelector((state) => state.myProperties.municipality);
  const listingType = useSelector((state) => state.myProperties.listingType);
  const detailsRef = useRef();

  const validationMapping = {
    price: Boolean(propertyDetails.price && propertyDetails.price !== "0"),
    bedrooms: Boolean(
      propertyDetails.bedrooms && propertyDetails.bedrooms !== 0,
    ),
    bathrooms: Boolean(
      propertyDetails.bathrooms && propertyDetails.bathrooms !== 0,
    ),
    size: Boolean(propertyDetails.size && propertyDetails.size !== "0"),
    detailedType: Boolean(
      propertyDetails.detailedType?.typology ||
        propertyDetails.detailedType?.subTypology,
    ),
  };
  const hasDetail = Object.values(validationMapping).some((detail) => detail);

  useEffect(() => {
    if (hasDetail) {
      setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT);
      setIsCompleted(
        Object.values(validationMapping).filter((detail) => detail).length ===
          5,
      );
    }
  }, []);

  useEffect(() => {
    if (!detailsRef.current) {
      detailsRef.current = propertyDetails;
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (content === SECTION_WRAPPER_CONTENT.SAVED_CONTENT) {
      setIsCompleted(
        Object.values(validationMapping).filter((detail) => detail).length ===
          5,
      );
    }
  }, [content]);

  const handleSaveDetails = async () => {
    try {
      await savePropertyRequest({
        property_id: propertyId,
        saleType: listingType,
        update_fields: {
          bathrooms: detailsRef.current.bathrooms,
          detailedType: detailsRef.current.detailedType,
          features: detailsRef.current.features,
          price: detailsRef.current.price,
          rooms: detailsRef.current.bedrooms,
          size: detailsRef.current.size,
          usableSize: detailsRef.current.usableSize,
          plotSize: detailsRef.current.plotSize,
          status: detailsRef.current.status,
          yearConstructed: detailsRef.current.yearConstructed,
          energyCertificate: detailsRef.current.energyCertificate,
          externalReference: detailsRef.current.agencyReference,
          title: getTitle(detailsRef.current.detailedType, municipality),
        },
      });

      setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT);

      dispatch(setPrice(detailsRef.current.price));
      dispatch(setDetailedType(detailsRef.current.detailedType));
      dispatch(setBedrooms(Number(detailsRef.current.bedrooms)));
      dispatch(setBathrooms(Number(detailsRef.current.bathrooms)));
      dispatch(setSize(detailsRef.current.size));
      dispatch(setFeatures(detailsRef.current.features));
      dispatch(setDescription(detailsRef.current.description || null));
      dispatch(setEnergyCertificate(detailsRef.current.energyCertificate));
      dispatch(setUsableSize(detailsRef.current.usableSize));
      dispatch(setPlotSize(detailsRef.current.plotSize));
      dispatch(setYearConstructed(detailsRef.current.yearConstructed));
      dispatch(setStatus(detailsRef.current.status));
      dispatch(setAgencyReference(detailsRef.current.agencyReference));
      dispatch(
        setTitle(getTitle(detailsRef.current.detailedType, municipality)),
      );

      toast.success(i18n("Details has been saved"));
    } catch (e) {
      console.log(e);
      toast.error(i18n("Error saving details"));
    }
  };

  const handleCancel = () => {
    detailsRef.current = propertyDetails;
    setContent(SECTION_WRAPPER_CONTENT.SAVED_CONTENT);
  };

  return (
    <SectionWrapper
      title={"Property Details"}
      onSave={handleSaveDetails}
      onEdit={() => setContent(SECTION_WRAPPER_CONTENT.INPUT_CONTENT)}
      onCancel={handleCancel}
      content={content}
      isCompleted={isCompleted}
    >
      <div className="flex w-full flex-col rounded-lg bg-white p-6">
        {content === SECTION_WRAPPER_CONTENT.EMPTY_CONTENT && (
          <EmptySection
            setContent={() => setContent(SECTION_WRAPPER_CONTENT.INPUT_CONTENT)}
            buttonLabel={"Add property details"}
            helperText={
              "Add a price, property subtype, bedrooms, bathrooms, ㎡ and property features."
            }
          />
        )}
        {content === SECTION_WRAPPER_CONTENT.INPUT_CONTENT && (
          <InputDetails
            propertyDetails={detailsRef.current}
            setDetails={setDetails}
          />
        )}
        {content === SECTION_WRAPPER_CONTENT.SAVED_CONTENT && <SavedDetails />}
      </div>
    </SectionWrapper>
  );
};

export default PropertyDetails;
