import React from "react";
import { i18n } from "i18n/localisation";
import "./catastro.scss";
import rightChevron from "../../assets/core/right_chevron.svg";
import { formatNumber } from "utils/helpers";

const CatastroCard = ({ catastro, onClick, isSelected }) => {
  // Ensure catastro is not null
  if (!catastro) {
    return null;
  }

  // Format date to a more readable format
  const formatDate = (dateString) => {
    if (!dateString) return "";
    
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } catch (error) {
      return dateString;
    }
  };

  // Handle different property naming conventions between mock API and our service
  const reference = catastro.refcat || "N/A";
  const area = catastro.surface_constructed || 0;
  const type = catastro.typology || "Not specified";
  const status = catastro.status || "Unknown";
  const usage = catastro.typology;
  const floor = catastro.floor || catastro.normalized_floor;
  const door = catastro.door || catastro.normalized_door;
  const constructionYear = catastro.year_constructed;

  const location = catastro.address ? 
  `${catastro.address}, Planta ${floor}, Puerta ${door}` : 
  "-";

  return (
    <div 
      className={`cma-property-card ${isSelected ? 'selected' : ''}`} 
      // onClick={onClick}
    >
      <h2>{reference}</h2>
      <p>{location}</p>
      <div className="cma-property-card_footer">
        <span style={{ textTransform: "capitalize" }}>{type}</span>
        <div className="vertical-divider"></div>
        <span className="cma-property-card_footer_constructed">
          {usage
            ? formatNumber(area)
            : formatNumber(area)}
          ㎡
        </span>
        {reference && (
          <a
            target="_blank"
            className="cma-property-card_footer_link"
            href={`https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCConCiud.aspx?UrbRus=U&RefC=${catastro.refcat}&esBice=&RCBice1=&RCBice2=&DenoBice=&from=OVCBusqueda&pest=rc&RCCompleta=${catastro.refcat}&final=&del=28&mun=900`}
            onClick={(e) => e.stopPropagation()}
          >
            <span>{i18n("Catastro")}</span>
            <img src={rightChevron} />
          </a>
         )}
       </div>
    </div>
  );
};

export default CatastroCard; 

// import React from "react";
// import rightChevron from "../../assets/core/right_chevron.svg";
// import { i18n } from "i18n/localisation";
// import { formatNumber } from "utils/helpers";

// const CatastroCard = ({ catastro, ...rest }) => {

//   const reference = catastro.reference || catastro.referenceNumber || "N/A";
//   const location = catastro.address ? 
//     `${catastro.address}, ${catastro.city}, ${catastro.province}` : 
//     catastro.location || "Location not available";
//   const area = catastro.area || 0;
//   const type = catastro.type || "Not specified";
//   const status = catastro.status || "Unknown";
//   const usage = catastro.usage || catastro.details?.usage || "Not specified";
//   const floors = catastro.floors || catastro.details?.floors;
//   const constructionYear = catastro.constructionYear || catastro.details?.constructionYear;
//   const owner = catastro.owner || "Not specified";
//   const registrationDate = catastro.registrationDate || "";

//   return (
//     <div
//       {...rest}
//       className={"cma-property-card" + (rest.selected ? " selected" : "")}
//     >
//       <h2>{reference}</h2>
//       <p>{location}</p>
//       <p>{area}</p>
//       <div className="cma-property-card_footer">
//         <span>{type}</span>
//         <div className="vertical-divider"></div>
//         <span className="cma-property-card_footer_constructed">
//           {usage
//             ? formatNumber(area)
//             : formatNumber(area)}
//           ㎡
//         </span>
//         {reference && (
//           <a
//             target={reference}
//             className="cma-property-card_footer_link"
//             href={reference}
//             onClick={(e) => e.stopPropagation()}
//           >
//             <span>{i18n("View")}</span>
//             <img src={rightChevron} />
//           </a>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CatastroCard;
