import React, { useState } from 'react';
import AgencyCard from './AgencyCard';
import AgencyFilterPanel from './AgencyFilterPanel';
import { connect } from 'react-redux';
import './agency.scss';
import SpinWheelLoaderAnimation from 'components/animations/SpinWheelLoaderAnimation';
import { i18n } from 'i18n/localisation';
import { adjustSearchBarAndMapTools } from 'utils/helpers';
import filterIcon from 'assets/core/outline_filter.svg';
import { VariableSizeList as List } from "react-window";
import Dropdown from 'components/core/Dropdown';
import { useDispatch } from 'react-redux';
import { setSelectedAgency } from 'actions/agencyActions';

const AGENCY_SORT_FILTERS = [
  { label: 'Name', value: 'name', numeric: false },
  { label: 'Rating', value: 'rating', numeric: true, reverse: true },
  { label: 'Popularity', value: 'reviews_count', numeric: true, reverse: true },
  { label: 'Email', value: 'email', numeric: false },
];

const AgencySidePanel = ({ 
  agencies, 
  fetchingAgencies, 
  mapReducer, 
  selectedAgency, 
  filteredAgencies 
}) => {
  const dispatch = useDispatch();
  const [sortFilter, setSortFilter] = React.useState(AGENCY_SORT_FILTERS[0]);
  const [filterMode, setFilterMode] = useState(false);
  let isActive = fetchingAgencies || mapReducer.areaSelectionMode || agencies.length > 0;
  adjustSearchBarAndMapTools(isActive);

  // Toggle filter panel
  const toggleFilterMode = () => {
    setFilterMode(!filterMode);
  };

  // Sort the agencies based on the sort filter
  let sortedAgencies = [...filteredAgencies];
  if (sortFilter.value === 'rating') {
    // Special case for rating - sort by rating first, then by reviews_count
    sortedAgencies = sortedAgencies.sort((a, b) => {
      const ratingA = parseFloat(a.rating) || 0;
      const ratingB = parseFloat(b.rating) || 0;
      
      // If ratings are equal, sort by reviews_count
      if (ratingA === ratingB) {
        const reviewsA = parseInt(a.reviews_count) || 0;
        const reviewsB = parseInt(b.reviews_count) || 0;
        return reviewsB - reviewsA; // Higher reviews count first
      }
      
      return ratingB - ratingA; // Higher rating first
    });
  } else if (sortFilter.numeric) {
    sortedAgencies = sortedAgencies.sort((a, b) => {
      const valueA = parseInt(a[sortFilter.value]) || 0;
      const valueB = parseInt(b[sortFilter.value]) || 0;
      
      return sortFilter.reverse 
        ? valueB - valueA
        : valueA - valueB;
    });
  } else {
    sortedAgencies = sortedAgencies.sort((a, b) => {
      // Apply the reverse logic directly in the sort function
      return sortFilter.reverse
        ? b[sortFilter.value].localeCompare(a[sortFilter.value])
        : a[sortFilter.value].localeCompare(b[sortFilter.value]);
    });
  }

  // Reference for the list container to calculate height
  const listContainerRef = React.useRef(null);

  // State to track container height
  const [containerHeight, setContainerHeight] = React.useState(0);

  // Update container height on mount and window resize
  React.useEffect(() => {
    const updateHeight = () => {
      if (listContainerRef.current) {
        setContainerHeight(listContainerRef.current.clientHeight);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  // State to track the selected agency
  const [selectedAgencyIndex, setSelectedAgencyIndex] = React.useState(null);

  // Reference for the list component
  const listRef = React.useRef(null);

  // Effect to find and scroll to selected agency when it changes
  React.useEffect(() => {
    if (selectedAgency) {
      // Find index in sortedAgencies instead of agencies
      const index = sortedAgencies.findIndex(agency => agency.id === selectedAgency);
      setSelectedAgencyIndex(index);

      // Scroll to the selected agency if we have a valid index and list reference
      if (index !== -1 && listRef.current) {
        // Check if the selected item is already in view
        const listElement = listRef.current._outerRef;
        const itemTop = index * getItemSize();
        const itemBottom = itemTop + getItemSize();
        const scrollTop = listElement.scrollTop;
        const scrollBottom = scrollTop + listElement.clientHeight;

        // Only scroll if item is not fully visible
        if (itemTop < scrollTop || itemBottom > scrollBottom) {
          listRef.current.scrollToItem(index, "start");
        }
      }
    }
  }, [selectedAgency, sortedAgencies]); // Add sortedAgencies as a dependency

  // Function to render each agency item
  const AgencyRow = React.useCallback(({ index, style }) => (
    <div style={style}>
      <AgencyCard isSelected={index === selectedAgencyIndex} agency={sortedAgencies[index]} />
    </div>
  ), [sortedAgencies, selectedAgencyIndex]);

  // Function to determine the height of each row
  const getItemSize = () => {
    // Fixed height based on AgencyCard's styled height (276px) plus margins (12px top + 12px bottom)
    return 296; // 276px card height + 24px margins
  }

  if (!fetchingAgencies && agencies.length === 0) {
    return null;
  }

  if (fetchingAgencies) {
    return (
      <div className="agency-side-panel">
        <div className="flex h-full flex-col justify-center self-center">
          <p className="property-panel_container_loading-text self-center">
            {i18n("Loading agencies...")}
          </p>
          <SpinWheelLoaderAnimation />
        </div>
      </div>
    );
  }

  return (
    <div className="agency-side-panel">
      <div className="agency-panel-header">
        <div className="flex flex-1">
          <div className="agency-count">
            <h2>{sortedAgencies.length} {sortedAgencies.length === 1 ? i18n("agency") : i18n("agencies")}</h2>
          </div>
          <button className="filter-button" onClick={toggleFilterMode}>
            <span>{i18n("Filters")}</span>
            <img src={filterIcon} alt="filter" />
          </button>
        </div>
        <div className="flex flex-1">
          <Dropdown
            className="sort-dropdown"
            items={AGENCY_SORT_FILTERS.map((filter, index) => ({
              label: i18n(filter.label),
              value: index,
              onSelect: () => {
                dispatch(setSelectedAgency(null));
                setSelectedAgencyIndex(null);
                setSortFilter(filter);
                if (listRef.current) {
                  listRef.current.scrollToItem(0);
                }
              },
            }))}
            label={i18n("Sort by") + ": " + i18n(sortFilter.label)}
          />
        </div>
      </div>
      <div className="agency-panel-content" ref={listContainerRef}>
        <List
          ref={listRef}
          className="agency-list"
          height={window.innerHeight - 140}
          width="100%"
          itemCount={sortedAgencies.length}
          itemSize={getItemSize}
        >
          {AgencyRow}
        </List>
      </div>
      <AgencyFilterPanel 
        isVisible={filterMode}
        toggleFilterMode={toggleFilterMode}
      />
    </div>
  );
};

export default connect(state => ({
  agencies: state.agency.agencies,
  filteredAgencies: state.agency.filteredAgencies,
  fetchingAgencies: state.agency.fetchingAgencies,
  selectedAgency: state.agency.selectedAgency,
  mapReducer: state.map,
}))(AgencySidePanel);