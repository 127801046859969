import Dropdown from "components/core/Dropdown";
import { connect, useDispatch } from "react-redux";
import { i18n } from "i18n/localisation";
import { addCondition, resetConditions } from "actions/valuationDetailsActions";
import {
  DEFAULT_COMMERCIAL_CONDITIONS,
  DEFAULT_FLAT_CONDITIONS,
  DEFAULT_HOUSE_CONDITIONS,
  DEFAULT_LAND_CONDITIONS,
} from "config/valuationConfig";
import YesNoOption from "./YesNoOption";
import { useEffect } from "react";
import { getSubscriptionPlan } from "utils/userHelpers";

function getDropdownLabel(label, dropdown) {
  if (!label) {
    return i18n("Select");
  }

  return dropdown.find((item) => item.label === label)?.label ?? i18n("Select");
}

function ValuationDetails({ property, conditions, metaConditions, userData }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetConditions());
  }, [property.typology]);

  const renderYesNoOption = (condition) => {
    const yesCondition = {
      id: condition.id,
      label: condition.label,
      type: condition.type,
      variant: "yes",
      value: condition.yesPercentage,
    };

    const noCondition = {
      id: condition.id,
      label: condition.label,
      type: condition.type,
      variant: "no",
      value: condition.noPercentage,
    };

    return (
      <YesNoOption
        question={i18n(condition.label)}
        value={conditions.find((c) => c.id === condition.id)?.variant}
        onYes={() => dispatch(addCondition(yesCondition))}
        onNo={() => dispatch(addCondition(noCondition))}
        yesLabel={condition.yesLabel}
        noLabel={condition.noLabel}
      />
    );
  };

  const renderDropdownOption = (condition, index, totalConditions) => {
    let existingCondition = conditions.find((c) => c.id === condition.id);

    return (
      <div className="valuation-details_dropdown">
        <span>{i18n(condition.label)}:</span>
        <Dropdown
          className="sort-dropdown"
          isInverted={index >= totalConditions - 2}
          items={condition.dropdownItems.map((item, index) => ({
            label: i18n(item.label),
            value: item.value,
            onSelect: () => {
              const conditionToAdd = {
                id: condition.id,
                label: item.label,
                type: condition.type,
                value: item.value,
              };
              dispatch(addCondition(conditionToAdd));
            },
          }))}
          label={getDropdownLabel(
            existingCondition?.label,
            condition.dropdownItems,
          )}
        />
      </div>
    );
  };

  const renderCondition = (condition, index, totalConditions) => {
    if (condition.type === "yesno") {
      return renderYesNoOption(condition, index, totalConditions);
    }

    return renderDropdownOption(condition, index, totalConditions);
  };

  const renderHouseConditions = () => {
    let conditions = DEFAULT_HOUSE_CONDITIONS;

    if (
      userData.valuationConditionsPrefs.house
    ) {
      let customConditions = metaConditions.filter(
        (condition) => condition.typology === "house",
      );

      if (
        userData.valuationConditionsPrefs.includeDefaultConditions?.includes(
          "house",
        )
      ) {
        conditions = [...conditions, ...customConditions];
      } else {
        conditions = customConditions;
      }
    }

    return (
      <div className="valuation-details_options">
        {conditions.map((condition, index) => {
          return renderCondition(condition, index, conditions.length);
        })}
      </div>
    );
  };

  const renderApartmentConditions = () => {
    let conditions = DEFAULT_FLAT_CONDITIONS;

    if (
      userData.valuationConditionsPrefs.flat
    ) {
      let customConditions = metaConditions.filter(
        (condition) => condition.typology === "flat",
      );

      if (
        userData.valuationConditionsPrefs.includeDefaultConditions?.includes(
          "flat",
        )
      ) {
        conditions = [...conditions, ...customConditions];
      } else {
        conditions = customConditions;
      }
    }

    return (
      <div className="valuation-details_options">
        {conditions.map((condition, index) => {
          return renderCondition(condition, index, conditions.length);
        })}
      </div>
    );
  };

  const renderLandConditions = () => {
    let conditions = DEFAULT_LAND_CONDITIONS;

    if (
      userData.valuationConditionsPrefs.land
    ) {
      let customConditions = metaConditions.filter(
        (condition) => condition.typology === "land",
      );

      if (
        userData.valuationConditionsPrefs.includeDefaultConditions?.includes(
          "land",
        )
      ) {
        conditions = [...conditions, ...customConditions];
      } else {
        conditions = customConditions;
      }
    }

    return (
      <div className="valuation-details_options">
        {conditions.map((condition, index) => {
          return renderCondition(condition, index, conditions.length);
        })}
      </div>
    );
  };

  const renderCommercialConditions = () => {
    let conditions = DEFAULT_COMMERCIAL_CONDITIONS;

    if (
      userData.valuationConditionsPrefs.commercial
    ) {
      let customConditions = metaConditions.filter(
        (condition) => condition.typology === "commercial",
      );

      if (
        userData.valuationConditionsPrefs.includeDefaultConditions?.includes(
          "commercial",
        )
      ) {
        conditions = [...conditions, ...customConditions];
      } else {
        conditions = customConditions;
      }
    }

    return (
      <div className="valuation-details_options">
        {conditions.map((condition, index) => {
          return renderCondition(condition, index, conditions.length);
        })}
      </div>
    );
  };

  const renderConditions = () => {
    if (
      property.buildingType === "property" &&
      (property.typology === "independantHouse" ||
        property.typology === "semidetachedHouse" ||
        property.typology === "villa" ||
        property.typology === "terracedHouse" ||
        property.typology === "countryHouse")
    ) {
      return renderHouseConditions();
    }

    if (
      property.buildingType === "property" &&
      (property.typology === "flat" ||
        property.typology === "penthouse" ||
        property.typology === "duplex")
    ) {
      return renderApartmentConditions();
    }

    if (property.buildingType === "land") {
      return renderLandConditions();
    }

    if (property.buildingType === "commercial") {
      return renderCommercialConditions();
    }

    return null;
  };

  return (
    <div className="valuation-details">
      <div className="valuation-details_title">{i18n("Valuation Details")}</div>
      <div className="valuation-details_title_info">
        {i18n(
          "Answer the next questions in order to get a more accurate valuation of the property. Leave blank the ones you don't know the answer.",
        )}
      </div>
      {renderConditions()}
    </div>
  );
}

export default connect((state) => ({
  conditions: state.valuationDetails.conditions,
  metaConditions: state.metaValuationConditions.conditions,
  userData: state.user.userData,
}))(ValuationDetails);
