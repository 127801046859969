import { createAction } from "@reduxjs/toolkit";

// Actions
export const setCatastros = createAction('SET_CATASTROS');
export const setSelectedCatastro = createAction('SET_SELECTED_CATASTRO');
export const setFilteredCatastros = createAction('SET_FILTERED_CATASTROS');
export const setActiveFilters = createAction('SET_ACTIVE_FILTERS');
export const setSortOption = createAction('SET_SORT_OPTION');
export const clearSelectedCatastro = createAction('CLEAR_SELECTED_CATASTRO');
export const setLoading = createAction('SET_CATASTRO_LOADING');
export const setError = createAction('SET_CATASTRO_ERROR');
export const setCatastroStage = createAction('SET_CATASTRO_STAGE');
export const setChildrenCatastros = createAction('SET_CHILDREN_CATASTROS');
export const setLastEvaluatedKey = createAction('SET_LAST_EVALUATED_KEY');

export const selectCatastro = (catastro) => (dispatch) => {
  dispatch(setSelectedCatastro(catastro));
};


export const updateFilters = (filters) => (dispatch, getState) => {
  dispatch(setActiveFilters(filters));
  
  // Get all catastros and apply the filters
  const { catastro } = getState();
  const allCatastros = catastro.all || [];
  
  // Import and use the filter utility functions
  const { getFilteredCatastros, applySorting } = require('../utils/catastroFilters');
  
  // Filter and sort catastros
  const filteredCatastros = getFilteredCatastros(allCatastros, filters);
  const sortedCatastros = applySorting(filteredCatastros, catastro.sortOption || 'newest');
  
  // Update filtered catastros in the store
  dispatch(setFilteredCatastros(sortedCatastros));
}; 