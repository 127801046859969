import { setCatastros, setFilteredCatastros, setSelectedCatastro, setSortOption, setCatastroStage, setChildrenCatastros } from "actions/catastro";
import { setHideMapTools, toggleSelectedArea, setSelectedAreas, setCatastroFilters, setCanDrawGeographicPolygons } from "actions/mapActions";
import { setGlobalLoading, setGlobalLoadingMessage, setPricingModal } from "actions/miscActions";
import { setCanDrawPlots, setSelectedPlot } from "actions/plotsActions";
import { setProperties } from "actions/propertiesActions";
import { getSearchIndexes } from "actions/geoActions";
import AreaSelectionPanelContent from "components/property/AreaSelectionPanelContent";
import Button from "components/ui/Button/Button";
import SearchInput from "components/ui/SearchInput/SearchInput";
import { ZOOM_LEVEL_TO_SHOW_PLOTS } from "config/constants";
import { CATASTRO_STAGE_AREA_SELECTION, CATASTRO_STAGE_FILTERS, CATASTRO_STAGE_REFERENCES } from "constants/catastroConstants";
import { getMapObjectById, getMapObjectByGeoboundarySkeleton } from "db/mapStore";
import { i18n } from "i18n/localisation";
import React from "react";
import { connect } from "react-redux";
import { toast } from "sonner";
import store from "store";
import {
  catastroToCmaProperty,
  clearAllButSelectedPlot,
  clearDrawnPlots,
  clearAllDrawnGeographicPolygons,
  fetchPlotsByMapBounds,
  resetSearch,
  fetchMoreSubplots,
  getGlobalMapInstance,
  searchMapForReferences,
  shiftMapForCatastroPanel,
} from "utils/map";
import { getNearestNeighbourGeoboundary } from "utils/polygon";
import { getDistrictsForMunicipality, normalizeString } from "utils/helpers";
import CatastroCard from "./CatastroCard";
import { 
  applySorting, 
  applyFilters, 
  getFilteredCatastros, 
  getPropertyTypeOptions, 
  COMMON_FILTERS, 
  CATASTRO_FILTER_TYPES,
  FILTER_LOWER_BOUND_KEY,
  FILTER_UPPER_BOUND_KEY
} from "utils/catastroFilters";
import Dropdown from "components/core/Dropdown";
import PrimaryInput from "components/core/PrimaryInput";
import { MAX_ALLOWED_AREAS_CATASTRO } from "constants/catastroConstants";
import { setAreaSelectionMode, setActiveDistrictMunicipality, setAvailableAreas } from "actions/mapActions";
import searchIcon from "assets/search/search_icon.svg";

class CatastroPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      selectedCatastro: null,
      loading: false,
      panelHidden: false,
      catastroFilters: {
        propertyType: null,
        constructionYearMin: "",
        constructionYearMax: "",
        areaMin: "",
        areaMax: "",
        floorMin: "",
        floorMax: ""
      },
      isPaginationLoading: false,
      lastEvaluatedKey: null,
      childrenCatastros: null,
      searchResults: [],
      searchFocusIndex: -1
    };
  }

  componentDidMount() {
    // Initial setup when component mounts
    this.props.dispatch(setHideMapTools(true));
    this.props.dispatch(setCanDrawPlots(true));

    // Setup map for catastro panel
    const map = getGlobalMapInstance();
    if (map && typeof map.addListener === 'function') {
      this.zoomListener = map.addListener("zoom_changed", this.onMapZoomEnd);
      this.moveListener = map.addListener("idle", this.onMapMoveEnd);
    } else {
      console.warn("Map instance doesn't have event listener methods or is not available");
    }

    // Initial fetch of plots based on map bounds
    this.fetchPlotsBasedOnMapBounds();
  }

  componentWillUnmount() {
    // Cleanup when component unmounts
    this.props.dispatch(setHideMapTools(false));
    this.props.dispatch(setCanDrawPlots(false));
    
    // Remove map event listeners
    if (this.zoomListener) {
      window.google.maps.event.removeListener(this.zoomListener);
    }
    if (this.moveListener) {
      window.google.maps.event.removeListener(this.moveListener);
    }
    
    // Clear drawn plots
    clearDrawnPlots();
  }

  componentDidUpdate(prevProps, prevState) {
    // Handle selection of plot
    if (prevProps.selectedPlot !== this.props.selectedPlot && this.props.selectedPlot) {
      this.handlePlotSelection(this.props.selectedPlot);
    }

    // Handle changes in catastro data
    if (prevProps.catastro.all !== this.props.catastro.all) {
      this.updateCatastroResults();
    }
    
    // When filters change and we're in the references stage, update the visualizations
    // if (JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters) &&
    //     this.props.catastro.stage === CATASTRO_STAGE_REFERENCES) {
    //   const { selectedAreas } = this.props.mapReducer;
    //   const selectedArea = selectedAreas.length > 0 ? selectedAreas[0] : null;
    //   this.fetchPlotsBasedOnMapBounds(selectedArea, this.state.filters);
    // }
  }

  onMapZoomEnd = () => {
    const map = getGlobalMapInstance();
    const { stage } = this.props.catastro;
    const { catastroFilters } = this.props.mapReducer;
    
    // Only fetch plots if we're at the filters stage and zoomed in enough
    if (map && typeof map.getZoom === 'function' && 
        map.getZoom() >= ZOOM_LEVEL_TO_SHOW_PLOTS + 6 && 
        stage >= CATASTRO_STAGE_REFERENCES && catastroFilters) {
      // Get the selected area
      const { selectedAreas } = this.props.mapReducer;
      const selectedArea = selectedAreas.length > 0 ? selectedAreas[0] : null;
      
      // Fetch plots with the stored filters
      this.fetchPlotsBasedOnMapBounds(selectedArea, catastroFilters);
    } else if (map && typeof map.getZoom === 'function' && 
              map.getZoom() < ZOOM_LEVEL_TO_SHOW_PLOTS + 6) {
      // Clear plots when zoomed out too far to avoid cluttering the map
      clearAllButSelectedPlot();
    }
  };

  onMapMoveEnd = () => {
    const map = getGlobalMapInstance();
    const { stage } = this.props.catastro;
    const { catastroFilters } = this.props.mapReducer;
    
    // Only fetch plots if we're at the references stage and zoomed in enough
    if (map && typeof map.getZoom === 'function' && 
        map.getZoom() >= ZOOM_LEVEL_TO_SHOW_PLOTS + 6 && 
        stage >= CATASTRO_STAGE_REFERENCES && catastroFilters) {
      
      // Get the selected area
      const { selectedAreas } = this.props.mapReducer;
      const selectedArea = selectedAreas.length > 0 ? selectedAreas[0] : null;
      
      // Fetch plots with the stored filters
      this.fetchPlotsBasedOnMapBounds(selectedArea, catastroFilters);
    }
  };

  fetchPlotsBasedOnMapBounds = (selectedArea, filters) => {
    const map = getGlobalMapInstance();
    if (!map) {
      console.warn("Map instance is not available for fetching plots");
      return;
    }
    
    // Check if we have a selected area from the map reducer
    // const { selectedAreas } = this.props.mapReducer;
    // const selectedArea = selectedAreas.length > 0 ? selectedAreas[0] : null;
    
    // If no area is selected and we're in a stage that requires a selected area, return
    const { stage } = this.props.catastro;
    if (!selectedArea && stage !== CATASTRO_STAGE_AREA_SELECTION) {
      console.info("No area selected, skipping plot fetch");
      return;
    }
    
    // this.props.dispatch(setGlobalLoading(true));
    // this.props.dispatch(setGlobalLoadingMessage(i18n("Loading plots from map...")));
    
    // Pass both the selectedArea and filters to fetchPlotsByMapBounds
    fetchPlotsByMapBounds(selectedArea, filters)
      .then(result => {
        // this.props.dispatch(setGlobalLoading(false));
        const { plots, subplots } = result;
        
        // If we're in stage 3 and have subplots, update the filtered catastros
        if (stage >= CATASTRO_STAGE_REFERENCES && subplots && subplots.length > 0) {
          this.props.dispatch(setFilteredCatastros(subplots.subplots));
        }
      })
      .catch(error => {
        // this.props.dispatch(setGlobalLoading(false));
        toast.error(i18n("Error loading plots."));
        console.error("Error loading plots:", error);
      });
  };

  handlePlotSelection = (plot) => {
    // Get catastro data for selected plot
    if (plot && plot.refcat) {
      this.props.dispatch(setGlobalLoading(true));
      this.props.dispatch(setGlobalLoadingMessage(i18n("Loading catastro data...")));
      
      // fiter through the filtered catastros and find the one that matches the plot.refcat
      const filteredCatastros = this.props.catastro.filtered;
      const selectedCatastro = filteredCatastros.find(catastro => catastro.refcat === 'es-' + plot.refcat);
      this.onCatastroDataRetrieved(selectedCatastro);
    }
  };

  onCatastroDataRetrieved = (data) => {
    if (!data) {
      return toast(i18n("No catastro data found for this location."), {
        duration: 5000,
      });
    }

    // Update state and store with retrieved catastro data
    this.setState({ selectedCatastro: data });
    this.props.dispatch(setSelectedCatastro(data));
    
    // Handle mobile view
    if (window.innerWidth <= 768) {
      this.setPanelHidden(false);
    }
  };

  onAddressSearch = (query) => {
    this.setState({ 
      searchQuery: query,
      searchResults: [],
      searchFocusIndex: -1
    });
    
    if (query.length > 2) {
      this.props.dispatch(setGlobalLoading(true));
      this.props.dispatch(setGlobalLoadingMessage(i18n("Searching...")));
      
      // Search for references on the map with a fallback function
      searchMapForReferences(query, "catastro", (searchQuery) => {
        // Fallback search function that uses the search indexes
        const searchIndexes = getSearchIndexes();
        if (!searchIndexes || !searchIndexes.indexes) {
          this.setState({ searchResults: [] });
          this.props.dispatch(setGlobalLoading(false));
          return;
        }

        const normalizedSearchValue = normalizeString(searchQuery);
        let searchResults = [];

        for (let index of searchIndexes.indexes) {
          let normalizedIndexName = normalizeString(index.name);

          // Only show municipalities, localities, districts, and zones
          if (
            index.type !== "municipality" &&
            index.type !== "locality" &&
            index.type !== "district" &&
            index.type !== "zone"
          ) {
            continue;
          }

          if (normalizedIndexName.includes(normalizedSearchValue)) {
            searchResults.push(index);
          } else if (index.municipality) {
            let entireName = normalizeString(
              index.name + " " + index.municipality,
            );

            if (entireName.includes(normalizedSearchValue)) {
              searchResults.push(index);
            }
          }
        }

        // Sort results by similarity
        searchResults.sort((a, b) => {
          let indexA = normalizeString(a.name);
          let indexB = normalizeString(b.name);

          const wordsA = indexA.split(" ");
          const wordsB = indexB.split(" ");

          const firstWordA = wordsA[0];
          const firstWordB = wordsB[0];

          if (
            firstWordA.startsWith(normalizedSearchValue) &&
            !firstWordB.startsWith(normalizedSearchValue)
          ) {
            return -1;
          } else if (
            !firstWordA.startsWith(normalizedSearchValue) &&
            firstWordB.startsWith(normalizedSearchValue)
          ) {
            return 1;
          } else {
            for (let i = 1; i < wordsA.length && i < wordsB.length; i++) {
              if (wordsA[i] < wordsB[i]) {
                return -1;
              } else if (wordsA[i] > wordsB[i]) {
                return 1;
              }
            }
            return wordsA.length - wordsB.length;
          }
        });

        // Limit results to 25 for performance
        searchResults = searchResults.slice(0, 25);

        this.setState({ searchResults });
        this.props.dispatch(setGlobalLoading(false));
      });
    }
  };

  onSearchInputKeyDown = (e) => {
    const { searchFocusIndex, searchResults } = this.state;

    // handle location select for selected search result index
    if (e.key === "Enter" || e.key === "Return") {
      if (searchFocusIndex >= 0 && searchFocusIndex < searchResults.length) {
        this.onLocationSelect(searchResults[searchFocusIndex]);
      }
    }

    // reset search bar state on escape
    if (e.key === "Escape") {
      this.setState({
        searchResults: [],
        searchFocusIndex: -1,
        searchQuery: "",
      });
    }

    // move the index of search result focus on keys up and down
    if (e.key === "ArrowUp") {
      this.setState({
        searchFocusIndex: Math.max(searchFocusIndex - 1, 0),
      });
    }

    if (e.key === "ArrowDown") {
      this.setState({
        searchFocusIndex: Math.min(
          searchFocusIndex + 1,
          searchResults.length - 1,
        ),
      });
    }
  };

  onLocationSelect = async (location) => {
    this.setState({
      searchResults: [],
      searchFocusIndex: -1,
      searchQuery: location.name,
    });

    // Define the bounds of the desired bounding box
    const southwest = new window.google.maps.LatLng(
      parseFloat(location.max_lat),
      parseFloat(location.min_lng),
    ); // Southwest corner
    const northeast = new window.google.maps.LatLng(
      parseFloat(location.min_lat),
      parseFloat(location.max_lng),
    ); // Northeast corner
    const bounds = new window.google.maps.LatLngBounds(southwest, northeast);

    // Get map instance from global instead of props
    const map = getGlobalMapInstance();
    if (!map) {
      console.error("Map instance not available");
      return;
    }

    // Use the fitBounds method to adjust the map's viewport
    map.fitBounds(bounds);
    

    let canDrawPolygon =
      location.type === "district" ||
      location.type === "locality" ||
      location.type === "municipality" ||
      location.type === "zone";

    // if its a selectable polygon, either fetch properties for it
    // or open the district selection panel if it has subdivisions
    if (canDrawPolygon) {
      // do not fetch properties if we have districts for the municipality
      // as we want to open the district selection panel for it
      if (location.type === "municipality") {
        const districtsOfMunicipality = await getDistrictsForMunicipality(
          location.mun_code,
        );

        if (districtsOfMunicipality.length > 0) {
          let municipality = await getMapObjectById(
            `${location.country}-municipalities`,
            location.id,
          );

          // timeout to allow the map to settle before selecting the municipality
          // otherwise the polygon will not be available to the state
          setTimeout(() => {
            if (this.props.handleMunicipalitySelection) {
              this.props.handleMunicipalitySelection(
                [municipality].concat(districtsOfMunicipality),
                location.mun_code,
                location.id,
              );
            }
          }, 250);
          return;
        }
      }

      try {
        let response = await getMapObjectByGeoboundarySkeleton(location);
        // get the area from the response
        console.log("response", response);
        this.props.dispatch(setAreaSelectionMode(null));
        store.dispatch(setCatastroStage(CATASTRO_STAGE_FILTERS));
        const { selectedAreas } = this.props.mapReducer;
        console.log("selectedAreas", selectedAreas);
        if (response.length === 0) return;
        store.dispatch(setCanDrawGeographicPolygons(false));
        // set the selected area to the first area
        this.props.dispatch(setSelectedAreas([response]));
        this.props.drawArea(response, {
          fetchProperties: false,
          type: "catastro",
        });
        let mapReducer = store.getState().map;
        // set the selected area to the first area
        if (response.length == 1) {
          if (
            mapReducer.selectedAreas.length == MAX_ALLOWED_AREAS_CATASTRO &&
            !mapReducer.selectedAreas.find((a) => a.id == response.id)
          ) {
            toast.error(i18n("Max number of areas reached"));
          } else {
            this.props.dispatch(toggleSelectedArea(response));
          }
        }
        if (this.props.zoomToDrawnPolygons) {
          this.props.zoomToDrawnPolygons([response]);
        }

        console.log("selectedAreas", selectedAreas);
        this.props.dispatch(setSelectedAreas([]));
        this.props.dispatch(setAvailableAreas([]));
        console.log("handleAreasSelected", response);
        this.props.handleAreasSelected([response]);
      } catch (e) {
        console.log(e);
      }
    }
  };

  onCatastroSelect = (catastro) => {
    this.setState({ selectedCatastro: catastro });
    this.props.dispatch(setSelectedCatastro(catastro));
    this.props.dispatch(setCatastroStage(CATASTRO_STAGE_FILTERS));
  };

  onBackClick = () => {
    const { stage } = this.props.catastro;
    
    // Clear children catastros when navigating back
    this.clearChildrenCatastros();
    
    if (stage === CATASTRO_STAGE_AREA_SELECTION) {
      // Return to map view
      this.props.dispatch(setSelectedCatastro(null));
      this.setState({ selectedCatastro: null });
      clearDrawnPlots();
      // clear the lastEvaluatedKey
      this.setState({ lastEvaluatedKey: null });
      this.props.dispatch(setFilteredCatastros([]));
    } else if (stage === CATASTRO_STAGE_REFERENCES) {
      // Going back to filters stage, reset highlighted plots
      clearAllButSelectedPlot();
      this.fetchPlotsBasedOnMapBounds();
      this.props.dispatch(setCatastroStage(CATASTRO_STAGE_FILTERS));
      this.setState({ lastEvaluatedKey: null });
      this.props.dispatch(setFilteredCatastros([]));
    } else {
      // Go back one stage
      console.log("going back one stage");
      this.props.dispatch(setCatastroStage(stage - 1));
      // reset the search query
      this.setState({ searchQuery: "" });
      this.setState({ searchResults: [] });
      this.setState({ searchFocusIndex: -1 }); 
      this.setState({ selectedCatastro: null });
      this.props.dispatch(setSelectedCatastro(null));
      this.props.dispatch(setFilteredCatastros([]));
      this.setState({ lastEvaluatedKey: null });
      
      // remove selected areas from the map reducer
      console.log("removing selected areas");
      const { selectedAreas } = this.props.mapReducer;
      console.log("selectedAreas", selectedAreas);
      // toggle selected area using the existing selected area
      if (selectedAreas.length > 0) {
        this.props.dispatch(toggleSelectedArea(selectedAreas[0]));
      }
      console.log("selectedAreas", selectedAreas);

      // clear the map of all plots
      clearDrawnPlots();
      clearAllDrawnGeographicPolygons();
      resetSearch();

    }
  };

  onNextClick = () => {
    const { stage } = this.props.catastro;
    
    // If we're on the filters stage, apply the filters before moving to the next stage
    if (stage === CATASTRO_STAGE_FILTERS) {
      // Save the filters to the map reducer
      this.props.dispatch(setCatastroFilters(this.state.catastroFilters));
      
      // Move to the next stage
      this.props.dispatch(setCatastroStage(stage + 1));
      
      // Then fetch the subplots with the filters
      // if the zoom level is more than 16, then fetch the subplots
      const map = getGlobalMapInstance();
      if (map && typeof map.getZoom === 'function' && map.getZoom() >= 16) {
        this.fetchSubplots();
      }
      return;
    }

    // if stage is references, then do not progress to next stage
    if (stage === CATASTRO_STAGE_REFERENCES) {
      return;
    }
    
    // Progress to next stage
    this.props.dispatch(setCatastroStage(stage + 1));
  
    // Special handling for area selection stage
    if (stage + 1 === CATASTRO_STAGE_AREA_SELECTION) {
      this.props.dispatch(setHideMapTools(false));
      
      const { selectedAreas } = this.props.mapReducer;
      if (selectedAreas.length > 0) {
        const selectedArea = selectedAreas[0];
        // Draw the selected area on map
        this.props.drawArea(selectedArea, {
          fetchProperties: true,
          type: "catastro",
        });
        
        this.props.zoomToDrawnPolygons([selectedArea]);
        shiftMapForCatastroPanel();
      }
    } else {
      this.props.dispatch(setHideMapTools(true));
    }
  };

  renderSearch = () => {
    const { searchResults, searchFocusIndex, searchQuery } = this.state;
    const hasSearchResults = searchResults.length > 0;

    return (
      <div className="catastro-form">
        <div className="catastro-form_search search-bar_input-content">
          <div className="search-bar_input-container">
            <input
              value={searchQuery}
              type="text"
              autoComplete="off"
              onKeyDown={this.onSearchInputKeyDown}
              onChange={(e) => this.onAddressSearch(e.target.value)}
              placeholder={i18n("Search areas")}
              className="search-bar_input"
            />
            <div className="search-bar_input-button-container">
              <button className="search-bar_input-button" aria-label="Search"><img src={searchIcon} alt="Search" /></button>
            </div>
          </div>
          {hasSearchResults && (
            <ul className="search-bar_input-content_search-results">
              {searchResults.map((result, index) => (
                <li
                  key={index}
                  className={
                    "search-bar_input-content_search-results_result" +
                    (searchFocusIndex === index ? " active" : "")
                  }
                  onMouseEnter={() => this.setState({ searchFocusIndex: index })}
                  onClick={() => this.onLocationSelect(result)}
                >
                  <span>{result.name}</span>
                  <div className={"search-bar_input-content_search-results_result_chip " + (result.type === 'municipality' ? '_mun' : result.type === 'district' ? '_dis' : result.type === 'locality' ? '_loc' : result.type === 'zone' ? '_zone' : '')}>
                    {i18n(result.type)}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  renderSearchResults = () => {
    const { filtered } = this.props.catastro;
    const { isPaginationLoading, lastEvaluatedKey } = this.state;

    // get ChildrenCatastros from store
    const { childrenCatastros } = this.props.catastro;
    
    // Determine which catastros to display
    const displayCatastros = childrenCatastros && childrenCatastros.length > 0 
      ? childrenCatastros 
      : filtered;
    
    if (!displayCatastros || displayCatastros.length === 0 && this.props.catastro.stage >= CATASTRO_STAGE_FILTERS) {
      return (
        <div className="cma-form_help-container">
          <p>{i18n("No catastro results found. Try a different search or select a parcel on the map.")}</p>
        </div>
      );
    }
    
    return (
      <div className="catastro-panel_search-results">
        {childrenCatastros && childrenCatastros.length > 0 && (
          <div className="catastro-panel_search-results_header">
            <h3>{i18n("Related Properties")}</h3>
            <p>{i18n(`${childrenCatastros.length} related properties found`)}</p>
          </div>
        )}
        
        {displayCatastros.map((plot, index) => (
          <CatastroCard
            key={plot.id || index}
            catastro={plot}
            onClick={() => this.onCatastroSelect(plot)}
            isSelected={this.state.selectedCatastro?.id === plot.id}
          />
        ))}
        
        {/* Only show pagination if not showing children catastros */}
        {!childrenCatastros && (
          <>
            {/* Pagination loading spinner */}
            {isPaginationLoading && (
              <div className="catastro-panel_pagination-loading">
                <div className="catastro-panel_loader"></div>
                <span>{i18n("Loading more results...")}</span>
              </div>
            )}
            
            {/* Load more button */}
            {lastEvaluatedKey && !isPaginationLoading && (
              <div className="catastro-panel_load-more">
                <span 
                  className="catastro-panel_load-more-button"
                  onClick={this.loadMoreResults}
                >
                  {i18n("Load More Results")}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  renderPropertyDetails = () => {
    const { selectedCatastro } = this.props.catastro;
    
    if (!selectedCatastro) {
      return (
        <div className="catastro-panel_details_empty">
          <p>{i18n("No property selected. Please go back and select a property.")}</p>
        </div>
      );
    }
    
    return (
      <div className="catastro-panel_details">
        <div className="catastro-panel_details_header">
          <h3>{selectedCatastro.reference || selectedCatastro.referenceNumber}</h3>
          <p>{selectedCatastro.address}</p>
        </div>
        
        <div className="catastro-panel_details_content">
          <div className="catastro-panel_details_item">
            <span className="label">{i18n("Property Type")}:</span>
            <span className="value">{selectedCatastro.type || i18n("Not specified")}</span>
          </div>
          
          <div className="catastro-panel_details_item">
            <span className="label">{i18n("Area")}:</span>
            <span className="value">
              {selectedCatastro.area ? `${selectedCatastro.area} m²` : i18n("Not available")}
            </span>
          </div>
          
          <div className="catastro-panel_details_item">
            <span className="label">{i18n("Status")}:</span>
            <span className="value">{selectedCatastro.status || i18n("Unknown")}</span>
          </div>
          
          <div className="catastro-panel_details_item">
            <span className="label">{i18n("Usage")}:</span>
            <span className="value">
              {selectedCatastro.usage || selectedCatastro.details?.usage || i18n("Not specified")}
            </span>
          </div>
          
          {selectedCatastro.constructionYear && (
            <div className="catastro-panel_details_item">
              <span className="label">{i18n("Construction Year")}:</span>
              <span className="value">{selectedCatastro.constructionYear}</span>
            </div>
          )}
          
          {selectedCatastro.floors !== undefined && (
            <div className="catastro-panel_details_item">
              <span className="label">{i18n("Floors")}:</span>
              <span className="value">{selectedCatastro.floors}</span>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  renderAreaSelection = () => {
    const { filtered } = this.props.catastro;
    const { isPaginationLoading, lastEvaluatedKey } = this.state;

    // get ChildrenCatastros
    const { childrenCatastros } = this.props.catastro;
    
    // Determine which catastros to display
    const displayCatastros = childrenCatastros && childrenCatastros.length > 0 
      ? childrenCatastros 
      : filtered;
    
    // If no catastros to display, show empty message
    if (!displayCatastros || displayCatastros.length === 0) {
      return (
        <div className="catastro-panel_area-selection_empty">
          <p>{i18n("No properties found matching your criteria. Try adjusting your filters.")}</p>
        </div>
      );
    }

    return (
      <div className="cma-form_search-results">
        {/* Display filtered plots as cards */}
        <div className="catastro-panel_area-selection_plots">
          <div className="catastro-panel_area-selection_plots_header">
            {childrenCatastros && childrenCatastros.length > 0 ? (
              <>
                <h3>{i18n("Properties in this plot")}</h3>
                <p>{i18n(`${childrenCatastros.length} properties found`)}</p>

                {/* add a button to clear the children catastros */}
                <button className="catastro-panel_area-selection_plots_clear" onClick={this.clearChildrenCatastros}>
                  {i18n("Clear")}
                </button>
              </>
            ) : (
              <>
                <h3>{i18n("Filtered Properties")}</h3>
                <p>{i18n(`${filtered.length} properties match your criteria`)}</p>
              </>
            )}
          </div>
          <div className="catastro-panel_area-selection_plots_list">
            {displayCatastros.map((plot, index) => (
              // render a card for each plot
              <CatastroCard
                key={plot.id || index}
                catastro={plot}
                // onClick={() => this.onCatastroSelect(plot)}
                isSelected={this.state.selectedCatastro?.id === plot.id}
              />
            ))}
            
            {/* Only show pagination if we're not showing children catastros */}
            {!childrenCatastros && (
              <>
                {/* Pagination loading spinner */}
                {isPaginationLoading && (
                  <div className="catastro-panel_pagination-loading">
                    <div className="catastro-panel_loader"></div>
                    <span>{i18n("Loading more results...")}</span>
                  </div>
                )}
                
                {/* Load more button */}
                {lastEvaluatedKey && !isPaginationLoading && (
                  <div className="catastro-panel_load-more">
                    <span 
                      className="catastro-panel_load-more-button"
                      onClick={this.loadMoreResults}
                    >
                      {i18n("Load More Results")}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  onDistrictSelect = (district) => {
    // Use Redux to toggle the selected area
    this.props.dispatch(toggleSelectedArea(district));
    
    // If in filters stage and a district was selected/deselected, update plots
    const { stage } = this.props.catastro;
    if (stage >= CATASTRO_STAGE_FILTERS) {
      // this.fetchPlotsForSelectedArea();
    }
  };

  // Handle filter changes
  handleFilterChange = (name, value) => {
    this.setState(prevState => ({
      catastroFilters: {
        ...prevState.catastroFilters,
        [name]: value
      }
    }));
    // Remove the callback that fetches plots after filter change
    // We'll only fetch plots when the Next button is clicked
  }

  // Handle number input validation
  handleNumberInput = (e, fieldName) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      this.handleFilterChange(fieldName, value);
    }
  }

  // Render a range filter section (min/max inputs)
  renderRangeFilterSection = (filterType) => {
    const { catastroFilters } = this.state;
    const filterConfig = COMMON_FILTERS[filterType];  
    
    if (!filterConfig) return null;
    
    return (
      <div className="catastro-panel_filter-section">
        <label className="catastro-panel_filter-label">
          {i18n(filterConfig.label)}
        </label>
        <div className="catastro-panel_filter-range">
          {filterType === CATASTRO_FILTER_TYPES.CONSTRUCTION_YEAR || filterType === CATASTRO_FILTER_TYPES.FLOORS ? (
            <Dropdown
              items={this.getDropdownOptionsForFilter(filterType, 'min')}
              selected={catastroFilters[filterConfig.minKey]}
              placeholder={i18n(filterConfig.minLabel)}
              onSelect={(value) => this.handleFilterChange(filterConfig.minKey, value)}
              className="catastro-panel_filter-dropdown"
              label={
                catastroFilters[filterConfig.minKey]
              }
            />
          ) : (
            <PrimaryInput
              type={filterType === CATASTRO_FILTER_TYPES.AREA ? "number" : "text"}
              value={catastroFilters[filterConfig.minKey]}
              onChange={(e) => this.handleNumberInput(e, filterConfig.minKey)}
              placeholder={i18n(filterConfig.minLabel)}
              className="catastro-panel_filter-input primary-input"
            />
          )}
          {filterType === CATASTRO_FILTER_TYPES.CONSTRUCTION_YEAR || filterType === CATASTRO_FILTER_TYPES.FLOORS ? (
            <Dropdown
              items={this.getDropdownOptionsForFilter(filterType, 'max')}
              selected={catastroFilters[filterConfig.maxKey]}
              placeholder={i18n(filterConfig.maxLabel)}
              label={catastroFilters[filterConfig.maxKey]}
              onSelect={(value) => this.handleFilterChange(filterConfig.maxKey, value)}
              className="catastro-panel_filter-dropdown"
            />
          ) : (
            <PrimaryInput
              type={filterType === CATASTRO_FILTER_TYPES.AREA ? "number" : "text"}
              value={catastroFilters[filterConfig.maxKey]}
              onChange={(e) => this.handleNumberInput(e, filterConfig.maxKey)}
              placeholder={i18n(filterConfig.maxLabel)}
              className="catastro-panel_filter-input primary-input"
            />
          )}
        </div>
      </div>
    );
  }

  renderFilters = () => {
    const { catastroFilters } = this.state;
    const propertyTypeOptions = getPropertyTypeOptions() || [];
    
    // Create the proper format for dropdown items with onSelect handlers
    const propertyTypeItems = propertyTypeOptions.map(option => ({
      id: option.id,
      label: option.label,
      value: option.id,
      onSelect: () => this.handleFilterChange("propertyType", option)
    }));
    
    return (
      <div className="catastro-panel_filters">
        <div className="catastro-panel_filter-section" style={{ display: "flex", flexDirection: "column", alignItems: "center", flexWrap: "wrap" }}>
          <label className="catastro-panel_filter-label" style={{ width: "100%" }}>
            {i18n("Property Type")}
          </label>
          <Dropdown
            items={propertyTypeItems}
            selected={catastroFilters.propertyType ? catastroFilters.propertyType.id : null}
            label={catastroFilters.propertyType ? catastroFilters.propertyType.label : ""}
            onSelect={(value) => this.handleFilterChange("propertyType", value)}
            placeholder={i18n("Select property type")}
            className="catastro-panel_filter-dropdown"
            style={{ width: "100%", flex: "1 1 auto" }}
          />
        </div>
        
        {/* Render range filters using the common configuration */}
        {this.renderRangeFilterSection(CATASTRO_FILTER_TYPES.CONSTRUCTION_YEAR)}
        {this.renderRangeFilterSection(CATASTRO_FILTER_TYPES.AREA)}
        {this.renderRangeFilterSection(CATASTRO_FILTER_TYPES.FLOORS)}
      </div>
    );
  }

  resetFilters = () => {
    // Create empty filters object using the common configuration
    const emptyFilters = {
      [CATASTRO_FILTER_TYPES.PROPERTY_TYPE]: null
    };

    // Add min/max keys for each range filter
    Object.values(COMMON_FILTERS).forEach(filterConfig => {
      emptyFilters[filterConfig.minKey] = "";
      emptyFilters[filterConfig.maxKey] = "";
    });

    this.setState({ catastroFilters: emptyFilters });
  }

  // Generate dropdown options for filters
  getDropdownOptionsForFilter = (filterType, minOrMax) => {
    // Generate options based on filter type
    if (filterType === CATASTRO_FILTER_TYPES.CONSTRUCTION_YEAR) {
      const currentYear = new Date().getFullYear();
      const startYear = 1900;
      const years = [];
      
      // Generate years from startYear to currentYear
      for (let year = startYear; year <= currentYear; year++) {
        years.push({
          id: year.toString(),
          label: year.toString(),
          value: year.toString(),
          onSelect: () => {
            const key = minOrMax === 'min' ? 'constructionYearMin' : 'constructionYearMax';
            this.handleFilterChange(key, year.toString());
          }
        });
      }
      
      // Sort in ascending order for min, descending for max
      return minOrMax === 'min' ? years : years.reverse();
    } 
    else if (filterType === CATASTRO_FILTER_TYPES.FLOORS) {
      // Generate options for floor numbers (typically 0-50 should be enough)
      const floors = [];
      const maxFloor = 50;
      
      for (let floor = 0; floor <= maxFloor; floor++) {
        floors.push({
          id: floor.toString(),
          label: floor.toString(),
          value: floor.toString(),
          onSelect: () => {
            const key = minOrMax === 'min' ? 'floorMin' : 'floorMax';
            this.handleFilterChange(key, floor.toString());
          }
        });
      }
      
      return floors;
    }
    
    return [];
  }

  fetchSubplots = () => {
    // Apply the filters and move to the next stage
    const { catastroFilters } = this.state;
    
    // Show loading indicator
    this.props.dispatch(setGlobalLoading(true));
    this.props.dispatch(setGlobalLoadingMessage(i18n("Applying filters...")));
    const { selectedAreas } = this.props.mapReducer;
    const selectedArea = selectedAreas.length > 0 ? selectedAreas[0] : null;
    
    // Get the map instance
    const map = getGlobalMapInstance();
    if (map) {
      // Clear all previous plots except for those that match the filters
      clearAllButSelectedPlot();
      
      if (!selectedArea) {
        // No selected area, show message and move to next stage
        this.props.dispatch(setGlobalLoading(false));
        toast.warning(i18n("No area selected. Please select an area first."));
        return;
      }
      
      // Apply filters and fetch both plots and subplots
      fetchPlotsByMapBounds(selectedArea, catastroFilters)
        .then((result) => {
          const { plots, subplots } = result;
          
          // Show a message with the number of subplots found
          if (!subplots || subplots.length === 0) {
            toast.info(i18n("No properties found matching your criteria."));
            this.props.dispatch(setFilteredCatastros([]));
            this.setState({ lastEvaluatedKey: null });
          } else {
            toast.success(i18n(`Found ${subplots.length} properties matching your criteria.`));
            
            // Store the filtered subplots for display in stage 3
            this.props.dispatch(setFilteredCatastros(subplots.subplots));
            
            // Store the lastEvaluatedKey for pagination
            if (subplots.last_evaluated_key) {
              this.setState({ lastEvaluatedKey: subplots.last_evaluated_key });
            } else {
              this.setState({ lastEvaluatedKey: null });
            }
          }
          
          this.props.dispatch(setGlobalLoading(false));
        })
        .catch(error => {
          this.props.dispatch(setGlobalLoading(false));
          toast.error(i18n("Error applying filters."));
          console.error("Error applying filters:", error);
        });
    } else {
      this.props.dispatch(setGlobalLoading(false));
    }
  }

  // Load more results for pagination
  loadMoreResults = () => {
    const { lastEvaluatedKey } = this.state;
    const { catastroFilters } = this.state;
    const { selectedAreas } = this.props.mapReducer;
    const selectedArea = selectedAreas.length > 0 ? selectedAreas[0] : null;
    
    if (!lastEvaluatedKey || !selectedArea) {
      return;
    }
    
    this.setState({ isPaginationLoading: true });
    
    // Fetch more plots using the lastEvaluatedKey
    fetchMoreSubplots(lastEvaluatedKey, catastroFilters, selectedArea)
      .then((result) => {
        const { subplots, last_evaluated_key } = result;
        if (!subplots || subplots.length === 0) {
          toast.info(i18n("No more properties found."));
        } else {
          // Append the new subplots to the existing filtered catastros
          const { filtered } = this.props.catastro;
          const combinedResults = [...filtered, ...subplots];
          this.props.dispatch(setFilteredCatastros(combinedResults));
          
          // Update the lastEvaluatedKey for next pagination
          if (last_evaluated_key) {
            this.setState({ lastEvaluatedKey: last_evaluated_key });
          } else {
            this.setState({ lastEvaluatedKey: null });
          }
        }
        
        this.setState({ isPaginationLoading: false });
      })
      .catch(error => {
        this.setState({ isPaginationLoading: false });
        toast.error(i18n("Error loading more results."));
        console.error("Error loading more results:", error);
      });
  }

  renderStage = () => {
    const { stage } = this.props.catastro;
    
    switch (stage) {
      case CATASTRO_STAGE_AREA_SELECTION:
        return (
          <>
            {this.renderSearch()}
          </>
        );
      case CATASTRO_STAGE_FILTERS:
        return this.renderFilters();
      case CATASTRO_STAGE_REFERENCES:
        return this.renderAreaSelection();
      default:
        return null;
    }
  };

  renderHeader = () => {
    const { stage } = this.props.catastro;
    
    let title = "";
    let description = "";
    
    switch (stage) {
      case CATASTRO_STAGE_AREA_SELECTION:
        title = i18n("Catastro Opportunities Search");
        description = i18n("Use catastro data to find opportunities off-market that match your criteria.");
        break;
      case CATASTRO_STAGE_FILTERS:
        title = i18n("Filters");
        description = i18n("Select the filters in order to find the properties that match your criteria.");
        break;
      case CATASTRO_STAGE_REFERENCES:
        title = i18n("References");
        description = i18n("Check all the properties that match your criteria based on catastro data.");
        break;
    }
    
    return (
      <div className="catastro-panel_header">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    );
  };

  renderFooter = () => {
    const { stage } = this.props.catastro;
    const isNextDisabled = stage === CATASTRO_STAGE_AREA_SELECTION && !this.state.selectedCatastro;
    
    return (
      <div className="catastro-panel_footer">
        {stage > 0 ? (
          <Button
            variant="text"
            onClick={this.onBackClick}
          >
            {i18n("Back")}
          </Button>
        ) : (
          <div style={{ width: "1px" }} />
        )}
        
        {stage < 2 && (
          <Button
            variant="primary"
            onClick={this.onNextClick}
            disabled={isNextDisabled}
          >
            {i18n("Next")}
          </Button>
        )}
      </div>
    );
  };

  setPanelHidden = (status) => {
    this.setState({ panelHidden: status });
    
    if (status) {
      document.getElementById("catastro-panel").classList.add("panel-hidden");
    } else {
      document.getElementById("catastro-panel").classList.remove("panel-hidden");
    }
  };

  updateCatastroResults = () => {
    const { all, activeFilters, sortOption } = this.props.catastro;
    
    if (all && all.length > 0) {
      // Filter and sort catastros
      const filteredResults = applySorting(all, sortOption || 'newest');
      this.props.dispatch(setFilteredCatastros(filteredResults));
    }
  };

  // Handler for plot card selection
  onPlotCardSelect = (plot) => {
    // Center the map on the selected plot
    const map = getGlobalMapInstance();
    if (!map) {
      console.warn("Map instance not available");
      return;
    } 
    
    // If it's a subplot but missing geometry, try to find the parent plot
    if (!plot.geometry && plot.refcat) {
      // Look for a drawn plot with matching refcat
      for (const drawnPlot of window.drawnPlots) {
        if (drawnPlot.isMatchingPlot && 
            drawnPlot.plotId && 
            drawnPlot.getMap()) {
          // Get the plot data
          const plotObj = getMapObjectById(drawnPlot.plotId);
          if (plotObj) {
            // Zoom to the plot
            const bounds = plotObj.getBounds();
            if (bounds) {
              map.fitBounds(bounds);
            }
            
            // Highlight the plot
            drawnPlot.setOptions({
              fillColor: '#FF0000',      // Bright red for selected plot
              fillOpacity: 0.6,          // Higher opacity for visibility
              strokeColor: '#FF0000',    // Red border
              strokeWeight: 4,           // Thicker border
              strokeOpacity: 1.0         // Fully opaque border
            });
            
            // Update selected plot in state and store
            this.props.dispatch(setSelectedPlot({
              ...plot,
              geometry: plotObj.geometry  // Add geometry from the parent plot
            }));
            
            return;
          }
        }
      }
      
      // If we couldn't find the parent plot, show a message
      toast.warning(i18n("Could not find the exact location of this property on the map."));
      this.props.dispatch(setSelectedPlot(plot));
      return;
    }
    
    // Handle plots with geometry
    if (plot.geometry) {
      // Find map bounds for the plot
      const bounds = getNearestNeighbourGeoboundary(plot.geometry);
      if (bounds && typeof map.fitBounds === 'function') {
        map.fitBounds(bounds);
      }
      
      // Highlight the selected plot on the map
      const plotObj = getMapObjectById(plot.id);
      if (plotObj && typeof plotObj.setStyle === 'function') {
        // Reset styling for all plots first
        const { filtered } = this.props.catastro;
        if (filtered) {
          filtered.forEach(p => {
            const pObj = getMapObjectById(p.id);
            if (pObj && p.id !== plot.id && typeof pObj.setStyle === 'function') {
              pObj.setStyle({
                color: '#FF4500',
                weight: 2,
                opacity: 0.7,
                fillColor: '#FFA07A',
                fillOpacity: 0.3
              });
            }
          });
        }
        
        // Add stronger highlight to the selected plot
        plotObj.setStyle({
          color: '#FF0000',
          weight: 4,
          opacity: 1,
          fillColor: '#FF6347',
          fillOpacity: 0.6
        });
      }
    }
    
    // Update selected plot in state and store
    this.props.dispatch(setSelectedPlot(plot));
  };

  // Special method to fetch plots for selected area regardless of zoom level
  fetchPlotsForSelectedArea = () => {
    const map = getGlobalMapInstance();
    if (!map) {
      console.warn("Map instance is not available for fetching plots");
      return;
    }
    
    // Check if we have a selected area from the map reducer
    const { selectedAreas } = this.props.mapReducer;
    const selectedArea = selectedAreas.length > 0 ? selectedAreas[0] : null;
    
    // If no area is selected, return early
    if (!selectedArea) {
      console.info("No area selected, skipping plot fetch");
      return;
    }
    
    this.props.dispatch(setGlobalLoading(true));
    this.props.dispatch(setGlobalLoadingMessage(i18n("Loading plots for selected area...")));
    
    // Always pass null for filters at the beginning of stage 2
    // We'll apply filters later when the user specifies them
    fetchPlotsByMapBounds(selectedArea)
      .then((plots) => {
        this.props.dispatch(setGlobalLoading(false));
        
        // Show a toast with the number of plots found
        if (plots && plots.length > 0) {
          toast.success(i18n(`Found ${plots.length} plots in selected area`));
          
          // Save the plots to the redux store for later use
          this.props.dispatch(setFilteredCatastros(plots));
        } else {
          toast.info(i18n("No plots found in selected area"));
        }
      })
      .catch(error => {
        this.props.dispatch(setGlobalLoading(false));
        toast.error(i18n("Error loading plots."));
        console.error("Error loading plots for selected area:", error);
      });
  };

  // Add method to clear children catastros
  clearChildrenCatastros = () => {
    this.props.dispatch(setChildrenCatastros(null));
  };

  render() {
    const { panelHidden } = this.state;
    const { stage } = this.props.catastro;
    
    return (
      <>
        {panelHidden && (
          <div className="catastro-show-panel">
            <Button
              variant="secondary"
              onClick={() => this.setPanelHidden(false)}
            >
              {i18n("Show Panel")}
            </Button>
          </div>
        )}
        <div id="catastro-panel" className="catastro-panel">
          <div className="catastro-form">
            <div className="catastro-show-map">
              <Button variant="text" onClick={() => this.setPanelHidden(true)}>
                {i18n("Show Map")}
              </Button>
            </div>
            {this.renderHeader()}
            <div className="catastro-form_stage">
              <div
                className={
                  "catastro-form_stage_item catastro-form_stage_item" +
                  (stage === CATASTRO_STAGE_AREA_SELECTION
                    ? " catastro-stage-active"
                    : "")
                }
              >
                <div className="catastro-form_stage_item_num">
                  <span>1</span>
                </div>
                <span>{i18n("Area")}</span>
              </div>
              <div className="divider"></div>
              <div
                className={
                  "catastro-form_stage_item catastro-form_stage_item" +
                  (stage === CATASTRO_STAGE_FILTERS
                    ? " catastro-stage-active"
                    : "")
                }
              >
                <div className="catastro-form_stage_item_num">
                  <span>2</span>
                </div>
                <span>{i18n("Filters")}</span>
              </div>
              <div className="divider"></div>
              <div
                className={
                  "catastro-form_stage_item catastro-form_stage_item" +
                  (stage === CATASTRO_STAGE_REFERENCES
                    ? " catastro-stage-active"
                    : "")
                }
              >
                <div className="catastro-form_stage_item_num">
                  <span>3</span>
                </div>
                <span>{i18n("References")}</span>
              </div>
            </div>
            {this.renderStage()}
            {this.renderFooter()}
          </div>
        </div>
        <style jsx>{`
          .catastro-panel_load-more {
            display: flex;
            justify-content: center;
            margin-top: 16px;
            margin-bottom: 16px;
          }
          
          .catastro-panel_load-more-button {
            color: #222;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 500;
            font-size: 12px;
            opacity: 0.75;
          }
          
          .catastro-panel_load-more-button:hover {
            opacity: 0.8;
          }
          
          .catastro-panel_pagination-loading {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 16px 0;
          }
          
          .catastro-panel_loader {
            border: 3px solid #f3f3f3;
            border-top: 3px solid #3366CC;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            animation: spin 1s linear infinite;
            margin-bottom: 8px;
          }
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </>
    );
  }
}

export default connect((state) => ({
  drawnPolygons: state.polygon.drawnPolygons,
  properties: state.property.properties,
  selectedPlot: state.plots.selectedPlot,
  catastro: state.catastro,
  user: state.user,
  mapReducer: state.map,
  catastroFilters: state.catastroFilters,
  selectedAreas: state.map.selectedAreas,
}))(React.memo(CatastroPanel)); 