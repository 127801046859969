import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import UploadPolygonForm from "components/admin/UploadPolygonForm";
import EmbeddableAuthenticator from "components/embeddables/EmbeddableAuthenticator";
import LoginBlurb from "components/login/LoginBlurb";
import SettingsPage from "components/settings/SettingsPage";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import logo from "./assets/logo_with_text.svg";
import UserProvider from "./components/UserProvider";
import CmaPage from "./components/cma/CmaPage";
import CollectionDetailsPage from "./components/collections/CollectionDetailsPage";
import CollectionsPage from "./components/collections/CollectionsPage";
import GlobalProvider from "./components/core/GlobalProvider";
import NotFound from "./components/core/NotFound";
import components from "./components/core/authComponents";
import "./components/core/core.scss";
import MyPropertiesPage from "./components/myProperties/MyPropertiesPage";
import UploadPage from "./components/myProperties/UploadPage";
import PropertyDetails from "./components/property/details/PropertyDetails";
import SearchPage from "./components/search/SearchPage";
import { Toaster } from "./components/ui/sonner";
import formFields from "./config/cognito-form-fields";
import { CollectionsProvider } from "./context/CollectionsContext";
import OnboardingPage from "components/onboarding/OnboardingPage";
import { isOnboardingComplete } from "utils/userHelpers";
import MarketInsights from "components/MarketInsights";
import HomePage from "components/HomePage";
import AgencyDirectory from "components/agency/AgencyDirectory";
import CatastroSearchPage from "./components/catastro/CatastroSearchPage";
import WebValuatorPage from "components/webValuator/WebValuatorPage";
import QuickValuator from "components/webValuator/QuickValuator";
import LeadTable from "components/leads/LeadTable";
import LeadDetails from "components/leads/LeadDetails";

function AuthedApp(props) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isEmbeddedUrl = location.pathname.includes("/embedded");
  const isOnboardingUrl = location.pathname === "/onboarding";

  useEffect(() => {
    // Assume the user state is already managed by Redux
    // Simulate an async check for user authentication and map data status
    const checkAppStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch {}

      setLoading(false);
    };

    checkAppStatus();
  }, [props.user]);

  useEffect(() => {
    // Check if user needs to complete onboarding
    if (isAuthenticated && !loading && !isEmbeddedUrl && !isOnboardingUrl) {
      const userData = props.userData;
      if (props.userDataFetched && !isOnboardingComplete(userData)) {
        navigate("/onboarding");
      }
    }
  }, [
    isAuthenticated,
    loading,
    props.userDataFetched,
    isEmbeddedUrl,
    isOnboardingUrl,
    navigate,
  ]);

  if (loading || props.appWideLogoLoader) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <img className="loader-logo" src={logo} />
      </div>
    );
  }

  // render login page if user is not authenticated
  if (!isAuthenticated && !loading && !isEmbeddedUrl) {
    return (
      <div className="login-wrapper">
        <div className="login-container flex h-screen flex-col gap-10">
          <div className="flex flex-1 items-center justify-center">
            <div className="login-logo flex flex-1">
              <img className="justify-start" src={logo} alt="logo" />
            </div>
          </div>
          <div className="login-row flex flex-1 justify-center">
            <LoginBlurb />
            <div className="login-form flex flex-1 items-center justify-center">
              <Authenticator
                initialState="signUp"
                formFields={formFields}
                components={{
                  SignUp: {
                    Header: components.SignUpHeader,
                  },
                }}
              >
                {({ signOut, user }) => (
                  <GlobalProvider>
                    <UserProvider amplifyUser={user} />
                  </GlobalProvider>
                )}
              </Authenticator>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // render embeddedable routes
  if (isEmbeddedUrl) {
    return (
      <Routes>
        <Route
          path="/embedded/register"
          element={<EmbeddableAuthenticator initialState="signUp" />}
        />
        <Route
          path="/embedded/login"
          element={<EmbeddableAuthenticator initialState="signIn" />}
        />
      </Routes>
    );
  }

  // else render authenticated app
  return (
    <Authenticator formFields={formFields}>
      {({ signOut, user }) => (
        <GlobalProvider>
          <UserProvider amplifyUser={user} />
          <CollectionsProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/catastro" element={<CatastroSearchPage />} />
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route
                path="/search/:collectionId/:searchId"
                element={<SearchPage />}
              />
              <Route path="/collections" element={<CollectionsPage />} />
              <Route
                path="/collections/:collectionsId"
                element={<CollectionDetailsPage />}
              />
              <Route path="/property/:propertyId" Component={PropertyDetails} />
              <Route path="/rental/:propertyId" Component={PropertyDetails} />
              <Route path="/valuation" element={<CmaPage />} />
              <Route path="/market-insights" element={<MarketInsights />} />
              <Route path="/agency-directory" element={<AgencyDirectory />} />
              <Route path="/my-properties" element={<MyPropertiesPage />} />
              <Route path="/leads" element={<LeadTable />} />
              <Route path="/leads/:leadId" element={<LeadDetails />} />
              <Route path="/web-valuator" element={<WebValuatorPage />} />
              <Route path="/web-valuator/test" element={<QuickValuator />} />
              <Route path="/upload/:propertyId" element={<UploadPage />} />
              <Route
                path="/settings"
                element={<SettingsPage signOut={signOut} />}
              />
              <Route path="/upload-polygon" element={<UploadPolygonForm />} />
              <Route path="*" Component={NotFound} />
            </Routes>
          </CollectionsProvider>
          <Toaster position="bottom-left" />
        </GlobalProvider>
      )}
    </Authenticator>
  );
}

export default connect((state) => ({
  user: state.auth.user,
  appWideLogoLoader: state.misc.appWideLogoLoader,
  userData: state.user.userData,
  userDataFetched: state.user.userDataFetched,
}))(AuthedApp);
