import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLeads, deleteLead } from 'actions/leadActions';
import { useNavigate } from 'react-router-dom';
import './leadTable.scss';
import Dashboard from 'components/Dashboard';
import Button from 'components/ui/Button/Button';
import { i18n } from 'i18n/localisation';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import { userHasRole } from 'utils/userHelpers';
import { setPricingModal } from 'actions/miscActions';
import trashIcon from "../../assets/map/trash.svg";
import editIcon from "../../assets/core/edit.svg";
import eyeIcon from "../../assets/core/eye.svg";
import EditLeadModal from './EditLeadModal';
import webValuatorIcon from "../../assets/property/types/detached.svg";
import emailIcon from "../../assets/core/email.svg";
import whatsappIcon from "../../assets/core/whatsapp.svg";
import { Helmet } from 'react-helmet';

const LeadTable = () => {
  const [deleteModalLeadId, setDeleteModalLeadId] = useState(null);
  const [editLeadModalLeadId, setEditLeadModalLeadId] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leadsResult, fetching } = useSelector((state) => state.lead);
  const { teamId } = useSelector((state) => state.user.userData);

  const currentPage = leadsResult.page ?? 1;
  const totalPages = leadsResult.total_pages ?? 1;
  const leads = leadsResult.leads;

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      dispatch(fetchLeads(1, query));
    }, 300),
    [dispatch]
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  useEffect(() => {
    if (teamId && searchQuery === '') {
      dispatch(fetchLeads(1));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
    }, 100);
  }, []);

  const handleAddNewLead = () => {
    setEditLeadModalLeadId({});
  };

  const handleCreateWebValuator = () => {
    navigate('/web-valuator');
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(fetchLeads(currentPage + 1, searchQuery));
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      dispatch(fetchLeads(currentPage - 1, searchQuery));
    }
  };

  const handleOpenLead = (leadId) => {
    window.open(`/leads/${leadId}`, `lead_${leadId}`);
  };

  const handleEditLead = (leadId) => {
    setEditLeadModalLeadId(leadId);
  };

  const handleDeleteLead = async (leadId) => {
    dispatch(deleteLead(leadId));
  };

  const deduceLeadSource = (lead) => {
    if (lead.source === "lead_magnet") {
      return i18n("Web Valuator");
    }

    if (lead.source === "internal") {
      return i18n("Internal");
    }

    return i18n("Unknown");
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedLeads(leads.map(lead => lead.id));
    } else {
      setSelectedLeads([]);
    }
  };

  const handleSelectLead = (leadId) => {
    setSelectedLeads(prev => {
      if (prev.includes(leadId)) {
        return prev.filter(id => id !== leadId);
      } else {
        return [...prev, leadId];
      }
    });
  };

  const handleExportCSV = () => {
    const csvData = leads
      .filter(lead => selectedLeads.length === 0 || selectedLeads.includes(lead.id))
      .map(lead => ({
        name: lead.name,
        phone: lead.phone,
        email: lead.email,
        source: deduceLeadSource(lead)
      }));

    const headers = [i18n('Name'), i18n('Phone Number'), i18n('Email'), i18n('Source')];
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'leads.csv';
    link.click();
  };

  const handleOpenWhatsApp = (phone) => {
    window.open(`https://wa.me/${phone.trim()}`, '_blank');
  };

  const handleOpenEmail = (email) => {
    window.open(`mailto:${email}`, '_blank');
  };

  const renderLoading = () => {
    return (
      <Dashboard>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      </Dashboard>
    )
  }

  const renderLeadTable = () => {
    return (
      <table className="lead-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectedLeads.length === leads?.length}
                onChange={handleSelectAll}
              />
            </th>
            <th>{i18n("Name")}</th>
            <th>{i18n("Phone Number")}</th>
            <th>{i18n("Email")}</th>
            <th>{i18n("Source")}</th>
            <th>{i18n("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {leads?.map((lead) => (
            <tr key={lead.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedLeads.includes(lead.id)}
                  onChange={() => handleSelectLead(lead.id)}
                />
              </td>
              <td>{lead.name}</td>
              <td>{lead.phone}</td>
              <td>{lead.email}</td>
              <td>{deduceLeadSource(lead)}</td>
              <td>
                <div className="flex gap-2">
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleOpenLead(lead.id)}
                    title={i18n("View Lead")}
                  >
                    <img src={eyeIcon} alt="View Lead" />
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleOpenWhatsApp(lead.phone)}
                    title={i18n("Open WhatsApp")}
                  >
                    <img src={whatsappIcon} alt="WhatsApp" />
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleOpenEmail(lead.email)}
                    title={i18n("Send Email")}
                  >
                    <img src={emailIcon} alt="Email" />
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleEditLead(lead.id)}
                    title={i18n("Edit Lead")}
                  >
                    <img src={editIcon} alt="Edit Lead" />
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => setDeleteModalLeadId(lead.id)}
                    title={i18n("Delete Lead")}
                  >
                    <img src={trashIcon} alt="Delete Lead" />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("Leads")}</title>
      </Helmet>
      <div className="lead-table-container">
        <div className="lead-table-header">
          <h1>Leads</h1>
          <div className="lead-table-actions">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={i18n("Search leads...")}
              className="px-4 py-2 border border-gray-300 rounded-md"
            />
            <Button
              className="export-csv-btn"
              variant="secondary"
              color="primary"
              onClick={handleExportCSV}
              sx={{ mr: 2 }}
              disabled={selectedLeads.length === 0}
            >
              {i18n("Export to CSV")}
            </Button>
            <Button
              variant="primary"
              color="primary"
              onClick={handleAddNewLead}
              sx={{ mr: 2 }}
            >
              + {i18n("Add New Lead")}
            </Button>
            <Button
              className="flex items-center gap-2 create-valuator-btn"
              variant="secondary"
              color="primary"
              onClick={handleCreateWebValuator}
            >
              <img className="w-5 h-5" src={webValuatorIcon} alt="Web Valuator" />
              <span>{i18n("Create Web Valuator")}</span>
            </Button>
          </div>
        </div>

        {fetching ? renderLoading() : renderLeadTable()}

        <div className="flex justify-center items-center gap-4 mt-4">
          <Button
            variant="secondary"
            color="primary"
            onClick={handlePrevPage}
            disabled={currentPage === 1 || fetching}
          >
            {i18n("Previous")}
          </Button>
          <span className="text-gray-600">
            {i18n("Page")} {currentPage} {i18n("of")} {totalPages}
          </span>
          <Button
            variant="secondary"
            color="primary"
            onClick={handleNextPage}
            disabled={currentPage === totalPages || fetching}
          >
            {i18n("Next")}
          </Button>
        </div>
      </div>
      <EditLeadModal
        open={editLeadModalLeadId !== null}
        onClose={() => setEditLeadModalLeadId(null)}
        lead={editLeadModalLeadId ? leads.find((lead) => lead.id === editLeadModalLeadId) : null}
      />
      <ConfirmModal
        open={deleteModalLeadId !== null}
        onClose={() => setDeleteModalLeadId(null)}
        onCancel={() => setDeleteModalLeadId(null)}
        onConfirm={() => {
          setDeleteModalLeadId(null);
          handleDeleteLead(deleteModalLeadId);
        }}
        title={i18n("Delete Lead")}
        confirmationText={i18n("Are you sure you want to delete this lead?")}
        confirmText={i18n("Delete")}
        cancelText={i18n("Cancel")}
      />
    </Dashboard>
  );
};

export default LeadTable;