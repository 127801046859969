import { getLang, i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics"; // Add this line
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getAveragePricePerSqmHistoricChartData,
  getAverageTimeOnMarketChartData,
  getPriceDistributionChartData,
  getPricePerSqmDistributionChartData,
  getPropertiesForProcessing,
  getStatsData,
} from "utils/stats";
import { fetchPropertyRequest } from "../../../api/properties";
import aiChatIcon from "../../../assets/property/ai_chat.svg";
import whiteArrow from "../../../assets/property/white_arrow.svg";
import { CollectionsContext } from "../../../context/CollectionsContext";
import {
  getPropertyDistrictByName,
  getPropertyGeoboundary,
  getPropertyTitle,
  getPropertyTypologyHuman,
  getPropertyZone,
} from "../../../utils/properties";
import Dashboard from "../../Dashboard";
import AdvertisersCommentsCard from "./AdvertisersCommentsCard";
import ChartCard from "./ChartCard";
import ContactFormWidget from "./contactFormWidget";
import ListingsWidget from "./ListingsWidget";
import LocationCard from "./LocationCard";
import MainPropertyDetailsCard from "./MainPropertyDetailsCard";
import PropertyChat from "./PropertyChat";
import "./propertyDetails.scss";
import PropertyDetailsHeader from "./PropertyDetailsHeader";
import ReportModal from "./ReportModal";
import StatisticsCard from "./StatisticsCard";
import { PROPERTY_ID_PREFIX } from "config/constants";
import { setGlobalLoading } from "../../../actions/miscActions";
import { useDispatch } from "react-redux";

const PropertyDetails = ({ urlParams, collections, globalLoading }) => {
  const dispatch = useDispatch();
  const [property, setProperty] = useState(null);
  const [duplicates, setDuplicates] = useState([]);
  const [aiChatOpen, setAiChatOpen] = useState(false);
  const [isDeletedProperty, setIsDeletedProperty] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [aiChatPopupOpen, setAiChatPopupOpen] = useState(
    !localStorage.getItem("AI_POPUP_COLLAPSED"),
  );
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [tabTitle, setTabTitle] = useState(null);
  const [priceDistributionChartData, setPriceDistributionChartData] =
    useState(null);
  const [
    pricePerSqmDistributionChartData,
    setPricePerSqmDistributionChartData,
  ] = useState(null);
  const [averageTimeOnMarketChartData, setAverageTimeOnMarketChartData] =
    useState(null);
  const [statsData, setStatsData] = useState(null);
  const [propertyDistrictName, setPropertyDistrictName] = useState(null);
  const [propertyZoneName, setPropertyZoneName] = useState(null);
  const [
    averagePricePerSqmHistoricChartData,
    setAveragePricePerSqmHistoricChartData,
  ] = useState(null);

  const { propertyId } = useParams();
  const location = useLocation();

  const { openAddToCollectionModal } = useContext(CollectionsContext);
  useEffect(() => {
    const isCRMProperty = propertyId.substring(0, 8) === PROPERTY_ID_PREFIX;

    let saleType = "sale";
    if (location.pathname.includes("/rental/")) {
      saleType = "rent";
    }

    // set global loading to true
    dispatch(setGlobalLoading(true));

    fetchPropertyRequest(propertyId, saleType, isCRMProperty).then(
      async (response) => {
        if (response.property) {
          setProperty(response.property);
          setDuplicates(response.duplicates);
          setIsDeletedProperty(response.is_deleted_property);
          setTabTitle(getPropertyTitle(response.property));
          // set global loading to false
          dispatch(setGlobalLoading(false));

          await processPropertyData(response.property);
        } else if (response.scraping) {
          let attempts = 0;
          const maxAttempts = 10;
          const interval = 2000;

          const attemptFetch = () => {
            if (attempts >= maxAttempts) {
              setProperty(null);
              return;
            }

            fetchPropertyRequest(
              propertyId,
              saleType,
              isCRMProperty,
              true,
            ).then(async (response) => {
              if (response.property) {
                setProperty(response.property);
                setDuplicates(response.duplicates);
                setIsDeletedProperty(response.is_deleted_property);
                setTabTitle(getPropertyTitle(response.property));
                dispatch(setGlobalLoading(false));
                await processPropertyData(response.property);
              } else {
                attempts++;
                setTimeout(attemptFetch, interval);
              }
            });
          };
          setTimeout(attemptFetch, interval);
        }
      },
    );
  }, [propertyId]);

  const processPropertyData = async (property) => {
    // get property geoboundaries for price distribution data
    // and average price per sqm historic chart data
    let [
      propertyDistrict,
      propertyMunicipality,
      propertyGeoboundary,
      propertyZone,
    ] = await Promise.all([
      getPropertyDistrictByName(property),
      getPropertyGeoboundary(property, true),
      getPropertyGeoboundary(property, false),
      getPropertyZone(property),
    ]);

    if (!propertyDistrict) {
      propertyDistrict = propertyGeoboundary;
    }

    const { properties, rentalProperties, resolvedDistrict } =
      await getPropertiesForProcessing(
        property,
        propertyDistrict,
        propertyZone,
        propertyMunicipality,
      );

    propertyDistrict = resolvedDistrict;

    // get price distribution data
    const priceDistributionChartData = await getPriceDistributionChartData(
      property,
      properties,
    );
    setPriceDistributionChartData(priceDistributionChartData);

    // get price per sqm distribution data
    const pricePerSqmDistributionChartData =
      await getPricePerSqmDistributionChartData(property, properties);
    setPricePerSqmDistributionChartData(pricePerSqmDistributionChartData);

    // get average time on market chart data
    if (property.saleType !== "rent") {
      const averageTimeOnMarketChartData =
        await getAverageTimeOnMarketChartData(property, properties);
      setAverageTimeOnMarketChartData(averageTimeOnMarketChartData);
    }

    // stats
    const statsData = await getStatsData(
      property,
      properties,
      rentalProperties,
    );
    setStatsData(statsData);

    // get property district name
    if (propertyDistrict.type === "district") {
      setPropertyDistrictName(
        propertyDistrict.name + ", " + propertyDistrict.municipality,
      );
    } else {
      setPropertyDistrictName(propertyDistrict.name);
    }

    // get average price per sqm historic chart data (priority to property's zone)
    // fallback to property's municipality
    setPropertyZoneName(
      propertyZone
        ? `${propertyZone.name}, ${propertyMunicipality.name}`
        : propertyMunicipality.name,
    );

    // if the property is a rental or not a house, skip the average price per sqm historic chart
    // as its only relevant to on sale residential properties
    if (property.saleType === "rent" || property.buildingType !== "property") {
      return;
    }

    let [
      averagePricePerSqmHistoricChartData,
      averagePricePerSqmZoneHistoricChartData,
    ] = await Promise.all([
      getAveragePricePerSqmHistoricChartData(propertyMunicipality?.id),
      getAveragePricePerSqmHistoricChartData(propertyZone?.id),
    ]);

    if (averagePricePerSqmZoneHistoricChartData) {
      setAveragePricePerSqmHistoricChartData(
        averagePricePerSqmZoneHistoricChartData,
      );
    } else if (averagePricePerSqmHistoricChartData) {
      setAveragePricePerSqmHistoricChartData(
        averagePricePerSqmHistoricChartData,
      );
    }
  };

  useEffect(() => {
    if (aiChatOpen) {
      const onKeyDown = (e) => {
        if (e.key === "Escape") {
          document.querySelector(".property-chat").classList.add("slide-out");
          setTimeout(() => {
            setAiChatOpen(false);
          }, 200);
          window.removeEventListener("keydown", onKeyDown);
        }
      };

      window.addEventListener("keydown", onKeyDown);
    }
  }, [aiChatOpen]);

  setTimeout(() => {
    window.onscroll = () => {
      const navbarElement = document.querySelector(".navbar-mobile");
      const fixedHeaderElement = document.querySelector(".mobile");

      if (
        document.querySelector(".details-card")?.getBoundingClientRect()
          .bottom <= navbarElement?.getBoundingClientRect().bottom
      ) {
        navbarElement.classList.add("hidden");
        fixedHeaderElement?.classList.remove("hidden");
      } else {
        navbarElement.classList.remove("hidden");
        fixedHeaderElement?.classList.add("hidden");
      }
    };
  }, 100);

  const onLikeProperty = (e) => {
    e.preventDefault();
    e.stopPropagation();

    openAddToCollectionModal({ ...property, objectType: "property" });
    sendAnalyticsEvent("Property Page Click", {
      type: "like",
      details: property,
    });
  };

  const onUnlikeProperty = (e) => {
    e.preventDefault();
    e.stopPropagation();

    openAddToCollectionModal({ ...property, objectType: "property" });
  };

  const toggleAiChat = () => {
    setAiChatOpen(!aiChatOpen);
    localStorage.setItem("AI_POPUP_COLLAPSED", true);
    setAiChatPopupOpen(false);

    // auto focus onto the property chat input
    if (!aiChatOpen) {
      setTimeout(() => {
        document.getElementById("property-chat-input").focus();
      }, 100);
    }
  };

  // fill property with any data that its missing from the duplicates
  const saturateProperty = () => {
    if (duplicates.length > 0) {
      for (let duplicate of duplicates) {
        if (
          duplicate.multimedia.videos.length > property.multimedia.videos.length
        ) {
          property.multimedia.videos = duplicate.multimedia.videos;
        }

        if (
          duplicate.multimedia.virtualTour &&
          !property.multimedia.virtualTour
        ) {
          property.multimedia.virtualTour = duplicate.multimedia.virtualTour;
        }

        if (
          duplicate.multimedia.plans.length > property.multimedia.plans.length
        ) {
          property.multimedia.plans = duplicate.multimedia.plans;
        }

        for (let key of Object.keys(duplicate.features)) {
          if (!property.features[key]) {
            property.features[key] = duplicate.features[key];
          }
        }

        // only get the property description of dupe if higher AND
        // if the language of the description matches the current language
        if (duplicate.description.length > property.description.length) {
          let { code } = getLang();
          if (code === property.country) {
            property.description = duplicate.description;
          }
        }
      }
    }
  };

  saturateProperty(property);
  let typologyConjunction = "";

  if (property) {
    typologyConjunction = (
      " " +
      i18n("for") +
      " " +
      getPropertyTypologyHuman(property, true, true)
    ).toLowerCase();
  }

  return (
    <Dashboard>
      <div className="details-backdrop"></div>
      {property && (
        <div className="property-details">
          <Helmet>
            <title>{tabTitle}</title>
          </Helmet>
          <PropertyDetailsHeader
            property={property}
            isDeletedProperty={isDeletedProperty}
            onLikeProperty={onLikeProperty}
            onUnlikeProperty={onUnlikeProperty}
            isReportModalOpen={isReportModalOpen}
            setIsReportModalOpen={setIsReportModalOpen}
          />
          <div className="property-details_container">
            <div className="property-details_wrapper">
              <MainPropertyDetailsCard
                property={property}
                onLikeProperty={onLikeProperty}
                onUnlikeProperty={onUnlikeProperty}
                duplicates={duplicates}
              />

              <AdvertisersCommentsCard property={property} />

              {statsData && (
                <StatisticsCard
                  title={
                    i18n("Statistics") +
                    typologyConjunction +
                    " " +
                    i18n("in") +
                    " " +
                    (propertyDistrictName
                      ? propertyDistrictName
                      : property.province)
                  }
                  statistics={statsData}
                />
              )}

              {property.saleType !== "rent" &&
                pricePerSqmDistributionChartData && (
                  <ChartCard
                    chartType="bar"
                    title={
                      (property.saleType != "rent"
                        ? i18n("Price/m² Distribution")
                        : i18n("Rental Price/m² Distribution") +
                          " (" +
                          i18n(
                            property.rental_isShortTerm
                              ? "per day"
                              : "per month",
                          ) +
                          ")") +
                      typologyConjunction +
                      " " +
                      i18n("in") +
                      " " +
                      (propertyDistrictName
                        ? propertyDistrictName
                        : property.province)
                    }
                    data={pricePerSqmDistributionChartData.chartData}
                    options={pricePerSqmDistributionChartData.options}
                  />
                )}

              {priceDistributionChartData && (
                <ChartCard
                  chartType="bar"
                  title={
                    (property.saleType != "rent"
                      ? i18n("Absolute Price Distribution")
                      : i18n("Absolute Rental Price Distribution") +
                        " (" +
                        i18n(
                          property.rental_isShortTerm ? "per day" : "per month",
                        ) +
                        ")") +
                    typologyConjunction +
                    " " +
                    i18n("in") +
                    " " +
                    (propertyDistrictName
                      ? propertyDistrictName
                      : property.province)
                  }
                  data={priceDistributionChartData.chartData}
                  options={priceDistributionChartData.options}
                />
              )}

              {averageTimeOnMarketChartData && (
                <ChartCard
                  chartType="bar"
                  title={i18n("Average Time on Market")}
                  data={averageTimeOnMarketChartData.chartData}
                  options={averageTimeOnMarketChartData.options}
                />
              )}
              {averagePricePerSqmHistoricChartData && (
                <ChartCard
                  chartType="line"
                  title={
                    i18n("Average Price per m²") +
                    " " +
                    i18n("in") +
                    " " +
                    propertyZoneName +
                    " (" +
                    i18n("Residential") +
                    ")"
                  }
                  data={averagePricePerSqmHistoricChartData.chartData}
                  options={averagePricePerSqmHistoricChartData.options}
                />
              )}
              <LocationCard property={property} />
            </div>
            <div className="property-details_listing-wrapper">
              {property.isPrivateBankProperty ? (
                <ContactFormWidget property={property} />
              ) : (
                <ListingsWidget property={property} duplicates={duplicates} />
              )}
            </div>
          </div>
          <div className="ai-property-chat-btn">
            {aiChatPopupOpen && (
              <div className="ai-property-chat-btn_popup">
                <img
                  alt="white-arrow"
                  className="ai-property-chat-btn_popup_nibble"
                  src={whiteArrow}
                />
                <div className="ai-property-chat-btn_popup_rect">
                  <span>
                    {i18n("Ask me anything you want about the property!")}
                  </span>
                </div>
              </div>
            )}
            <div onClick={toggleAiChat} className="ai-property-chat-btn_btn">
              <img alt="chat-icon" src={aiChatIcon} />
              <span>{i18n("AI Property Chat")}</span>
            </div>
          </div>
          {aiChatOpen && (
            <PropertyChat
              setMessages={setChatMessages}
              messages={chatMessages}
              onCloseChat={toggleAiChat}
              property={property}
              statistics={statsData ? statsData.statistics : null}
            />
          )}
          <ReportModal
            isOpen={isReportModalOpen}
            closeModal={setIsReportModalOpen}
            propertyId={property.id}
          />
        </div>
      )}
    </Dashboard>
  );
};

export default connect((state) => {
  return {
    collections: state.collections,
    user: state.user,
    globalLoading: state.misc.globalLoading,
  };
})(PropertyDetails);
