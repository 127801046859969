import { setIsPublished } from "actions/myPropertiesActions";
import { savePropertyRequest } from "api/crm";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import eyeIcon from "../../assets/core/eye.svg";
import Button from "../ui/Button/Button";

const UnpublishModal = ({ isOpen, closeModal }) => {
  const [isModalContentPublishStatus, setIsModalContentPublishStatus] =
    useState(true);
  const { propertyId } = useSelector((state) => state.myProperties);
  const listingType = useSelector((state) => state.myProperties.listingType);
  const dispatch = useDispatch();

  const handleUnpublish = async () => {
    dispatch(setIsPublished(false));
    closeModal();
    try {
      await savePropertyRequest({
        property_id: propertyId,
        saleType: listingType,
        update_fields: { published: 0 },
      });
    } catch (e) {
      console.log(e);
      dispatch(setIsPublished(true));
      toast.error(i18n("Failed to unpublish property. Please try again."), {
        duration: 5000,
      });
    }

    toast.success(i18n("Property unpublished"), { duration: 5000 });
  };

  const renderPublishStatus = () => {
    return (
      <>
        <DialogHeader>
          <DialogTitle>{i18n("Publish status settings")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex w-full flex-col gap-6 p-6 pt-[0px]">
          <div
            className={
              "flex h-auto w-full justify-between rounded-[8px] border border-[#E1E4EB] bg-[#F2F4F8] p-4"
            }
          >
            <img src={eyeIcon} alt="share icon" className="w-5" />
            <div>
              <span className="font-bold">
                {i18n("Your property is published")}
              </span>
              <p className="text-[16px]">
                {i18n("Property is live and others are able to view it.")}
              </p>
            </div>
            <Button
              onClick={() => setIsModalContentPublishStatus(false)}
              variant="secondary"
              className="h-11 min-w-fit"
            >
              <span className="text-base font-medium">{i18n("Unpublish")}</span>
            </Button>
          </div>

          <div className="flex w-full flex-col gap-3 border-t">
            <p className="mt-6 text-[#717171]">
              {i18n(
                "Publishing your property will make it accessible for others to see.",
              )}
            </p>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-start">
            <Button onClick={() => closeModal()} variant="ghost">
              <div className="text-base font-medium">{i18n("Cancel")}</div>
            </Button>
          </div>
        </DialogFooter>
      </>
    );
  };

  const renderUnpublishConfirmation = () => {
    return (
      <>
        <DialogHeader>
          <DialogTitle>{i18n("Unpublish property listing?")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex w-full flex-col gap-6 p-6 pt-[0px]">
          <p className="text-[#717171]">
            {i18n(
              "Are you sure you want to unpublish the property: Villa in Elviria-Cabopino?",
            )}
          </p>

          <div className="flex w-full flex-col gap-3 border-t">
            <p className="mt-6 text-base font-normal text-[#717171]">
              {i18n(
                "Unpublishing your property listing means it will not be visible publicly or privately.",
              )}
            </p>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-between">
            <Button onClick={() => closeModal()} variant="ghost">
              <div className="text-base font-medium">{i18n("Cancel")}</div>
            </Button>
            <Button onClick={handleUnpublish}>
              <div className="text-base font-medium">
                {i18n("Yes, unpublish")}
              </div>
            </Button>
          </div>
        </DialogFooter>
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        {isModalContentPublishStatus
          ? renderPublishStatus()
          : renderUnpublishConfirmation()}
      </DialogContent>
    </Dialog>
  );
};

export default UnpublishModal;
