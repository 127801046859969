import { ReactComponent as CamIcon } from "assets/core/cam.svg";
import { ReactComponent as FloorplanIcon } from "assets/core/floorplan.svg";
import { ReactComponent as ImageIcon } from "assets/core/image.svg";
import { ReactComponent as VirtualTourIcon } from "assets/core/virtualTour.svg";
import { PROPERTY_FEATURE_TYPE } from "components/property/PropertyFeatureChip";
import { i18n } from "i18n/localisation";

export const ACCORDION_SECTIONS = [
  {
    key: "media",
    title: "Media",
    steps: [
      { label: "Upload photo*", key: "photos", isRequired: true },
      { label: "Upload video", key: "videos", isRequired: false },
      { label: "Upload 360 image", key: "virtualTours", isRequired: false },
      { label: "Upload floor plan", key: "floorPlans", isRequired: false },
    ],
  },
  {
    key: "propertyDetails",
    title: "Details",
    steps: [
      { label: "Price*", key: "price", isRequired: true },
      { label: "Property subtype*", key: "detailedType", isRequired: true },
      { label: "No. of bedrooms*", key: "bedrooms", isRequired: true },
      { label: "No. of bathrooms*", key: "bathrooms", isRequired: true },
      { label: "Built area*", key: "size", isRequired: true },
    ],
  },
  {
    key: "description",
    title: "Description",
    steps: [
      { label: "Property description", key: "description", isRequired: false },
    ],
  },
  {
    key: "location",
    title: "Location",
    steps: [{ label: "Property Address*", key: "location", isRequired: true }],
  },
];

export const MODAL_STEPS = {
  PROPERTY_DETAILS: 1,
  CATASTRO: 2,
};

export const MODAL_STEPS_CONFIG = {
  [MODAL_STEPS.PROPERTY_DETAILS]: {
    title: "Upload a new property",
    description: i18n(
      "Select the listing type and property type to get started with your listing",
    ),
    validate: (formData) =>
      Boolean(formData.propertyType && formData.listingType),
  },
  [MODAL_STEPS.CATASTRO]: {
    title: i18n("Click or Type Catastral Reference"),
    description: i18n(
      "Showing exact location increases sale rate by an average of X amount",
    ),
    validate: (formData) => formData.isApproximateLocation !== null,
  },
};

export const MEDIA_TABS = [
  { key: "photos", label: i18n("Photo's"), icon: ImageIcon },
  { key: "videos", label: i18n("Video's"), icon: CamIcon },
  { key: "virtualTours", label: i18n("360 Images"), icon: VirtualTourIcon },
  { key: "floorPlans", label: i18n("Floorplans"), icon: FloorplanIcon },
];

export const ICON_CONFIG = {
  photos: { icon: ImageIcon, parameter: "stroke" },
  videos: { icon: CamIcon, parameter: "stroke" },
  virtualTours: { icon: VirtualTourIcon, parameter: "fill" },
  floorPlans: { icon: FloorplanIcon, parameter: "fill" },
};

export const NO_MEDIA_CONTAINER = {
  photos: {
    key: "photos",
    label: i18n("Add images"),
    text: i18n("Upload up to 100 images up to 32MB each in png or jpeg"),
  },
  videos: {
    key: "videos",
    label: i18n("Add videos"),
    text: i18n("Upload up to 10 videos up to 100MB each in mp4"),
  },
  virtualTours: {
    key: "virtualTours",
    label: i18n("Add 360 images"),
    text: i18n("Upload up to 10 images up to 32MB each in png or jpeg"),
  },
  floorPlans: {
    key: "floorPlans",
    label: i18n("Add floor plans"),
    text: i18n("Upload up to 10 images up to 32MB each in png or jpeg"),
  },
};

export const LOCATION_PREFERENCE_ITEMS = [
  {
    label: "Show exact location",
    value: false,
  },
  { label: "Show approximate location", value: true },
];

export const ENERGY_RATING_GRADE = ["A", "B", "C", "D", "E", "F"];

export const PATH_DICT = {
  "detailedType.typology": "typology",
  "detailedType.subTypology": "subTypology",
  "detailedType.isVilla": "subTypology",
  "features.hasTerrace": "hasTerrace",
  "features.hasAirConditioning": "hasAirConditioning",
  "features.hasGarden": "hasGarden",
  "features.hasSwimmingPool": "hasSwimmingPool",
  "features.hasLift": "hasLift",
  "features.hasGarage": "hasGarage",
  "features.hasSeaView": "hasSeaView",
  "features.hasCentralHeating": "hasCentralHeating",
  "features.hasStorage": "hasStorage",
  "features.hasFittedWardrobes": "hasFittedWardrobes",
  "features.hasBalcony": "hasBalcony",
  "features.hasGreenery": "hasGreenery",
  "features.isLuxury": "isLuxury",
};

export const STEPPER_CONFIG = [
  {
    key: 1,
    step: "1",
    text: "Details",
  },
  {
    key: 2,
    step: "2",
    text: "Pricing",
  },
  {
    key: 3,
    step: "3",
    text: "Specifications",
  },
];

export const STATUS_CONFIG = {
  available: {
    status: "available",
    label: "Available",
    style: "bg-status-available text-white",
  },
  reserved: {
    status: "reserved",
    label: "Reserved",
    style: "bg-status-reserved text-white",
  },
  underContract: {
    status: "underContract",
    label: "Under Contract",
    style: "bg-status-under-contract text-white",
  },
  sold: {
    status: "sold",
    label: "Sold",
    style: "bg-status-sold text-white",
  },
  offMarket: {
    status: "offMarket",
    label: "Off-Market",
    style: "bg-status-off-market text-white",
  },
  published: {
    status: "published",
    label: "Published",
    style: "bg-status-chip-published text-status-label-published",
  },
  unpublished: {
    status: "unpublished",
    label: "Unpublished",
    style: "bg-status-chip-unpublished text-status-label-unpublished",
  },
};

export const SECTION_WRAPPER_CONTENT = {
  EMPTY_CONTENT: "emptyContent",
  SAVED_CONTENT: "savedContent",
  INPUT_CONTENT: "inputContent",
};

export const FEATURE_CHIP_MAPPING = {
  bathrooms: { type: PROPERTY_FEATURE_TYPE.bathrooms },
  bedrooms: { type: PROPERTY_FEATURE_TYPE.bedrooms },
  hasAirConditioning: {
    type: PROPERTY_FEATURE_TYPE.ac,
    value: "Air conditioning",
  },
  hasBalcony: { type: PROPERTY_FEATURE_TYPE.balcony, value: "Balcony" },
  hasCentralHeating: { type: PROPERTY_FEATURE_TYPE.heating, value: "Heating" },
  hasFittedWardrobes: {
    type: PROPERTY_FEATURE_TYPE.wardrobe,
    value: "Wardrobe",
  },
  hasGarage: { type: PROPERTY_FEATURE_TYPE.garage, value: "Garage" },
  hasGarden: { type: PROPERTY_FEATURE_TYPE.garden, value: "Garden" },
  hasGreenery: { type: PROPERTY_FEATURE_TYPE.greenary, value: "Greenery" },
  hasLift: { type: PROPERTY_FEATURE_TYPE.lift, value: "Lift" },
  hasSeaView: { type: PROPERTY_FEATURE_TYPE.seaView, value: "Sea view" },
  hasStorage: { type: PROPERTY_FEATURE_TYPE.storeroom, value: "Storage" },
  hasSwimmingPool: { type: PROPERTY_FEATURE_TYPE.pool, value: "Pool" },
  hasTerrace: { type: PROPERTY_FEATURE_TYPE.terrace, value: "Terrace" },
};

export const PROPERTY_TYPES_ABLED = ["property"];
