import leftArrow from "assets/core/left_chevron.svg";
import rightArrow from "assets/core/right_chevron.svg";
import Dropdown from "components/core/Dropdown";
import Checkbox from "components/input/Checkbox";
import { i18n } from "i18n/localisation";
import {
  PROPERTY_CONDITTION_FILTERS,
  PROPERTY_FEATURES_FILTERS,
  PROPERTY_TYPE_FILTERS,
} from "lib/filter/filterTypes";
import { useState } from "react";
import Button from "../../ui/Button/Button";
import { ENERGY_RATING_GRADE, PATH_DICT } from "../uploadPropertyPageConfig";
import Stepper from "./Stepper";

const InputDynamic = ({ label, value, onIncrement, onDecrement }) => {
  return (
    <div>
      <p className="mb-1 text-sm">{label}</p>
      <div className="flex h-[48px] w-[260px] max-w-[260px] rounded-lg border border-[#BABABA]">
        <div
          className="flex w-[44px] min-w-[44px] cursor-pointer items-center justify-center rounded-l-lg bg-[#F4F6F8]"
          onClick={() => value > 0 && onDecrement()}
        >
          <span>-</span>
        </div>
        <div className="flex w-full min-w-[44px] items-center justify-center border-x border-[#BABABA]">
          {value}
        </div>
        <div
          className="flex w-[44px] min-w-[44px] cursor-pointer items-center justify-center rounded-r-lg bg-[#F4F6F8]"
          onClick={onIncrement}
        >
          <span>+</span>
        </div>
      </div>
    </div>
  );
};

const InputNumber = ({ label, type, value, onChange }) => {
  const TYPE_UNIT = {
    none: "",
    area: "m²",
    currency: "€",
    percent: "%",
  };

  return (
    <div>
      <p className="mb-1 text-sm">{label}</p>
      <div className="flex h-[48px] w-[260px] max-w-[260px] items-center justify-between rounded-lg border border-[#BABABA] p-2">
        <input
          id="property-details-input-number"
          type="number"
          onChange={onChange}
          value={value}
        ></input>
        <span className="text-sm text-[#717171]">{TYPE_UNIT[type]}</span>
      </div>
    </div>
  );
};

const InputDetailsFooter = ({ setCurrentStep, currentStep }) => {
  return (
    <div className="mt-4 flex justify-between">
      <Button
        disabled={currentStep === 1}
        variant="secondary"
        onClick={() => {
          setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
        }}
      >
        <img src={leftArrow} alt="left arrow" />
        <span className="font-medium">{i18n("Previous")}</span>
      </Button>
      <div className="flex items-center gap-[6px]">
        <div
          onClick={() => setCurrentStep(1)}
          className={`h-[6px] w-[6px] cursor-pointer rounded-full ${currentStep === 1 ? "bg-[#717171]" : "bg-[#717171]/40"}`}
        />
        <div
          onClick={() => setCurrentStep(2)}
          className={`h-[6px] w-[6px] cursor-pointer rounded-full ${currentStep === 2 ? "bg-[#717171]" : "bg-[#717171]/40"}`}
        />
        <div
          onClick={() => setCurrentStep(3)}
          className={`h-[6px] w-[6px] cursor-pointer rounded-full ${currentStep === 3 ? "bg-[#717171]" : "bg-[#717171]/40"}`}
        />
      </div>
      <Button
        disabled={currentStep === 3}
        variant="secondary"
        onClick={() => {
          setCurrentStep(currentStep === 3 ? 3 : currentStep + 1);
        }}
      >
        <span className="font-medium">{i18n("Next")}</span>
        <img src={rightArrow} alt="right arrow" />
      </Button>
    </div>
  );
};

const SectionDivider = () => {
  return <div className="h-[1px] w-full bg-[#E1E4EB]/[50%]"></div>;
};

const InputDetails = ({ propertyDetails, setDetails }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const renderConstructionSection = () => {
    return (
      <div className="my-6 flex w-full flex-col gap-3">
        <div>
          <p className="font-medium">{i18n("Construction")}</p>
        </div>
        <div className="flex w-full max-w-[700px] justify-between">
          <InputDynamic
            label={i18n("Number of bedrooms*")}
            value={propertyDetails.bedrooms}
            onIncrement={() => {
              propertyDetails.bedrooms = propertyDetails.bedrooms + 1;
              setDetails({ ...propertyDetails });
            }}
            onDecrement={() => {
              propertyDetails.bedrooms = propertyDetails.bedrooms - 1;
              setDetails({ ...propertyDetails });
            }}
          />
          <InputDynamic
            label={i18n("Number of bathrooms*")}
            value={propertyDetails.bathrooms}
            onIncrement={() => {
              propertyDetails.bathrooms = propertyDetails.bathrooms + 1;
              setDetails({ ...propertyDetails });
            }}
            onDecrement={() => {
              propertyDetails.bathrooms = propertyDetails.bathrooms - 1;
              setDetails({ ...propertyDetails });
            }}
          />
        </div>
        <div className="flex w-full max-w-[700px] justify-between">
          <InputNumber
            label={i18n("Built area*")}
            type={"area"}
            value={propertyDetails.size}
            onChange={(e) => {
              propertyDetails.size = e.target.value;
              setDetails({ ...propertyDetails });
            }}
          />
          <InputNumber
            label={i18n("Plot area")}
            type={"area"}
            value={propertyDetails.plotSize}
            onChange={(e) => {
              propertyDetails.plotSize = e.target.value;
              setDetails({ ...propertyDetails });
            }}
          />
        </div>
        <div className="flex w-full max-w-[700px] justify-between">
          <InputNumber
            label={i18n("Usable area")}
            type={"area"}
            value={propertyDetails.usableSize}
            onChange={(e) => {
              propertyDetails.usableSize = e.target.value;
              setDetails({ ...propertyDetails });
            }}
          />
          <InputNumber
            label={i18n("Year of construction")}
            type={"none"}
            value={propertyDetails.yearConstructed}
            onChange={(e) => {
              propertyDetails.yearConstructed = e.target.value;
              setDetails({ ...propertyDetails });
            }}
          />
        </div>
      </div>
    );
  };

  const renderEnergyRating = () => {
    return (
      <div className="my-6 flex w-full flex-col gap-3">
        <div>
          <p className="font-medium">{i18n("Energy Rating")}</p>
        </div>
        <div className="flex w-full max-w-[700px] justify-between">
          <div className="w-[260px]">
            <Dropdown
              className="upload-modal-dropdown"
              placeholder={i18n("Select energy rating...")}
              items={ENERGY_RATING_GRADE.map((value) => ({
                label: value,
                value: value,
                onSelect: () => {
                  propertyDetails.energyCertificate = {
                    ...propertyDetails.energyCertificate,
                    consumptionGrade: value,
                  };
                  setDetails({ ...propertyDetails });
                },
              }))}
              label={propertyDetails.energyCertificate?.consumptionGrade}
              selected={propertyDetails.energyCertificate?.consumptionGrade}
            />
          </div>
          <div className="w-[260px]">
            <Dropdown
              className="upload-modal-dropdown"
              placeholder={i18n("Select CO2 emissions...")}
              items={ENERGY_RATING_GRADE.map((value) => ({
                label: value,
                value: value,
                onSelect: () => {
                  propertyDetails.energyCertificate = {
                    ...propertyDetails.energyCertificate,
                    emissionGrade: value,
                  };
                  setDetails({ ...propertyDetails });
                },
              }))}
              label={propertyDetails.energyCertificate?.emissionGrade}
              selected={propertyDetails.energyCertificate?.emissionGrade}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderStepOne = () => {
    return (
      <>
        <div className="mb-6 flex w-full max-w-[700px] items-center justify-between">
          <div className="w-[260px]">
            <p className="mb-1 text-sm">{i18n("Property Subtype*")}</p>
            <Dropdown
              className="upload-modal-dropdown"
              placeholder={i18n("Select property subtype...")}
              items={PROPERTY_TYPE_FILTERS.map((type) => ({
                label: i18n(type.label),
                value: type.code,
                onSelect: () => {
                  propertyDetails.detailedType = {
                    typology: type.code,
                    subTypology: type.code,
                    isVilla: type.code === "villa",
                  };
                  setDetails({ ...propertyDetails });
                },
              }))}
              label={
                PROPERTY_TYPE_FILTERS.find(
                  (type) =>
                    type.code ===
                    propertyDetails.detailedType?.[PATH_DICT[type.path]],
                )?.label
              }
              selected={
                propertyDetails.detailedType?.typology ||
                propertyDetails.detailedType?.subTypology
              }
            />
          </div>
          <div>
            <p className="mb-1 text-sm">{i18n("Agency reference")}</p>
            <div className="h-[48px] w-[260px] max-w-[260px] rounded-lg border border-[#BABABA] p-2">
              <input
                id="property-details-input-number"
                onChange={(e) => {
                  propertyDetails.agencyReference = e.target.value;
                  setDetails({ ...propertyDetails });
                }}
                value={propertyDetails.agencyReference}
              ></input>
            </div>
          </div>
        </div>
        <SectionDivider />
        {renderConstructionSection()}
        <SectionDivider />
        {renderEnergyRating()}
        <SectionDivider />
      </>
    );
  };

  const renderStepTwo = () => {
    return (
      <>
        <div className="my-6 flex w-full flex-col gap-3">
          <div className="flex w-full max-w-[700px] justify-between">
            <InputNumber
              label={i18n("Price*")}
              type={"currency"}
              value={propertyDetails.price}
              onChange={(e) => {
                propertyDetails.price = e.target.value;
                setDetails({ ...propertyDetails });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderStepThree = () => {
    const toggleFilter = (filter) => {
      const filterSet = PROPERTY_FEATURES_FILTERS.find(
        (f) => f.id === filter.id,
      );

      if (filterSet) {
        const currentValue =
          propertyDetails.features[PATH_DICT[filterSet.path]];

        propertyDetails.features = {
          ...propertyDetails.features,
          [PATH_DICT[filterSet.path]]: !currentValue,
        };
        setDetails({ ...propertyDetails });
      }
    };

    return (
      <>
        <div className="my-6 flex w-full flex-col gap-3">
          <div className="flex w-full max-w-[700px] justify-between">
            <div className="w-[260px]">
              <p className="mb-1 text-sm">{i18n("Condition")}</p>
              <Dropdown
                className="upload-modal-dropdown"
                placeholder={i18n("Select condition...")}
                items={PROPERTY_CONDITTION_FILTERS.slice(1).map((type) => ({
                  label: i18n(type.label),
                  value: type.value,
                  onSelect: () => {
                    propertyDetails.status = type.value;
                    setDetails({ ...propertyDetails });
                  },
                }))}
                label={i18n(
                  PROPERTY_CONDITTION_FILTERS.find(
                    (type) => type.value === propertyDetails.status,
                  )?.label,
                )}
                selected={propertyDetails.status}
              />
            </div>
          </div>
          <div className="flex w-full max-w-[700px] flex-col justify-between">
            <SectionDivider />
            <div className="mt-6">
              <p className="mb-3 font-medium">{i18n("Property features")}</p>
              <div className="mb-6 grid grid-cols-2 gap-3">
                {PROPERTY_FEATURES_FILTERS.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => toggleFilter(option)}
                    className="property-filter-panel_section_land-features"
                  >
                    <Checkbox
                      label={i18n(option.label)}
                      checked={propertyDetails.features[PATH_DICT[option.path]]}
                    />
                  </div>
                ))}
              </div>
              <SectionDivider />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} />
      {currentStep === 1 && renderStepOne()}
      {currentStep === 2 && renderStepTwo()}
      {currentStep === 3 && renderStepThree()}
      <InputDetailsFooter
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
      />
    </div>
  );
};

export default InputDetails;
