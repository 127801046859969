import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropertyMap from "../components/map/PropertyMap";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar, Line, Pie } from "react-chartjs-2";
import { i18n } from "i18n/localisation";
import { formatPrice } from "utils/helpers";
import {
  getAveragePricePerSqmHistoricChartData,
  getAverageTimeOnMarketChartData,
  getPriceDistributionChartData,
  getPricePerSqmDistributionChartData,
} from "utils/stats";
import Navbar from "./navbar/Navbar";
import { area } from "@turf/turf";
import { getFilteredProperties } from "lib/filter/filters";
import filterIcon from "../assets/core/outline_filter.svg";
import filterIconHighlighted from "../assets/core/outline_filter_highlighted.svg";
import moment from "moment";
import Dropdown from "./core/Dropdown";
import {
  BUILDING_TYPE_FILTERS,
  FILTER_LABEL_MAPPINGS,
} from "lib/filter/filterTypes";
import { setFilters, setPropertyBuildingType } from "actions/filterActions";
import FilterPanel from "./property/filters/FilterPanel";
import { CSSTransition } from "react-transition-group";
import * as turf from "@turf/turf";
import { searchPolygonToGeoJson } from "utils/polygon";
import { getSubscriptionPlan, userHasRole } from "utils/userHelpers";
import { setPricingModal } from "actions/miscActions";
import Dashboard from "./Dashboard";
import { MOBILE_BREAKPOINT } from "config/constants";
import { setDrawnPolygons } from "actions/polygonActions";
import {
  setFilteredProperties,
  setProperties,
} from "actions/propertiesActions";
import { setCanDrawGeographicPolygons } from "actions/mapActions";
import { shouldDrawGeographicBounds } from "utils/map";
import SpinWheelLoaderAnimation from "./animations/SpinWheelLoaderAnimation";
import MarketInsightsToolbar from "./map/MarketInsightsToolbar";
import { Helmet } from "react-helmet";

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const MapContainer = styled.div`
  flex: 1;
  position: relative;
`;

const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  transition: opacity 0.2s ease-in-out;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
`;

const SidePanel = styled.div`
  width: ${(props) => (props.isExpanded ? "calc(100vw - 80px)" : "600px")};
  background: rgba(255, 255, 255, 0.85);
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  padding: 32px 24px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  right: 0;
  top: 0;
  position: absolute;
  height: 100svh;
  backdrop-filter: blur(10px);
  transition: width 0.3s ease;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100vw;
    padding: 24px 16px;
    top: 58px;
    height: calc(100svh - 58px);
  }
`;

const ExpandButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #666;
  font-size: 16px;

  &:hover {
    color: #333;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const CancelButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #222;
  font-size: 14px;
  display: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
  }
`;

const ChartContainer = styled.div`
  margin: 24px 0 32px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 12px;
`;

const ChartTitle = styled.h3`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;

  span {
    font-size: 12px;
    color: #666;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 20px 0;
`;

const StatCard = styled.div`
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.2s ease;

  &:hover {
    background: #f2f4f8;
  }
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
`;

const StatValue = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #222;
`;

const AreasContainer = styled.div`
  ${(props) =>
    props.isExpanded &&
    `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 600px));
    gap: 24px;
    padding: 0 16px;
    width: 100%;
  `}
`;

const AreaSection = styled.div`
  margin-bottom: ${(props) => (props.isExpanded ? "0" : "48px")};
  padding-bottom: ${(props) => (props.isExpanded ? "0" : "32px")};
  border-bottom: ${(props) =>
    props.isExpanded ? "none" : "2px solid #e0e0e0"};

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const AreaTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #1a1a1a;
  padding-bottom: 8px;
  display: flex;
  gap: 12px;
  border-bottom: 1px solid rgb(215, 215, 215);
  ${(props) =>
    props.isExpanded &&
    `
    grid-column: 1 / -1;
  `}
`;

const StatsAndChartsContainer = styled.div`
  ${(props) =>
    props.isExpanded &&
    `
    display: flex;
    flex-direction: column;
    gap: 24px;
  `}
`;

const MarketInsights = ({
  drawnPolygons,
  properties,
  saleType,
  filters,
  buildingType,
  dispatch,
  fetchingProperties,
  userDataFetched,
  areaSelectionMode,
}) => {
  if (userDataFetched && !userHasRole("market_insights")) {
    window.location.href = "/";
  }

  const [areaStats, setAreaStats] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  const generateBedroomDistributionData = (properties) => {
    const bedroomCounts = properties.reduce((acc, property) => {
      const bedrooms = parseInt(property.rooms) || 0;
      acc[bedrooms] = (acc[bedrooms] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(bedroomCounts).sort((a, b) => parseInt(a) - parseInt(b));
    const data = labels.map(key => bedroomCounts[key]);

    return {
      chartData: {
        labels,
        datasets: [{
          label: i18n('Number of Properties'),
          data,
          backgroundColor: '#f06168',
          borderColor: '#f06168',
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1
            }
          },
          x: {
            title: {
              display: true,
              text: i18n('Number of Bedrooms')
            }
          }
        }
      }
    };
  };

  const generatePropertyTypeDistributionData = (properties) => {
    // Initialize counters for each property type
    const typeCounts = {
      'Flat': 0,
      'Duplex': 0,
      'Penthouse': 0,
      'Detached House': 0,
      'Semi-detached House': 0,
      'Terraced House': 0,
      'Villa': 0
    };
    
    // Count properties by type
    properties.forEach(property => {
      if (!property.detailedType) {
        return;
      }
      
      const { typology, subTypology, isVilla } = property.detailedType;
      
      if (isVilla) {
        typeCounts['Villa']++;
      } else if (typology === 'flat') {
        if (subTypology === 'duplex') {
          typeCounts['Duplex']++;
        } else if (subTypology === 'penthouse') {
          typeCounts['Penthouse']++;
        } else {
          typeCounts['Flat']++;
        }
      } else if (typology === 'house') {
        if (subTypology === 'independantHouse') {
          typeCounts['Detached House']++;
        } else if (subTypology === 'semidetatchedHouse') {
          typeCounts['Semi-detached House']++;
        } else if (subTypology === 'terracedHouse') {
          typeCounts['Terraced House']++;
        }
      }
    });
    
    // Filter out types with zero properties
    const labels = [];
    const data = [];
    
    Object.entries(typeCounts).forEach(([type, count]) => {
      if (count > 0) {
        labels.push(i18n(type));
        data.push(count);
      }
    });
    
    // More harmonious color palette with good contrast
    const backgroundColors = [
      '#f06168', // Brand red (primary)
      '#2c7bb6', // Deep blue
      '#abd9e9', // Light blue
      '#fdae61', // Orange
      '#d7191c', // Dark red
      '#7b3294', // Purple
      '#c2a5cf'  // Light purple
    ];
    
    return {
      chartData: {
        labels,
        datasets: [{
          data,
          backgroundColor: backgroundColors.slice(0, labels.length),
          borderWidth: 1,
          borderColor: '#ffffff'
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              boxWidth: 12,
              padding: 15,
              font: {
                size: 11
              }
            }
          },
          title: {
            display: false
          }
        }
      }
    };
  };

  useEffect(() => {
    const generateStats = async () => {
      const stats = await Promise.all(
        drawnPolygons.map(async (polygon, index) => {
          let polygonCpy = { ...polygon };
          // Filter properties within this polygon
          const polygonProperties = getFilteredProperties().filter(
            (property) => {
              // custom drawn polygons need to be closed
              if (!polygon.metadata) {
                polygonCpy.geometry = [
                  ...polygonCpy.geometry,
                  polygonCpy.geometry[0],
                ];
              }

              const normalizedPolygon = searchPolygonToGeoJson(polygonCpy);
              const coordinates = [...normalizedPolygon.geometry.coordinates];
              const firstRing = coordinates[0];

              // Ensure the first ring is closed
              if (JSON.stringify(firstRing[0]) !== JSON.stringify(firstRing[firstRing.length - 1])) {
                firstRing.push([...firstRing[0]]);
              }
              
              const _polygon = turf.polygon(coordinates);
              const point = turf.point([
                parseFloat(property.longitude),
                parseFloat(property.latitude),
              ]);
              return turf.booleanPointInPolygon(point, _polygon);
            },
          );

          const timeOnMarketData = await getAverageTimeOnMarketChartData(
            null,
            polygonProperties,
          );
          const priceDistributionData = await getPriceDistributionChartData(
            null,
            polygonProperties,
          );
          const pricePerSqmDistributionData =
            await getPricePerSqmDistributionChartData(null, polygonProperties);

          let historicPriceData = null;

          if (polygon.metadata) {
            historicPriceData = await getAveragePricePerSqmHistoricChartData(
              polygon.metadata.id,
            );
          }

          const averagePrice =
            polygonProperties.length > 0
              ? polygonProperties.reduce((sum, prop) => {
                  const price = parseFloat(prop.price);
                  return isNaN(price) ? sum : sum + price;
                }, 0) /
                polygonProperties.filter(
                  (prop) => !isNaN(parseFloat(prop.price)),
                ).length
              : 0;

          const averagePricePerSqm =
            polygonProperties.length > 0
              ? polygonProperties.reduce((sum, prop) => {
                  const price = parseFloat(prop.price);
                  const size = parseFloat(prop.size);
                  const pricePerSqm =
                    !isNaN(price) && !isNaN(size) && size > 0
                      ? price / size
                      : 0;
                  return sum + pricePerSqm;
                }, 0) /
                polygonProperties.filter((prop) => {
                  const price = parseFloat(prop.price);
                  const size = parseFloat(prop.size);
                  return !isNaN(price) && !isNaN(size) && size > 0;
                }).length
              : 0;

          const averageTimeOnMarket =
            polygonProperties.length > 0
              ? polygonProperties.reduce((sum, prop) => {
                  const daysOnMarket = moment().diff(
                    moment.unix(parseFloat(prop.initialImportTime)),
                    "days",
                  );
                  return sum + daysOnMarket;
                }, 0) / polygonProperties.length
              : 0;

          return {
            name: polygon.metadata
              ? polygon.metadata.name
              : i18n("Custom area"),
            propertyCount: polygonProperties.length,
            averagePrice,
            averagePricePerSqm,
            averageTimeOnMarket,
            timeOnMarketData,
            priceDistributionData,
            pricePerSqmDistributionData,
            historicPriceData,
            colourCode: polygon.colourCode,
            bedroomDistributionData: buildingType.value === 'property' ? 
              generateBedroomDistributionData(polygonProperties) : null,
            propertyTypeDistributionData: buildingType.value === 'property' ?
              generatePropertyTypeDistributionData(polygonProperties) : null,
          };
        }),
      );

      setAreaStats(stats);
    };

    generateStats();
  }, [drawnPolygons, properties, filters, buildingType]);

  useEffect(() => {
    const searchBar = document.querySelector(".search-bar-wrapper");

    if (!searchBar) return;

    if (drawnPolygons.length > 0) {
      searchBar.classList.add("left-pinned");
    } else {
      searchBar.classList.remove("left-pinned");
    }
  }, [drawnPolygons]);

  let filtersClassName = "property-panel-header_filters_btn filters-btn";
  let filtersIcon = filterIcon;

  if (filters.length > 0) {
    filtersClassName += " active-filters";
    filtersIcon = filterIconHighlighted;
  }

  const transformedColourAreaStats = areaStats.map((areaStat) => {
    return {
      ...areaStat,
      pricePerSqmDistributionData: areaStat.pricePerSqmDistributionData
        ? {
            ...areaStat.pricePerSqmDistributionData,
            chartData: {
              ...areaStat.pricePerSqmDistributionData.chartData,
              datasets:
                areaStat.pricePerSqmDistributionData.chartData.datasets.map(
                  (dataset) => ({
                    ...dataset,
                    backgroundColor: "#f06168",
                    borderColor: "#f06168",
                  }),
                ),
            },
          }
        : null,

      priceDistributionData: areaStat.priceDistributionData
        ? {
            ...areaStat.priceDistributionData,
            chartData: {
              ...areaStat.priceDistributionData.chartData,
              datasets: areaStat.priceDistributionData.chartData.datasets.map(
                (dataset) => ({
                  ...dataset,
                  backgroundColor: "#f06168",
                  borderColor: "#f06168",
                }),
              ),
            },
          }
        : null,

      timeOnMarketData: areaStat.timeOnMarketData
        ? {
            ...areaStat.timeOnMarketData,
            chartData: {
              ...areaStat.timeOnMarketData.chartData,
              datasets: areaStat.timeOnMarketData.chartData.datasets.map(
                (dataset) => ({
                  ...dataset,
                  backgroundColor: "#f06168",
                  borderColor: "#f06168",
                }),
              ),
            },
          }
        : null,

      propertyTypeDistributionData: areaStat.propertyTypeDistributionData
        ? {
            ...areaStat.propertyTypeDistributionData,
            chartData: {
              ...areaStat.propertyTypeDistributionData.chartData,
            },
          }
        : null,
    };
  });

  const buildingTypesItems =
    saleType === "rent"
      ? BUILDING_TYPE_FILTERS.filter(
          (filter) => filter.value === "property" || filter.value === "room",
        )
      : BUILDING_TYPE_FILTERS.filter((filter) => filter.value !== "room");

  function cancelMarketInsightsSelection() {
    dispatch(setDrawnPolygons([]));
    window.drawnPolygons.forEach((p) => {
      p.setMap(null);
    });
    window.drawnPolygons = [];
    dispatch(setProperties([]));
    dispatch(setFilteredProperties([]));
    dispatch(setFilters([]));
    dispatch(setCanDrawGeographicPolygons(true));
    shouldDrawGeographicBounds(true);
  }

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("Market Insights")}</title>
      </Helmet>
      <PageContainer>
        <MapContainer>
          <PropertyMap 
            includeSearch 
            page="marketInsights" 
            disableDropdown 
          />
          <MarketInsightsToolbar />
        </MapContainer>

        {fetchingProperties && (
          <SidePanel isExpanded={false}>
            <div className="flex h-full flex-col items-center justify-center px-8 text-center">
              <p className="text-gray-600">{i18n("Loading data...")}</p>
              <SpinWheelLoaderAnimation />
            </div>
          </SidePanel>
        )}

        {areaStats.length > 0 &&
          !fetchingProperties &&
          !areaSelectionMode && (
            <SidePanel isExpanded={isExpanded}>
              <div className="market-insights_filters-container mb-12 ml-3 flex items-center justify-end gap-4">
                <div className="max-w-[150px] flex-1">
                  <Dropdown
                    className="sort-dropdown"
                    items={buildingTypesItems.map((filter, index) => ({
                      label: FILTER_LABEL_MAPPINGS[filter.label](),
                      value: index,
                      selectedIds: [buildingType.id],
                      onSelect: () => {
                        dispatch(setPropertyBuildingType(filter));
                      },
                    }))}
                    label={FILTER_LABEL_MAPPINGS[buildingType.label]()}
                  />
                </div>
                <div className="max-w-[150px] flex-1">
                  <div
                    onClick={() => setFilterPanelOpen(true)}
                    className={filtersClassName}
                  >
                    {filters.length > 0 && (
                      <div className="property-panel-header_filters_bubble">
                        <span>{filters.length}</span>
                      </div>
                    )}
                    <img src={filtersIcon} alt="Filter Icon" />
                    <span>{i18n("Filters")}</span>
                  </div>
                </div>
                <div className="flex-1"></div>
                <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? "-> " + i18n("Collapse") : "<- " + i18n("Expand")}
                </ExpandButton>
                <CancelButton onClick={cancelMarketInsightsSelection}>
                  {i18n("Close")}
                </CancelButton>
              </div>
              <AreasContainer isExpanded={isExpanded}>
                {areaStats.map((areaStat, index) => (
                  <AreaSection key={index} isExpanded={isExpanded}>
                    <AreaTitle isExpanded={isExpanded}>
                      <span>{areaStat.name}</span>
                      <div className="flex items-center gap-2">
                        <div
                          className="h-3 w-3 flex-shrink-0 rounded-full"
                          style={{ backgroundColor: areaStat.colourCode }}
                        ></div>
                      </div>
                    </AreaTitle>

                    <StatsAndChartsContainer isExpanded={isExpanded}>
                      <StatsGrid>
                        <StatCard>
                          <StatLabel>{i18n("Properties")}</StatLabel>
                          <StatValue>{areaStat.propertyCount}</StatValue>
                        </StatCard>
                        <StatCard>
                          <StatLabel>{i18n("Average Price")}</StatLabel>
                          <StatValue>
                            {formatPrice(Math.round(areaStat.averagePrice))}
                          </StatValue>
                        </StatCard>
                        {saleType !== "rent" && (
                          <StatCard>
                            <StatLabel>{i18n("Average Price/m²")}</StatLabel>
                            <StatValue>
                              {formatPrice(
                                Math.round(areaStat.averagePricePerSqm),
                              )}
                            </StatValue>
                          </StatCard>
                        )}
                        {saleType === "sale" && (
                          <StatCard>
                            <StatLabel>{i18n("Avg. Days on Market")}</StatLabel>
                            <StatValue>
                              {Math.round(areaStat.averageTimeOnMarket)} days
                            </StatValue>
                          </StatCard>
                        )}
                      </StatsGrid>

                      {transformedColourAreaStats[index]
                        .priceDistributionData && (
                        <ChartContainer>
                          <ChartTitle>{i18n("Price Distribution")} <span>({areaStat.name})</span></ChartTitle>
                          <Bar
                            data={
                              transformedColourAreaStats[index]
                                .priceDistributionData.chartData
                            }
                            options={
                              transformedColourAreaStats[index]
                                .priceDistributionData.options
                            }
                          />
                        </ChartContainer>
                      )}

                      {transformedColourAreaStats[index]
                        .pricePerSqmDistributionData &&
                        saleType !== "rent" && (
                          <ChartContainer>
                            <ChartTitle>
                              {i18n("Price/m² Distribution")} <span>({areaStat.name})</span>
                            </ChartTitle>
                            <Bar
                              data={
                                transformedColourAreaStats[index]
                                  .pricePerSqmDistributionData.chartData
                              }
                              options={
                                transformedColourAreaStats[index]
                                  .pricePerSqmDistributionData.options
                              }
                            />
                          </ChartContainer>
                        )}
                    </StatsAndChartsContainer>

                    {saleType === "sale" && (
                      <StatsAndChartsContainer isExpanded={isExpanded}>
                        {transformedColourAreaStats[index].timeOnMarketData && (
                          <ChartContainer>
                            <ChartTitle>
                              {i18n("Time on Market by Price Range")} <span>({areaStat.name})</span>
                            </ChartTitle>
                            <Bar
                              data={
                                transformedColourAreaStats[index]
                                  .timeOnMarketData.chartData
                              }
                              options={
                                transformedColourAreaStats[index]
                                  .timeOnMarketData.options
                              }
                            />
                          </ChartContainer>
                        )}
                      </StatsAndChartsContainer>
                    )}

                    {buildingType.value === 'property' && saleType !== 'sold' && transformedColourAreaStats[index].bedroomDistributionData && (
                      <ChartContainer>
                        <ChartTitle>{i18n("Bedroom Distribution")} <span>({areaStat.name})</span></ChartTitle>
                        <Bar
                          data={transformedColourAreaStats[index].bedroomDistributionData.chartData}
                          options={transformedColourAreaStats[index].bedroomDistributionData.options}
                        />
                      </ChartContainer>
                    )}

                    {buildingType.value === 'property' && transformedColourAreaStats[index].propertyTypeDistributionData && (
                      <ChartContainer>
                        <ChartTitle>{i18n("Property Type Distribution")} <span>({areaStat.name})</span></ChartTitle>
                        <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '90%', height: '90%', position: 'relative' }}>
                            <Pie
                              data={transformedColourAreaStats[index].propertyTypeDistributionData.chartData}
                              options={transformedColourAreaStats[index].propertyTypeDistributionData.options}
                            />
                          </div>
                        </div>
                      </ChartContainer>
                    )}

                    {transformedColourAreaStats[index].historicPriceData && (
                      <ChartContainer>
                        <ChartTitle>{i18n("Average Price/m²")} <span>({areaStat.name})</span></ChartTitle>
                        <Line
                          data={
                            transformedColourAreaStats[index].historicPriceData
                              .chartData
                          }
                          options={
                            transformedColourAreaStats[index].historicPriceData
                              .options
                          }
                        />
                      </ChartContainer>
                    )}
                  </AreaSection>
                ))}
              </AreasContainer>
            </SidePanel>
          )}

        <CSSTransition in={filterPanelOpen} timeout={200} classNames="slide-in">
          <FilterPanel toggleFilterMode={() => setFilterPanelOpen(false)} />
        </CSSTransition>
      </PageContainer>
    </Dashboard>
  );
};

export default connect((state) => ({
  drawnPolygons: state.polygon.drawnPolygons,
  properties: state.property.properties,
  saleType: state.filters.saleType,
  filters: state.filters.filters,
  buildingType: state.filters.buildingType,
  userData: state.user.userData,
  subscription: state.auth.subscription,
  fetchingProperties: state.property.fetchingProperties,
  userDataFetched: state.user.userDataFetched,
  areaSelectionMode: state.map.areaSelectionMode,
}))(MarketInsights);
