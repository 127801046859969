import { addAgencyFilter, removeAgencyFilter, setFilteredAgencies } from "actions/agencyActions";
import store from "store";

// filter the agencies based on the filters
export function filterAgencies() {
  const state = store.getState();
  const agencies = state.agency.agencies;
  const filters = state.agency.agencyFilters;

  // If no filters are applied, return all agencies
  if (filters.length === 0) {
    store.dispatch(setFilteredAgencies(agencies));
    return;
  }

  const filteredAgencies = agencies.filter(agency => {
    return filters.every(filter => {
      if (filter.type === "name") {
        return agency.name.toLowerCase().includes(filter.value.toLowerCase());
      }

      if (filter.type === "address") {
        return agency.address.toLowerCase().includes(filter.value.toLowerCase());
      }

      if (filter.type === "phone") {
        return agency.phone.toLowerCase().includes(filter.value.toLowerCase());
      }

      if (filter.type === "email") {
        return agency.email.toLowerCase().includes(filter.value.toLowerCase());
      }

      if (filter.type === "rating") {
        return parseInt(agency.rating) >= parseInt(filter.value);
      }
    })
  });

  store.dispatch(setFilteredAgencies(filteredAgencies));
}

// helper to add a filter and update the filtered agencies
export function addAgencyFilterUpdate(filter) {
  store.dispatch(addAgencyFilter(filter));
  filterAgencies();
}

// helper to remove a filter and update the filtered agencies
export function removeAgencyFilterUpdate(filter) {
  store.dispatch(removeAgencyFilter(filter));
  filterAgencies();
}
