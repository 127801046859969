import Dashboard from "components/Dashboard";
import { getLang } from "i18n/localisation";
import { useEffect, useState } from "react"
import { connect } from "react-redux";
import "./webValuator.scss";

// for testing lead magnet (web valuator) on a page
function QuickValuator({ teamId }) {
  // Function to get query parameters from URL
  const getQueryParam = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  };

  const [loading, setLoading] = useState(true);
  const lang = getQueryParam('lang') ?? getLang().code;
  const primaryColor = getQueryParam('primaryColor') ?? '#222222';
  const secondaryColor = getQueryParam('secondaryColor') ?? '#D1D1D1';

  useEffect(() => {
    if (!teamId) return;

    let scriptUrl = 'https://irealty-lead-magnet-dev.s3.eu-west-2.amazonaws.com/lead-magnet.js';
    let stylesUrl = 'https://irealty-lead-magnet-dev.s3.eu-west-2.amazonaws.com/lead-magnet.css';

    if (process.env.REACT_APP_NODE_ENV === "production") {
      scriptUrl = 'https://irealty-lead-magnet.s3.eu-west-2.amazonaws.com/lead-magnet.js';
      stylesUrl = 'https://irealty-lead-magnet.s3.eu-west-2.amazonaws.com/lead-magnet.css';
    }

    const script = document.createElement('script');
    script.src = scriptUrl;
    document.body.appendChild(script);

    const styles = document.createElement('link');
    styles.href = stylesUrl;
    styles.rel = 'stylesheet';
    document.head.appendChild(styles);

    setTimeout(() => {
      window.initLeadMagnet('lead-magnet-container', {
        lang,
        primaryColor,
        secondaryColor,
        clientId: teamId
      });
      setLoading(false);
    }, 2000);
  }, [teamId]);

  return (
    <Dashboard>
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900 dark:border-white"></div>
        </div>
      )}
      <div className="mt-12"></div>
      <div id="lead-magnet-container"></div>
    </Dashboard>
  );
}

export default connect(state => ({
  teamId: state.user.userData?.teamId,
}))(QuickValuator);