import { setSaleType } from "actions/filterActions";
import { setMarketInsightsMode } from "actions/mapActions";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import { MARKET_INSIGHTS_MODES } from "reducers/mapReducer";

function SaleTypeToggle(props) {
  const { saleType, dispatch, onSaleTypeChange, cmaMode } = props;

  const _onSaleTypeChange = (saleType) => {
    // if we are on the market insights page, we need to set the mode to the correct one
    // based on sale type
    if (window.location.pathname.includes("/market-insights")) {
      if (saleType === "rent") {
        dispatch(setMarketInsightsMode(MARKET_INSIGHTS_MODES.AVG_PRICE));
      } else if (saleType === "sold") {
        dispatch(setMarketInsightsMode(MARKET_INSIGHTS_MODES.PRICE_PER_M2));
      }
    }

    if (onSaleTypeChange) {
      onSaleTypeChange(saleType);
    } else {
      dispatch(setSaleType(saleType));
    }
  };

  return (
    <div className={`search-bar_toggle-content ${cmaMode ? "cma-mode" : ""}`}>
      <div
        onClick={() => _onSaleTypeChange("sale")}
        className={`search-bar_toggle-btn sale ${saleType === "sale" && "selected"}`}
      >
        <span>{i18n("Sale")}</span>
      </div>
      <div className="vertical-divider"></div>
      <div
        onClick={() => _onSaleTypeChange("rent")}
        className={`search-bar_toggle-btn rent ${saleType === "rent" && "selected"}`}
      >
        <span>{i18n("Rent")}</span>
      </div>
      <div className="vertical-divider"></div>
      {!cmaMode && (
        <div
          onClick={() => _onSaleTypeChange("sold")}
          className={`search-bar_toggle-btn sold ${saleType === "sold" && "selected"}`}
        >
          <span>{i18n("Sold")}</span>
        </div>
      )}
    </div>
  );
}

export default connect((state) => ({
  saleType: state.filters.saleType,
}))(SaleTypeToggle);
