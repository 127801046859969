import {
  setCanDrawGeographicPolygons,
  setHideBounds,
} from "actions/mapActions";
import { i18n } from "i18n/localisation";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { clearAllDrawnGeographicPolygons, disableAreaClickability, enableAreaClickability, resetSearch, shouldDrawGeographicBounds } from "utils/map";
import { saveUserData } from "../../actions/userActions";
import closeIcon from "../../assets/core/close.svg";
import closeInactiveIcon from "../../assets/core/close_inactive.svg";
import minusIcon from "../../assets/core/minus.svg";
import plusIcon from "../../assets/core/plus.svg";
import handIcon from "../../assets/map/hand.svg";
import handIconSelected from "../../assets/map/hand_selected.svg";
import polygonIcon from "../../assets/map/polygon.svg";
import polygonIconSelected from "../../assets/map/polygon_selected.svg";
import trashIcon from "../../assets/map/trash.svg";
import addAreaIcon from "../../assets/map/add_area.svg";
import resetIcon from "../../assets/core/reset.svg";
import { getMunicipalityByMunCode } from "db/mapStore";
import { setFilters } from "actions/filterActions";
import { setDrawnPolygons } from "actions/polygonActions";
import { setFilteredProperties, setProperties } from "actions/propertiesActions";
import { setAgencies, setFilteredAgencies } from "actions/agencyActions";

const MapToolbar = (props) => {
  const addAreaMode = props.map.canDrawGeographicPolygons;

  const onMapTypeChange = () => {
    let preferences = Object.assign({}, props.user.userData.preferences);
    let mapType = "hybrid";

    if (
      !props.user.userData.preferences.mapType ||
      props.user.userData.preferences.mapType === "hybrid"
    ) {
      mapType = "roadmap";
    } else {
      mapType = "hybrid";
    }

    preferences.mapType = mapType;
    props.dispatch(saveUserData({ preferences }));
    props.onMapTypeChange(mapType);
  };

  // reset filters, drawn polygons, properties, agencies, filtered properties, filtered agencies
  const onResetSearch = () => {
    resetSearch();
    props.shouldDrawGeographicBounds(props.handleMunicipalityClick);
  };

  const onPolygonClick = () => {
    disableAreaClickability();
    window.googleMapsDrawingManager.setDrawingMode("polygon");
    props.setDrawingMode(true);

    if (!props.map.areaSelectionMode) {
      props.dispatch(setCanDrawGeographicPolygons(false));
    }
  };

  const onHandClick = () => {
    enableAreaClickability();
    window.googleMapsDrawingManager.setDrawingMode(null);
    props.setDrawingMode(false);

    if (!props.map.areaSelectionMode) {
      props.dispatch(setCanDrawGeographicPolygons(true));
      props.shouldDrawGeographicBounds(props.handleMunicipalityClick);
    }
  };

  const onAddAreaClick = () => {
    if (addAreaMode) {
      props.dispatch(setCanDrawGeographicPolygons(false));
      clearAllDrawnGeographicPolygons();
    } else {
      props.dispatch(setCanDrawGeographicPolygons(true));
      props.shouldDrawGeographicBounds(props.handleMunicipalityClick);

      // if all polygons belong to the same municipality, focus on that municipality
      if (props.polygon.drawnPolygons.length > 0) {
        let munCode = null;
        let allSameMunCode = props.polygon.drawnPolygons.every((polygon) => {
          if (polygon.metadata && polygon.metadata.mun_code) {
            // dont focus on a municipality if we have a municipality selected
            if (props.polygon.drawnPolygons[0].metadata && props.polygon.drawnPolygons[0].metadata.mun_code) {
              if (props.polygon.drawnPolygons.some((polygon) => polygon.metadata && polygon.metadata.type === "municipality")) {
                return false;
              }

              if (polygon.metadata.mun_code === props.polygon.drawnPolygons[0].metadata.mun_code) {
                munCode = polygon.metadata.mun_code;
                return true;
              } else {
                return false;
              }
            }
          }
        });

        if (allSameMunCode && munCode) {
          getMunicipalityByMunCode('es', munCode).then((municipality) => {
            props.handleMunicipalityClick(municipality);
          });
        }
      }
    }
  };

  return (
    <div
      className={
        "map-toolbar-container" +
        (props.page === "cma" ? " with-side-panel" : "")
      }
    >
      <div className="map-toolbar">
        <div
          onClick={onHandClick}
          className={
            "map-toolbar_item" + (props.drawingMode ? "" : " selected")
          }
          data-tooltip-id="drag-tool-tooltip"
          data-tooltip-content={i18n("Drag")}
        >
          {props.drawingMode ? (
            <img draggable={false} src={handIcon} alt="hand icon" />
          ) : (
            <img draggable={false} src={handIconSelected} alt="hand icon" />
          )}
        </div>
        <div
          onClick={onPolygonClick}
          className={
            "map-toolbar_item" + (props.drawingMode ? " selected" : "")
          }
          data-tooltip-id="draw-polygon-tooltip"
          data-tooltip-content={i18n("Draw polygon")}
        >
          {props.drawingMode ? (
            <img
              draggable={false}
              src={polygonIconSelected}
              alt="polygon icon"
            />
          ) : (
            <img draggable={false} src={polygonIcon} alt="polygon icon" />
          )}
        </div>
        <div
          className={`map-toolbar_item ${props.polygon.selectedPolygons.length > 0 ? "opacity-100" : "opacity-50"}`}
          data-tooltip-id="delete-polygon-tooltip"
          data-tooltip-content={i18n("Delete polygon")}
          onClick={() => {
            if (props.polygon.selectedPolygons.length > 0) {
              props.deletePolygon(props.polygon.selectedPolygons[0]);
            }
          }}
        >
          <img
            draggable={false}
            src={trashIcon}
            alt="trash icon"
          />
        </div>
        {props.page !== "cma" && (
          <>
            <div className="map-toolbar_divider"></div>
            <div
              onClick={onResetSearch}
              data-tooltip-id="reset-search-tooltip"
              data-tooltip-content={i18n("Reset search")}
              className={`map-toolbar_item ${props.polygon.drawnPolygons.length > 0 ? "opacity-100" : "opacity-50"}`}
            >
              <img
                draggable={false}
                src={resetIcon}
                alt="reset icon"
              />
            </div>
          </>
        )}
        <div className="map-toolbar_zoom-controls">
          <div className="map-toolbar_divider"></div>
          <div onClick={() => props.zoomBy(1)} className="map-toolbar_item">
            <img draggable={false} src={plusIcon} alt="Plus icon" />
          </div>
          <div onClick={() => props.zoomBy(-1)} className="map-toolbar_item">
            <img draggable={false} src={minusIcon} alt="Minus icon" />
          </div>
        </div>
        {props.polygon.drawnPolygons.length > 0 && props.page !== "cma" && (
          <>
            <div className="map-toolbar_divider"></div>
            <div
              onClick={onAddAreaClick}
              className={`map-toolbar_item ${addAreaMode ? "selected" : ""}`}
              data-tooltip-id="add-area-tooltip"
              data-tooltip-content={i18n("Add area")}
            >
              <img
                draggable={false}
                src={addAreaIcon}
                alt="add area icon"
              />
              <span>{i18n("Add area")}</span>
            </div>
          </>
        )}
      </div>
      <Tooltip id="clear-tooltip" />
      <Tooltip id="draw-polygon-tooltip" />
      <Tooltip id="drag-tool-tooltip" />
      <Tooltip id="add-area-tooltip" />
      <Tooltip id="delete-polygon-tooltip" />
      <Tooltip id="reset-search-tooltip" />
    </div>
  );
};

export default connect((state) => ({
  user: state.user,
  map: state.map,
  polygon: state.polygon,
}))(MapToolbar);
