import { createReducer } from "@reduxjs/toolkit";
import { 
  setLeads, 
  setFetchingLeads, 
  setFetchedLeads,
  setCurrentLeadPage,
  resetLeadState
} from "../actions/leadActions";

const initialState = {
  leadsResult: {},
  fetching: false,
  fetched: false,
  currentLeadPage: 1,
};

const leadReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLeads, (state, action) => {
    state.leadsResult = action.payload;
  });
  builder.addCase(setFetchingLeads, (state, action) => {
    state.fetching = action.payload;
  });
  builder.addCase(setFetchedLeads, (state, action) => {
    state.fetched = action.payload;
  });
  builder.addCase(setCurrentLeadPage, (state, action) => {
    state.currentLeadPage = action.payload;
  });
  builder.addCase(resetLeadState, (state, action) => {
    state.leadsResult = {};
    state.fetching = false;
    state.fetched = false;
    state.currentLeadPage = 1;
  });
});

export default leadReducer;
