import { createAction } from "@reduxjs/toolkit";

const setCmaProperty = createAction("cma/setCmaProperty");
const setIsPDFGenerated = createAction("cma/setIsPDFGenerated");
const setTempFullRef = createAction("cma/setTempFullRef");
const setCmaSearchResults = createAction("cma/setCmaSearchResults");
const setManualValuationMode = createAction("cma/setManualValuationMode");
const setCmaStage = createAction("cma/setCmaStage");

export {
  setCmaProperty,
  setIsPDFGenerated,
  setTempFullRef,
  setCmaSearchResults,
  setManualValuationMode,
  setCmaStage
};
