import { createAction } from "@reduxjs/toolkit";
import { createLeadRequest, deleteLeadRequest, getLeadsRequest, updateLeadRequest } from "api/leads";
import { i18n } from "i18n/localisation";
import { toast } from "sonner";

const setLeads = createAction("lead/setLeads");
const setFetchingLeads = createAction("lead/setFetchingLeads");
const setFetchedLeads = createAction("lead/setFetchedLeads");
const setCurrentLeadPage = createAction("lead/setCurrentLeadPage");
const resetLeadState = createAction("lead/resetLeadState");

function fetchLeads(page, searchQuery = null) {
  return async (dispatch, getState) => {
    const { teamId } = getState().user.userData;
    dispatch(setFetchingLeads(true));
    const leads = await getLeadsRequest(page, teamId, searchQuery);
    dispatch(setLeads(leads));
    dispatch(setFetchingLeads(false));
  };
}

function deleteLead(leadId) {
  return async (dispatch, getState) => {
    dispatch(setFetchingLeads(true));
    const { teamId } = getState().user.userData;
    await deleteLeadRequest(leadId);
    const currentPage = getState().lead.currentLeadPage;
    dispatch(fetchLeads(currentPage));
    toast.success(i18n("Lead deleted successfully"));
  };
}

function updateLead(leadId, lead) {
  return async (dispatch, getState) => {
    dispatch(setFetchingLeads(true));
    const { teamId } = getState().user.userData;
    await updateLeadRequest(leadId, lead);
    const currentPage = getState().lead.currentLeadPage;
    dispatch(fetchLeads(currentPage));
    toast.success(i18n("Lead updated successfully"));
  };
}

function createLead(lead) {
  return async (dispatch, getState) => {
    dispatch(setFetchingLeads(true));
    const { teamId } = getState().user.userData;
    await createLeadRequest(lead);
    const currentPage = getState().lead.currentLeadPage;
    dispatch(fetchLeads(currentPage));
    toast.success(i18n("Lead created successfully"));
  };
}

export {
  setLeads,
  setFetchingLeads,
  setFetchedLeads,
  fetchLeads,
  deleteLead,
  updateLead,
  createLead,
  setCurrentLeadPage,
  resetLeadState,
};
