import { Marker } from "@react-google-maps/api";
import styled from "styled-components";
import MapOverlay from "./MapOverlay";

// Styled Components - Reordered
const AgencyTooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  margin-bottom: 8px;
  z-index: 1000;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

const MarkerContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.isSelected ? '#ee3943' : '#ffffff'};
  border: 2px solid ${props => props.isSelected ? '#ee3943' : '#dddddd'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
    ${AgencyTooltip} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const AgencyLogo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`;

const AgencyInitial = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.isSelected ? "white" : "#333"};
  text-transform: uppercase;
`;

const AgencyName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
`;

const AgencyAddress = styled.div`
  font-size: 12px;
  color: #666;
`;

const ClusterContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2196F3;
  border: 2px solid #1976D2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ClusterCount = styled.span`
  color: white;
  font-size: 14px;
  font-weight: bold;
`;

const AgencyMarker = ({
  position,
  agency,
  cluster,
  clusterAgencies,
  onClusterClick,
  isSelected,
  selectMarker,
  map,
}) => {
  if (!map) {
    return null;
  }

  const isCluster = cluster.properties.cluster;

  // Handle marker click
  const handleClick = (e) => {
    e.stopPropagation();

    if (isCluster) {
      onClusterClick(cluster);
    } else {
      selectMarker();
    }
  };

  // Render cluster marker
  if (isCluster) {
    return (
      <MapOverlay
        position={position}
        map={map}
      >
        <ClusterContainer onClick={handleClick}>
          <ClusterCount>{clusterAgencies.length}</ClusterCount>
        </ClusterContainer>
      </MapOverlay>
    );
  }

  // Render single agency marker
  return (
    <MapOverlay
      position={position}
      map={map}
    >
      <MarkerContainer onClick={handleClick} isSelected={isSelected}>
        <AgencyInitial isSelected={isSelected}>{agency.name.charAt(0)}</AgencyInitial>
        <AgencyTooltip>
          <AgencyName isSelected={isSelected}>{agency.name}</AgencyName>
          {agency.address && <AgencyAddress>{agency.address}</AgencyAddress>}
        </AgencyTooltip>
      </MarkerContainer>
    </MapOverlay>
  );
};

export default AgencyMarker; 