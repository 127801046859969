import React, { useState } from 'react';
import styled from 'styled-components';
// Import icons (you can use React Icons instead of Material icons)
import { FaDollarSign, FaRulerCombined, FaBed, FaLayerGroup, FaClock } from 'react-icons/fa';
import { connect } from 'react-redux';
import { setMarketInsightsMode } from 'actions/mapActions';
import { MARKET_INSIGHTS_MODES } from 'reducers/mapReducer';
import { i18n } from 'i18n/localisation';

// Styled Components
const ToolbarContainer = styled.div`
  position: absolute;
  bottom: 80px;
  left: 112px;
  z-index: 1;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 4px;
  display: flex;
  flex-direction: column;
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: ${props => props.active ? '#e0e0e0' : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  margin: 2px 0;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: ${props => props.active ? '#e0e0e0' : '#f5f5f5'};
  }
  
  svg {
    font-size: 20px;
    color: ${props => props.active ? '#1976d2' : '#757575'};
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  
  &:hover::before {
    content: "${props => props.title}";
    position: absolute;
    left: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
  }
`;

const MarketInsightsToolbar = ({ mode, dispatch, saleType }) => {
  const handleModeChange = (newMode) => {
    dispatch(setMarketInsightsMode(newMode));
  };

  return (
    <ToolbarContainer>
      {
        saleType !== "rent" && (
          <TooltipWrapper title={i18n("Avg price per m²")}>
            <ToggleButton
              active={mode === MARKET_INSIGHTS_MODES.PRICE_PER_M2}
              onClick={() => handleModeChange(MARKET_INSIGHTS_MODES.PRICE_PER_M2)}
              aria-label="price per square meter"
            >
              <FaRulerCombined />
            </ToggleButton>
          </TooltipWrapper>
        )
      }

      <TooltipWrapper title={i18n("Avg price")}>
        <ToggleButton
          active={mode === MARKET_INSIGHTS_MODES.AVG_PRICE}
          onClick={() => handleModeChange(MARKET_INSIGHTS_MODES.AVG_PRICE)}
          aria-label="average price"
        >
          <FaDollarSign />
        </ToggleButton>
      </TooltipWrapper>

      {
        saleType !== "sold" && (
          <TooltipWrapper title={i18n("Avg bedrooms")}>
            <ToggleButton
              active={mode === MARKET_INSIGHTS_MODES.AVG_BEDROOMS}
              onClick={() => handleModeChange(MARKET_INSIGHTS_MODES.AVG_BEDROOMS)}
              aria-label="average bedrooms"
            >
              <FaBed />
            </ToggleButton>
          </TooltipWrapper>
        )
      }

      <TooltipWrapper title={i18n("Property density")}>
        <ToggleButton
          active={mode === MARKET_INSIGHTS_MODES.DENSITY}
          onClick={() => handleModeChange(MARKET_INSIGHTS_MODES.DENSITY)}
          aria-label="property density"
        >
          <FaLayerGroup />
        </ToggleButton>
      </TooltipWrapper>

      {
        saleType === "sale" && (
          <TooltipWrapper title={i18n("Avg days on market")}>
            <ToggleButton
              active={mode === MARKET_INSIGHTS_MODES.DAYS_ON_MARKET}
              onClick={() => handleModeChange(MARKET_INSIGHTS_MODES.DAYS_ON_MARKET)}
              aria-label="days on market"
            >
              <FaClock />
            </ToggleButton>
          </TooltipWrapper>
        )
      }
    </ToolbarContainer>
  );
};

export default connect(state => ({
  mode: state.map.marketInsightsMode,
  saleType: state.filters.saleType,
}))(MarketInsightsToolbar);
