import { setSelectedAgency } from 'actions/agencyActions';
import { i18n } from 'i18n/localisation';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const Card = styled.div`
  height: 276px;
  cursor: pointer;
  background: white;
  border-radius: 6px;
  padding: 22px;
  margin: 12px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.06);
  transition: all 0.25s ease;
  border: ${props => props.isSelected ? '2px' : '1px'} solid ${props => props.isSelected ? '#ee3943' : 'rgba(0, 0, 0, 0.03)'};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  }
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 16px;
  border: 2px solid #f0f0f0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AgencyName = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 4px 0;
  letter-spacing: -0.01em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Category = styled.p`
  font-size: 0.75rem;
  color: #777;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
  position: relative;
  padding-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 36px;
    height: 2px;
    background-color: #f0f0f0;
  }
`;

const ContactInfo = styled.div`
  font-size: 0.85rem;
  color: #444;
  flex: 1;
  overflow: hidden;
  margin-top: 6px;
  
  a {
    margin: 6px 0;
    display: flex;
    align-items: flex-start;
    gap: 0px;
  }
`;

const WebsiteLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  background-color: #4a90e2;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.85rem;
  transition: all 0.2s ease;
  border: none;
  margin-top: auto;
  
  &:hover {
    background-color: #3a7bc8;
    box-shadow: 0 2px 4px rgba(74, 144, 226, 0.2);
  }
`;

const Address = styled.a`
  font-size: 0.82rem;
  color: #444;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  
  span {
    color: #777;
    font-size: 0.9rem;
    flex-shrink: 0;
    margin-right: 8px;
  }
  
  &:hover {
    color: #2563eb;
    text-decoration: underline;
  }
`;

const Phone = styled.a`
  color: #2563eb;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.82rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  display: flex;
  align-items: center;
  
  span {
    color: #777;
    font-size: 0.9rem;
    flex-shrink: 0;
    margin-right: 8px;
  }
  
  &:hover {
    color: #1d4ed8;
    text-decoration: underline;
  }
`;

const Email = styled.a`
  color: #2563eb;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.82rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  display: flex;
  align-items: center;
  
  span {
    color: #777;
    font-size: 0.9rem;
    flex-shrink: 0;
    margin-right: 8px;
  }
  
  &:hover {
    color: #1d4ed8;
    text-decoration: underline;
  }
`;

const Rating = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 8px;
  align-items: center;
`;

const Star = styled.span`
  color: ${props => props.filled ? '#FFD700' : '#e0e0e0'};
  font-size: 1rem;
  margin-right: 2px;
`;

const ReviewCount = styled.span`
  font-size: 0.8rem;
  color: #777;
  margin-left: 6px;
`;

const AgencyCard = ({ agency, isSelected, onClick }) => {
  const dispatch = useDispatch();
  let rating = 0;
  let reviewCount = 0;

  if (agency.rating != 'nan') {
    rating = parseInt(agency.rating);
  }

  if (agency.reviews_count != 'nan') {
    reviewCount = parseInt(agency.reviews_count);
  }

  // Function to create a Google Maps URL from an address
  const getGoogleMapsUrl = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  // Function to format phone number for tel protocol
  const formatPhoneForTel = (phone) => {
    // Remove all non-digit characters
    return phone.replace(/\D/g, '');
  };

  const onCardSelect = () => {
    dispatch(setSelectedAgency(agency.id));
    window.googleMapsInstance.panTo({
      lat: parseFloat(agency.latitude),
      lng: parseFloat(agency.longitude)
    });
    window.googleMapsInstance.setZoom(18);
    setTimeout(() => {
      window.googleMapsInstance.panBy(150, 0);
    }, 100);
  };

  // Prevent event propagation when clicking on the website link
  const handleWebsiteLinkClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Card 
      onClick={onCardSelect} 
      isSelected={isSelected}
    >
      {/* <Logo>
        <img 
          src={agency.logo || 'https://via.placeholder.com/80'} 
          alt={`${agency.name} logo`}
          onError={(e) => {
            e.target.src = 'https://via.placeholder.com/80';
          }}
        />
      </Logo>
       */}
      <AgencyName title={agency.name}>{agency.name}</AgencyName>
      <Category title={agency.category}>{agency.category}</Category>
      
      <ContactInfo>
        <Address 
          href={null}
          title={agency.address}
        >
          <span>📍</span> {agency.address}
        </Address>
        <Phone 
          href={`tel:${formatPhoneForTel(agency.phone)}`}
          title={agency.phone}
        >
          <span>📞</span> {agency.phone}
        </Phone>
        {agency.email && (
          <Email 
            href={`mailto:${agency.email}`}
            title={agency.email}
          >
            <span>📧</span> {agency.email}
          </Email>
        )}

      </ContactInfo>
      
      <Rating>
        {[...Array(5)].map((_, index) => (
          <Star 
            key={index}
            filled={index < rating}
          >
            ★
          </Star>
        ))}
        <ReviewCount>
          ({reviewCount} {reviewCount === 1 ? i18n('review') : i18n('reviews')})
        </ReviewCount>
      </Rating>

      <WebsiteLink 
        href={agency.url} 
        target="_blank" 
        rel="noopener noreferrer"
        onClick={handleWebsiteLinkClick}
      >
        {i18n("Visit website")}
      </WebsiteLink>
    </Card>
  );
};

export default AgencyCard; 