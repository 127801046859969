import { GoogleMap, DrawingManager } from "@react-google-maps/api";
import { useState } from "react";
import Supercluster from "supercluster";
import { mapConfig, mapStyles, polygonColours } from "config/map";
import AgencyMarker from "./AgencyMarker.js";
import { useDispatch } from "react-redux";
import { setSelectedAgency } from "actions/agencyActions";
import { shouldDrawGeographicBounds } from "utils/map.js";
import { MOBILE_BREAKPOINT } from "config/constants.js";

const AgencyGoogleMapWrapper = ({
  setMap,
  map,
  agencies,
  selectedAgency,
  onMapClick,
  handlePolygonComplete,
  setDrawingManager,
  drawingMode,
  handleMunicipalityClick,
  overlay,
  overlayClickable,
  overlayBackground,
}) => {
  const dispatch = useDispatch();
  const [bounds, setBounds] = useState([0, 0, 0, 0]);
  const [zoom, setZoom] = useState(0);
  const device = window.innerWidth < MOBILE_BREAKPOINT ? "mobile" : "desktop";

  // Configure clustering for agencies
  const sc = new Supercluster({ radius: 190, maxZoom: 20 });
  sc.load(formatAgenciesToGeoJsonPoints(agencies));
  const clusters = sc.getClusters(bounds, zoom);

  // Handle map bounds changes
  const onMapBoundsChange = () => {
    if (!map) return;
    const _bounds = map.getBounds().toJSON();
    setBounds([_bounds.west, _bounds.south, _bounds.east, _bounds.north]);
  };

  // Handle cluster clicks
  const onClusterClick = (cluster) => {
    const expansionZoom = Math.min(sc.getClusterExpansionZoom(cluster.id), 20);
    map.setZoom(expansionZoom);
    map.panTo({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
  };

  // when the map zoom levek changes this is called
  const onZoomLevelChange = () => {
    if (!map) {
      return;
    }

    setZoom(map.zoom);
    shouldDrawGeographicBounds(handleMunicipalityClick);
  };

  const onDragEnd = () => {
    shouldDrawGeographicBounds(handleMunicipalityClick);
  };

  // Render agency markers
  const renderMarker = (cluster, map, index) => {
    const { agency } = cluster.properties;
    let latitude = cluster.properties.cluster
      ? cluster.geometry.coordinates[1]
      : parseFloat(agency.latitude);
    let longitude = cluster.properties.cluster
      ? cluster.geometry.coordinates[0]
      : parseFloat(agency.longitude);

    let clusterAgencies = [];
    if (cluster.properties.cluster) {
      const clusterPoints = sc.getLeaves(cluster.properties.cluster_id, Infinity);
      clusterAgencies = clusterPoints.map(point => point.properties.agency);
    }

    return (
      <AgencyMarker
        key={index}
        map={map}
        position={{ lat: latitude, lng: longitude }}
        agency={agency}
        cluster={cluster}
        clusterAgencies={clusterAgencies}
        onClusterClick={onClusterClick}
        isSelected={agency && selectedAgency === agency.id}
        selectMarker={() => dispatch(setSelectedAgency(agency ? agency.id : null))}
      />
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <GoogleMap
        mapContainerStyle={mapConfig.agencyDirectory.style[device]}
        center={mapConfig.agencyDirectory.center[device]}
        zoom={mapConfig.agencyDirectory.zoom[device]}
        onIdle={onDragEnd}
        onBoundsChanged={onMapBoundsChange}
        onZoomChanged={onZoomLevelChange}
        onLoad={(map) => {
          setMap(map);
          map.addListener("click", onMapClick);
          map.setMapTypeId("hybrid");
          window.googleMapsInstance = map;
          shouldDrawGeographicBounds();
        }}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: false,
          gestureHandling: "greedy",
        }}
      >
        <DrawingManager
          drawingMode={drawingMode}
          onPolygonComplete={(polygon) =>
            handlePolygonComplete(polygon, true, true)
          }
          onLoad={(drawingManager) => {
            window.googleMapsDrawingManager = drawingManager;
            setDrawingManager(drawingManager);
          }}
          options={{
            map: map,
            drawingControl: false,
            polygonOptions: {
              draggable: false,
              editable: true,
              fillColor: polygonColours.primary,
              strokeColor: polygonColours.primary,
              fillOpacity: polygonColours.fillOpacity,
              strokeWeight: 4,
            },
          }}
        />
        {clusters.map((cluster, index) => renderMarker(cluster, map, index))}
      </GoogleMap>
      
      {/* Agency map overlay */}
      {overlay && (
        <div 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            pointerEvents: overlayClickable ? 'auto' : 'none',
            background: overlayBackground || 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {overlay}
        </div>
      )}
    </div>
  );
};

// Helper function to format agencies data for clustering
const formatAgenciesToGeoJsonPoints = (agencies) => {
  return agencies.map(agency => ({
    type: "Feature",
    properties: {
      cluster: false,
      agency,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(agency.longitude),
        parseFloat(agency.latitude)
      ]
    }
  }));
};

export default AgencyGoogleMapWrapper; 