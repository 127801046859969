import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { i18n } from "i18n/localisation";
import searchIcon from "../assets/navbar/search.svg";
import valuationIcon from "../assets/navbar/cma.svg";
import marketInsightsIcon from "../assets/navbar/market_insights.svg";
import propertiesIcon from "../assets/navbar/my_properties.svg";
import collectionsIcon from "../assets/navbar/favorites.svg";
import settingsIcon from "../assets/navbar/settings.svg";
import newDevelopmentsIcon from "../assets/navbar/new_developments.svg";
import Dashboard from "./Dashboard";
import { connect } from "react-redux";
import { MOBILE_BREAKPOINT } from "config/constants";
import fireApiRequest from "api/core";
import { getSubscriptionPlan, userHasRole } from "utils/userHelpers";
import { setPricingModal } from "actions/miscActions";
import agencyDirectoryIcon from "../assets/settings/team.svg";
import catastroIcon from "../assets/navbar/catastro.svg";
import webValuatorIcon from "../assets/property/types/detached.svg";
import leadIcon from "../assets/core/natural_gas.svg";

var FETCH_STATS_ENDPOINT = "https://738suk9yma.execute-api.eu-west-2.amazonaws.com/dev/fetch_dashboard_analytics";

if (process.env.REACT_APP_NODE_ENV === "production") {
  FETCH_STATS_ENDPOINT =
    "https://jrpcub9z7l.execute-api.eu-west-2.amazonaws.com/production/fetch_dashboard_analytics";
}

const bodyBackground = "rgb(242 244 248 / var(--tw-bg-opacity, 1))";

const HomePageContainer = styled.div`
  padding: 40px;
  margin-left: 88px; /* Account for the navbar width */
  max-width: calc(1400px + 88px); /* Adjust max-width to include navbar */
  margin-right: auto;
  background: ${bodyBackground};
  min-height: 100vh;
  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0;
    margin-top: 88px; /* Account for top navbar on mobile */
    padding: 24px;
    width: 100%;
    max-width: 100%;
  }
`;

const Header = styled.div`
  margin-bottom: 60px;
  position: relative;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  background: linear-gradient(135deg, #333 0%, #111 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #666;
  max-width: 600px;
  line-height: 1.6;
`;

const TilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 28px;
`;

const Tile = styled(Link)`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.04), 
              0 1px 1px rgba(0, 0, 0, 0.02),
              0 20px 40px rgba(0, 0, 0, 0.03);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(238, 57, 67, 0.3);

  &:hover {
    transform: translateY(-6px);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    background: linear-gradient(135deg, rgba(238, 57, 67, 0.05) 0%, rgba(238, 57, 67, 0) 100%);
    border-radius: 0 0 0 100%;
    transition: all 0.3s ease;
    opacity: 0.5;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const TileIcon = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 12px;
  background: linear-gradient(135deg, #f8f9fa 0%, #f1f3f5 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;

  img {
    width: 26px;
    height: 26px;
    transition: all 0.3s ease;
  }

  ${Tile}:hover & {
    background: linear-gradient(135deg, rgba(238, 57, 67, 0.08) 0%, rgba(238, 57, 67, 0.03) 100%);
    transform: scale(1.05);
    
    img {
      filter: brightness(0.9);
    }
  }
`;

const TileTitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 14px;
  color: #222;
  transition: all 0.3s ease;

  ${Tile}:hover & {
    color: #ee3943;
  }
`;

const TileDescription = styled.p`
  font-size: 15px;
  color: #666;
  line-height: 1.6;
  flex-grow: 1;
`;

const ActionButton = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #ee3943;
  margin-top: 16px;
  display: inline-flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.3s ease;

  &::after {
    content: '→';
    margin-left: 6px;
    transition: transform 0.2s ease;
  }

  ${Tile}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  ${Tile}:hover &::after {
    transform: translateX(4px);
  }
`;

const ComingSoonBadge = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background: linear-gradient(135deg, rgba(238, 57, 67, 0.9) 0%, rgba(238, 57, 67, 0.8) 100%);
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(238, 57, 67, 0.3);
  z-index: 2;
`;

const UltraBadge = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background: black;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(238, 57, 67, 0.3);
  z-index: 2;
`;

const WelcomeSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    gap: 32px;
  }
`;

const Stats = styled.div`
  display: flex;
  gap: 24px;
  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
  }
`;

const StatItem = styled.div`
  background: rgba(255, 255, 255, 0.7);
  padding: 16px 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.7);
  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex: 1;
    min-width: calc(33% - 16px);
    text-align: center;
  }
  
  @media (max-width: 600px) {
    min-width: calc(50% - 12px);
    margin-bottom: 16px;
  }
`;

const StatValue = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #ee3943;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 4px;
`;

const HomePage = ({
  user,
  dispatch
}) => {
  const [analytics, setAnalytics] = useState({
    propertyCount: 0,
    dataAccuracy: 0,
    marketUpdates: 0,
  });
  const [analyticsFetched, setAnalyticsFetched] = useState(false);
  const isProduction = process.env.REACT_APP_NODE_ENV === "production";

  useEffect(() => {
    document.body.style.background = bodyBackground;
    // fetch analytics
    fireApiRequest(FETCH_STATS_ENDPOINT, {
      method: "POST",
    }).then((res) => {
      setAnalytics({
        propertyCount: res.total_items,
      });
      setAnalyticsFetched(true);
    });

    return () => {
      document.body.style.background = "";
    };
  }, []);

  let tiles = [
    {
      id: "search",
      title: i18n("Property Search"),
      description: i18n("Find properties with our advanced AI-powered search engine tailored to your specific criteria."),
      icon: searchIcon,
      path: "/search",
    },
    {
      id: "valuation",
      title: i18n("Smart Valuation"),
      description: i18n("Get precise property valuations using our machine learning algorithms and market data."),
      icon: valuationIcon,
      path: "/valuation",
    },
    {
      id: "market-insights",
      title: i18n("Market Analytics"),
      description: i18n("Explore real-time market trends, analytics, and comprehensive area statistics."),
      icon: marketInsightsIcon,
      role: "market_insights",
      path: "/market-insights",
      ultra: true,
    },
    {
      id: "my-properties",
      title: i18n("CRM"),
      description: i18n("Manage your property portfolio and upload new properties to the market."),
      icon: propertiesIcon,
      path: "/my-properties",
      comingSoon: isProduction,
    },
    {
      id: "collections",
      title: i18n("Collections"),
      description: i18n("Save properties and searches into collections for easy access."),
      icon: collectionsIcon,
      path: "/collections",
    },
    {
      id: "leads",
      title: i18n("My Leads"),
      description: i18n("Manage all your leads from a single place, with integrations of Whatsapp and Email to share the information of your properties."),
      icon: leadIcon,
      path: "/leads",
    },
    {
      id: "web-valuator",
      title: i18n("Web Valuator"),
      description: i18n("Embed a web valuator on your website to attract leads."),
      icon: webValuatorIcon,
      path: "/web-valuator"
    },
    {
      id: "valuation-settings",
      title: i18n("Valuation Conditions"),
      description: i18n("Configure valuation conditions tailored to your specific criteria."),
      icon: newDevelopmentsIcon,
      path: "/settings?tab=valuation",
    },
    {
      id: "settings",
      title: i18n("Account Settings"),
      description: i18n("Customize your experience and manage your preferences."),
      icon: settingsIcon,
      path: "/settings",
    },
  ];

  function formatMillions(number) {
    return (number / 1000000).toFixed(1) + "M";
  }

  // Mock stats - in a real app these would come from your state/API
  const stats = [
    { value: "+" + formatMillions(analytics.propertyCount + 4000000), label: i18n("Current properties on the system") }
  ];

  if (userHasRole("agency_directory")) {
    tiles.splice(4, 0, {
      id: "agency-directory",
      title: i18n("Agency Directory"),
      description: i18n("Explore a directory of real estate agencies in your area."),
      icon: agencyDirectoryIcon,
      path: "/agency-directory",
    });
  }

  if (userHasRole("catastro")) {
    tiles.splice(4, 0, {
      id: "catastro",
      title: i18n("Catastro"),
      description: i18n("Explore opportunities in Spain using the Catastro database."),
      icon: catastroIcon,
      path: "/catastro",
    });
  }

  return (
    <Dashboard>
      <HomePageContainer>
        <WelcomeSection>
          <Header>
            <Title>{i18n("Welcome to iRealty")}, {user?.personalDetails?.firstName ?? i18n("Professional")}</Title>
            <Subtitle>
              {i18n("Access powerful real estate analytics and tools designed to give you the competitive edge in today's market.")}
            </Subtitle>
          </Header>
          
          {analyticsFetched && (
            <Stats>
              {stats.map((stat, index) => (
                <StatItem key={index}>
                <StatValue>{stat.value}</StatValue>
                <StatLabel>{stat.label}</StatLabel>
                </StatItem>
              ))}
            </Stats>
          )}
        </WelcomeSection>
        
        <TilesGrid>
          {tiles.map((tile) => (
            <Tile 
              key={tile.id} 
              to={tile.comingSoon ? null : tile.path}
              onClick={(e) => {
                if (tile.role && !userHasRole(tile.role)) {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(setPricingModal(true));
                  return;
                }
              }}
            >
              {tile.comingSoon && <ComingSoonBadge>{i18n("Coming Soon")}</ComingSoonBadge>}
              {tile.ultra && <UltraBadge>{i18n("Ultra")}</UltraBadge>}
              <TileIcon>
                <img src={tile.icon} alt={tile.title} />
              </TileIcon>
              <TileTitle>{tile.title}</TileTitle>
              <TileDescription>{tile.description}</TileDescription>
              <ActionButton>{i18n("Explore")}</ActionButton>
            </Tile>
          ))}
        </TilesGrid>
      </HomePageContainer>
    </Dashboard>
  );
};

export default connect((state) => ({
  user: state.user.userData,
}))(HomePage);
