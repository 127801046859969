import { createAction } from "@reduxjs/toolkit";

const setAgencies = createAction("agencies/set");
const setFetchingAgencies = createAction("agencies/fetching");
const setFilteredAgencies = createAction("agencies/setFiltered");
const setSelectedAgency = createAction("agencies/setSelected");
const resetAgencyState = createAction("agencies/reset");
const setAgencyFilters = createAction("agencies/setFilters");
const addAgencyFilter = createAction("agencies/addFilter");
const removeAgencyFilter = createAction("agencies/removeFilter");

export {
  setAgencies,
  setFetchingAgencies,
  setFilteredAgencies,
  setSelectedAgency,
  resetAgencyState,
  setAgencyFilters,
  addAgencyFilter,
  removeAgencyFilter
};
