import { savePropertyRequest } from "api/crm";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { i18n } from "i18n/localisation";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import Button from "../ui/Button/Button";
import ListingAgent from "./ListingAgent";

const ListingAgentSettingsModal = ({ isOpen, closeModal }) => {
  const listingAgent = useSelector((state) => state.myProperties.listingAgent);
  const propertyId = useSelector((state) => state.myProperties.propertyId);
  const listingType = useSelector((state) => state.myProperties.listingType);

  const handleSave = async () => {
    closeModal();
    try {
      await savePropertyRequest({
        property_id: propertyId,
        saleType: listingType,
        update_fields: { user_id: listingAgent.id },
      });
    } catch (e) {
      console.log(e);
      toast.error(i18n("Failed to set listing agent. Please try again."), {
        duration: 5000,
      });
    }

    toast.success(i18n("Listing agent updated"), { duration: 5000 });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <DialogContent className="max-h-[calc(100svh-10px)] gap-0 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{i18n("Listing agent settings")}</DialogTitle>
        </DialogHeader>
        <div className="mt-6 flex w-full flex-col gap-6 p-6 pt-[0px]">
          <ListingAgent styleClass="settings-modal" />
        </div>
        <DialogFooter>
          <div className="flex w-full justify-between">
            <Button onClick={closeModal}>
              <div>{i18n("Cancel")}</div>
            </Button>
            <Button onClick={handleSave}>
              <div>{i18n("Save")}</div>
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ListingAgentSettingsModal;
