import { i18n } from "../i18n/localisation";

// Filter constants
export const FILTER_LOWER_BOUND_KEY = 'min';
export const FILTER_UPPER_BOUND_KEY = 'max';

// Filter types
export const CATASTRO_FILTER_TYPES = {
  PROPERTY_TYPE: 'propertyType',
  CONSTRUCTION_YEAR: 'constructionYear',
  AREA: 'area',
  FLOORS: 'floors'
};

// Common filter properties
export const COMMON_FILTERS = {
  [CATASTRO_FILTER_TYPES.CONSTRUCTION_YEAR]: {
    id: CATASTRO_FILTER_TYPES.CONSTRUCTION_YEAR,
    label: 'Construction Year',
    minLabel: 'Min',
    maxLabel: 'Max',
    minKey: 'constructionYearMin',
    maxKey: 'constructionYearMax',
    valueType: 'integer'
  },
  [CATASTRO_FILTER_TYPES.AREA]: {
    id: CATASTRO_FILTER_TYPES.AREA,
    label: 'Area (m²)',
    minLabel: 'Min',
    maxLabel: 'Max',
    minKey: 'areaMin',
    maxKey: 'areaMax',
    valueType: 'float'
  },
  [CATASTRO_FILTER_TYPES.FLOORS]: {
    id: CATASTRO_FILTER_TYPES.FLOORS,
    label: 'Floors',
    minLabel: 'Min',
    maxLabel: 'Max',
    minKey: 'floorMin',
    maxKey: 'floorMax',
    valueType: 'integer'
  }
};

// Property type options
export const PROPERTY_TYPE_OPTIONS = [
  { id: "residential", label: "Residential" },
  { id: "commercial", label: "Commercial" },
  { id: "office", label: "Office" },
  { id: "industrial", label: "Industrial" },
  { id: "land", label: "Land" },
  { id: "parking", label: "Parking" },
  { id: "other", label: "Other" },
  { id: "all", label: "All" }
];

// Get localized property type options
export const getPropertyTypeOptions = () => {
  return PROPERTY_TYPE_OPTIONS.map(type => ({
    id: type.id,
    label: i18n(type.label)
  }));
};

/**
 * Sorts an array of catastro properties based on the specified sort option
 * @param {Array} catastros - Array of catastro properties to sort
 * @param {string} sortOption - The sort option ('newest', 'price_asc', 'price_desc', etc.)
 * @returns {Array} - Sorted array of catastro properties
 */
export const applySorting = (catastros, sortOption = 'newest') => {
  if (!catastros || catastros.length === 0) return [];

  const sortedCatastros = [...catastros];

  switch (sortOption) {
    case 'newest':
      // Sort by creation date (newest first)
      return sortedCatastros.sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
        return dateB - dateA;
      });
    
    case 'oldest':
      // Sort by creation date (oldest first)
      return sortedCatastros.sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
        return dateA - dateB;
      });
    
    case 'area_asc':
      // Sort by area (smallest first)
      return sortedCatastros.sort((a, b) => {
        const areaA = a.area ? parseFloat(a.area) : 0;
        const areaB = b.area ? parseFloat(b.area) : 0;
        return areaA - areaB;
      });
    
    case 'area_desc':
      // Sort by area (largest first)
      return sortedCatastros.sort((a, b) => {
        const areaA = a.area ? parseFloat(a.area) : 0;
        const areaB = b.area ? parseFloat(b.area) : 0;
        return areaB - areaA;
      });
    
    case 'year_asc':
      // Sort by construction year (oldest first)
      return sortedCatastros.sort((a, b) => {
        const yearA = a.constructionYear ? parseInt(a.constructionYear) : 0;
        const yearB = b.constructionYear ? parseInt(b.constructionYear) : 0;
        return yearA - yearB;
      });
    
    case 'year_desc':
      // Sort by construction year (newest first)
      return sortedCatastros.sort((a, b) => {
        const yearA = a.constructionYear ? parseInt(a.constructionYear) : 0;
        const yearB = b.constructionYear ? parseInt(b.constructionYear) : 0;
        return yearB - yearA;
      });

    default:
      return sortedCatastros;
  }
};

/**
 * Applies filters to an array of catastro properties
 * @param {Array} catastros - Array of catastro properties to filter
 * @param {Object} filters - Filter criteria to apply
 * @returns {Array} - Filtered array of catastro properties
 */
export const applyFilters = (catastros, filters) => {
  if (!catastros || catastros.length === 0) return [];
  if (!filters) return catastros;

  return catastros.filter(catastro => {
    // Filter by property type
    if (filters.propertyType && catastro.type !== filters.propertyType.id) {
      return false;
    }

    // Filter by construction year range
    if (filters.constructionYearMin && catastro.constructionYear) {
      if (parseInt(catastro.constructionYear) < parseInt(filters.constructionYearMin)) {
        return false;
      }
    }
    if (filters.constructionYearMax && catastro.constructionYear) {
      if (parseInt(catastro.constructionYear) > parseInt(filters.constructionYearMax)) {
        return false;
      }
    }

    // Filter by area range
    if (filters.areaMin && catastro.area) {
      if (parseFloat(catastro.area) < parseFloat(filters.areaMin)) {
        return false;
      }
    }
    if (filters.areaMax && catastro.area) {
      if (parseFloat(catastro.area) > parseFloat(filters.areaMax)) {
        return false;
      }
    }

    // Filter by number of floors range
    if (filters.floorMin && catastro.floors) {
      if (parseInt(catastro.floors) < parseInt(filters.floorMin)) {
        return false;
      }
    }
    if (filters.floorMax && catastro.floors) {
      if (parseInt(catastro.floors) > parseInt(filters.floorMax)) {
        return false;
      }
    }

    // All filters passed
    return true;
  });
};

/**
 * Gets filtered catastros based on active filters
 * @param {Array} catastros - Array of catastro properties
 * @param {Object} activeFilters - Active filter criteria
 * @returns {Array} - Filtered array of catastro properties
 */
export const getFilteredCatastros = (catastros, activeFilters = {}) => {
  if (!catastros || catastros.length === 0) return [];
  if (!activeFilters || Object.keys(activeFilters).length === 0) return catastros;

  return catastros.filter(catastro => {
    // Check each active filter
    for (const [filterKey, filterValue] of Object.entries(activeFilters)) {
      if (!filterValue) continue; // Skip null/undefined/empty filters

      switch (filterKey) {
        case 'propertyType':
          if (catastro.type !== filterValue.id) {
            return false;
          }
          break;

        case 'constructionYearMin':
          if (catastro.constructionYear && parseInt(catastro.constructionYear) < parseInt(filterValue)) {
            return false;
          }
          break;

        case 'constructionYearMax':
          if (catastro.constructionYear && parseInt(catastro.constructionYear) > parseInt(filterValue)) {
            return false;
          }
          break;

        case 'areaMin':
          if (catastro.area && parseFloat(catastro.area) < parseFloat(filterValue)) {
            return false;
          }
          break;

        case 'areaMax':
          if (catastro.area && parseFloat(catastro.area) > parseFloat(filterValue)) {
            return false;
          }
          break;

        case 'floorMin':
          if (catastro.floors && parseInt(catastro.floors) < parseInt(filterValue)) {
            return false;
          }
          break;

        case 'floorMax':
          if (catastro.floors && parseInt(catastro.floors) > parseInt(filterValue)) {
            return false;
          }
          break;

        // Add other filter types as needed
        default:
          // For unknown filter types, do nothing
          break;
      }
    }
    
    // All filters passed
    return true;
  });
};

// Export other catastro filter-related functions as needed 