import { GoogleMap } from "@react-google-maps/api";
import { geolocatePropertyRequest } from "api/ai";
import FullscreenContainer from "components/core/FullscreenContainer";
import PropertyMarker from "components/map/PropertyMarker";
import Button from "components/ui/Button/Button";
import { i18n } from "i18n/localisation";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { animateMapPan, animateMapZoom } from "utils/map";
import { getSubscriptionPlan, userHasRole } from "utils/userHelpers";
import { setPricingModal } from "actions/miscActions";
import globeIcon from "assets/core/globe.svg";
import googleEarthIcon from "assets/core/google_earth.svg";
import streetViewIcon from "assets/core/street_view.svg";
import mapViewIcon from "assets/core/map_view.svg";
import { mapStyles } from "config/map";
import { Tooltip } from "react-tooltip";
import { formatNumber, openCatastroRef } from "utils/helpers";

// Styles for the property info box
const styles = `
  .property-info-box {
    position: absolute;
    bottom: 32px;
    right: 32px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 280px;
    overflow: hidden;
    z-index: 10;
    transition: all 0.3s ease;
    animation: fadeInUp 0.3s ease-out;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .property-info-update {
    animation: slideAndFade 0.5s ease-out;
  }

  @keyframes slideAndFade {
    0% {
      opacity: 0.3;
      transform: translateY(10px);
    }
    50% {
      opacity: 0.7;
      transform: translateY(-5px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .property-info-header {
    background-color: #f2f4f8;
    padding: 12px 16px;
    border-bottom: 1px solid #e1e4eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .property-info-header h3 {
    margin: 0;
    color: #222;
    font-size: 16px;
    font-weight: 600;
  }

  .location-status {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: #717171;
  }

  .location-status-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #FFC107;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 10px;
  }

  .street-view-toggle {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-color: #FFC107;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    outline: none;
  }

  .street-view-toggle:hover {
    background-color: #FFB000;
    transform: scale(1.05);
  }

  .street-view-toggle img {
    width: 20px;
    height: 20px;
  }

  .street-view-toggle.active {
    background-color: #f2f4f8; /* Google Maps blue color */
  }

  .street-view-toggle.active:hover {
    background-color: #f2f4f8; /* Darker Google Maps blue */
  }

  .street-view-toggle.loading {
    background-color: #FFD54F;
    cursor: wait;
    position: relative;
  }

  .street-view-toggle.loading img {
    opacity: 0.5;
  }

  .street-view-toggle.loading::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    border-top-color: #333;
    border-radius: 50%;
    animation: spinner 0.8s linear infinite;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .property-info-box.street-view-active {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .property-info-header.street-view-active {
    background-color: #FF9800;
  }

  .property-info-header.street-view-active h3 {
    color: white;
  }

  .street-view-info {
    padding: 8px 0;
  }

  .street-view-info p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #555;
  }

  .street-view-info .street-view-tip {
    margin-top: 8px;
    font-size: 12px;
    color: #777;
    font-style: italic;
  }

  .property-info-content {
    padding: 12px 16px;
  }

  .property-facade-image {
    margin-bottom: 12px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  .property-facade-image img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }

  .property-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .property-info-row:last-child {
    margin-bottom: 0;
  }

  .property-info-label {
    color: #717171;
    font-size: 14px;
    font-weight: 500;
  }

  .property-info-value {
    color: #222;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
  }

  .catastro-link {
    color: #0066cc;
    cursor: pointer;
    text-decoration: underline;
  }

  .catastro-link:hover {
    color: #004c99;
  }

  .location-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 1002;
    pointer-events: none;
    white-space: nowrap;
    transform: translate(-50%, -100%);
    margin-top: -8px;
  }

  /* Google Maps InfoWindow custom styling */
  .marker-tooltip {
    font-family: Inter, sans-serif;
    font-size: 12px;
    padding: 2px;
  }

  .marker-tooltip div {
    margin-bottom: 4px;
  }

  .marker-tooltip div:last-child {
    margin-bottom: 0;
  }

  /* Override Google Maps InfoWindow styles */
  .gm-style .gm-style-iw-c {
    padding: 8px 12px !important;
    border-radius: 6px !important;
  }

  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style .gm-style-iw-t::after {
    background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%) !important;
  }

  .geolocation-loading-message {
    position: absolute;
    bottom: 32px;
    right: 32px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 8px;
    padding: 12px 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 10;
    animation: fadeInUp 0.3s ease-out;
    max-width: 280px;
    text-align: center;
  }
`;

export default function ({ property, setShowMap }) {
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [isGeolocating, setIsGeolocating] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [showMarker, setShowMarker] = useState(true);
  const [geolocatedMatches, setGeolocatedMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [facadeImageExists, setFacadeImageExists] = useState(false);
  const [isStreetViewActive, setIsStreetViewActive] = useState(false);
  const [isStreetViewAvailable, setIsStreetViewAvailable] = useState(false);
  const [isStreetViewLoading, setIsStreetViewLoading] = useState(false);
  const [hasBeenGeolocated, setHasBeenGeolocated] = useState(false);
  const selectedMarkerRef = useRef(null);
  const streetViewPanoramaRef = useRef(null);
  // Store geolocated data in a ref to persist between component mounts
  const geolocatedDataRef = useRef(null);

  useEffect(() => {
    setLatitude(parseFloat(property.latitude));
    setLongitude(parseFloat(property.longitude));
    
    // Check if facade image exists
    if (property.refcat) {
      // get the first 14 characters of the refcat
      const refcatShort = property.refcat.substring(0, 14);
      checkFacadeImageExists(refcatShort);
    }

    // Check if we have previously geolocated this property
    const storedData = localStorage.getItem(`geolocated_property_${property.id}`);
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        geolocatedDataRef.current = parsedData;
        setHasBeenGeolocated(true);
      } catch (error) {
        console.error("Error parsing stored geolocation data:", error);
      }
    }
  }, [property]);

  // Load previously geolocated data when map is ready
  useEffect(() => {
    if (map && hasBeenGeolocated && geolocatedDataRef.current && markers.length === 0) {
      const data = geolocatedDataRef.current;
      
      // Apply the stored geolocation data
      setShowMarker(data.showMarker);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
      setGeolocatedMatches(data.matches);
      setSelectedMatch(data.selectedMatch);
      
      // Pan and zoom to the location
      animateMapZoom(map, 18, 1000);
      animateMapPan(map, {
        lat: data.latitude,
        lng: data.longitude,
      });
      
      // Clear any existing markers first
      markers.forEach(marker => {
        if (marker) marker.setMap(null);
      });
      setMarkers([]);
      selectedMarkerRef.current = null;
      
      // Create markers if there are multiple matches
      if (data.matches.length > 1) {
        setShowMarker(false);
        
        // Group matches by location to detect duplicates
        const locationGroups = {};
        data.matches.forEach((match) => {
          const locationKey = `${match.lat},${match.lon}`;
          if (!locationGroups[locationKey]) {
            locationGroups[locationKey] = [];
          }
          locationGroups[locationKey].push(match);
        });
        
        const newMarkers = [];
        let markerIndex = 0;
        
        // Create markers for each location, handling duplicates
        Object.entries(locationGroups).forEach(([locationKey, matchesAtLocation]) => {
          const [baseLat, baseLng] = locationKey.split(',').map(parseFloat);
          
          // If there's only one match at this location, create a normal marker
          if (matchesAtLocation.length === 1) {
            const match = matchesAtLocation[0];
            // Only select the first marker if it's the first load or if it's the selected match
            const shouldSelect = (data.firstLoad && markerIndex === 0) || match.id === data.selectedMatch.id;
            const marker = createMarker(match, markerIndex, baseLat, baseLng, shouldSelect);
            marker.setMap(map);
            newMarkers.push(marker);
            markerIndex++;
          } 
          // If there are multiple matches at the same location, create a cluster of markers
          else {
            // Calculate a small offset for each marker in the cluster
            matchesAtLocation.forEach((match, clusterIndex) => {
              // Create a small offset pattern around the base location
              // This creates a small circle of markers around the actual point
              const angle = (2 * Math.PI * clusterIndex) / matchesAtLocation.length;
              
              // Calculate offset based on zoom level
              // At higher zoom levels, we need smaller offsets
              const zoomLevel = map.getZoom();
              let offsetDistance;
              
              if (zoomLevel >= 20) {
                offsetDistance = 0.000015; // Very small offset for very high zoom
              } else if (zoomLevel >= 18) {
                offsetDistance = 0.00003; // Small offset for high zoom
              } else {
                offsetDistance = 0.00006; // Larger offset for lower zoom
              }
              
              const offsetLat = baseLat + offsetDistance * Math.sin(angle);
              const offsetLng = baseLng + offsetDistance * Math.cos(angle);
              
              // Only select the first marker if it's the first load or if it's the selected match
              const shouldSelect = (data.firstLoad && markerIndex === 0) || match.id === data.selectedMatch.id;
              const marker = createMarker(match, markerIndex, offsetLat, offsetLng, shouldSelect);
              marker.setMap(map);
              newMarkers.push(marker);
              markerIndex++;
            });
          }
        });
        
        setMarkers(newMarkers);
        
        // If this is not the first load, update the firstLoad flag to false
        if (data.firstLoad) {
          const updatedData = {
            ...data,
            firstLoad: false
          };
          
          // Update the ref
          geolocatedDataRef.current = updatedData;
          
          // Update localStorage
          localStorage.setItem(`geolocated_property_${property.id}`, JSON.stringify(updatedData));
        }
      }
    }
  }, [map, hasBeenGeolocated, markers.length]);

  // Exit street view when component unmounts or when map changes
  useEffect(() => {
    return () => {
      if (streetViewPanoramaRef.current) {
        streetViewPanoramaRef.current.setVisible(false);
      }
    };
  }, [map]);

  // Check if facade image exists when selectedMatch changes
  useEffect(() => {
    if (selectedMatch && (selectedMatch.refcat || property.refcat)) {
      const refcat = selectedMatch.refcat || property.refcat;
      // get the first 14 characters of the refcat
      const refcatShort = refcat.substring(0, 14);
      checkFacadeImageExists(refcatShort);
    }
  }, [selectedMatch]);

  // Check if Street View is available when selectedMatch changes
  useEffect(() => {
    if (selectedMatch && map) {
      const position = {
        lat: parseFloat(selectedMatch.lat || latitude),
        lng: parseFloat(selectedMatch.lon || longitude)
      };
      
      const streetViewService = new window.google.maps.StreetViewService();
      // Increase the radius to find street view panoramas that might be a bit further away
      // Use source: 'outdoor' to only get official Google Street View panoramas
      streetViewService.getPanorama({ 
        location: position, 
        radius: 100,
        source: window.google.maps.StreetViewSource.OUTDOOR // Only use official Street View imagery
      }, (data, status) => {
        setIsStreetViewAvailable(status === window.google.maps.StreetViewStatus.OK);
      });
    } else {
      setIsStreetViewAvailable(false);
    }
  }, [selectedMatch, map, latitude, longitude]);

  // Cleanup markers when component unmounts
  useEffect(() => {
    return () => {
      // Clear all markers when component unmounts
      markers.forEach(marker => {
        if (marker) marker.setMap(null);
      });
    };
  }, [markers]);

  // Ensure markers are recreated when the component is reopened
  useEffect(() => {
    // If we have geolocated data but no markers, recreate them
    if (map && hasBeenGeolocated && geolocatedDataRef.current && markers.length === 0 && geolocatedDataRef.current.matches.length > 1) {
      // Force a recreation of markers by setting markers to empty array
      setMarkers([]);
    }
  }, [map, hasBeenGeolocated, markers.length]);

  // opens the property in google earth
  const openGoogleEarth = () => {
    // Use the selected marker's coordinates if available, otherwise use the default lat/lon
    const lat = selectedMatch ? parseFloat(selectedMatch.lat) : latitude;
    const lon = selectedMatch ? parseFloat(selectedMatch.lon) : longitude;
    window.open(
      `https://earth.google.com/web/@${lat},${lon},50a,1000d,35y,0h,0t,0r/data=KAI?map_type=satellite&layers=3d`,
      "earth-" + property.id,
    );
    // The 50a is altitude (zoomed in more), 1000d is distance, and /data=KAI adds a marker
  };

  // geolocates the property with ai backend
  const geolocateProperty = async () => {
    // Check if user has the Ultra plan
    if (!userHasRole("geolocate")) {
      // If not, close the geolocator view first, then open the pricing modal
      // load for 3 seconds
      setIsGeolocating(true);
      setTimeout(() => {
        setIsGeolocating(false);
        setShowMap(false);
        dispatch(setPricingModal(true));
      }, 3000);
      return;
    }
    
    if (!isGeolocatorSupported()) {
      return;
    }

    setIsGeolocating(true);
    animateMapZoom(map, 10, 1000);
    
    // Clear any existing markers
    markers.forEach(marker => {
      if (marker) marker.setMap(null);
    });
    setMarkers([]);
    setGeolocatedMatches([]);
    setSelectedMatch(null);
    selectedMarkerRef.current = null;

    try {
      let response = await geolocatePropertyRequest(property.id);

      if (response) {
        // if matches
        if (response.matches) {
          setShowMarker(true);
          setLatitude(parseFloat(response.matches[0].lat));
          setLongitude(parseFloat(response.matches[0].lon));
          animateMapZoom(map, 18, 1000);
          animateMapPan(map, {
            lat: parseFloat(response.matches[0].lat),
            lng: parseFloat(response.matches[0].lon),
          });
          
          // Store all matches for info display
          setGeolocatedMatches(response.matches);
          setSelectedMatch(response.matches[0]);
          
          // Add numbered pins if there are multiple matches
          if (response.matches.length > 1) {
            // remove the property marker component
            setShowMarker(false);
            
            // Group matches by location to detect duplicates
            const locationGroups = {};
            response.matches.forEach((match) => {
              const locationKey = `${match.lat},${match.lon}`;
              if (!locationGroups[locationKey]) {
                locationGroups[locationKey] = [];
              }
              locationGroups[locationKey].push(match);
            });
            
            const newMarkers = [];
            let markerIndex = 0;
            
            // Create markers for each location, handling duplicates
            Object.entries(locationGroups).forEach(([locationKey, matchesAtLocation]) => {
              const [baseLat, baseLng] = locationKey.split(',').map(parseFloat);
              
              // If there's only one match at this location, create a normal marker
              if (matchesAtLocation.length === 1) {
                const match = matchesAtLocation[0];
                const marker = createMarker(match, markerIndex, baseLat, baseLng);
                marker.setMap(map);
                newMarkers.push(marker);
                markerIndex++;
              } 
              // If there are multiple matches at the same location, create a cluster of markers
              else {
                // Calculate a small offset for each marker in the cluster
                matchesAtLocation.forEach((match, clusterIndex) => {
                  // Create a small offset pattern around the base location
                  // This creates a small circle of markers around the actual point
                  const angle = (2 * Math.PI * clusterIndex) / matchesAtLocation.length;
                  
                  // Calculate offset based on zoom level
                  // At higher zoom levels, we need smaller offsets
                  const zoomLevel = map.getZoom();
                  let offsetDistance;
                  
                  if (zoomLevel >= 20) {
                    offsetDistance = 0.000015; // Very small offset for very high zoom
                  } else if (zoomLevel >= 18) {
                    offsetDistance = 0.00003; // Small offset for high zoom
                  } else {
                    offsetDistance = 0.00006; // Larger offset for lower zoom
                  }
                  
                  const offsetLat = baseLat + offsetDistance * Math.sin(angle);
                  const offsetLng = baseLng + offsetDistance * Math.cos(angle);
                  
                  // Check if this match is the first one (which should be selected by default)
                  const isFirstMatch = match.id === response.matches[0].id;
                  const marker = createMarker(match, markerIndex, offsetLat, offsetLng, isFirstMatch);
                  marker.setMap(map);
                  newMarkers.push(marker);
                  markerIndex++;
                });
              }
            });
            
            setMarkers(newMarkers);
          }

          // Save geolocation data to localStorage and ref
          const geolocatedData = {
            latitude: parseFloat(response.matches[0].lat),
            longitude: parseFloat(response.matches[0].lon),
            matches: response.matches,
            selectedMatch: response.matches[0],
            showMarker: response.matches.length <= 1,
            firstLoad: true // Add flag to indicate this is the first load
          };
          
          // Save to localStorage
          localStorage.setItem(`geolocated_property_${property.id}`, JSON.stringify(geolocatedData));
          
          // Save to ref
          geolocatedDataRef.current = geolocatedData;
          
          // Mark as geolocated
          setHasBeenGeolocated(true);

          toast.success(i18n("Property geolocated"));
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(i18n("Failed to geolocate property"));
    }

    setIsGeolocating(false);
  };

  // Helper function to create a marker with consistent styling
  const createMarker = (match, index, lat, lng, forceSelected = false) => {
    const isFirstMarker = (index === 0 || forceSelected) && !selectedMarkerRef.current;
    const marker = new window.google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: map,
      label: {
        text: (index + 1).toString(),
        color: "#FFFFFF",
        fontWeight: "bold"
      },
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 12,
        fillColor: isFirstMarker ? "#FF0000" : "#555555", // First marker is selected by default
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#FFFFFF",
      },
      zIndex: isFirstMarker ? 1001 : 1000 - index // Selected marker has highest z-index
    });

    // If this is the first marker and no marker is selected, set it as the selected marker
    if (isFirstMarker && !selectedMarkerRef.current) {
      selectedMarkerRef.current = marker;
    }

    // Add click handler to marker
    marker.addListener("click", () => {
      // If there's a previously selected marker, deselect it
      if (selectedMarkerRef.current) {
        selectedMarkerRef.current.setIcon({
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 12,
          fillColor: "#555555",
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#FFFFFF",
        });
        
        // Set a default z-index for deselected markers
        // This ensures they go back to their normal stacking order
        selectedMarkerRef.current.setZIndex(1000 - markers.findIndex(m => 
          m.getPosition().lat() === selectedMarkerRef.current.getPosition().lat() && 
          m.getPosition().lng() === selectedMarkerRef.current.getPosition().lng()
        ));
      }
      
      // Update the clicked marker to show it's selected
      marker.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 12,
        fillColor: "#FF0000", // Highlight color
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#FFFFFF",
      });
      marker.setZIndex(1001); // Bring to front
      
      // Update the selected marker reference
      selectedMarkerRef.current = marker;
      
      // Add slide and fade animation to info box when changing selection
      const infoBox = document.querySelector('.property-info-box');
      if (infoBox) {
        infoBox.classList.remove('property-info-update');
        // Force a reflow to restart the animation
        void infoBox.offsetWidth;
        infoBox.classList.add('property-info-update');
      }
      
      setSelectedMatch(match);
      animateMapPan(map, {
        lat: parseFloat(match.lat),
        lng: parseFloat(match.lon),
      });
      
      // Update the stored geolocation data with the new selected match
      if (geolocatedDataRef.current && hasBeenGeolocated) {
        const updatedData = {
          ...geolocatedDataRef.current,
          selectedMatch: match,
          latitude: parseFloat(match.lat),
          longitude: parseFloat(match.lon),
          firstLoad: false // Set firstLoad to false when a marker is manually selected
        };
        
        // Update the ref
        geolocatedDataRef.current = updatedData;
        
        // Update localStorage
        localStorage.setItem(`geolocated_property_${property.id}`, JSON.stringify(updatedData));
      }
    });
    
    return marker;
  };

  const isGeolocatorSupported = () => {
    // return property.detailedType.typology == "house";
    // if property is in madrid
    // not supported if rental 
    if (property.saleType == "rent") {
      return false;
    }
    return property.province == "Madrid" || property.province == "Málaga" || property.province == "Malaga";
  };

  // Function to check if facade image exists (not 404)
  const checkFacadeImageExists = (refcat) => {
    const imageUrl = `https://dev-catastro-images.s3.amazonaws.com/${refcat}.jpg`;
    
    // Create a new image element to check if the image exists
    const img = new Image();
    
    img.onload = () => {
      // Image loaded successfully
      setFacadeImageExists(true);
    };
    
    img.onerror = (error) => {
      // If it's a 404, the image doesn't exist
      // But if it's a CORS error, the image exists but can't be accessed directly
      // We can check the error message or just assume CORS errors mean the image exists
      
      // For S3 images, a CORS error typically means the image exists
      // Only set to false if we're sure it's a 404 (which is hard to determine)
      // So we'll assume the image exists unless proven otherwise

      setFacadeImageExists(true);
    };
    
    // Start loading the image
    img.src = imageUrl;
  };

  // Function to toggle street view mode
  const toggleStreetView = () => {
    if (!map || !selectedMatch || !isStreetViewAvailable || isStreetViewLoading) return;
    
    const position = {
      lat: parseFloat(selectedMatch.lat || latitude),
      lng: parseFloat(selectedMatch.lon || longitude)
    };
    
    if (!isStreetViewActive) {
      // Enable Street View
      setIsStreetViewLoading(true);
      const streetViewService = new window.google.maps.StreetViewService();
      
      // Find the closest street view panorama to the marker position
      // Use source: 'outdoor' to only get official Google Street View panoramas
      streetViewService.getPanorama({ 
        location: position, 
        radius: 100,
        preference: window.google.maps.StreetViewPreference.NEAREST,
        source: window.google.maps.StreetViewSource.OUTDOOR // Only use official Street View imagery
      }, (data, status) => {
        setIsStreetViewLoading(false);
        if (status === window.google.maps.StreetViewStatus.OK) {
          // Get the panorama and set its position to the closest street view point
          const panorama = map.getStreetView();
          
          // Use the location of the found panorama (which might be the closest point on the street)
          const panoramaPosition = data.location.latLng;
          panorama.setPosition(panoramaPosition);
          
          // Ensure we're only showing official Street View imagery
          panorama.setOptions({
            source: window.google.maps.StreetViewSource.OUTDOOR
          });
          
          // Calculate heading to look toward the property if geometry library is available
          try {
            if (window.google.maps.geometry && window.google.maps.geometry.spherical) {
              const heading = window.google.maps.geometry.spherical.computeHeading(
                panoramaPosition,
                new window.google.maps.LatLng(position)
              );
              
              panorama.setPov({
                heading: heading,
                pitch: 0,
              });
            } else {
              // Fallback if geometry library is not available
              panorama.setPov({
                heading: 0,
                pitch: 0,
              });
            }
          } catch (error) {
            console.error("Error calculating heading:", error);
            // Fallback if there's an error
            panorama.setPov({
              heading: 0,
              pitch: 0,
            });
          }
          
          panorama.setVisible(true);
          
          // Store reference to panorama
          streetViewPanoramaRef.current = panorama;
          
          // Add listener for when street view is closed directly via the X button
          const listener = panorama.addListener('visible_changed', () => {
            if (!panorama.getVisible()) {
              setIsStreetViewActive(false);
              // Remove the listener to avoid memory leaks
              window.google.maps.event.removeListener(listener);
            }
          });
          
          setIsStreetViewActive(true);
        } else {
          // This shouldn't happen since we already checked availability, but just in case
          toast.error(i18n("Street View not available at this location"));
          setIsStreetViewAvailable(false);
        }
      });
    } else {
      // Disable Street View
      if (streetViewPanoramaRef.current) {
        streetViewPanoramaRef.current.setVisible(false);
      }
      setIsStreetViewActive(false);
    }
  };

  // Render property info box
  const renderPropertyInfoBox = () => {
    if (!selectedMatch) return null;

    // Check if the selected match shares location with other matches
    const selectedLocationKey = `${selectedMatch.lat},${selectedMatch.lon}`;
    const matchesAtSameLocation = geolocatedMatches.filter(
      match => `${match.lat},${match.lon}` === selectedLocationKey
    );
    const hasMultipleAtSameLocation = matchesAtSameLocation.length > 1;
    
    // Get the refcat to use for the image
    const refcat = selectedMatch.refcat || property.refcat;
    // get the first 14 characters of the refcat
    const refcatShort = refcat.substring(0, 14);


    return (
      <div className={`property-info-box ${isStreetViewActive ? 'street-view-active' : ''}`}>
        <div className={`property-info-header ${isStreetViewActive ? 'street-view-active' : ''}`}>
          <h3>{isStreetViewActive ? i18n("Street View") : i18n("Property Details")}</h3>
          {isStreetViewAvailable && (
            <button 
              className={`street-view-toggle ${isStreetViewActive ? 'active' : ''} ${isStreetViewLoading ? 'loading' : ''}`}
              onClick={toggleStreetView}
              disabled={isStreetViewLoading}
              title={isStreetViewActive ? i18n("Exit Street View") : i18n("View in Street View")}
            >
              <img 
                src={isStreetViewActive ? mapViewIcon : streetViewIcon} 
                alt={isStreetViewActive ? i18n("Exit Street View") : i18n("View in Street View")} 
              />
            </button>
          )}
        </div>
        <div className="property-info-content">
          {!isStreetViewActive && facadeImageExists && (
            <div className="property-facade-image">
              <img 
                src={`https://dev-catastro-images.s3.amazonaws.com/${refcatShort}.jpg`} 
                alt={i18n("Property Facade")}
              />
            </div>
          )}
          {isStreetViewActive ? (
            <div className="street-view-info">
              <p>{i18n("You are viewing the closest official Google Street View to this property. Use the controls to navigate or click the map icon to return to map view.")}</p>
              <p className="street-view-tip">{i18n("Tip: The view is automatically oriented toward the property location.")}</p>
            </div>
          ) : (
            <>
              <div className="property-info-row">
                <span className="property-info-label">{i18n("ID")}:</span>
                <span className="property-info-value">{selectedMatch.refcat || property.refcat}</span>
              </div>
              {selectedMatch.floor && (
                <div className="property-info-row">
                  <span className="property-info-label">{i18n("Floor")}:</span>
                  <span className="property-info-value">{selectedMatch.floor}</span>
                </div>
              )}
              {selectedMatch.year && (
                <div className="property-info-row">
                  <span className="property-info-label">{i18n("Year")}:</span>
                  <span className="property-info-value">{selectedMatch.year}</span>
                </div>
              )}
              {selectedMatch.m2 && (
                <div className="property-info-row">
                  <span className="property-info-label">{i18n("Size")}:</span>
                  <span className="property-info-value">{formatNumber(selectedMatch.m2)} m²</span>
                </div>
              )}
              {selectedMatch.refcat && (
                <div className="property-info-row">
                  <span className="property-info-label">{i18n("Catastro")}:</span>
                  <span 
                    className="property-info-value catastro-link"
                    onClick={() => openCatastroRef(selectedMatch.refcat)}
                  >
                    {selectedMatch.refcat}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };


  return (
    <FullscreenContainer onCloseFullscreen={() => setShowMap(false)}>
      <style>{styles}</style>
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        center={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
        zoom={18}
        onLoad={(map) => {
          map.setMapTypeId("hybrid");
          setMap(map);
        }}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: false,
          gestureHandling: "greedy",
        }}
      >
        {showMarker && (
          <PropertyMarker
            disableActions
            map={map}
            position={{
              lat: latitude,
              lng: longitude,
            }}
            property={property}
            isSelected={true}
          />
        )}
      </GoogleMap>
      <div className="geo-buttons">
        {
          !hasBeenGeolocated && (
            <Button
              className={"geolocate-ai-btn" + (isGeolocatorSupported() ? "" : " disabled")}
              variant="secondary"
              onClick={geolocateProperty}
              loading={isGeolocating}
              data-tooltip-id="disabled-geolocator-tooltip"
              data-tooltip-content={isGeolocatorSupported() 
                ? (getSubscriptionPlan() !== "ultra" ? i18n("This feature requires an Ultra plan") : "")
                : i18n("This property type is not supported")}
            >
              <img src={globeIcon} alt="Geolocate" />
              <span>{i18n("Geolocate with AI")}</span>
            </Button>
          )
        }
        <Button
          className="google-earth-btn"
          variant="secondary"
          onClick={openGoogleEarth}
        >
          <img src={googleEarthIcon} alt="Google Earth" />
          <span>{i18n("Open in Google Earth")}</span>
        </Button>
      </div>
      {!isGeolocatorSupported() && <Tooltip id="disabled-geolocator-tooltip" />}
      {selectedMatch && renderPropertyInfoBox()}
      {isGeolocating && (
        <div className="geolocation-loading-message">
          {i18n("We are working on locating this property to an exact position. Please wait...")}
        </div>
      )}
    </FullscreenContainer>
  );
}
