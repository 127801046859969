import { DrawingManager, GoogleMap } from "@react-google-maps/api";
import { setCanShowBoundsToolbarItem } from "actions/mapActions";
import withRouter from "components/core/withRouter";
import {
  ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES,
  ZOOM_LEVEL_TO_SHOW_PLOTS,
} from "config/constants";
import {
  mapConfig,
  mapStyles,
  marketInsightMapStyles,
  polygonColours,
  roadMapStyles,
} from "config/map";
import { useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import Supercluster from "supercluster";
import {
  clearAllButSelectedPlot,
  fetchPlotsByMapBounds,
  formatPropertiesToGeoJsonPoints,
  getGlobalMapInstance,
  shouldDrawGeographicBounds,
} from "utils/map";
import PropertyMarker from "./PropertyMarker";
import { getSubscriptionPlan } from "utils/userHelpers";
import { toast } from "sonner";
import { setFilteredCatastros, setLastEvaluatedKey } from "actions/catastro";
import { setGlobalLoading } from "actions/miscActions";
import { i18n } from "i18n/localisation";
import { isInPolygon, isInMultiPolygon } from "utils/polygon";

const sidePanelWidth = 370;

/**
 * Wrapper to wrap the google maps library
 * so we prevent rerenders of dependant components within
 * the property map
 */
const GoogleMapWrapper = ({
  setMap,
  map,
  handlePolygonComplete,
  setDrawingManager,
  setSelectedProperty,
  selectedProperty,
  onMapClick,
  filteredProperties,
  page,
  drawingMode,
  plotMode,
  saleType,
  handleMunicipalityClick,
  overlay,
  overlayClickable,
  overlayBackground,
  zoomOverlayThreshold,
  ...props
}) => {
  const [bounds, setBounds] = useState([0, 0, 0, 0]);
  const [zoom, setZoom] = useState(0);
  const device = window.innerWidth < 1100 ? "mobile" : "desktop";
  const [showZoomOverlay, setShowZoomOverlay] = useState(false);

  // Adjust cluster radius for market insights mode
  const clusterRadius = page === "marketInsights" ? 250 : 190;

  // Memoize the clusters to prevent recalculation on every render
  const clusters = useMemo(() => {
    const sc = new Supercluster({ radius: clusterRadius, maxZoom: 20 });
    sc.load(formatPropertiesToGeoJsonPoints(filteredProperties));
    return {
      clusters: sc.getClusters(bounds, zoom),
      supercluster: sc
    };
  }, [filteredProperties, bounds, zoom, clusterRadius]);

  // when the viewport on the map changes this function is fired
  const onMapBoundsChange = () => {
    if (!map) {
      return;
    }

    const _bounds = map.getBounds().toJSON();
    setBounds([_bounds.west, _bounds.south, _bounds.east, _bounds.north]);
  };

  // if plot mode is enabled then we need to draw
  // the plots of buildings if we are at a certain zoom level
  const onShouldDrawPlots = () => {
    let map = getGlobalMapInstance();

    if (map.zoom > ZOOM_LEVEL_TO_SHOW_PLOTS && plotMode) {
      // Handle non-catastro pages
      if (page !== "catastro") {
        fetchPlotsByMapBounds(null);
      } 
      // For catastro page, handle differently based on stage
      else if (map.zoom > ZOOM_LEVEL_TO_SHOW_PLOTS) {
        const { mapReducer } = props;
        const { catastroFilters } = mapReducer;
        const selectedArea = mapReducer.selectedAreas?.length > 0 ? mapReducer.selectedAreas[0] : null;
        
        // Get the current catastro stage from redux
        const catastroStage = props.catastro?.stage;
        
        // Only use filters if we're in references stage (stage 2) and filters are defined
        if (catastroStage >= 2 && catastroFilters) {
          fetchPlotsByMapBounds(selectedArea, catastroFilters).then((result) => {
            const { plots, subplots } = result;
            
            // Show a message with the number of subplots found
            if (!subplots || subplots.length === 0) {
              toast.info(i18n("No properties found matching your criteria."));
              props.dispatch(setFilteredCatastros([]));
              props.dispatch(setLastEvaluatedKey(null));
            } else {
              toast.success(i18n(`Found ${subplots.subplots.length} properties matching your criteria.`));

              // check if the subplots are inside the selected area

              // const subplotsInsideSelectedArea = subplots.subplots.filter((subplot) => {
              //   // if multi polygon then we need to check if any of the vertices are inside the selected area
              //   if (selectedArea.geometry.type === "MultiPolygon") {
              //     return isInMultiPolygon(selectedArea.geometry, {
              //       latitude: subplot.center_y,
              //       longitude: subplot.center_x,
              //     });
              //   } else {
              //     return isInPolygon(selectedArea.geometry, {
              //       latitude: subplot.center_y,
              //       longitude: subplot.center_x,
              //     });
              //   }
              // });
              
              // console.log(subplotsInsideSelectedArea);
              // console.log(subplotsInsideSelectedArea.length);

              // Store the filtered subplots for display in stage 3
              props.dispatch(setFilteredCatastros(subplots.subplots));
              
              // Store the lastEvaluatedKey for pagination
              if (subplots.last_evaluated_key) {
                // Using dispatch instead of this.setState since this is a functional component
                props.dispatch(setLastEvaluatedKey(subplots.last_evaluated_key));
              } else {
                props.dispatch(setLastEvaluatedKey(null));
              }
            }
            
            props.dispatch(setGlobalLoading(false));
          })
          .catch(error => {
            props.dispatch(setGlobalLoading(false));
            toast.error(i18n("Error applying filters."));
            console.error("Error applying filters:", error);
          });
        } else {
          // For earlier stages, fetch without filters
          fetchPlotsByMapBounds(selectedArea);
        }
      }
    } else if (plotMode) {
      clearAllButSelectedPlot();
    }
  };

  const onDragEnd = () => {
    // When in catastro mode and in the references stage, use stored filters
    if (page === "catastro") {
      const { mapReducer } = props;
      const { catastroFilters } = mapReducer;
      const catastroStage = props.catastro?.stage;
      
      // If we're in the references stage (stage 2) and have filters, use them
      if (catastroStage >= 2 && catastroFilters) {
        onShouldDrawPlots();
      } else {
        // For other stages, just use the regular function
        onShouldDrawPlots();
      }
    } else {
      // For other pages, use the regular function
      onShouldDrawPlots();
    }
    
    shouldDrawGeographicBounds(handleMunicipalityClick);
  };

  // when the map zoom level changes this is called
  const onZoomLevelChange = () => {
    if (!map) {
      return;
    }

    setZoom(map.zoom);
    
    // Check if we should show the zoom level overlay
    if (zoomOverlayThreshold) {
      setShowZoomOverlay(map.zoom < zoomOverlayThreshold);
    } else {
      setShowZoomOverlay(true);
    }
    
    // When in catastro mode and in the references stage, use stored filters
    if (page === "catastro") {
      const { mapReducer } = props;
      const { catastroFilters } = mapReducer;
      const catastroStage = props.catastro?.stage;
      
      // If we're in the references stage (stage 2) and have filters, use them
      if (catastroStage >= 2 && catastroFilters) {
        onShouldDrawPlots();
      } else {
        // For other stages, just use the regular function
        onShouldDrawPlots();
      }
    } else {
      // For other pages, use the regular function
      onShouldDrawPlots();
    }
    
    shouldDrawGeographicBounds(handleMunicipalityClick);

    if (map.zoom <= ZOOM_LEVEL_TO_HIDE_MUNICIPALITIES) {
      props.dispatch(setCanShowBoundsToolbarItem(true));
    } else {
      props.dispatch(setCanShowBoundsToolbarItem(false));
    }
  };

  // Memoize the onClusterClick function
  const onClusterClick = useCallback((cluster) => {
    const expansionZoom = Math.min(clusters.supercluster.getClusterExpansionZoom(cluster.id), 20);
    map.setZoom(expansionZoom);
    map.panTo({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
  }, [clusters.supercluster, map]);

  // Memoize the renderMarker function
  const renderMarker = useCallback((cluster, map, index) => {
    const { property } = cluster.properties;
    let latitude = 0;
    let longitude = 0;
    let clusterProperties = [];

    if (cluster.properties.cluster) {
      latitude = cluster.geometry.coordinates[1];
      longitude = cluster.geometry.coordinates[0];
    } else {
      latitude = parseFloat(property.latitude);
      longitude = parseFloat(property.longitude);
    }

    // collect all a cluster's properties for the cluster marker
    if (cluster.properties.cluster) {
      const clusterPoints = clusters.supercluster.getLeaves(
        cluster.properties.cluster_id,
        Infinity,
      );
      clusterPoints.forEach((point) => {
        clusterProperties.push(point.properties.property);
      });
    }

    // selected states for property markers
    let isSelected = false;
    if (property && selectedProperty === property.id) {
      isSelected = true;
    }

    return (
      <PropertyMarker
        disableActions={saleType == "sold" && getSubscriptionPlan() === null}
        page={page}
        onClusterClick={onClusterClick}
        cluster={cluster}
        key={`marker-${cluster.properties.cluster ? cluster.id : property.id}`}
        map={map}
        position={{ lat: latitude, lng: longitude }}
        property={property}
        clusterProperties={clusterProperties}
        isSelected={isSelected}
        selectMarker={() => setSelectedProperty(property ? property.id : null)}
      />
    );
  }, [clusters.supercluster, selectedProperty, onClusterClick, page, saleType, setSelectedProperty]);

  const renderHoverInfo = () => {
    const { hoveredArea, hideBounds } = props.mapReducer;

    if (!hoveredArea || hideBounds) {
      return null;
    }

    return (
      <div id="hoverInfo">
        <span>{hoveredArea.name}</span>
      </div>
    );
  };

  // Memoize the map options to prevent recreating the object on every render
  const mapOptions = useMemo(() => {
    return {
      styles: page === "marketInsights" ? marketInsightMapStyles : mapStyles,
      disableDefaultUI: true,
      zoomControl: false,
      gestureHandling: "greedy",
    };
  }, [page]);

  // Memoize the drawing manager options
  const drawingManagerOptions = useMemo(() => {
    return {
      map: map,
      drawingControl: false,
      polygonOptions: {
        draggable: false,
        editable: true,
        fillColor: polygonColours.primary,
        strokeColor: polygonColours.primary,
        fillOpacity: polygonColours.fillOpacity,
        strokeWeight: 4,
      },
    };
  }, [map]);

  return (
    <div style={{ position: 'relative' }}>
      {overlay && showZoomOverlay && (
        <div 
          style={{
            ...mapConfig[page].style[device],
            width: `calc(100% - 88px - ${sidePanelWidth}px)`,
            position: 'absolute',
            zIndex: 2,
            pointerEvents: overlayClickable ? 'auto' : 'none',
            background: overlayBackground || 'rgba(0, 0, 0, 0.5)',
          }}
        >
          {overlay}
        </div>
      )}
      <GoogleMap
        mapContainerStyle={mapConfig[page].style[device]}
        center={mapConfig[page].center[device]}
        zoom={mapConfig[page].zoom[device]}
        onIdle={onDragEnd}
        onBoundsChanged={onMapBoundsChange}
        onZoomChanged={onZoomLevelChange}
        onLoad={(map) => {
          // force satellite view for cma mode
          if (
            props.user.userData.preferences.mapType !== "roadmap" ||
            page === "cma" ||
            page === "myProperties" ||
            page === "catastro"
          ) {
            map.setMapTypeId("hybrid");
          }

          // if (page === "marketInsights") {
          //   map.setMapTypeId("roadmap");
          // }

          setMap(map);
          map.addListener("click", onMapClick);
          window.googleMapsInstance = map;
          shouldDrawGeographicBounds();

          // if url params contain a property id then dont center map
          // on user location
          // also dont center map on user location if loading a search
          const urlParams = new URLSearchParams(window.location.search);
          const propertyId = urlParams.get("id");

          // MARK: - Commented out to prevent annoying geolocation request
          // if (!propertyId && !props.urlParams.collectionId) {
          //   setDefaultCenter(map, cmaMode);
          // }
        }}
        tilt={page === "cma" || page === "myProperties" || page === "catastro" ? 0 : null}
        options={mapOptions}
      >
        <DrawingManager
          drawingMode={drawingMode}
          onPolygonComplete={(polygon) =>
            handlePolygonComplete(polygon, true, true)
          }
          onLoad={(drawingManager) => {
            window.googleMapsDrawingManager = drawingManager;
            setDrawingManager(drawingManager);
          }}
          options={drawingManagerOptions}
        />
        {clusters.clusters.map((cluster, index) => renderMarker(cluster, map, index))}
        {renderHoverInfo()}
      </GoogleMap>
    </div>
  );
};

export default connect((state) => ({
  filters: state.filters.filters,
  invisibleFilters: state.filters.invisibleFilters,
  mapReducer: state.map,
  user: state.user,
  catastro: state.catastro,
  saleType: state.filters.saleType,
}))(withRouter(GoogleMapWrapper));
