import {
  addSearchToCollection,
  removePropertyFromCollection,
  removeSearchFromCollection,
  saveCollection,
} from "actions/collectionActions";
import { i18n } from "i18n/localisation";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import leftChevronIcon from "../../assets/core/left_chevron.svg";
import { CollectionsContext } from "../../context/CollectionsContext";
import Dashboard from "../Dashboard";
import Button from "../ui/Button/Button";
import ObjectDeletionModal from "./modals/ObjectDeletionModal";
import PropertyTile from "./PropertyTile";
import SearchTile from "./SearchTile";
import { getCollectionById, isEmpty } from "./utils";

const CollectionDetailsPage = (props) => {
  const { collectionsId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openDeleteModal, openMetaModal, setEditingObjectTitle } =
    useContext(CollectionsContext);
  const [isObjectDeleteModalOpen, setIsObjectDeleteModalOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);
  const [collection, setCollection] = useState(null);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [tabTitle, setTabTitle] = useState(null);

  useEffect(() => {
    const collection = getCollectionById(
      props.collections.collections,
      collectionsId,
    );
    setCollection(collection);
    setCollectionTitle(collection ? collection.title : "");
    setTabTitle(collection ? collection.title : i18n("Collection"));
  }, [collectionsId, props.collections.collections]);

  if (!collection) {
    return null;
  }

  const objects = [].concat(collection.properties, collection.searches);

  const handleDeleteObject = () => {
    if (objectToDelete.objectType == "search") {
      dispatch(removeSearchFromCollection(collection, objectToDelete));
      toast(i18n("Search deleted successfully"));
    } else {
      dispatch(removePropertyFromCollection(collection, objectToDelete.id));
      toast(i18n("Property deleted successfully"));
    }

    setIsObjectDeleteModalOpen(false);
  };

  const openDeleteObjectModal = (object) => {
    setObjectToDelete(object);
    setIsObjectDeleteModalOpen(true);
  };

  const onDuplicateSearch = (search) => {
    let newSearch = { ...search };
    newSearch.id = uuidv4();
    dispatch(addSearchToCollection(collection, newSearch));
    toast(i18n("Search has been duplicated"), { duration: 2000 });
  };

  const onEditTitle = (search) => {
    let searchWithParentId = { ...search };
    searchWithParentId.parentId = collection.id;
    setEditingObjectTitle(searchWithParentId);
  };

  const onEditCollectionTitle = () => {
    let collectionToUpdate = { ...collection };
    collectionToUpdate.title = collectionTitle;
    dispatch(saveCollection(collectionToUpdate));
    toast(i18n("Collection has been updated"), { duration: 2000 });
  };

  const onTitleKeyDown = (e) => {
    if (e.key == "Enter") {
      e.target.blur();
      onEditCollectionTitle();
    }
  };

  const renderObjects = () => {
    const sortedSearches = collection.searches.slice().sort((a, b) => {
      return a.creationDate - b.creationDate;
    });

    return (
      <>
        {collection.searches.length > 0 && (
          <div className="mb-8">
            <h3 className="mb-3 text-2xl font-semibold">{i18n("Searches")}</h3>
            <div className="flex max-w-screen-xl flex-wrap">
              {sortedSearches.map((search) => (
                <SearchTile
                  key={search.id}
                  className="mb-8 mr-6"
                  search={search}
                  collection={collection}
                  onClick={() =>
                    window.open(`/search/${collection.id}/${search.id}`)
                  }
                  onEditTitle={() => onEditTitle(search)}
                  onDuplicateSearch={() => onDuplicateSearch(search)}
                  onDeleteSearch={() => openDeleteObjectModal(search)}
                />
              ))}
            </div>
          </div>
        )}
        {collection.properties.length > 0 && (
          <div className="mb-8">
            <h3 className="mb-3 text-2xl font-semibold">
              {i18n("Properties")}
            </h3>
            <div className="flex max-w-screen-xl flex-wrap">
              {collection.properties.map((property) => (
                <PropertyTile
                  key={property.id}
                  className="mb-8 mr-6"
                  property={property}
                  onDeleteProperty={() => openDeleteObjectModal(property)}
                  onClick={() =>
                    window.open(
                      `/${property.saleType == "rent" ? "rental" : "property"}/${property.id}`,
                      "_blank",
                    )
                  }
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Dashboard>
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      <div className="collections-details flex min-h-screen w-full justify-center bg-gray-100 py-16 pl-36">
        <div className="collections-details_content w-full">
          <div className="-mx-3 mb-4 max-w-10">
            <img
              src={leftChevronIcon}
              alt="left chevron"
              className="w-full transform cursor-pointer p-2 transition-transform duration-200 hover:scale-110"
              onClick={() => navigate("/collections")}
            />
          </div>
          <input
            value={collectionTitle}
            onChange={(e) => setCollectionTitle(e.target.value)}
            onBlur={onEditCollectionTitle}
            onKeyDown={onTitleKeyDown}
            className="mb-8 w-full bg-transparent text-3xl font-bold outline-none"
          />
          {isEmpty(collection.metadata) ? (
            <div className="mb-8">
              <Button
                variant="secondary"
                onClick={() => openMetaModal(collection)}
                icon="plus"
              >
                {i18n("Add notes")}
              </Button>
              <Button
                className="ml-3"
                variant="secondary"
                onClick={() => {
                  openDeleteModal(collection);
                }}
                icon="delete"
              ></Button>
            </div>
          ) : (
            <div className="mb-4 flex flex-row">
              <div className="mb-4">
                <span className="mb-2 text-sm text-gray-500">
                  {i18n("Area")}:{" "}
                </span>
                <span className="mb-2 text-sm text-gray-800">
                  {collection.metadata.area}
                </span>
                <span className="mx-4 text-sm text-gray-500">|</span>
                <span className="mb-2 text-sm text-gray-500">
                  {i18n("Bedrooms")}:{" "}
                </span>
                <span className="mb-2 text-sm text-gray-500">
                  {collection.metadata.bedrooms}
                </span>
                <span className="mx-4 text-sm text-gray-500">|</span>
                <span className="mb-2 text-sm text-gray-500">
                  {i18n("Bathrooms")}:{" "}
                </span>
                <span className="mb-2 text-sm text-gray-500">
                  {collection.metadata.bathrooms}
                </span>
                <span className="mx-4 text-sm text-gray-500">|</span>
                <span className="mb-2 text-sm text-gray-500">
                  {i18n("Budget")}:{" "}
                </span>
                <span className="mb-2 text-sm text-gray-500">
                  {collection.metadata.budget}
                </span>
                <div className="mt-2">
                  <span className="text-sm text-gray-500">
                    {i18n("Notes")}:{" "}
                  </span>
                  <span className="max-w-prose text-sm text-gray-800">
                    {collection.metadata.notes}
                  </span>
                </div>
              </div>
              <div className="ml-auto">
                <Button
                  variant="secondary"
                  onClick={() => {
                    openMetaModal(collection);
                  }}
                  icon="edit"
                >
                  {i18n("Edit notes")}
                </Button>
                <Button
                  className="ml-3"
                  variant="secondary"
                  onClick={() => {
                    openDeleteModal(collection);
                  }}
                  icon="delete"
                ></Button>
              </div>
            </div>
          )}
          {objects.length === 0 ? (
            <>
              <h3 className="mb-3 text-2xl font-semibold">
                {i18n("Add properties and searches to your collection")}
              </h3>

              <div className="mb-8 max-w-prose">
                <p className="text-base">
                  {i18n(
                    "As you search, you can click on the heart icon to save a property or ‘Save search’ to save your search query to a collection.",
                  )}
                </p>
              </div>

              <div className="mb-8">
                <Button
                  variant="primary"
                  onClick={() => navigate("/")}
                  icon="search-white"
                >
                  {i18n("Search properties")}
                </Button>
              </div>
            </>
          ) : (
            renderObjects()
          )}
        </div>
      </div>
      <ObjectDeletionModal
        isOpen={isObjectDeleteModalOpen}
        object={objectToDelete}
        onSubmit={handleDeleteObject}
        closeModal={() => setIsObjectDeleteModalOpen(false)}
      />
    </Dashboard>
  );
};

export default connect((state) => {
  return {
    collections: state.collections,
  };
})(CollectionDetailsPage);
