import { pdf } from "@react-pdf/renderer";
import Dropdown from "components/core/Dropdown";
import { GOOGLE_MAPS_API_KEY, SUPPORTED_LANGUAGES } from "config/constants";
import { i18n } from "i18n/localisation";
import { sendAnalyticsEvent } from "lib/analytics";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getDataUrl,
  roundToNearest100,
  roundToNearest1000,
  roundToNearest50,
  unwatermarkImg,
} from "utils/helpers";
import Button from "../ui/Button/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { CmaDocument } from "./CmaPDF";
import ValuationSlider from "./CmaValuationSlider";
import CmaPDFProvider from "./CmaPDF";
import {
  fetchPropertyByUrlRequest,
  fetchPropertyRequest,
} from "api/properties";
import Checkbox from "../input/Checkbox";

const CmaModal = ({
  isValutionModalOpen,
  valuationValue,
  property,
  closeModal,
  district,
  municipality,
  province,
  studyAreas,
  rooms,
  bathrooms,
  features,
  agencyDetails,
  personalDetails,
  user,
  saleType,
  selectedProperties,
  properties,
  selectedDistricts,
  conditions,
  ...props
}) => {
  let roundedOriginalValuationValue = roundToNearest1000(valuationValue);

  if (saleType == "rent") {
    roundedOriginalValuationValue = roundToNearest50(valuationValue);
  }

  const [valuation, setValuation] = useState(roundedOriginalValuationValue);
  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [customImage, setCustomImage] = useState(null);
  const [renderCount, setRenderCount] = useState(0);
  const [language, setLanguage] = useState(SUPPORTED_LANGUAGES[0]);
  const [address, setAddress] = useState(property.address);
  const [pdfSections, setPdfSections] = useState({
    statistics: true,
    marketAnalysis: true,
    comparables: true,
    salesInfo: true,
  });

  // reset the valuation to the original value when the ref changes
  useEffect(() => {
    setValuation(roundedOriginalValuationValue);
  }, [valuationValue]);

  let upperRange = Math.round(roundedOriginalValuationValue * 1.05);
  let lowerRange = Math.round(roundedOriginalValuationValue * 0.95);
  let currentValuation = roundedOriginalValuationValue;

  if (valuation) {
    upperRange = Math.round(valuation * 1.05);
    lowerRange = Math.round(valuation * 0.95);
    currentValuation = valuation;
  }

  let agencyLogoUrl = "https://dashboard.irealty.app/logo.png";
  if (agencyDetails && agencyDetails.picture) {
    agencyLogoUrl = agencyDetails.picture;
  }

  // Resolve property image URL
  let propertyImageUrl = null;
  const isInternalValuation = !property.isCatastro;

  if (!property.images || property.images.length === 0) {
    const apiKey = GOOGLE_MAPS_API_KEY;
    const latitude = property.latitude;
    const longitude = property.longitude;
    const zoom = 20;
    const size = "1600x400";
    const mapType = "hybrid";
    const scale = 2;
    propertyImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${size}&maptype=${mapType}&key=${apiKey}&scale=${scale}`;
  } else if (property.images && property.images.length > 0) {
    propertyImageUrl = unwatermarkImg(property.images[0].url);
  }

  const location = `${property.latitude},${property.longitude}`;
  const locationUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=15&size=1600x400&key=${GOOGLE_MAPS_API_KEY}&scale=2&markers=color:0xef5533%7C${location}`;

  const onCustomImageInputChange = async (e) => {
    const file = e.target.files[0];
    try {
      const dataUrl = await getDataUrl(file);
      setCustomImage(dataUrl);
      setRenderCount(renderCount + 1);
    } catch (error) {
      setCustomImage(null);
      console.error("Error reading file:", error);
    }
  };

  const [pdfKey, setPdfKey] = useState(0);
  const [loading, setLoading] = useState(false);
  // State to control rendering of CmaPDFProvider
  const [showMergedPdf, setShowMergedPdf] = useState(false);

  const fetchAllPropertyDetails = async () => {
    const properties = selectedProperties.filter(
      (property) =>
        property.saleType === "sale" || property.saleType === "rent",
    );
    for (const property of properties) {
      const details = await fetchPropertyRequest(
        property.id,
        property.saleType,
      );
      property.energyCertificate = details.property.energyCertificate;
      property.yearConstructed = details.property.yearConstructed;
    }
  };

  const openPdf = () => {
    // Fetch property details for all sale properties
    // fetch all property details
    fetchAllPropertyDetails();
    setLoading(true);
    setShowMergedPdf(false);
    setPdfKey((prevKey) => prevKey + 1);
    setShowMergedPdf(true);

    sendAnalyticsEvent("CMA Modal Click", {
      type: "generate pdf",
      details: property,
    });

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  };

  const isCheckboxChecked = (label) => {
    switch (label) {
      case i18n("Statistics"):
        return pdfSections.statistics;
      case i18n("Market Analysis"):
        return pdfSections.marketAnalysis;
      case i18n("Comparables"):
        return pdfSections.comparables;
      case i18n("Sales Info Pages"):
        return pdfSections.salesInfo;
      default:
        return false;
    }
  };

  const handleCheckboxChange = (label) => {
    switch (label) {
      case i18n("Statistics"):
        setPdfSections((prev) => ({ ...prev, statistics: !prev.statistics }));
        break;
      case i18n("Market Analysis"):
        setPdfSections((prev) => ({
          ...prev,
          marketAnalysis: !prev.marketAnalysis,
        }));
        break;
      case i18n("Comparables"):
        setPdfSections((prev) => ({ ...prev, comparables: !prev.comparables }));
        break;
      case i18n("Sales Info Pages"):
        setPdfSections((prev) => ({ ...prev, salesInfo: !prev.salesInfo }));
        break;
    }
  };

  return (
    <Dialog
      open={isValutionModalOpen}
      onOpenChange={(open) => {
        closeModal();
        setShowMergedPdf(false); // Reset merged PDF link when modal closes
      }}
    >
      <DialogContent className="sm:max-w-[425px] h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>{i18n("Valuation Report")}</DialogTitle>
        </DialogHeader>
        <div className="flex-1 overflow-y-auto">
          <div className="cma-model_content">
            <span className="cma-model_content_header pt-6">
              {i18n("Confirm client details to add to your valuation report.")}
            </span>
            <div className="divider" />
            <ValuationSlider
              saleType={saleType}
              step={saleType == "rent" ? 50 : 1000}
              originalValuation={roundedOriginalValuationValue}
              onValuationChange={(value) => setValuation(value)}
            />
            <div className="divider" />
            <div className="cma-model_content_form">
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Client first name")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setClientName(e.target.value)}
                    placeholder={i18n("Enter customer name") + "..."}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Client surname")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setClientSurname(e.target.value)}
                    placeholder={i18n("Enter customer surname") + "..."}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
              </div>
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Property name")}*
                  </p>
                  <input
                    type="text"
                    onChange={(e) => setPropertyName(e.target.value)}
                    placeholder={i18n("Enter property name") + "..."}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Language")}
                  </p>
                  <Dropdown
                    className="standard-dropdown"
                    items={SUPPORTED_LANGUAGES.map((lang) => ({
                      label: lang.name,
                      value: lang.code,
                      onSelect: () => {
                        setLanguage(lang);
                      },
                    }))}
                    label={language.name}
                  />
                </div>
              </div>
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Address")}*
                  </p>
                  <textarea
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder={i18n("Enter address") + "..."}
                    className="w-full rounded-lg border border-gray-300 p-2 h-[100px]"
                    defaultValue={property.address}
                  />
                </div>
              </div>
              <div className="cma-model_content_form_row">
                <div className="cma-model_content_form_row_col">
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {i18n("Property image")}{" "}
                    {property.isCatastro
                      ? i18n("(will use satellite image if this is empty)")
                      : i18n("(will use default image if this is empty)")}
                  </p>
                  <input
                    type="file"
                    onChange={(e) => onCustomImageInputChange(e)}
                    className="w-full rounded-lg border border-gray-300 p-2"
                  />
                </div>
              </div>
              {saleType !== "rent" && (
                <div className="cma-model_content_form_row mt-4 flex-wrap">
                  <p
                    className="text-sm font-medium text-gray-700"
                    style={{ width: "100%" }}
                  >
                    {i18n("PDF Sections")}
                  </p>
                  <div className="flex flex-col gap-2">
                    <div
                      className="cma-form_selected-flow_col_checkbox"
                      onClick={() => handleCheckboxChange(i18n("Statistics"))}
                    >
                      <Checkbox
                        label={i18n("Statistics")}
                        checked={isCheckboxChecked(i18n("Statistics"))}
                      />
                    </div>
                    <div
                      className="cma-form_selected-flow_col_checkbox"
                      onClick={() =>
                        handleCheckboxChange(i18n("Market Analysis"))
                      }
                    >
                      <Checkbox
                        label={i18n("Market Analysis")}
                        checked={isCheckboxChecked(i18n("Market Analysis"))}
                      />
                    </div>
                    <div
                      className="cma-form_selected-flow_col_checkbox"
                      onClick={() => handleCheckboxChange(i18n("Comparables"))}
                    >
                      <Checkbox
                        label={i18n("Comparables")}
                        checked={isCheckboxChecked(i18n("Comparables"))}
                      />
                    </div>
                    {saleType === "sale" && (
                      <div
                        className="cma-form_selected-flow_col_checkbox"
                        onClick={() =>
                          handleCheckboxChange(i18n("Sales Info Pages"))
                        }
                      >
                        <Checkbox
                          label={i18n("Sales Info Pages")}
                          checked={isCheckboxChecked(i18n("Sales Info Pages"))}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex w-full justify-end">
            <Button loading={loading} onClick={openPdf}>
              {i18n("Generate PDF")}
            </Button>
          </div>
        </DialogFooter>
        {showMergedPdf && (
          <CmaPDFProvider
            key={pdfKey}
            isInternalValuation={isInternalValuation}
            refcat={property.ref}
            clientName={clientName}
            clientSurname={clientSurname}
            propertyName={propertyName}
            language={language}
            property={property}
            address={address}
            valuationValue={currentValuation}
            propertyImageUrl={propertyImageUrl}
            district={district}
            municipality={municipality}
            province={province}
            agencyLogoUrl={agencyLogoUrl}
            upperRange={upperRange}
            lowerRange={lowerRange}
            studyAreas={studyAreas}
            rooms={rooms}
            bathrooms={bathrooms}
            features={features}
            locationUrl={locationUrl}
            personalDetails={{ ...personalDetails, email: user.email }}
            agencyDetails={agencyDetails}
            lang={language.code}
            saleType={saleType}
            customImage={customImage}
            selectedProperties={selectedProperties}
            properties={properties}
            selectedDistricts={selectedDistricts}
            pdfSections={pdfSections}
            conditions={conditions}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setIsPDFGenerated: require("../../actions/cmaActions").setIsPDFGenerated,
    },
    dispatch,
  );
};

export default connect(
  (state) => ({
    agencyDetails: state.user.userData.agencyDetails,
    personalDetails: state.user.userData.personalDetails,
    user: state.auth.user,
    saleType: state.filters.saleType,
  }),
  mapDispatchToProps,
)(CmaModal);
