import React, { useEffect, useState } from "react";
import Button from "components/ui/Button/Button";
import Dropdown from "components/core/Dropdown";
import { i18n } from "i18n/localisation";
import { Dialog } from "components/ui/dialog";
import { connect } from "react-redux";
import CustomConditionsModal from "./CustomConditionsModal";
import { deleteValuationMetaCondition } from "actions/metaValuationConditionsActions";
import { toast } from "sonner";
import { deleteValuationCondition } from "api/valuationConditions";
import { saveUserData } from "actions/userActions";
import Checkbox from "components/input/Checkbox";

const ValuationContent = ({ dispatch, conditions, userData }) => {
  const [buildingType, setBuildingType] = useState(null);
  const [typology, setTypology] = useState(null);
  const [showCustomConditionModal, setShowCustomConditionModal] =
    useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const currentConditions = conditions.filter(
    (condition) =>
      condition.typology === typology &&
      condition.buildingType === buildingType,
  );

  // Add helper function to auto select typology
  const autoSelectTypology = (type) => {
    const items = getTypologyItems(type);
    if (items.length === 1) {
      setTypology(items[0].value);
    }
  };

  useEffect(() => {
    if (
      userData.valuationConditionsPrefs.flat ||
      userData.valuationConditionsPrefs.house
    ) {
      setBuildingType("property");

      const flatConditionsCount = conditions.filter(
        (condition) => condition.typology === "flat",
      ).length;
      const houseConditionsCount = conditions.filter(
        (condition) => condition.typology === "house",
      ).length;

      if (houseConditionsCount > flatConditionsCount) {
        setTypology("house");
      } else {
        setTypology("flat");
      }
    } else if (userData.valuationConditionsPrefs.commercial) {
      setBuildingType("commercial");
      setTypology("commercial");
    } else if (userData.valuationConditionsPrefs.land) {
      setBuildingType("land");
      setTypology("land");
    }
  }, []);

  // Add effect to handle typology auto-selection when building type changes
  useEffect(() => {
    if (buildingType) {
      autoSelectTypology(buildingType);
    }
  }, [buildingType]);

  function getBuildingTypeLabel(buildingType) {
    switch (buildingType) {
      case "property":
        return i18n("Residential");
      case "commercial":
        return i18n("Commercial");
      case "land":
        return i18n("Land");
      default:
        return i18n("Select");
    }
  }

  function getTypologyItems(buildingType) {
    switch (buildingType) {
      case "property":
        return [
          {
            label: i18n("Apartment"),
            value: "flat",
            onSelect: () => {
              setTypology("flat");
            },
          },
          {
            label: i18n("House"),
            value: "house",
            onSelect: () => {
              setTypology("house");
            },
          },
        ];
      case "commercial":
        return [
          {
            label: i18n("Commercial"),
            value: "commercial",
            onSelect: () => {
              setTypology("commercial");
            },
          },
        ];
      case "land":
        return [
          {
            label: i18n("Land"),
            value: "land",
            onSelect: () => {
              setTypology("land");
            },
          },
        ];
      default:
        return [];
    }
  }

  function getTypologyLabel(typology) {
    switch (typology) {
      case "flat":
        return i18n("Apartment");
      case "house":
        return i18n("House");
      case "commercial":
        return i18n("Commercial");
      case "land":
        return i18n("Land");
      default:
        return i18n("Select typology");
    }
  }

  return (
    <div className="settings_tabs_content">
      <div className="settings_tabs_content_header">
        <div className="settings_tabs_content_header_row">
          <div className="settings_tabs_content_header_col">
            <h2>{i18n("Valuation Conditions")}</h2>
            <span>
              {i18n(
                "Here you can create custom conditions that will be appended to the valuation process for your team",
              )}
              .
            </span>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="settings_tabs_content_body">
        <div className="settings_tabs_content_body_row">
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
            <div>
              <span className="setting-heading">{i18n("Property type")}</span>
              <Dropdown
                className="standard-dropdown w-full"
                items={[
                  {
                    label: i18n("Residential"),
                    value: "residential",
                    onSelect: () => {
                      setBuildingType("property");
                      setTypology(null);
                    },
                  },
                  {
                    label: i18n("Commercial"),
                    value: "commercial",
                    onSelect: () => {
                      setBuildingType("commercial");
                    },
                  },
                  {
                    label: i18n("Land"),
                    value: "land",
                    onSelect: () => {
                      setBuildingType("land");
                    },
                  },
                ]}
                label={getBuildingTypeLabel(buildingType)}
              />
            </div>

            <div className={!buildingType && "invisible"}>
              <span className="setting-heading">{i18n("Typology")}</span>
              <Dropdown
                className="standard-dropdown w-full"
                items={getTypologyItems(buildingType)}
                label={getTypologyLabel(typology)}
                disabled={buildingType !== "property"}
              />
            </div>

            <div className={!typology && "invisible"}>
              <span className="setting-heading">{i18n("Settings type")}</span>
              <Dropdown
                className="standard-dropdown w-full"
                items={[
                  {
                    label: i18n("Default Settings"),
                    value: "default",
                    onSelect: () => {
                      const pref = {
                        ...userData.valuationConditionsPrefs,
                        [typology]: false,
                      };

                      dispatch(
                        saveUserData({
                          valuationConditionsPrefs: pref,
                        }),
                      );
                    },
                  },
                  {
                    label: i18n("Custom Conditions"),
                    value: "custom",
                    onSelect: () => {
                      const pref = {
                        ...userData.valuationConditionsPrefs,
                        [typology]: true,
                      };

                      dispatch(
                        saveUserData({
                          valuationConditionsPrefs: pref,
                        }),
                      );
                    },
                  },
                ]}
                label={
                  userData.valuationConditionsPrefs[typology]
                    ? i18n("Custom Conditions")
                    : i18n("Default Settings")
                }
                disabled={!typology}
              />
            </div>
          </div>
        </div>
      </div>
      {userData.valuationConditionsPrefs[typology] && (
        <>
          <div className="settings_tabs_content_body_row mt-4">
            <div className="settings_tabs_content_body_col">
              <h3 className="mb-4 text-lg font-medium">
                {i18n("Custom Conditions")}
              </h3>
              <div className="space-y-4">
                <div
                  className="mb-4"
                  onClick={() => {
                    let pref = {
                      ...userData.valuationConditionsPrefs,
                    };

                    if (!pref.includeDefaultConditions) {
                      pref.includeDefaultConditions = [];
                    }

                    if (pref.includeDefaultConditions.includes(typology)) {
                      pref.includeDefaultConditions =
                        pref.includeDefaultConditions.filter(
                          (condition) => condition !== typology,
                        );
                    } else {
                      pref.includeDefaultConditions = [
                        ...pref.includeDefaultConditions,
                        typology,
                      ];
                    }

                    dispatch(
                      saveUserData({
                        valuationConditionsPrefs: pref,
                      }),
                    );
                  }}
                >
                  <Checkbox
                    label={i18n("Include default conditions")}
                    checked={(
                      userData.valuationConditionsPrefs
                        .includeDefaultConditions ?? []
                    ).includes(typology)}
                  />
                </div>
                {currentConditions.map((condition) => (
                  <div key={condition.id} className="rounded-lg border p-4">
                    <div className="flex items-start justify-between">
                      <div>
                        <h4 className="font-medium">{condition.label}</h4>
                        {condition.type === "yesno" ? (
                          <div className="mt-2 text-sm">
                            <div>
                              {i18n("Yes")}: {condition.yesPercentage * 100}%
                            </div>
                            <div>
                              {i18n("No")}: {condition.noPercentage * 100}%
                            </div>
                          </div>
                        ) : (
                          <div className="mt-2 text-sm">
                            {condition.dropdownItems.map((item, index) => (
                              <div key={index}>
                                {item.label}: {item.value * 100}%
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="flex gap-2">
                        <Button
                          variant="text"
                          onClick={() => {
                            setSelectedCondition(condition);
                            setShowCustomConditionModal(true);
                          }}
                        >
                          {i18n("Edit")}
                        </Button>
                        <Button
                          variant="text"
                          className="text-red-600"
                          onClick={() => {
                            deleteValuationCondition(condition.id).then(() => {
                              toast.success(
                                i18n("Condition deleted successfully"),
                              );
                            });
                            dispatch(
                              deleteValuationMetaCondition({
                                id: condition.id,
                              }),
                            );
                          }}
                        >
                          {i18n("Delete")}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="settings_tabs_content_body_row mt-4">
            <div className="settings_tabs_content_body_col">
              <Button
                style={{ width: "fit-content" }}
                onClick={() => setShowCustomConditionModal(true)}
                variant="secondary"
              >
                {i18n("Create Custom Condition")}
              </Button>
            </div>
          </div>
        </>
      )}

      <CustomConditionsModal
        open={showCustomConditionModal}
        closeModal={() => {
          setShowCustomConditionModal(false);
          setSelectedCondition(null);
        }}
        onHandleComplete={() => {
          setShowCustomConditionModal(false);
          setSelectedCondition(null);
        }}
        typology={typology}
        buildingType={buildingType}
        condition={selectedCondition}
      />
    </div>
  );
};

export default connect((state) => ({
  conditions: state.metaValuationConditions.conditions,
  userData: state.user.userData,
}))(ValuationContent);
