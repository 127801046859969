import { connect } from "react-redux";
import Dashboard from "../Dashboard";
import PropertyMap from "../map/PropertyMap";
import AgencySidePanel from "./AgencySidePanel";
import { userHasRole } from "utils/userHelpers";
import SpinWheelLoaderAnimation from "components/animations/SpinWheelLoaderAnimation";
import { Helmet } from "react-helmet";
import { i18n } from "i18n/localisation";

function AgencyDirectory({ userDataFetched }) {
  if (userDataFetched && !userHasRole("agency_directory")) {
    window.location.href = "/";
  }

  return (
    <Dashboard>
      <Helmet>
        <title>{i18n("Agency Directory")}</title>
      </Helmet>
      <PropertyMap
        page="agencyDirectory"
        includeSearch
        hideSaleTypeToggle
        disableDropdown
      />
      <AgencySidePanel />
    </Dashboard>
  );
}

export default connect(state => ({
  userDataFetched: state.user.userDataFetched,
}))(AgencyDirectory);